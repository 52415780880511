import { AxiosPromise } from 'axios';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

import { User, Tenant, UpdateUserData, UserFeedback } from '../utils/interfaces';
import { FilterParams } from '../utils/types';

export type ProfileResponse = User & {
  Tenant: Tenant;
};

export const getProfile = async (): Promise<ProfileResponse> => {
  const { data } = await axiosV2.get<{ data: ProfileResponse }>('/self');
  return data.data;
};

export const postUserFeedback = (feedback: UserFeedback): Promise<ProfileResponse> => axios.post('/self/feedback/cash', feedback);

export const updateProfile = async (user: UpdateUserData, id: string): Promise<boolean> => {
  const { status } = await axios.patch<{ data: ProfileResponse }>(`/user/${id}`, user);
  return status === 200;
};

export const getUsers = async (params?: FilterParams): Promise<AxiosPromise> => {
  const response = await axiosV2.get('/users', { params });
  return response;
};
export const getUser = async (id: string): Promise<AxiosPromise> => {
  const response = await axiosV2.get(`/users/${id}`);
  return response.data;
};
export const userAdd = async (params: Record<string, unknown>): Promise<AxiosPromise> => {
  const response = await axiosV2.post(`/users`, params);
  return response;
};
export const userUpdate = async (id: string, params: Record<string, unknown>): Promise<AxiosPromise> => {
  const response = await axiosV2.patch(`/users/${id}`, params);
  return response;
};
export const userDelete = async (id: string): Promise<AxiosPromise> => {
  const response = await axiosV2.delete(`/users/${id}`);
  return response;
};

export const userEnabled = async (id: string): Promise<AxiosPromise> => {
  const response = await axiosV2.patch(`/users/${id}/enable`);
  return response;
};
export default { getProfile, updateProfile, getUsers, getUser, userAdd, userDelete, userEnabled };
