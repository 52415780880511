import React from 'react';
import { NubceoCashIsoSVG } from '../../assets/svgComponents';
import variables from '../../scss/_exports.module.scss';
import { Subtitle } from '../textComponents';

const LoaderScreen: React.FC<{ className?: string; label?: string }> = ({ className, label }) => (
  <div className={`position-relative loader-container ${className}`}>
    <div className="loader-wrapper">
      <NubceoCashIsoSVG className="loader-cloud" />
      <span className="mt-2">
        <Subtitle size="sm" weight="bold" color={variables.colorBlue}>
          {label ?? 'Cargando'}
        </Subtitle>
      </span>
    </div>
  </div>
);

export default LoaderScreen;
