import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormButtonComponent } from '../../components';
import { useAppSelector } from '../../redux/hooks';
import { config } from '../../utils/constants';

const InfoFiservFTP: FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userProfile);

  const msg = `Hola! Necesito ayuda.`;

  const getWhatsAppUrl = (): string => {
    const phone = user?.Tenant.countryCode === 'AR' ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy;
    return `https://wa.me/${phone}?text=${msg}`;
  };

  const handleClick = (): void => {
    window.open(getWhatsAppUrl(), '_blank', 'noreferrer');
  };
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          background:
            'url(https://ci3.googleusercontent.com/meips/ADKq_NahPYQC99d_nI5aAx3MuEl2EtvqiBplMOGPc5t5BVz4eeXNza9I3jivZqV_gDH-2vsK3yxdsfpJXVKS41h_IJDEu_WZrBmyLnVA5rMbpHh1l0N72zThRnq6bUGIlyLZPgMUR3jZte_VXU9Tob2H8iN-ls-IJQIMDOs=s0-d-e1-ft#https://mcusercontent.com/b2cec28f6a90903884374e377/images/83a55e7a-94b4-0ca3-2b77-b3f77f82c7ed.png) 50% 50%/cover no-repeat rgb(12,107,244)',
          borderTop: '0px',
          borderBottom: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <div
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            background: 'none 50% 50%/cover no-repeat transparent',
            borderTop: '0px',
            borderBottom: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <div style={{ padding: '9px' }}>
            <div style={{ padding: '0px 9px', textAlign: 'center' }}>
              <img
                alt=""
                src="https://ci3.googleusercontent.com/meips/ADKq_NYHlmesf4yH0z83YqgH548WHZX3_ivcQX67TVcYc1_VYBbHJ43WFt5z6dxAcQ4EqIePZ4MQQqkc9XlJ8g-4DDio0FebisY9JXU8ToXcY1JPyb4NqrI-g3nf-Gy6ek3D0MhERFl-4p2j9tsdMeGK6DfF9EVqczpd-GA=s0-d-e1-ft#https://mcusercontent.com/b2cec28f6a90903884374e377/images/e38cfea1-4cbd-368b-b81b-362da63c43bd.png"
                width="564"
                style={{
                  outline: 'none',
                  maxWidth: '1580px',
                  paddingBottom: '0px',
                  verticalAlign: 'bottom',
                  border: '0px',
                  height: 'auto',
                  display: 'inline',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'left', marginTop: '20px', padding: '60px' }}>
        <strong>
          <span style={{ color: 'rgb(0,0,0)' }}>Estimados clientes de</span>
        </strong>
        <span style={{ color: 'rgb(0,0,255)' }}>
          <strong> nubceo</strong>
        </span>
        <span style={{ color: 'rgb(0,0,0)' }}>
          :<br />
          <br />
          Nos comunicamos con ustedes para actualizarlos sobre las acciones que estamos tomando derivado del cambio de portal de Fiserv{' '}
          <strong>durante el mes de agosto de 2024</strong>. <br />
          <br />
          El nuevo portal implementa múltiples controles a través de diversos mecanismos (captchas, entre otros) orientados a impedir la
          automatización del consumo de información a través del portal.
          <br />
          <br />
          Por lo tanto queremos comunicarles que el mecanismo de <strong>vinculación de nubceo con Fiserv</strong> será a través de su
          servicio de FTP <strong>durante el mes de agosto de 2024.</strong> Este servicio tiene múltiples ventajas respecto al acceso a
          través de portal:
          <br />
          <br />
          1) Es el recomendado por Fiserv para el consumo automatizado de información.
          <br />
          2) Es mucho más estable que el portal.
          <br />
          3) Permite el procesamiento de volúmenes altos de información
          <br />
          <br />
          Es por ello, que les recordamos soliciten a la procesadora los accesos a dicha conexión{' '}
          <strong>durate el mes de agosto de 2024</strong>. Una vez que les otorguen USUARIO, CONTRASEÑA, URL y ARCHIVO PPK,{' '}
          <strong>solicitamos nos los envíen</strong> para poder establecer la nueva vinculación en <strong>nubceo</strong>.
          <br />
          <br />
          Este cambio es <strong>esencial para garantizar la continuidad </strong>del acceso a sus datos con exactitud y seguridad.
          <br />
          <br />
          En base a la experiencia hemos visto que este proceso de <strong>Fiserv</strong> demora unos días en proveer el acceso a las
          credenciales correspondientes, con lo cual les sugerimos ser <strong>proactivos y anticipar </strong>la solicitud lo máximo que se
          pueda.
          <br />
          <br />
          Es importante destacar que este cambio es una decisión exclusiva de <strong>Fiserv</strong> en la cual <strong>nubceo</strong> no
          ha tenido participación.
          <br />
          <br />
          Estamos a su disposición para ayudarlos ante cualquier duda que pueda surgir así agilizan esta solicitud.
          <br />
          <br />
          Desde ya, muchas gracias.
        </span>
        <br />
        <br />
        <div className="d-flex flex-row ">
          <FormButtonComponent className="submit-button mt-4 w-100 me-2" type="button" onClick={handleClick}>
            Contáctanos
          </FormButtonComponent>
          <FormButtonComponent className="submit-button mt-4 w-100" type="button" onClick={(): void => navigate('/')}>
            Volver al Inicio
          </FormButtonComponent>
        </div>
      </div>
    </>
  );
};

export default InfoFiservFTP;
