import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CloseIconSVG } from '../../assets/svgComponents';
import { useAppSelector } from '../../redux/hooks';
import { externalPlatformsBasicData } from '../../utils/constants';
import { handleKeyDownDefault } from '../../utils/helper';
import { CallbackFunction } from '../../utils/types';
import { Branch } from '../../utils/interfaces';

type FilterTabComponentType = {
  property: string;
  array: Record<string, string | null>;
  onClick: CallbackFunction;
  countCheckBranches?: number;
};

const parseFiltersLabels = {
  startDate: 'Fecha desde: ',
  startPaymentDate: 'Fecha de pago desde: ',
  endDate: 'Fecha hasta: ',
  endPaymentDate: 'Fecha de pago hasta: ',
  companyId: 'Empresa: ',
  companies: 'Empresa: ',
  branchId: 'Sucursal: ',
  branches: 'Sucursal: ',
  platformCode: 'Plataforma: ',
  presentedStartDate: 'Fecha de presentación desde: ',
  presentedEndDate: 'Fecha de presentación hasta: ',
  included_settlement: 'Ventas : ',
  type: 'Tipo de operación : ',
};

type FilterFormikKeys = 'companyId' | 'platformCode' | 'startDate' | 'endDate' | 'included_settlement' | 'type';

const FilterTabComponent: FC<FilterTabComponentType> = ({ property, array, onClick, countCheckBranches }) => {
  const location = useLocation();
  const url = location.pathname;
  const isSettlementsClientPage = url.includes('settlements-client');
  const isSalesClientPage = url.includes('sales-client');
  const isTaxesClientPage = url.includes('taxes-client');
  const { companiesList } = useAppSelector((state) => state.company);
  const { branchesList } = useAppSelector((state) => state.branch);
  const { taxTypes } = useAppSelector((state) => state.taxes);
  const { singleClient: relCompanyList, clientCompanies } = useAppSelector((state) => state.clients);

  const parseFiltersValues = (): string | undefined | null => {
    switch (property) {
      case 'companyId':
        if (
          (isSettlementsClientPage || isSalesClientPage || isTaxesClientPage) &&
          relCompanyList?.RelCompany.id.toString() === array[property]
        ) {
          return relCompanyList.name;
        }
        return companiesList.find((company) => company.id === array[property])?.name;
      case 'included_settlement': {
        const included = array[property];
        if (included === 'not_included') {
          return 'No liquidadas';
        }
        if (included === 'included') {
          return 'Liquidadas';
        }
        return `Todas`;
      }
      case 'taxTypeCode': {
        const taxType = array[property]?.split(',');
        if (!taxType) return null;
        if (taxType.length > 1) return `(${taxType.length}) Impuestos seleccionados`;
        return taxTypes.find((tax) => tax.code === array[property])?.name;
      }
      case 'companies': {
        const companies = array[property]?.split(',');
        if (!companies) return null;
        if (companies.length > 1) return `(${companies.length}) Empresas seleccionadas`;
        if (
          (isSettlementsClientPage || isSalesClientPage || isTaxesClientPage) &&
          relCompanyList?.relCompanyId?.toString() === array[property]
        ) {
          const companyFiltered = clientCompanies.find(({ id }) => id === array[property]);
          return companyFiltered?.name;
        }
        return companiesList.find((company) => company.id === array[property])?.name;
      }

      case 'type': {
        const types = array[property]?.split(',');
        const option = array[property];
        if (!types) return null;
        if (types.length > 1) return `(${types.length}) Tipos de operación seleccionados`;
        if (option === 'order') {
          return 'Venta';
        }
        if (option === 'chargeback') {
          return 'Contracargo';
        }
        if (option === 'return') {
          return 'Devolución';
        }
        if (option === 'adjustment') {
          return 'Ajuste';
        }
        return array[property];
      }
      case 'platformCode':
        return externalPlatformsBasicData(array[property] as string).name;
      case 'branchId':
        return branchesList.find((branch) => branch.id.toString() === array[property])?.name;
      case 'branches': {
        const branches = array[property]?.split(',');
        const filtered = branchesList?.filter((branch: Branch) => branches?.includes(branch.id) && branch.relatedBranches.length > 0);
        const totalRelatedBranches = filtered?.reduce((sum, branch) => sum + branch.relatedBranches.length, 0);

        if (!branches) return null;
        if (branches.length > 1 && totalRelatedBranches === 0) return `Sucursales seleccionadas: ${branches.length}`;
        if (branches.length > 1 && totalRelatedBranches > 0)
          return `${countCheckBranches} Sucursales seleccionadas (${totalRelatedBranches}) Agrupadas`;
        return branchesList.find((branch) => branch.id === array[property])?.name;
      }
      default:
        return array[property];
    }
  };

  if (property === 'page') return null;

  return (
    <div
      className="filter-tag align-items-center px-2 py-1 me-2 fw-bold mt-2"
      onKeyDown={handleKeyDownDefault}
      role="none"
      onClick={onClick}
    >
      <small className="font-xs ms-2 fw-light">{parseFiltersLabels[property as FilterFormikKeys]}</small>
      {parseFiltersValues()}
      <CloseIconSVG className="close-icon ms-2 color-black cursor-pointer" />
    </div>
  );
};

export default FilterTabComponent;
