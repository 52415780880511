import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { tiendaNubeAuth } from '../../services/tiendaNubeService';
import errorCodeManager from '../../utils/errorCodeManager';
import errorCodes from '../../utils/errorCodes.json';
import { TiendaNubeProps } from '../../utils/types';
import TiendaNubeEndLinking from './TiendaNubeEndLinking';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import TiendaNubeDefaultLanding from './TiendanubeDefaultLanding';
import { getExternalActivePlatforms } from '../../services/externalPlatformService';
import { externalPlatformsBasicData, platformExternalCodesTrimmed } from '../../utils/constants';
import errorHandlerHelper from '../../utils/errorHandler';
import { getCompanies } from '../../services/companyService';
import Logo from '../../assets/images/integrationTiendaNube.png';
import { Company, ExternalActivePlatform } from '../../utils/interfaces';
import { Subtitle } from '../../components';

type TiendaNubeResponse = {
  title: string;
  message: string;
  error?: boolean;
};

const tiendaNubeResponse = (title: string, message: string, error?: boolean): TiendaNubeResponse => ({
  title,
  message,
  error,
});

const prepareTiendaNubeAuth = async ({ code, state }: TiendaNubeProps): Promise<{ title: string; message: string }> => {
  if (code && state) {
    try {
      const [tenantId, platformExternalCredentialId] = state.split('-');
      await tiendaNubeAuth({ code, tenantId, id: platformExternalCredentialId });
      return tiendaNubeResponse('Conexión exitosa', 'Tu cuenta se conectó con éxito');
    } catch (error) {
      if (error.response?.data.code === '1005') {
        return tiendaNubeResponse('Ocurrió un error', 'Esta cuenta ya fué conectada.', true);
      }
      if (error?.message.includes('expired' || "Authorization code doesn't exist")) {
        return tiendaNubeResponse(
          'Ocurrió un error',
          'Lamentablemente pasó mucho tiempo y no pudimos vincular. Volvé a Tiendanube y probá otra vez haciendo el proceso un poco más rápido.',
          true,
        );
      }
      if (error.response?.data.status === 409) {
        return tiendaNubeResponse(
          'Ocurrió un error',
          'La cuenta que estás intentando conectar ya fue vinculada para la misma empresa.',
          true,
        );
      }
      return tiendaNubeResponse('Lo sentimos', 'Hubo un error al conectar la cuenta.', true);
    }
  } else {
    return tiendaNubeResponse('Lo sentimos', 'Ocurrió un error inesperado.', true);
  }
};

const TiendaNube: FC = () => {
  const [companiesList, setCompaniesList] = useState<Company[] | null>(null);
  const [tiendaNubeList, setTiendaNubeList] = useState<ExternalActivePlatform[] | null>(null);
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState<{ title: string; message: string; error?: boolean } | null>();
  const { user } = useAppSelector((state) => state.userProfile);
  const code = new URLSearchParams(useLocation().search).get('code');
  const state = new URLSearchParams(useLocation().search).get('state');
  const error = new URLSearchParams(useLocation().search).get('error');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((code && state) || error) {
      if (code && state)
        prepareTiendaNubeAuth({ code, state, error })
          .then((res) => setResponse(res))
          .catch((err) => setResponse(err));
      else if (error !== null) {
        const errorsKnown = errorCodeManager.find((errorCode) => errorCode.code === error.trim());
        if (errorsKnown) {
          setResponse(tiendaNubeResponse(errorsKnown.title, errorsKnown.message));
        } else {
          setResponse(tiendaNubeResponse(errorCodes.default.title, errorCodes.default.message));
        }
      }
    }
  }, [code, state, error]);

  useEffect(() => {
    setLoading(true);
    const fetchExternalPlatforms = async (): Promise<void> => {
      try {
        setLoading(true);
        const { documents } = await getExternalActivePlatforms();
        if (documents.docs.length) {
          const userHasTiendaNube = documents.docs.filter(
            (item) => externalPlatformsBasicData(item.platformExternalCode).code === platformExternalCodesTrimmed.TIENDANUBE,
          );
          if (userHasTiendaNube?.length) setTiendaNubeList(userHasTiendaNube);
        }
        setLoading(false);
      } catch (promiseError) {
        errorHandlerHelper(promiseError, dispatch);
        setLoading(false);
      }
    };

    getCompanies()
      .then(({ data: companies }) => {
        setCompaniesList(companies.data);
        fetchExternalPlatforms();
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const handleSubmitButton = (tiendanubeCredentialId: number): void => {
    prepareTiendaNubeAuth({ code, state: `${user?.tenantId}-${tiendanubeCredentialId}`, error })
      .then((res) => setResponse(res))
      .catch((err) => setResponse(err));
  };

  return (
    <div>
      <div className="header">
        <p className="header-title fw-bold font-lg">Vinculación de Tiendanube</p>
      </div>
      <div className="row content align-items-center">
        {code && !state && <Subtitle className="m-4">¡Estás a un paso de terminar de vincular tu cuenta de Tiendanube!</Subtitle>}
        <div className="img-container col-12 col-xl-6">
          <img src={Logo} alt="Nubceo Logo" className="img" />
        </div>
      </div>
      <div className="col-xl-6">
        {/* eslint-disable-next-line no-nested-ternary */}
        {((code && state) || response) && !loading ? (
          <TiendaNubeDefaultLanding response={response || undefined} error={error || response?.error || undefined} />
        ) : (companiesList || tiendaNubeList) && !loading ? (
          <TiendaNubeEndLinking companiesList={companiesList} tiendaNubeList={tiendaNubeList} handleSubmitButton={handleSubmitButton} />
        ) : (
          <div className="tiendaNube-loader" />
        )}
      </div>
    </div>
  );
};

export default TiendaNube;
