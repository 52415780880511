import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User, UsersListResponse } from '../../utils/interfaces';
import { FilterParams, LoadingStateType } from '../../utils/types';
import { getUsers } from '../../services/userService';

export type UserSliceState = {
  usersList: User[];
  loading: LoadingStateType;
  pages: number;
  total: number;
};

export const asyncListUsers = createAsyncThunk<UsersListResponse, FilterParams>('getUsersList', async (params) => {
  const { data: response } = await getUsers(params);
  return response;
});

const initialState: UserSliceState = {
  usersList: [],
  loading: 'idle',
  pages: 0,
  total: 0,
};

export const notificationsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncListUsers.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListUsers.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(asyncListUsers.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.usersList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
    });
  },
});

export default notificationsSlice.reducer;
