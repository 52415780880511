import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import WhatsAppImage from '../../assets/images/WhatsApp.png';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { config } from '../../utils/constants';
import { CountryCode } from '../../utils/interfaces';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';

const WhatsAppFloatingButton = (): React.ReactElement => {
  const { user } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const url = location.pathname;
  const isLoginPage = url.includes('login');
  const message = (): React.ReactElement => (
    <>
      <div className="modal-popUp border-round d-flex flex-column position-relative">
        <div className="container-help">
          <div className="titleText ms-3">
            <p className="font-lg">¿Necesitás ayuda?</p>
          </div>
          <hr className="line-through" />
          <div className="d-flex flex-column justify-content-around">
            <div className="d-flex flex-row justify-content-between my-3">
              <a
                href={`https://wa.me/${config.nubceo.supportPhone}?text=`}
                target="_blank"
                className="text-decoration-none ms-4 color-black fw-bold"
                rel="noreferrer"
              >
                Contacto soporte Argentina
              </a>
            </div>
            <div className="d-flex flex-row justify-content-between my-3">
              <a
                href={`https://wa.me/${config.nubceo.supportPhoneUy}?text=`}
                target="_blank"
                className="text-decoration-none ms-4 color-black fw-bold"
                rel="noreferrer"
              >
                Contacto soporte Uruguay
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const sendWhatsapp = (): void => {
    if (!isLoginPage) {
      let userText = '';
      if (user) {
        userText = `Mi email es ${user.email}. `;
      }
      const msg = `Hola! ${userText} Necesito ayuda.`;
      const urlWhatsApp = `https://wa.me/${
        user?.Tenant.countryCode === CountryCode.AR ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy
      }?text=${msg}`;
      window.open(urlWhatsApp);
    } else {
      dispatch(
        addModalToList({
          body: message(),
          okButton: {
            buttonText: 'Aceptar',
            onClick: () => dispatch(removeModalFromList()),
          },
        }),
      );
    }
  };

  return (
    <div className="container-fab">
      <div className="container-img-fab" onClick={(): void => sendWhatsapp()} role="none" data-tip="¿Necesitás ayuda? ¡Contactanos!">
        <img src={WhatsAppImage} className="whatsAppImage" alt="WhatsApp" />
        <ReactTooltip />
      </div>
    </div>
  );
};

export default WhatsAppFloatingButton;
