import React, { FC, useState } from 'react';
import { AiOutlineQuestionCircle, AiOutlineUser } from 'react-icons/ai';
import { FaBars, FaRegBell, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DropdownMenuComponent, LinkComponent } from '..';
import { CloseIconSVG, NubceoCashIsoSVG, UserIconSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logoutUserProfile } from '../../redux/slices/userSlice';
import { clearStore } from '../../redux/store';
import logoNubceo from '../../assets/images/logoBlanco.png';
import variables from '../../scss/_exports.module.scss';
import logger from '../../services/loggerService';
import { deleteUserToken } from '../../utils/firebaseHelper';
import useWindowDimensions from '../../utils/hooks';
import { ActivityCode } from '../../utils/interfaces';
import ActivityMedal from '../ActivityMedal';
import CurrencySelector from '../CurrencySelector';
import countries from '../CurrencySelector/countries';
import { accountantSidebarData, showSidebarData } from './SidebarData';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentReset, segmentTrack } from '../../utils/segment/hooks';
import SubMenu from './Submenu';
import { VERSION_APP } from '../../utils/constants';
import { validateWalkthroughStorage } from '../Walkthrough';
import { addModalToList, removeAllModalsFromList, removeModalFromList } from '../../redux/slices/modalSlice';
import { setAuthMessages } from '../../redux/slices/authSlice';
import { ModalTypes } from '../../containers/ModalContainer';
import { logoutUser } from '../../utils/helper';

const SidebarComponent: FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width <= 575;
  const [open, setOpen] = useState(!isMobile);
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.userProfile);
  const { activeCurrency, currencyList } = useAppSelector((state) => state.currencies);
  const isAccountant = activity === ActivityCode.CONTADOR;
  const sidebarData = isAccountant ? accountantSidebarData : showSidebarData();

  const handleSideBar = (): void => {
    // if (!open) segmentTrack(EVENTS.LAYOUT.MENU.EXPAND);
    // if (open) segmentTrack(EVENTS.LAYOUT.MENU.REDUCE);
    setOpen(!open);
  };

  const menuItems = [
    {
      icon: <AiOutlineUser size={25} />,
      text: 'Mi información',
      route: '/profile',
      onClick: (): void => {
        // segmentTrack(EVENTS.PROFILE.PAGE);
      },
    },
    {
      icon: <AiOutlineQuestionCircle size={25} />,
      text: 'Ayuda',
      route: '#',
      onClick: (): void => {
        dispatch(addModalToList({ type: ModalTypes.HELP }));
      },
    },
    {
      icon: <CloseIconSVG height={20} width={20} />,
      text: 'Cerrar sesión',
      route: '#',
      onClick: (): void => {
        dispatch(
          addModalToList({
            body: '¿Estás seguro que querés cerrar tu sesión de nubceo?',
            required: true,
            okButton: {
              buttonText: 'Si',
              onClick: async () => {
                logoutUser(dispatch);
              },
            },
            cancelButton: {
              buttonText: 'No',
              onClick: async (): Promise<void> => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      },
    },
  ];

  const accountantMenuItems = [
    {
      icon: <AiOutlineUser size={25} />,
      text: 'Mi información',
      route: '/profile',
      onClick: (): void => {
        // segmentTrack(EVENTS.PROFILE.PAGE);
      },
    },
    {
      icon: <AiOutlineQuestionCircle size={25} />,
      text: 'Ayuda',
      route: '#',
      onClick: (): void => {
        dispatch(addModalToList({ type: ModalTypes.HELP }));
      },
    },
    {
      icon: <CloseIconSVG height={20} width={20} />,
      text: 'Cerrar sesión',
      route: '#',
      onClick: (): void => {
        dispatch(setAuthMessages({ message: { logout: 'Saliendo...' } }));
        dispatch(removeAllModalsFromList());
        deleteUserToken()
          .catch((error) => logger.error('[ERROR]', { error }))
          .finally(() => {
            validateWalkthroughStorage();
            // segmentReset();
            dispatch(clearStore());
            dispatch(logoutUserProfile());
          });
      },
    },
  ];
  const getVersion = (): React.ReactElement => {
    const parts = VERSION_APP.split('-');

    if (parts?.length === 2) {
      const versionNumber = parts[0];
      const qaVersion = parts[1];
      return (
        <div className="align-items-end d-flex flex-column version">
          <p className="">v{versionNumber}</p>
          <p className="">{qaVersion}</p>
        </div>
      );
    }

    return <p className="version align-items-end">v{VERSION_APP} </p>;
  };

  return (
    <div className={`sidebar-component ${open && 'open'}`}>
      <div className="header-menu">
        <div className="icon-container">
          {open ? (
            <FaChevronRight onClick={handleSideBar} className="close-icon" size={24} color={variables.colorWhite} />
          ) : (
            <FaBars onClick={handleSideBar} className="open-icon" size={24} color={variables.colorWhite} />
          )}
        </div>
        <LinkComponent className="logo-container" route={isAccountant ? '/clients' : '/'}>
          {isMobile || open ? (
            <img src={logoNubceo} alt="Fiserv" className="img-fluid w-75" />
          ) : (
            <NubceoCashIsoSVG fillBars={variables.colorBlue} fillCloud={variables.colorWhite} className="logo-nubceo mb-3 small" />
          )}
        </LinkComponent>
        <FaRegBell onClick={(): void => navigate('/notifications')} className="ms-3 color-white notification-icon" size={22} />

        <div className="d-sm-none dropdown-container">
          <DropdownMenuComponent
            toggle={<UserIconSVG width={20} height={22} className="color-white" />}
            items={isAccountant ? accountantMenuItems : menuItems}
          />
          {activity && <ActivityMedal activity={activity} />}
        </div>
      </div>
      <div className={`sidebar ${open && 'open'}`}>
        {open && currencyList.length > 1 && (
          <div className="currency-container-sidebar">
            <CurrencySelector />
          </div>
        )}
        {!isMobile && !open && currencyList.length > 1 && (
          <img
            role="none"
            onClick={handleSideBar}
            className="sidebar-link closed-menu currency-img"
            src={countries[countries.findIndex((elem) => elem.code === activeCurrency?.countryCode)]?.imageFlag}
            alt=""
            style={{ objectFit: 'contain' }}
          />
        )}
        <div className="mt-3">
          {sidebarData.map((item) => (
            <SubMenu onClick={handleSideBar} item={item} key={item.title} openMenu={open} />
          ))}
        </div>
        {getVersion()}
      </div>
    </div>
  );
};

export default SidebarComponent;
