import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoaderSpinner } from '..';
import DropdownArrowIcon from '../../assets/svgComponents/DropdownArrowIcon';
import { useAppSelector } from '../../redux/hooks';

import { CashflowSummary, LoadingStateType } from '../../utils/interfaces';

import { capitalizeFirstLetter, currencyFormat, formatNumber } from '../../utils/stringUtils';

interface IProps {
  containerClassName?: string;
  onClick: () => void;
  selectMonth: string;
  collectionReportList: CashflowSummary[];
  set: 'prev' | 'next';
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const CalendarMoveButton: React.FC<IProps> = ({ loading, collectionReportList, set, selectMonth, onClick, containerClassName }) => {
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (collectionReportList) {
      let totalAmount = 0;
      collectionReportList.forEach((item) => {
        if (set === 'prev') {
          const date = moment(selectMonth).subtract(1, 'month').format('YYYY-MM-DD');

          if (moment(date).isSame(item.summaryDate, 'month')) {
            totalAmount += item.amountIn - item.amountOut;
          }
        } else {
          const date = moment(selectMonth).add(1, 'month').format('YYYY-MM-DD');

          if (moment(date).isSame(item.summaryDate, 'month')) {
            totalAmount += item.amountIn - item.amountOut;
          }
        }
      });
      setTotal(totalAmount);
    }
  }, [collectionReportList]);

  return (
    <div role="button" tabIndex={0} onKeyPress={onClick} onClick={onClick} className={`content-button ${containerClassName}`}>
      <div className={`month ${set} font-md font-weight-bold`}>
        {set === 'prev' ? (
          <>
            <div className="prev-month">
              <DropdownArrowIcon width={15} />
            </div>
            <p className="prev-month-text">{capitalizeFirstLetter(moment(selectMonth).subtract(1, 'month').format('MMMM YYYY'))}</p>
          </>
        ) : (
          <>
            <p className="next-month-text">{capitalizeFirstLetter(moment(selectMonth).add(1, 'month').format('MMMM YYYY'))}</p>
            <div className="next-month">
              <DropdownArrowIcon width={15} />
            </div>
          </>
        )}
      </div>
      <p className="amount font-md font-weight-bold">
        {' '}
        {loading === LoadingStateType.PENDING ? (
          <LoaderSpinner className="xs" />
        ) : (
          `${activeCurrency ? currencyFormat(activeCurrency?.code) : '$'}${formatNumber(total, false, 0)}`
        )}
      </p>
    </div>
  );
};

export default CalendarMoveButton;
