/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { refreshToken } from '../../services/authService';
import logger from '../../services/loggerService';
import { selfChangeEmail, selfGenerateReferrerCode } from '../../services/selfService';
import { getProfile, ProfileResponse } from '../../services/userService';
import { errorHandlerHelper } from '../../utils/errorHandler';
import { EditEmail } from '../../utils/interfaces';
import { RequestParams, StorageVars } from '../../utils/types';

interface UserState {
  user: ProfileResponse | null;
  loading: 'pending' | 'succeeded' | 'failed';
  activity: string | null;
}

export const getProfileDataAsync = createAsyncThunk<ProfileResponse, RequestParams>(
  'userProfile/self',
  async (_params, { rejectWithValue, dispatch }) => {
    try {
      const profile = await getProfile();
      if (!profile?.referrerInviteLink) {
        await selfGenerateReferrerCode();
      }

      return profile;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncEditUserEmail = createAsyncThunk<any, EditEmail>('userProfile/editEmail', async (data, { rejectWithValue, dispatch }) => {
  try {
    const profile = await selfChangeEmail(data);
    const { data: tokenResult } = await refreshToken();
    localStorage.setItem(StorageVars.token, tokenResult.data.token);
    localStorage.setItem(StorageVars.tokenRefresh, tokenResult.data.tokenRefresh);
    return profile;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return rejectWithValue(error);
  }
});

const initialState: UserState = {
  user: null,
  loading: 'pending',
  activity: null,
};

export const userSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    logoutUserProfile: (state) => {
      state.loading = 'succeeded';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileDataAsync.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(getProfileDataAsync.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('getProfileDataAsync.error', action.error);
    });
    builder.addCase(getProfileDataAsync.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.user = action.payload;
      // TODO: THIS NEXT LANES ACTIVATE OR DEACTIVATE ACCOUNTANT FEATURE. WHILE ASSIGNING NULL, MEANS DEACTIVATED
      // state.activity = null;
      state.activity = action.payload.Tenant.activityCode;
      logger.info('getProfileDataAsync.success', state.user);
    });
  },
});

export const { logoutUserProfile } = userSlice.actions;

export default userSlice.reducer;
