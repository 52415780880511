import moment from 'moment';
import { useState, useEffect, RefObject, Dispatch, SetStateAction, useCallback } from 'react';

type WindowDimensionsType = {
  width: number;
  height: number;
};

const getWindowDimensions = (): WindowDimensionsType => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function useWindowDimensions(): WindowDimensionsType {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = (): void => setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useHandleMouseDownOutside = (
  ref: RefObject<HTMLElement>,
  multiple?: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [opened, setOpened] = useState(false);

  const handleClickOutside = useCallback(
    (event: Event): void => {
      const target = event.target as HTMLElement;

      if (!ref.current?.contains(target) && !target.closest('.options-container')) {
        setOpened(false);
      }
    },
    [ref],
  );

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside, multiple]);

  return [opened, setOpened];
};

export const useTimer = (dateFrom: string | number): string | null => {
  const calculateTimeLeft = (): number => {
    let timeLeft = 0;
    const now = moment().utc();
    const time2 = moment(dateFrom);
    const diffInSecs = Math.floor(moment.duration(now.diff(time2)).asSeconds());
    if (diffInSecs >= 0 && diffInSecs <= 600) {
      timeLeft = diffInSecs;
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return (): void => clearTimeout(timer);
  });

  const timeLeftFormatted = moment((600 - timeLeft) * 1000).format('mm:ss');
  return timeLeftFormatted !== '10:00' ? timeLeftFormatted : null;
};
