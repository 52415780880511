import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';
import { CashflowSummary } from '../../utils/interfaces';
import { formatNumber } from '../../utils/stringUtils';

type CouponAdvanceRowsProps = {
  presentCashflow: CashflowSummary[];
};

const CouponAdvanceRows: FC<CouponAdvanceRowsProps> = ({ presentCashflow }) => {
  const navigate = useNavigate();

  const rowClickHandler = (settlement: CashflowSummary): void => {
    navigate('/advance-settlement', { state: { settlement } });
  };

  return (
    <>
      <h2 className="advance-settlements-title">Adelanto de Liquidaciones</h2>
      <div className="d-flex flex-row justify-content-end">
        <div className="d-flex flex-row me-5">
          <p className="powered-by-text">powered by</p>
          <p className="powered-by-nubceo-text"> nubceo</p>
        </div>
      </div>
      {presentCashflow.map((settlement) => (
        <div key={settlement?.date}>
          <div className="d-flex flex-row mb-2 justify-content-between p-2 container-row">
            <div role="none" onClick={(): void => rowClickHandler(settlement)}>
              <div className="d-flex flex-row">
                <p className="container-row-date">Fecha</p>
                <p className="ms-2 container-row-date-value"> {moment(settlement?.date).format('DD-MM-YYYY')}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="container-row-detail">Detalles</p>
                <div className="left-arrow">
                  <DropdownArrowIconSVG width={12} className="color-black" />
                </div>
              </div>
            </div>
            <div>
              <p className="container-row-detail-value">${formatNumber(settlement?.platforms[0]?.amount, true)}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CouponAdvanceRows;
