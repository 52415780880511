import React, { FC, useState, ReactNode, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Field, Form, Formik, FormikValues } from 'formik';
import { MessageToApplyFilters, externalPlatformsBasicData } from '../../utils/constants';
import { paramsToObject } from '../../utils/helper';
import {
  SelectInputComponent,
  FilterTabComponent,
  FormButtonComponent,
  SearchInputComponent,
  Paragraph,
  DateInputComponent,
} from '../../components';
import variables from '../../scss/_exports.module.scss';
import { FormFieldType } from '../../utils/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Branch, ExternalActivePlatform, ExternalPlatformByBranch } from '../../utils/interfaces';
import errorHandlerHelper from '../../utils/errorHandler';
import {
  joinBranchesWhenPaginating,
  debouncedInputValueFunction,
  listBranchesAccordingToAppliedFilters,
  branchPagination,
  handleSearchInput,
  handleSubmit,
  getInitialValues,
  sortPlatforms,
  deleteFilterTab,
  handlePlatformsList,
  clearAllFilters,
  ScreenType,
  defaultDateFilter,
} from '../../utils/helperFilters';

const FilterSettlement = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = location.pathname;
  const URLFilters: Record<string, string> = paramsToObject(new URLSearchParams(location.search));
  const { companiesList } = useAppSelector((state) => state.company);
  const { externalUsedPlatformList: platformListUsed } = useAppSelector((state) => state.externalPlatform);
  const { clientExternalPlatforms, clientCompanies } = useAppSelector((state) => state.clients);
  const { branchesList, pages } = useAppSelector((state) => state.branch);
  const companiesListSorted = [...companiesList]?.sort((company) => (company.enabled ? -1 : 1));
  const [platformExternalArray, setPlatformExternalArray] = useState<ExternalActivePlatform[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string[]>();
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [actualPage, setActualPage] = useState<number>(1);
  const [countCheck, setCountCheck] = useState<number>(0);
  const [concatenateBranches, setConcatenateBranches] = useState<Branch[]>([]);
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [endOfScroll, setEndOfScroll] = useState<boolean>(false);
  const [loadingBranches, setLoadingBranches] = useState<boolean>(false);
  const [inputValueSearch, setInputValueSearch] = useState('');
  const [platformExternalArrayByBranch, setPlatformExternalArrayByBranch] = useState<ExternalPlatformByBranch[]>([]);
  const isSettlementsClientPage = url.includes('settlements-client');
  const segments = url.split('/');
  const clientID = `/${segments[1]}/${segments[2]}`;

  useEffect(() => {
    joinBranchesWhenPaginating(endOfScroll, setConcatenateBranches, setLoadingBranches, branchesList);
  }, [branchesList]);

  useEffect(() => {
    debouncedInputValueFunction(debouncedInputValue, setConcatenateBranches, dispatch);
  }, [debouncedInputValue]);

  const listBranches = (): void => {
    listBranchesAccordingToAppliedFilters(selectedCompany, selectedPlatform, dispatch, setConcatenateBranches);
  };

  useEffect(() => {
    handleSearchInput(setActualPage, setDebouncedInputValue, inputValueSearch);
    listBranches();
  }, [selectedCompany, selectedPlatform, inputValueSearch]);

  useEffect(() => {
    branchPagination(actualPage, pages, endOfScroll, selectedCompany, selectedPlatform, dispatch, setLoadingBranches, setActualPage);
  }, [endOfScroll === true]);

  const applyFilters = (values: FormikValues): void => {
    handleSubmit(values, navigate, url);
  };

  useMemo(() => {
    if (isSettlementsClientPage) {
      return setPlatformExternalArray(clientExternalPlatforms);
    }
    return setPlatformExternalArray(platformListUsed);
  }, [platformListUsed, clientExternalPlatforms]);

  const initialValues = getInitialValues(URLFilters, false);

  return (
    <div className="border-round flex-grow-1">
      <Formik onSubmit={applyFilters} initialValues={initialValues}>
        {({ values, setFieldValue }): ReturnType<FC> => (
          <>
            <Form id="filters">
              <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                <Field name="companies">
                  {({ field }: FormFieldType): ReactNode => {
                    let companiesArray = companiesListSorted;
                    if (isSettlementsClientPage) {
                      companiesArray = clientCompanies;
                    }

                    return (
                      <SearchInputComponent
                        label="Empresas"
                        options={companiesArray.map((company) => ({
                          id: company?.id,
                          value: company?.id,
                          name: company?.name,
                          pill: company.enabled ? undefined : (
                            <div className="filter-tag px-3 py-1 inactive-badge">
                              <Paragraph color={variables.colorGray}>Inactiva</Paragraph>
                            </div>
                          ),
                        }))}
                        onOptionClick={(name, value): void => {
                          setSelectedCompany(value);
                          setFieldValue(name, value);
                        }}
                        containerClassName="input"
                        placeholder={
                          companiesListSorted?.length === values.companies?.length ? 'Todas las empresas' : 'Seleccionar empresa(s)'
                        }
                        {...field}
                        value={selectedCompany?.length ? values.companies : []}
                      />
                    );
                  }}
                </Field>
                <Field name="branches">
                  {({ field }: FormFieldType): ReactNode => {
                    const branchesOptions = concatenateBranches.map((branchItem) => {
                      const relatedBranches = branchItem.relatedBranches?.length
                        ? branchItem.relatedBranches.map((relatedBranchItem) => relatedBranchItem.id)
                        : null;
                      return {
                        id: branchItem.id,
                        name: branchItem.name,
                        value: relatedBranches ? `${relatedBranches.join(',')},${branchItem.id}` : branchItem.id,
                        optionLabel: companiesList.find((companyItem) => companyItem.id === branchItem.companyId)?.name || undefined,
                        externalCode: branchItem?.PlatformExternals[0]?.externalCode,
                        platformExternals: branchItem?.PlatformExternals,
                      };
                    });

                    return (
                      <SearchInputComponent
                        label="Sucursal/Comercio"
                        disabled={false}
                        setCountCheck={setCountCheck}
                        countCheck={countCheck}
                        options={branchesOptions}
                        setInputValueSearch={setInputValueSearch}
                        setEndOfScroll={setEndOfScroll}
                        loading={loadingBranches}
                        onOptionClick={(name, value): void => {
                          handlePlatformsList(value, setPlatformExternalArrayByBranch, dispatch).catch((error) =>
                            errorHandlerHelper(error, dispatch),
                          );
                          if (!value.length) {
                            return setFieldValue(name, null);
                          }
                          return setFieldValue(name, value);
                        }}
                        containerClassName="input"
                        placeholder={
                          values.branches?.length === concatenateBranches?.length
                            ? 'Todas las sucursales/comercios'
                            : 'Seleccionar sucursales/comercios'
                        }
                        {...field}
                        value={values.branches ?? []}
                      />
                    );
                  }}
                </Field>
                <Field name="platformCode">
                  {({ field }: FormFieldType): ReactNode => {
                    const platformArray = platformExternalArray;
                    return (
                      <SelectInputComponent
                        label="Plataforma"
                        options={
                          !values?.branches
                            ? platformArray.reduce<{ id: string | number; name: string; value: string }[]>((acc, platform) => {
                                const found = acc.find(
                                  (el) => el.name === externalPlatformsBasicData(platform?.platformExternalCode)?.name,
                                );
                                if (!found) {
                                  acc.push({
                                    id: platform.id,
                                    name: externalPlatformsBasicData(platform.platformExternalCode)?.name,
                                    value: platform.platformExternalCode,
                                  });
                                }
                                return sortPlatforms(acc);
                              }, [])
                            : platformExternalArrayByBranch.reduce<{ id: string | number; name: string; value: string }[]>(
                                (acc, platform) => {
                                  const found = acc.find((el) => el.name === externalPlatformsBasicData(platform.code)?.name);
                                  if (!found) {
                                    acc.push({
                                      id: `${Math.random()}-${platform.code}`,
                                      name: externalPlatformsBasicData(platform.code)?.name,
                                      value: platform.code,
                                    });
                                  }
                                  return sortPlatforms(acc);
                                },
                                [],
                              )
                        }
                        containerClassName="input"
                        onOptionClick={(name, value): void => {
                          setSelectedPlatform(value);
                          setFieldValue(name, value);
                        }}
                        {...field}
                        value={selectedPlatform ? values.platformCode : ''}
                        placeholder="Todas las Plataformas"
                      />
                    );
                  }}
                </Field>
                <div className="d-flex flex-wrap col-12 col-md-10 col-lg-12 mt-2">
                  <Field name="startDate">
                    {({ field }: FormFieldType): ReactNode => (
                      <DateInputComponent
                        label="Fecha desde"
                        containerClassName="input"
                        {...field}
                        value={values.startDate ?? ''}
                        max={values.endDate ?? undefined}
                      />
                    )}
                  </Field>
                  <Field name="endDate">
                    {({ field }: FormFieldType): ReactNode => (
                      <DateInputComponent
                        label="Fecha hasta"
                        containerClassName="input"
                        {...field}
                        value={values.endDate ?? ''}
                        min={values.startDate ?? undefined}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="w-100">
                {Object.values(values).find((elem) => elem !== null) ? <MessageToApplyFilters /> : null}
                <FormButtonComponent type="submit" className="mt-3">
                  Aplicar filtros
                </FormButtonComponent>
              </div>
              <FormButtonComponent
                onClick={(): void => {
                  setSelectedPlatform(undefined);
                  setCountCheck(0);
                  clearAllFilters(
                    isSettlementsClientPage ? ScreenType.SETTLEMENTS_CLIENT : ScreenType.SETTLEMENTS,
                    setFieldValue,
                    initialValues,
                    navigate,
                    dispatch,
                    setConcatenateBranches,
                    clientID,
                  );
                  setSelectedCompany([]);
                  defaultDateFilter(setFieldValue, isSettlementsClientPage ? ScreenType.SETTLEMENTS_CLIENT : ScreenType.SETTLEMENTS);
                }}
                className="mt-3"
              >
                Limpiar filtros
              </FormButtonComponent>
            </Form>
            {Object.keys(URLFilters).filter((filter) => filter !== 'page').length
              ? Object.values(URLFilters).find((value) => value) && (
                  <div className="w-100 p-0 mt-5 d-flex flex-column">
                    <p className="flex-grow-1 font-weight-bold">Filtros Aplicados</p>
                    <div className="d-flex flex-wrap">
                      {Object.keys(URLFilters).map((property) =>
                        URLFilters[property] ? (
                          <FilterTabComponent
                            property={property}
                            key={property}
                            array={URLFilters}
                            countCheckBranches={countCheck}
                            onClick={(): void => {
                              deleteFilterTab(values, property, navigate, url);
                              setFieldValue(property, null);
                            }}
                          />
                        ) : null,
                      )}
                    </div>
                  </div>
                )
              : null}
          </>
        )}
      </Formik>
    </div>
  );
};

export default FilterSettlement;
