import { AxiosError, AxiosRequestConfig } from 'axios';
import { StorageVars } from './types';

export const handleRefreshTokenExpired = (error: AxiosError): void => {
  const { config, response } = error;

  // Current url of the page
  const currentUrl = window.location.href;
  const splitCurrentUrl = currentUrl?.split('/');
  const lastPartCurrentUrl = splitCurrentUrl?.length ? splitCurrentUrl[splitCurrentUrl?.length - 1] : null;
  const pagesNotSupported = lastPartCurrentUrl === 'login' || currentUrl[currentUrl?.length - 1] === '/';

  // Query url
  const splitUrlQuery = config?.url?.split('/');
  const lastPartUrlQuery = splitUrlQuery?.length ? splitUrlQuery[splitUrlQuery?.length - 1] : null;

  // url Meli or TN
  const urlsToCheck = ['tiendanube/auth', 'mercadopago/auth'];
  const externalLinkingUrl = urlsToCheck.some((url) => currentUrl.includes(url));

  if (lastPartUrlQuery === 'refresh-token' && response?.status === 401 && !pagesNotSupported && !externalLinkingUrl) {
    localStorage.setItem(StorageVars.tokenExpired, 'expired');
    window.location.replace('/');
  }
};

// Function to cancel /balance queries when leaving the home
export const handleBalanceQuery = (config: AxiosRequestConfig): void => {
  const isHomePage = window.location.pathname === '/';
  if (config.url && config.url.includes('/balance') && !isHomePage) {
    throw new Error('Cancelled request');
  }
};
