import * as React from 'react';
import { SVGProps } from '../../utils/types';

function SelectCaretSVG({ fill, className, height, width, onClick }: SVGProps): React.ReactElement {
  return (
    <svg
      width={15}
      height={10}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ height, width }}
      onClick={onClick}
    >
      <path d="M1.189 2.483l5.73 6.96a1 1 0 001.543 0l5.73-6.96A1 1 0 0013.42.848H1.96a1 1 0 00-.772 1.635z" fill={fill || '#0C6BF4'} />
    </svg>
  );
}

export default SelectCaretSVG;
