import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExternalActivePlatform } from '../../../utils/interfaces';
import LoaderScreen from '../../LoaderScreen';
import naranjaImage1 from '../../../assets/images/illustrations/naranja-tutorial-1.webp';
import naranjaImage2 from '../../../assets/images/illustrations/naranja-tutorial-2.webp';
import naranjaImage3 from '../../../assets/images/illustrations/naranja-tutorial-3.webp';
import naranjaImage4 from '../../../assets/images/illustrations/naranja-tutorial-4.webp';
import naranjaImage5 from '../../../assets/images/illustrations/naranja-tutorial-5.webp';
import naranjaImage6 from '../../../assets/images/illustrations/naranja-tutorial-6.webp';
import naranjaImage7 from '../../../assets/images/illustrations/naranja-tutorial-7.webp';
import { ActionButtonComponent } from '../../buttons';
import { Subtitle } from '../../textComponents';
import { useAppDispatch } from '../../../redux/hooks';
import { asyncAddExternalPlatform } from '../../../redux/slices/externalPlatformSlice';
import errorHandlerHelper from '../../../utils/errorHandler';
import ToggleSwitch from '../../inputs/ToggleSwitch';
import NaranjaCredentialsFormSTA from './NaranjaCredentialsFormSTA';

interface NaranjaCredentialsFormProps {
  selectedPlatform: string;
  companyId: string;
  handleDeletePlatform: () => void;
  currentValues: ExternalActivePlatform | undefined;
}

const NaranjaCredentialsForm = ({
  selectedPlatform,
  companyId,
  handleDeletePlatform,
  currentValues,
}: NaranjaCredentialsFormProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [pecId, setPecId] = useState('');
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const toggler = (): void => setToggleSwitch(!toggleSwitch);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const urlNaranja = 'https://tucomercio.naranjax.com/';
  const newString = 'new';

  useEffect(() => {
    if (currentValues?.codeAux) {
      setToggleSwitch(true);
    }
  }, []);

  const createEmptyPec = (): void => {
    setLoading(true);
    if (pecId === '') {
      dispatch(
        asyncAddExternalPlatform({
          platformExternalCode: selectedPlatform,
          username: '',
          password: '',
          companyId,
        }),
      )
        .then(async (res: any) => {
          setLoading(false);
          setPecId(res?.payload?.document?.id);
        })
        .catch((error) => {
          errorHandlerHelper(error, dispatch);
          setLoading(false);
        });
    }
  };

  return loading ? (
    <LoaderScreen label="Iniciando vinculación" />
  ) : (
    <>
      {id === newString && !currentValues ? (
        <div className="mt-4 mb-2 fw-bold">
          <ToggleSwitch label="Formulario STA" name="naranja-STA" onChange={(): void => toggler()} checked={toggleSwitch} />
        </div>
      ) : null}
      {!toggleSwitch ? (
        <>
          <div className="container-input background-yellow-30 my-3 w-75">
            <div className="notification warning">
              <div className="notification-content">
                <div className="font-xs mx-4">
                  La vinculación con Naranja consta de algunos pasos. No te preocupes, te vamos a ir guiando en cada uno de ellos.
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 w-100 d-flex justify-content-start align-items-center flex-wrap">
            <ActionButtonComponent
              text={id !== 'new' || pecId !== '' ? 'Vinculación Iniciada' : 'Iniciar Vinculación'}
              onClick={(): void => createEmptyPec()}
              removeIcon
              inactive={id !== 'new' || pecId !== ''}
            />
            {id !== 'new' ? (
              <ActionButtonComponent containerClassName="ms-3" text="Eliminar" onClick={(): void => handleDeletePlatform()} removeIcon />
            ) : null}
          </div>
          {id !== 'new' || pecId !== '' ? (
            <div className="mt-5">
              <Subtitle className="mb-3">¡Bien! Hemos iniciado el proceso de vinculación. </Subtitle>
              <ul>
                <li>
                  <Subtitle size="md">
                    Ahora debés ingresar a{' '}
                    <a className="text-decoration-none" href={urlNaranja} target="blank">
                      Naranja.
                    </a>{' '}
                    Y dirígete a la sección <b>Tu negocio</b>
                  </Subtitle>
                  <img src={naranjaImage1} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle size="md">En esta sección podrás ver tu equipo de trabajo.</Subtitle>
                  <img src={naranjaImage2} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle size="md">
                    Para agregar nubceo, deberás hacer clic en <b>Invitar a una persona</b>
                  </Subtitle>
                  <img src={naranjaImage3} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle size="md" className="mt-3">
                    Permisos requeridos para el nuevo usuario:
                  </Subtitle>
                  <div className="container-input background-blue-10 mt-2 mb-5 w-50">
                    <div className="notification warning">
                      <div className="notification-content">
                        <div className="mx-4">
                          <Subtitle size="xs">
                            <b>.</b> Ventas, liquidaciones y conciliaciones.
                          </Subtitle>
                          <Subtitle size="xs">
                            <b>.</b> Números de comercio y planes de financiación.
                          </Subtitle>
                          <Subtitle size="xs">
                            <b>.</b> Ventas desconocidas.
                          </Subtitle>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={naranjaImage4} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle size="md">
                    Luego debés agregar el email a asociar: <b>pec_{id !== 'new' ? id : pecId}@asistente.nubceo.com</b>
                  </Subtitle>
                  <img src={naranjaImage5} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle size="md">Completá los campos Nombre y Apellido</Subtitle>
                  <img src={naranjaImage6} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
                <li>
                  <Subtitle className="mb-3">¡Bien! Si ves el siguiente mensaje, quiere decir que la vinculación fue exitosa.</Subtitle>
                  <img src={naranjaImage7} alt="Naranja" className="img-fluid my-3 w-50 w-25" />
                </li>
              </ul>
            </div>
          ) : null}
        </>
      ) : (
        <NaranjaCredentialsFormSTA
          currentValues={currentValues}
          companyId={companyId}
          selectedPlatform={selectedPlatform}
          handleDeletePlatform={handleDeletePlatform}
        />
      )}
    </>
  );
};

export default NaranjaCredentialsForm;
