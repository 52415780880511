import React, { FC } from 'react';
import { AiOutlineQuestionCircle, AiOutlineUser } from 'react-icons/ai';
import { FaRegBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CloseIconSVG, UserIconSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ActivityMedal from '../ActivityMedal';
import DropdownMenuComponent from '../DropdownMenu';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentReset, segmentTrack } from '../../utils/segment/hooks';
import { ActivityCode } from '../../utils/interfaces';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { ModalTypes } from '../../containers/ModalContainer';
import { logoutUser } from '../../utils/helper';

const TopBarComponent: FC = () => {
  const { user, activity } = useAppSelector((state) => state.userProfile);
  const { externalActivePlatformList } = useAppSelector((state) => state.externalPlatform);
  const dispatch = useAppDispatch();
  const isAccountant = activity === ActivityCode.CONTADOR;
  const navigate = useNavigate();
  const fiservLinkingNotFtp = externalActivePlatformList.find(
    (platform) => platform?.platformExternalCode.includes('firstdata') && platform?.codeAux !== 'ftp',
  );

  const menuItems = [
    {
      icon: <AiOutlineUser size={25} />,
      text: 'Mi información',
      route: '/profile',
      onClick: (): void => {
        // segmentTrack(EVENTS.PROFILE.PAGE);
      },
    },
    {
      icon: <AiOutlineQuestionCircle size={25} />,
      text: 'Ayuda',
      route: '#',
      onClick: (): void => {
        dispatch(addModalToList({ type: ModalTypes.HELP }));
      },
    },

    {
      icon: <CloseIconSVG height={20} width={20} />,
      text: 'Cerrar sesión',
      route: '#',
      onClick: (): void => {
        dispatch(
          addModalToList({
            body: '¿Estás seguro que querés cerrar tu sesión de nubceo?',
            required: true,
            okButton: {
              buttonText: 'Si',
              onClick: async () => {
                logoutUser(dispatch);
              },
            },
            cancelButton: {
              buttonText: 'No',
              onClick: async (): Promise<void> => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      },
    },
  ];
  const accountantMenuItems = [
    {
      icon: <AiOutlineUser size={25} />,
      text: 'Mi información',
      route: '/profile',
      onClick: (): void => {
        // segmentTrack(EVENTS.PROFILE.PAGE);
      },
    },
    {
      icon: <AiOutlineQuestionCircle size={25} />,
      text: 'Ayuda',
      route: '#',
      onClick: (): void => {
        dispatch(addModalToList({ type: ModalTypes.HELP }));
      },
    },
    {
      icon: <CloseIconSVG height={20} width={20} />,
      text: 'Cerrar sesión',
      route: '#',
      onClick: (): void => {
        dispatch(
          addModalToList({
            body: '¿Estás seguro que querés cerrar tu sesión de nubceo?',
            required: true,
            okButton: {
              buttonText: 'Si',
              onClick: async () => {
                logoutUser(dispatch);
              },
            },
            cancelButton: {
              buttonText: 'No',
              onClick: async (): Promise<void> => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      },
    },
  ];

  const handleNotificationClick = (): void => navigate('/notifications');

  return (
    <div className="background-white w-100 d-sm-flex align-items-center top-bar-component px-4 d-none">
      <div className="ms-auto me-2">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <p className="user-name">{user ? `${user?.firstName} ${user?.sureName}` : ''}</p>
            <p>{activity && <ActivityMedal activity={activity} className="color-gray fst-italic text-end ms-3" />}</p>
          </div>
        </div>
      </div>
      <DropdownMenuComponent toggle={<UserIconSVG width={30} height={33} />} items={isAccountant ? accountantMenuItems : menuItems} />
      <div className="badge-container">
        <FaRegBell onClick={handleNotificationClick} className="ms-3 color-blue notification-icon" size={33} />
        {fiservLinkingNotFtp ? <span className="badge">1</span> : null}
      </div>
    </div>
  );
};

export default TopBarComponent;
