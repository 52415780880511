import ar from '../../assets/countriesFlags/ar.png';
import uy from '../../assets/countriesFlags/uy.png';
import co from '../../assets/countriesFlags/co.png';
import usa from '../../assets/countriesFlags/usa.png';

const countries = [
  {
    code: 'AR',
    currencyCode: 'ARS',
    name: 'Argentina',
    imageFlag: ar,
    symbol: '$',
  },
  {
    code: 'US',
    currencyCode: 'USD',
    name: 'Estados Unidos',
    imageFlag: usa,
    symbol: 'U$S',
  },
  {
    code: 'UY',
    currencyCode: 'UYU',
    name: 'Uruguay',
    imageFlag: uy,
    symbol: '$',
  },
  {
    code: 'CO',
    currencyCode: 'COP',
    name: 'Colombia',
    imageFlag: co,
    symbol: '$',
  },
];
export default countries;
