import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { handleKeyDownDefault } from '../../utils/helper';
import { TextInputComponent } from '../inputs';
import { CloseIconSVG } from '../../assets/svgComponents';
import { FormButtonComponent } from '../buttons';
import { editBranchService } from '../../services/branchService';
import { asyncListBranches } from '../../redux/slices/branchSlice';
import successImage from '../../assets/images/success.png';
import errorHandlerHelper from '../../utils/errorHandler';

const ModalEditNameBranch: React.FC = () => {
  const dispatch = useAppDispatch();
  const { modalList } = useAppSelector((state) => state.modal);
  const modal = modalList[0];
  const [newNameBranch, setNewNameBranch] = useState('');

  const handleSubmit = async (): Promise<void> => {
    try {
      const { data: res } = await editBranchService({
        companyId: modal?.companyId || '',
        tenantId: modal?.tenantId || '',
        name: newNameBranch || '',
        id: modal?.branchId || '',
      });
      if (res.status === 200) {
        dispatch(removeModalFromList());
        dispatch(
          addModalToList({
            body: 'La sucursal se edito correctamente.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch<void>(asyncListBranches({ pageSize: '25', 'filter[companyId][in]': `[${modal?.companyId}]` }));
              },
            },
          }),
        );
      }
    } catch (error) {
      errorHandlerHelper(error, dispatch);
    }
  };

  const handleClose = (): void => {
    dispatch(removeModalFromList());
  };
  return (
    <div className="background-gray-60 modal-wrapper-edit-branch" onKeyDown={handleKeyDownDefault} role="none" id="modal">
      <div className="modal-popUp border-round d-flex flex-column position-relative">
        <div className="container-edit p-3">
          <button className="align-self-end close-button" type="button" onClick={() => dispatch(removeModalFromList())}>
            <CloseIconSVG className="me-1 color-blue" />
          </button>
          <div className="titleText">
            <p className="font-md">¿Qué nombre deseas asignarle a la sucursal agrupada?</p>
          </div>
          <br />
          <div className="input-container">
            <TextInputComponent
              containerClassName="w-100"
              className="input"
              placeholder="Ingresá el nombre"
              type="text"
              value={newNameBranch}
              onChange={(ev): void => setNewNameBranch(ev.target.value)}
              defaultValue={setNewNameBranch}
              autoComplete="new-email"
            />
          </div>
          <div className="button-container">
            <FormButtonComponent
              className="submit-button mt-4 me-1 w-100"
              disabled={!newNameBranch.length}
              onClick={(): Promise<void> => handleSubmit()}
            >
              Modificar
            </FormButtonComponent>
            <FormButtonComponent className="submit-button mt-4 me-1 w-100" onClick={(): void => handleClose()}>
              Cancelar
            </FormButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditNameBranch;
