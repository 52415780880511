import moment from 'moment';
import React from 'react';
import { FaDollarSign, FaFileDownload, FaStore, FaCreditCard, FaUsers, FaHome, FaCog, FaFile, FaChartPie, FaCube } from 'react-icons/fa';
import { optionsDateValues, THIS_MONTH_DATE_FILTER_OPTION } from '../../utils/constants';
import { SidebarItem } from '../../utils/types';

const startExpenseReports = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][0];
const endExpenseReports = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][1];

const startFilterSalesDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
const endFilterSalesDate = moment().add(1, 'days').format('YYYY-MM-DD');

export const showSidebarData = (): SidebarItem[] => {
  const regularSidebarData: SidebarItem[] = [
    {
      title: 'Inicio',
      path: '/',
      icon: <FaHome size={25} />,
    },
    {
      title: 'Liquidaciones',
      path: `/settlements?startDate=${startFilterSalesDate}&endDate=${endFilterSalesDate}`,
      icon: <FaDollarSign size={25} />,
    },
    {
      title: 'Ventas',
      path: `/sales?startDate=${startFilterSalesDate}&endDate=${endFilterSalesDate}`,
      icon: <FaCreditCard size={25} />,
    },
    {
      title: 'Gastos',
      path: `/expense-reports?startDate=${startExpenseReports}&endDate=${endExpenseReports}&presetDateFilters=${THIS_MONTH_DATE_FILTER_OPTION}`,
      icon: <FaChartPie size={25} />,
    },
    {
      title: 'Mi negocio',
      path: '/companies',
      icon: <FaStore size={25} />,
    },
    {
      title: 'Reportes',
      path: null,
      icon: <FaFile size={25} />,
      subnav: [
        { title: 'Solicitudes', path: '/reports', icon: <FaFileDownload size={25} /> },
        { title: 'Smart Data', path: '/smart-data', icon: <FaCube size={20} /> },
      ],
    },
    {
      title: 'Configuración',
      path: '/settings',
      icon: <FaCog size={25} />,
    },
  ];
  return regularSidebarData;
};

export const accountantSidebarData: SidebarItem[] = [
  {
    title: 'Clientes',
    path: '/clients',
    icon: <FaUsers size={25} />,
  },
  {
    title: 'Reportes',
    path: '/reports',
    icon: <FaFileDownload size={25} />,
  },
];
