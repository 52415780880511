import * as React from 'react';
import { SVGProps } from '../../utils/types';

const ArrowIcon = ({ className, width = 12, height = 14 }: SVGProps): React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M11.92 7.38a1 1 0 01-.21.33l-5 5a1 1 0 01-1.639-.325 1 1 0 01.219-1.095L8.59 8H1a1 1 0 110-2h7.59l-3.3-3.29a1.004 1.004 0 011.42-1.42l5 5a1 1 0 01.21.33 1 1 0 010 .76z" />
  </svg>
);

export default ArrowIcon;
