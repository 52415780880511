import { AxiosPromise } from 'axios';
import { ListsAxiosPromise, Sale } from '../utils/interfaces';
import { FilterParams } from '../utils/types';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

export const getSales = (params: FilterParams): AxiosPromise<ListsAxiosPromise<Sale[]>> => axiosV2.get('/sale', { params: { ...params } });

export const getSale = (id: string): AxiosPromise<ListsAxiosPromise<Sale>> => axiosV2.get(`/sale/${id}`);

export const getSummarySalesByPe = (id: number, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/sales/summary-by-pe`, { params: { ...params } });

export const getSalesClient = (client: number, params?: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${client}/sales`, { params });

export const getSalesXLSX = (params: FilterParams): AxiosPromise => axios.get('/sale/generate-report/xlsx', { params: { ...params } });
