import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { meliAuth } from '../../services/meliService';
import Logo from '../../assets/images/integration.png';
import errorCodeManager from '../../utils/errorCodeManager';
import errorCodes from '../../utils/errorCodes.json';
import { MeliProps } from '../../utils/types';

type MeliResponse = {
  title: string;
  message: string;
};

const meliResponse = (title: string, message: string): MeliResponse => ({
  title,
  message,
});

const prepareMeliAuth = async ({ code, state }: MeliProps): Promise<{ title: string; message: string }> => {
  if (code && state) {
    try {
      const [tenantId, platformExternalCredentialId] = state.split('-');
      await meliAuth({ code, id: platformExternalCredentialId, tenantId });
      return meliResponse('Conexión exitosa', 'Tu cuenta se conectó con éxito');
    } catch (error) {
      if (error.response?.data.code === '1005') {
        return meliResponse('Ocurrió un error', 'Esta cuenta ya fué conectada.');
      }
      if (error.response?.data.status === 409) {
        return meliResponse('Ocurrió un error', 'La cuenta que estás intentando conectar ya fue vinculada para la misma empresa.');
      }
      return meliResponse('Lo sentimos', 'Hubo un error al conectar la cuenta.');
    }
  } else {
    return meliResponse('Lo sentimos', 'Ocurrió un error inesperado.');
  }
};

const Meli: FC = () => {
  const [response, setResponse] = useState<{ title: string; message: string } | null>(null);
  const code = new URLSearchParams(useLocation().search).get('code');
  const state = new URLSearchParams(useLocation().search).get('state');
  const error = new URLSearchParams(useLocation().search).get('error');
  useEffect(() => {
    if (code && state)
      prepareMeliAuth({ code, state, error })
        .then((res) => setResponse(res))
        .catch((err) => setResponse(err));
    else if (error !== null) {
      const errorsKnown = errorCodeManager.find((errorCode) => errorCode.code === error.trim());
      if (errorsKnown) {
        setResponse(meliResponse(errorsKnown.title, errorsKnown.message));
      } else {
        setResponse(meliResponse(errorCodes.default.title, errorCodes.default.message));
      }
    }
  }, [code, state, error]);

  return (
    <div>
      <div className="header">
        <p className="header-title fw-bold font-lg">Vinculación de MercadoPago</p>
      </div>
      <div className="row content d-flex align-items-center mb-5">
        <div className="img-container col-12 col-lg-6">
          <img src={Logo} alt="Nubceo Logo" className="img" />
        </div>
        <div className="container-content col-12 col-lg-5">
          {!response ? (
            <div className="meli-loader" />
          ) : (
            <div className="text-center">
              <h2 className="color-gray-dark">{response.title}</h2>
              <p className={`font-lg ${error !== null ? 'color-red' : 'color-gray-dark'} font-weight-medium`}>{response.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Meli;
