import React from 'react';
import { platformExternalCodesTrimmed } from './constants';
import imgRappi from '../assets/images/externalPlatforms/delivery/rappi.png';
import imgPrisma from '../assets/images/externalPlatforms/card/prisma.png';
import imgPedidosYa from '../assets/images/externalPlatforms/delivery/pedidosya.png';
import imgPasscard from '../assets/images/externalPlatforms/delivery/passcard.png';
import imgEdenred from '../assets/images/externalPlatforms/delivery/edenred.png';
import imgDLocal from '../assets/images/externalPlatforms/delivery/d-local.png';
import imgCreditel from '../assets/images/externalPlatforms/delivery/creditel.png';
import imgFiserv from '../assets/images/externalPlatforms/card/fiserv.png';
import imgMercadoPago from '../assets/images/externalPlatforms/wallet/mercadopago.png';
import imgVisaNet from '../assets/images/externalPlatforms/card/visa.png';
import imgItalcred from '../assets/images/externalPlatforms/card/italcred.png';
import imgGetnet from '../assets/images/externalPlatforms/card/getnet.png';
import imgAccor from '../assets/images/externalPlatforms/card/accor.png';
import imgContabilium from '../assets/images/externalPlatforms/erp/contabilium.png';
import imgZoologic from '../assets/images/externalPlatforms/erp/zoologic.png';
import imgModdo from '../assets/images/externalPlatforms/erp/moddo.png';
import imgNaranja from '../assets/images/externalPlatforms/card/naranja.png';
import imgBillSantaFe from '../assets/images/externalPlatforms/wallet/santafe.png';
import imgOca from '../assets/images/externalPlatforms/card/oca.png';
import imgCash from '../assets/images/externalPlatforms/cash/cash.png';
import imgTiendaNube from '../assets/images/externalPlatforms/ecommerce/tiendanube.png';
import imgAmex from '../assets/images/externalPlatforms/card/amex.png';
import imgCabal from '../assets/images/externalPlatforms/card/cabal.png';
import imgMaxirest from '../assets/images/externalPlatforms/erp/maxirest.png';
import imgXubio from '../assets/images/externalPlatforms/erp/xubio.png';
import imgDiners from '../assets/images/externalPlatforms/card/diners.png';
import imgAnda from '../assets/images/externalPlatforms/card/anda.png';
import imgCDirectos from '../assets/images/externalPlatforms/card/creditosDirectos.png';
import imgCiti from '../assets/images/externalPlatforms/card/citi.png';
import imgDidi from '../assets/images/externalPlatforms/delivery/didi.png';
import imgColpatria from '../assets/images/externalPlatforms/card/colpatria.png';

type externalPlatformIconType = {
  code?: string;
  codeDataFlex?: string;
  className?: string;
};
const icons = {
  [platformExternalCodesTrimmed.RAPPI]: { logoImage: imgRappi },
  [platformExternalCodesTrimmed.PRISMA]: { logoImage: imgPrisma },
  [platformExternalCodesTrimmed.PEDIDOSYA]: { logoImage: imgPedidosYa },
  [platformExternalCodesTrimmed.FISERV]: { logoImage: imgFiserv },
  [platformExternalCodesTrimmed.MERCADOPAGO]: { logoImage: imgMercadoPago },
  [platformExternalCodesTrimmed.CONTABILIUM]: { logoImage: imgContabilium },
  [platformExternalCodesTrimmed.ZOOLOGIC]: { logoImage: imgZoologic },
  [platformExternalCodesTrimmed.MODDO]: { logoImage: imgModdo },
  [platformExternalCodesTrimmed.VISANET]: { logoImage: imgVisaNet },
  [platformExternalCodesTrimmed.ITALCRED]: { logoImage: imgItalcred },
  [platformExternalCodesTrimmed.GETNET]: { logoImage: imgGetnet },
  [platformExternalCodesTrimmed.ACCOR]: { logoImage: imgAccor },
  [platformExternalCodesTrimmed.NARANJA]: { logoImage: imgNaranja },
  [platformExternalCodesTrimmed.BILSANTAFE]: { logoImage: imgBillSantaFe },
  [platformExternalCodesTrimmed.OCA]: { logoImage: imgOca },
  [platformExternalCodesTrimmed.TIENDANUBE]: { logoImage: imgTiendaNube },
  [platformExternalCodesTrimmed.CASH]: { logoImage: imgCash },
  [platformExternalCodesTrimmed.AMEX]: { logoImage: imgAmex },
  [platformExternalCodesTrimmed.CABAL]: { logoImage: imgCabal },
  [platformExternalCodesTrimmed.MAXIREST]: { logoImage: imgMaxirest },
  [platformExternalCodesTrimmed.XUBIO]: { logoImage: imgXubio },
  [platformExternalCodesTrimmed.PASSCARD]: { logoImage: imgPasscard },
  [platformExternalCodesTrimmed.EDENRED]: { logoImage: imgEdenred },
  [platformExternalCodesTrimmed.D_LOCAL]: { logoImage: imgDLocal },
  [platformExternalCodesTrimmed.CREDITEL]: { logoImage: imgCreditel },
  [platformExternalCodesTrimmed.ANDA]: { logoImage: imgAnda },
  [platformExternalCodesTrimmed.DINERS]: { logoImage: imgDiners },
  [platformExternalCodesTrimmed.CDIRECTOS]: { logoImage: imgCDirectos },
  [platformExternalCodesTrimmed.CITI]: { logoImage: imgCiti },
  [platformExternalCodesTrimmed.DIDI]: { logoImage: imgDidi },
  [platformExternalCodesTrimmed.COLPATRIA]: { logoImage: imgColpatria },
};

const externalPlatformIcon = ({ code, codeDataFlex, className }: externalPlatformIconType): React.ReactElement => {
  if (code) {
    return (
      <img
        className={className}
        src={icons[code?.indexOf('_') > -1 ? code.toLowerCase().trim().slice(0, -3) : code]?.logoImage}
        alt={code}
      />
    );
  }
  if (codeDataFlex) {
    const codeConcat = codeDataFlex?.concat('_ar');
    return (
      <img
        className={className}
        src={icons[codeConcat?.indexOf('_') > -1 ? codeConcat.toLowerCase().trim().slice(0, -3) : codeConcat]?.logoImage}
        alt={code}
      />
    );
  }
  return <img className={className} src={code && icons[code]?.logoImage} alt={code} />;
};

export default externalPlatformIcon;
