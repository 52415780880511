import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import ReactTooltip from 'react-tooltip';
import CardCollection from '../../components/CardCollection';
import CardTaxation from '../../components/CardTaxation';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { formatNumber, mesaggeError } from '../../utils/stringUtils';
import variables from '../../scss/_exports.module.scss';
import { fetchTaxesBalance } from '../../redux/slices/taxesSlice';
import { FormButtonComponent } from '../../components';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import useDeviceInfo from '../../hooks/useUserDeviceInfo';
import { fetchPresentCashflowSummaryDay } from '../../redux/slices/cashflowSlice';
import { STEPS_DASHBOARD, joyrideStyles } from '../../components/Walkthrough';
import { getTrialStatus } from '../../utils/helper';
import errorImage from '../../assets/images/error.png';
import errorHandlerHelper from '../../utils/errorHandler';
import { ActivityCode, CashflowSummary } from '../../utils/interfaces';
import { StorageVars } from '../../utils/types';
// import ModalContainerFeedback from '../../containers/ModalContainerFeedback';
import { ModalTypes } from '../../containers/ModalContainer';
import { ENV_URL } from '../../config';

const DashboardComponent: React.FC = () => {
  const deviceInfo = useDeviceInfo();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, activity } = useAppSelector((state) => state.userProfile);
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const { presentCashflowSummaryDayList, presentCashflowSummaryDayLoading } = useAppSelector((state) => state.cashflow);
  const { balanceTaxes, loading: taxesLoading, failedQueries } = useAppSelector((state) => state.taxes);
  const [todayAmount, setTodayAmount] = useState<string | null>(null);
  const [nextAmount, setNextAmount] = useState<string | null>(null);
  const [nextDate, setNextDate] = useState<string | null>(null);
  const [currentMonthAmount, setCurrentMonthAmount] = useState<string | null>(null);
  const [lastMonthAmount, setLastMonthAmount] = useState<string | null>(null);
  const [prevLastMonthAmount, setPrevLastMonthAmount] = useState<string | null>(null);
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  const [trialStatus, setTrialStatus] = useState<boolean>(false);
  const [hasCurrencyChanged, setHasCurrencyChanged] = useState<boolean>(false);
  const isAccountant = activity === ActivityCode.CONTADOR;
  const currentURL = window.location.href;
  const API_URL = ENV_URL;
  const omittedFeedback = window.localStorage.getItem(StorageVars.omittedFeedback);

  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialRemainingDays } = cashTier;
        if (trialRemainingDays !== 0) {
          setTrialStatus(true);
        } else {
          setTrialStatus(false);
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  useEffect(() => {
    setHasCurrencyChanged(true);
  }, [activeCurrency?.code]);

  useEffect(() => {
    const fetchBalanceForMonth = async (monthsAgo: number) => {
      const startDate = moment().startOf('month').subtract(monthsAgo, 'month').format();
      const endDate = moment().endOf('month').subtract(monthsAgo, 'month').format();

      try {
        await dispatch(
          fetchTaxesBalance({
            'filter[date][between]': `${startDate},${endDate}`,
          }),
        );
      } catch (err) {
        errorHandlerHelper(err, dispatch);
      }
    };

    const fetchData = async () => {
      if (activeCurrency?.code) {
        const start = moment().utc().startOf('day').format();
        const end = moment().add(31, 'days').utc().endOf('day').format();

        const params = {
          'filter[date][between]': `${start},${end}`,
          'filter[currencyCode][eq]': activeCurrency.code,
        };

        await dispatch(fetchPresentCashflowSummaryDay(params));

        try {
          await fetchBalanceForMonth(0);
          await fetchBalanceForMonth(1);
          await fetchBalanceForMonth(2);
        } catch (err) {
          errorHandlerHelper(err, dispatch);
        }
      }
    };
    if (!balanceTaxes.length || failedQueries !== 0 || hasCurrencyChanged) {
      fetchData();
    }
  }, [activeCurrency?.code]);

  useEffect(() => {
    if (failedQueries && failedQueries === 1 && presentCashflowSummaryDayLoading !== 'failed') {
      if (currentURL === API_URL) {
        dispatch(
          addModalToList({
            body: mesaggeError(),
            pictureUrl: errorImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      }
    }
  }, [failedQueries]);

  useEffect(() => {
    if (runWalkthrough) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [runWalkthrough]);

  const getNextCollection = (): CashflowSummary | undefined => {
    const reportData = presentCashflowSummaryDayList && presentCashflowSummaryDayList.length > 0 ? [...presentCashflowSummaryDayList] : [];
    const nextCollection = reportData
      ?.sort((a, b) => moment(a.summaryDate).diff(moment(b.summaryDate).format('YYYY-MM-DD')))
      .find((col) => col.amountIn - col.amountOut > 0 && moment(col.summaryDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD'));
    return nextCollection;
  };

  const calculateTotals = (): void => {
    setTodayAmount(null);
    setNextDate(null);
    setNextAmount(null);
    const todayCollection = presentCashflowSummaryDayList.find(
      (col) => moment(col.summaryDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
    );
    if (todayCollection) {
      const balance = todayCollection.amountIn - todayCollection.amountOut;
      setTodayAmount(formatNumber(balance));
    }
    if (getNextCollection()) {
      setNextDate(getNextCollection()?.summaryDate ?? null);
      const balance = (getNextCollection()?.amountIn ?? 0) - (getNextCollection()?.amountOut ?? 0);
      setNextAmount(formatNumber(balance));
    }
  };

  const displayFeedbackModal = (): void => {
    if (!trialStatus && !isAccountant && currentURL === API_URL) {
      const now = moment();
      const feedbackDate = moment(user?.createdAt);
      const lastFeedbackAtDate = moment(user?.lastFeedbackAt);

      const isFeedbackDate = now > feedbackDate.add(30, 'days');
      const secondFeedback = now > lastFeedbackAtDate.add(90, 'days');

      const showModal = (): ReturnType<typeof setTimeout> =>
        setTimeout(() => {
          dispatch(addModalToList({ type: ModalTypes.FEEDBACK, required: true }));
        }, 30000);

      if (!user) clearTimeout(showModal());
      if (!user?.lastFeedbackAt && isFeedbackDate) {
        if (trialStatus === false) {
          showModal();
        }
      } else if (user?.lastFeedbackAt && secondFeedback) {
        showModal();
      }
    }
  };

  const modalDownloadApp = (): void => {
    const omittedDownload = localStorage.getItem('omittedDownloadApp');
    if (!omittedDownload && deviceInfo.any()) {
      setTimeout(() => {
        dispatch(addModalToList({ type: ModalTypes.DOWNLOAD, required: true }));
        localStorage.setItem('omittedDownloadApp', new Date().toString());
      }, 3000);
    }
  };
  const walkthrough = (): void => {
    const tourViewed = window.localStorage.getItem('walkthrough');
    if (!tourViewed) {
      setRunWalkthrough(true);
    } else {
      modalDownloadApp();
      if (!omittedFeedback) {
        localStorage.setItem(StorageVars.omittedFeedback, new Date().toString());
        displayFeedbackModal();
      }
    }
  };

  useEffect(() => {
    walkthrough();
  }, [trialStatus]);

  useEffect(() => {
    calculateTotals();

    if (balanceTaxes && balanceTaxes.length > 0 && activeCurrency?.countryCode) {
      const balance = balanceTaxes && balanceTaxes.length > 0 ? [...balanceTaxes] : [];
      balance.forEach((item) => {
        if (moment(item.date).month() === moment().month()) {
          setCurrentMonthAmount(
            formatNumber(
              item.taxes.filter((tax) => tax.currencyCode === activeCurrency.code).reduce((sum, current) => sum + current.amount, 0),
            ),
          );
        }
        if (moment(item.date).month() === moment().subtract(1, 'month').month()) {
          setLastMonthAmount(
            formatNumber(
              item.taxes.filter((tax) => tax.currencyCode === activeCurrency.code).reduce((sum, current) => sum + current.amount, 0),
            ),
          );
        }
        if (moment(item.date).month() === moment().subtract(2, 'month').month()) {
          setPrevLastMonthAmount(
            formatNumber(
              item.taxes.filter((tax) => tax.currencyCode === activeCurrency.code).reduce((sum, current) => sum + current.amount, 0),
            ),
          );
        }
      });
    }
  }, [presentCashflowSummaryDayList, balanceTaxes, activeCurrency]);

  const nextDateFormatted = nextDate ? moment(nextDate).format('D MMMM') : null;
  const currentDateFormatted = moment().format('D MMMM');

  const handleJoyrideCallback = (data: { action?: any; status?: any }): void => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      navigate('/calendar');
    } else if (data.action === ACTIONS.CLOSE) {
      window.localStorage.setItem('walkthrough', 'true');
      window.localStorage.setItem('walkthroughCompanies', 'true');
      window.localStorage.setItem('walkthroughPlatforms', 'true');
    }
  };
  return (
    <>
      <div className="first-step dashboard-component mb-4">
        {runWalkthrough ? (
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            run
            disableCloseOnEsc
            disableOverlayClose
            steps={STEPS_DASHBOARD}
            styles={joyrideStyles}
          />
        ) : null}
        <div className="row cards-container">
          <div className="collections-cards-today col-12 col-lg-6">
            <div className="d-flex flex-row align-items-center">
              <p className="description-title">Ingresos</p>
              <IoInformationCircleOutline
                className="mt-1 cursor-pointer"
                color={variables.colorBlue}
                size={22}
                data-tip="Importes netos a cobrar: ventas brutas descontando comisiones y descuentos. Según informado por cada plataforma de
                    cobro."
              />
            </div>
            <CardCollection
              unconfirmed={undefined}
              currencyCode={activeCurrency?.code}
              amount={todayAmount}
              date={currentDateFormatted}
              today
              dateText="Hoy"
              loading={presentCashflowSummaryDayLoading}
            />
          </div>
          <div className="collections-cards-next col-12 col-lg-6">
            <CardCollection
              unconfirmed={getNextCollection()?.unconfirmed}
              amount={nextAmount}
              currencyCode={activeCurrency?.code}
              date={nextDateFormatted || currentDateFormatted}
              dateText="Próximo"
              today={false}
              loading={presentCashflowSummaryDayLoading}
            />
          </div>
          <div className="my-calendar-step text-center mt-3">
            <FormButtonComponent onClick={(): void => navigate('/calendar')}>Ver calendario mensual</FormButtonComponent>
          </div>
          <div>
            <div className="taxes-step card-container">
              <div className="d-flex flex-row align-items-center">
                <p className="description-title">Impuestos</p>
                <IoInformationCircleOutline
                  className="mt-1 cursor-pointer"
                  color={variables.colorBlue}
                  size={22}
                  data-tip="Los importes indicados corresponden a los impuestos retenidos o cobrados por parte de las plataformas de cobro, de
                      acuerdo a lo informado por las mismas."
                />
              </div>
              <CardTaxation
                currencyCode={activeCurrency?.code}
                currentMonthAmount={currentMonthAmount}
                lastMonthAmount={lastMonthAmount}
                prevLastMonthAmount={prevLastMonthAmount}
                loading={taxesLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </>
  );
};
export default DashboardComponent;
