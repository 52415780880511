import React, { FC, useState, ReactNode, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Field, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import { MessageToApplyFilters, externalPlatformsBasicData } from '../../utils/constants';
import { paramsToObject } from '../../utils/helper';
import {
  SelectInputComponent,
  FilterTabComponent,
  FormButtonComponent,
  SearchInputComponent,
  Paragraph,
  DateInputComponent,
} from '../../components';
import variables from '../../scss/_exports.module.scss';
import { FormFieldType } from '../../utils/types';
import { useAppSelector } from '../../redux/hooks';
import { ExternalActivePlatform } from '../../utils/interfaces';
import { ScreenType, clearAllFilters, deleteFilterTab, getInitialValues, handleSubmit, sortPlatforms } from '../../utils/helperFilters';

type FiltersCalendarProps = {
  setSelectedMonth?: React.Dispatch<React.SetStateAction<moment.Moment>>;
  selectMonth?: moment.Moment;
};

const FilterCalendar: FC<FiltersCalendarProps> = ({ setSelectedMonth, selectMonth }): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const URLFilters: Record<string, string> = paramsToObject(new URLSearchParams(location.search));
  const { companiesList } = useAppSelector((state) => state.company);
  const { externalUsedPlatformList: platformListUsed } = useAppSelector((state) => state.externalPlatform);
  const companiesListSorted = [...companiesList]?.sort((company) => (company.enabled ? -1 : 1));
  const [platformExternalArray, setPlatformExternalArray] = useState<ExternalActivePlatform[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string[]>();
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedMonthCalendar, setSelectedMonthCalendar] = useState(moment(selectMonth).format('YYYY-MM').toString());

  const applyFilters = (values: FormikValues): void => {
    handleSubmit(values, navigate, url);
  };

  useMemo(() => setPlatformExternalArray(platformListUsed), [platformListUsed]);

  const initialValues = getInitialValues(URLFilters, false);

  const changeSelectMonth = (newDate: string): void => {
    const formattedDate = moment(newDate, 'YYYY-MM').endOf('month');
    if (setSelectedMonth) {
      setSelectedMonth(formattedDate);
    }
  };

  const clearDateFilter = (): void => {
    const currentMonth = moment().format('YYYY-MM').toString();
    setSelectedMonthCalendar(currentMonth);
    changeSelectMonth(currentMonth);
  };

  return (
    <div className="border-round flex-grow-1">
      <Formik onSubmit={applyFilters} initialValues={initialValues}>
        {({ values, setFieldValue }): ReturnType<FC> => (
          <>
            <Form id="filters">
              <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                <Field name="companies">
                  {({ field }: FormFieldType): ReactNode => {
                    const companiesArray = companiesListSorted;

                    return (
                      <SearchInputComponent
                        label="Empresas"
                        options={companiesArray.map((company) => ({
                          id: company?.id,
                          value: company?.id,
                          name: company?.name,
                          pill: company.enabled ? undefined : (
                            <div className="filter-tag px-3 py-1 inactive-badge">
                              <Paragraph color={variables.colorGray}>Inactiva</Paragraph>
                            </div>
                          ),
                        }))}
                        onOptionClick={(name, value): void => {
                          setSelectedCompany(value);
                          setFieldValue(name, value);
                        }}
                        containerClassName="input"
                        placeholder={
                          companiesListSorted?.length === values.companies?.length ? 'Todas las empresas' : 'Seleccionar empresa(s)'
                        }
                        {...field}
                        value={selectedCompany?.length ? values.companies : []}
                      />
                    );
                  }}
                </Field>
                <Field name="platformCode">
                  {({ field }: FormFieldType): ReactNode => {
                    const platformArray = platformExternalArray;
                    return (
                      <SelectInputComponent
                        label="Plataforma"
                        options={platformArray.reduce<{ id: string | number; name: string; value: string }[]>((acc, platform) => {
                          const found = acc.find((el) => el.name === externalPlatformsBasicData(platform?.platformExternalCode)?.name);
                          if (!found) {
                            acc.push({
                              id: platform.id,
                              name: externalPlatformsBasicData(platform.platformExternalCode)?.name,
                              value: platform.platformExternalCode,
                            });
                          }
                          return sortPlatforms(acc);
                        }, [])}
                        containerClassName="input"
                        onOptionClick={(name, value): void => {
                          setSelectedPlatform(value);
                          setFieldValue(name, value);
                        }}
                        {...field}
                        value={selectedPlatform ? values.platformCode : ''}
                        placeholder="Todas las Plataformas"
                      />
                    );
                  }}
                </Field>
                <Field>
                  {({ field }: FormFieldType): ReactNode => (
                    <DateInputComponent
                      label="Fecha"
                      containerClassName="input"
                      {...field}
                      value={selectedMonthCalendar || ''}
                      type="month"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        const selectedDate = e.target.value;
                        setSelectedMonthCalendar(selectedDate);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="w-100">
                {Object.values(values).find((elem) => elem !== null) ? <MessageToApplyFilters /> : null}
                <FormButtonComponent type="submit" className="mt-3" onClick={(): void => changeSelectMonth(selectedMonthCalendar)}>
                  Aplicar filtros
                </FormButtonComponent>
              </div>
              <FormButtonComponent
                onClick={(): void => {
                  clearAllFilters(ScreenType.CALENDAR, setFieldValue, initialValues, navigate);
                  setSelectedCompany([]);
                  clearDateFilter();
                }}
                className="mt-3"
              >
                Limpiar filtros
              </FormButtonComponent>
            </Form>
            {Object.keys(URLFilters).filter((filter) => filter !== 'page').length
              ? Object.values(URLFilters).find((value) => value) && (
                  <div className="w-100 p-0 mt-5 d-flex flex-column">
                    <p className="flex-grow-1 font-weight-bold">Filtros Aplicados</p>
                    <div className="d-flex flex-wrap">
                      {Object.keys(URLFilters).map((property) =>
                        URLFilters[property] ? (
                          <FilterTabComponent
                            property={property}
                            key={property}
                            array={URLFilters}
                            onClick={(): void => {
                              deleteFilterTab(values, property, navigate, url);
                              setFieldValue(property, null);
                            }}
                          />
                        ) : null,
                      )}
                    </div>
                  </div>
                )
              : null}
          </>
        )}
      </Formik>
    </div>
  );
};

export default FilterCalendar;
