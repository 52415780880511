import moment from 'moment';
import React, { FC } from 'react';
import { LoaderSpinner } from '..';
import { currencyFormat } from '../../utils/stringUtils';
import { LoadingStateType } from '../../utils/types';

interface CardTaxationProps {
  currentMonthAmount: string | null;
  lastMonthAmount: string | null;
  prevLastMonthAmount: string | null;
  currencyCode: string | undefined;
  loading: LoadingStateType;
}
const CardTaxation: FC<CardTaxationProps> = ({
  currentMonthAmount,
  lastMonthAmount,
  prevLastMonthAmount,
  loading,
  currencyCode,
}: CardTaxationProps) => (
  <div className="card-taxation">
    <div className="card-body">
      <div className="prev-months-container">
        <div className="prev-month-1">
          <p className="prev-month-1-date">{moment().subtract('2', 'month').locale('es').format('MMMM YYYY')}</p>
          <p className="prev-month-1-amount">
            {loading !== 'pending' ? (
              // anterior2
              `${currencyCode ? currencyFormat(currencyCode) : '$'} ${prevLastMonthAmount ?? '0'}`
            ) : (
              <LoaderSpinner />
            )}
          </p>
        </div>
        <div className="prev-month">
          <p className="prev-month-date">{moment().utc().subtract('1', 'month').locale('es').format('MMMM YYYY')}</p>
          <p className="prev-month-amount">
            {/* anterior1 */}
            {loading !== 'pending' ? `${currencyCode ? currencyFormat(currencyCode) : '$'} ${lastMonthAmount ?? '0'}` : <LoaderSpinner />}
          </p>
        </div>
      </div>
      <hr className="line-through" />
      <div className="current-month-container">
        <p className="current-month-date">{moment().locale('es').format('MMMM YYYY')}</p>
        <p className="current-month-amount">
          {/* actual */}
          {loading !== 'pending' ? `${currencyCode ? currencyFormat(currencyCode) : '$'} ${currentMonthAmount ?? '0'}` : <LoaderSpinner />}
        </p>
      </div>
    </div>
  </div>
);
export default CardTaxation;
