import React, { FC } from 'react';
import Modal from '../../components/Modal';
import ModalDownload from '../../components/ModalDownloadApp';
import ModalHelp from '../../components/ModalHelp';
import ModalFeedback from '../../components/ModalFeedback';
import { useAppSelector } from '../../redux/hooks';
import ModalEditNameBranch from '../../components/ModalEditNameBranch';

export enum ModalTypes {
  EDIT_NAME_BRANCH = 'EDIT_NAME_BRANCH',
  FEEDBACK = 'FEEDBACK',
  DOWNLOAD = 'DOWNLOAD',
  HELP = 'HELP',
  MARKETING = 'MARKETING',
}

const ModalContainer: FC = () => {
  const { user } = useAppSelector((state) => state.userProfile);
  const { modalList } = useAppSelector((state) => state.modal);

  if (modalList?.length) {
    if (modalList[0]?.body) {
      return <Modal />;
    }
    if (modalList[0]?.type) {
      switch (modalList[0]?.type) {
        case ModalTypes.FEEDBACK:
          return user ? <ModalFeedback /> : null;
        case ModalTypes.EDIT_NAME_BRANCH:
          return user ? <ModalEditNameBranch /> : null;
        case ModalTypes.DOWNLOAD:
          return user ? <ModalDownload /> : null;
        case ModalTypes.HELP:
          return user ? <ModalHelp /> : null;
        default:
          break;
      }
    }
  }
  return null;
};
export default ModalContainer;
