/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import logger from '../../services/loggerService';
import { SettlementWithCollections } from '../../utils/interfaces';
import { FilterParams, LoadingStateType } from '../../utils/types';
import { getSettlement, getSettlementClient } from '../../services/settlementService';

interface SettlementResponse {
  data: Array<SettlementWithCollections>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
  status: number;
}
interface SettlementsState {
  settlementsList: Array<SettlementWithCollections> | null;
  loadingSettlements: LoadingStateType;
  loadingSettlementsClient: LoadingStateType;
  pages: number;
  total: number;
}

interface ClientIdAndParams {
  clientId: number;
  params?: FilterParams;
}

export const fetchSettlements = createAsyncThunk<SettlementResponse, FilterParams>('settlement/with-collections', async (params) => {
  const { data: response } = await getSettlement(params);
  return response;
});

export const asyncListSettlementsClient = createAsyncThunk<SettlementResponse, ClientIdAndParams>(
  'settlement/with-collections-clients',
  async ({ clientId, params }) => {
    const { data: response } = await getSettlementClient(clientId, params || {});
    return response.data;
  },
);

const initialState: SettlementsState = {
  settlementsList: [],
  loadingSettlements: 'idle',
  loadingSettlementsClient: 'idle',
  pages: 1,
  total: 0,
};

export const settlementsSlice = createSlice({
  name: 'settlement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettlements.pending, (state, _action) => {
      state.loadingSettlements = 'pending';
    });
    builder.addCase(fetchSettlements.rejected, (state, action) => {
      state.loadingSettlements = 'failed';
      logger.error('fetchSettlements.error', action.error);
    });
    builder.addCase(fetchSettlements.fulfilled, (state, action) => {
      state.loadingSettlements = 'succeeded';
      state.settlementsList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
    });
    builder.addCase(asyncListSettlementsClient.pending, (state, _action) => {
      state.loadingSettlementsClient = 'pending';
    });
    builder.addCase(asyncListSettlementsClient.rejected, (state, action) => {
      state.loadingSettlementsClient = 'failed';
      logger.error('fetchSettlements.error', action.error);
    });
    builder.addCase(asyncListSettlementsClient.fulfilled, (state, action) => {
      state.loadingSettlementsClient = 'succeeded';
      state.settlementsList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
    });
  },
});

export default settlementsSlice.reducer;
