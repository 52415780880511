import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FormButtonComponent } from '../../../buttons';

interface PlatformCredentialsFooterButtonsProps {
  disabled?: boolean;
  deleteButtonText?: string;
  handleDeletePlatform?: () => void;
  redirectingPlatformExternal?: boolean;
}

const PlatformCredentialsFooterButtons: FC<PlatformCredentialsFooterButtonsProps> = ({
  disabled = false,
  deleteButtonText,
  handleDeletePlatform,
  redirectingPlatformExternal,
}) => {
  const { id } = useParams();

  const renderTextButton = (): string => {
    let msg = 'Vincular';
    if (id !== 'new') {
      msg = 'Guardar';
    }
    if (id !== 'new' && redirectingPlatformExternal) {
      msg = 'Volver a vincular';
    }
    return msg;
  };

  return (
    <>
      <FormButtonComponent disabled={disabled} className="submit-button mt-4 w-100" type="submit">
        {renderTextButton()}
      </FormButtonComponent>
      {id !== 'new' && handleDeletePlatform && (
        <FormButtonComponent className="mt-2 isNewPlatform ms-2" onClick={(): void => handleDeletePlatform()}>
          {deleteButtonText || 'Eliminar'}
        </FormButtonComponent>
      )}
    </>
  );
};

export default PlatformCredentialsFooterButtons;
