/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import logger from '../../services/loggerService';
import { getBalance, getSummaryTaxesByPe, getTaxesClient, getTaxTypes } from '../../services/taxesService';
import {
  SummaryTax,
  SummaryTaxesByPeResponse,
  Tax,
  TaxBalance,
  TaxesByClientResponse,
  TaxType,
  TaxTypesByClientResponse,
} from '../../utils/interfaces';
import { FilterParams } from '../../utils/types';
import errorHandlerHelper from '../../utils/errorHandler';

interface TaxesResponse {
  documents: {
    docs: Array<TaxBalance>;
    pages: number;
  };
}
interface TaxesState {
  balanceTaxes: Array<TaxBalance>;
  taxesList: Array<Tax> | null;
  taxTypes: Array<TaxType>;
  summaryTaxes: Array<SummaryTax>;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingClient: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingSummaryTax: 'idle' | 'pending' | 'succeeded' | 'failed';
  pages: number;
  count: number;
  total: number;
  failedQueries: 0;
}

interface IdAndParams {
  id: number;
  params?: FilterParams;
}

export const fetchTaxesBalance = createAsyncThunk<TaxesResponse, FilterParams>('taxes/getBalance', async (params, { dispatch }) => {
  try {
    const { data: response } = await getBalance(params);
    return response.data;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    throw error;
  }
});

export const asyncListTaxesClient = createAsyncThunk<TaxesByClientResponse, IdAndParams>(`/tenant/taxes`, async (props) => {
  const { id, params } = props;
  const { data: response } = await getTaxesClient(id, params || {});
  return response;
});

export const asyncListTaxTypesClient = createAsyncThunk<TaxTypesByClientResponse, IdAndParams>(`/tax/types`, async ({ id, params }) => {
  const { data: response } = await getTaxTypes(id, params || {});
  return response;
});

export const asyncSummaryTaxesByPe = createAsyncThunk<SummaryTaxesByPeResponse, IdAndParams>('summary-taxes', async ({ id, params }) => {
  const { data: response } = await getSummaryTaxesByPe(id, params || {});
  return response;
});

const initialState: TaxesState = {
  balanceTaxes: [],
  taxesList: [],
  taxTypes: [],
  summaryTaxes: [],
  loading: 'idle',
  loadingClient: 'idle',
  loadingSummaryTax: 'idle',
  pages: 1,
  count: 1,
  total: 0,
  failedQueries: 0,
};

export const taxesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTaxesBalance.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchTaxesBalance.rejected, (state, action) => {
      state.loading = 'failed';
      state.failedQueries += 1;
      logger.error('fetchTaxesBalance.error', action.error);
    });
    builder.addCase(fetchTaxesBalance.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.balanceTaxes = [...state.balanceTaxes, ...action.payload.documents.docs];
      logger.info('fetchTaxesBalance.success', state.balanceTaxes);
    });
    builder.addCase(asyncListTaxesClient.pending, (state, _action) => {
      state.loadingClient = 'pending';
    });
    builder.addCase(asyncListTaxesClient.rejected, (state, action) => {
      state.loadingClient = 'failed';
      logger.error('asyncListTaxesClient.error', action.error);
    });
    builder.addCase(asyncListTaxesClient.fulfilled, (state, action) => {
      state.loadingClient = 'succeeded';
      state.taxesList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
    });
    builder.addCase(asyncListTaxTypesClient.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListTaxTypesClient.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListTaxTypesClient.error', action.error);
    });
    builder.addCase(asyncListTaxTypesClient.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.taxTypes = action.payload.data;
      state.total = action.payload.meta.total;
    });
    builder.addCase(asyncSummaryTaxesByPe.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncSummaryTaxesByPe.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListTaxTypesClient.error', action.error);
    });
    builder.addCase(asyncSummaryTaxesByPe.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.summaryTaxes = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.count;
    });
  },
});

export default taxesSlice.reducer;
