import React, { FC, ReactNode } from 'react';
import { DropdownArrowIconSVG } from '../../../assets/svgComponents';

interface ActionButtonProps {
  text?: string;
  onClick: () => void | null;
  inactive?: boolean;
  removeIcon?: boolean;
  icon?: ReactNode;
  containerClassName?: string;
}

const ActionButtonComponent: FC<ActionButtonProps> = ({
  inactive,
  text,
  removeIcon,
  icon = <DropdownArrowIconSVG className="color-white" />,
  containerClassName,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={`${containerClassName}  text-center background-blue ${
      inactive ? 'action-button-component disabled-button' : 'action-button-component'
    }  ps-4 pe-2 py-2 `}
    role="none"
  >
    <p className="me-3 color-white">{text}</p>
    {!removeIcon ? !inactive && icon : null}
  </div>
);

export default ActionButtonComponent;
