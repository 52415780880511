/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddAddressResponse,
  AddCompanyResponse,
  AddressRequestPatch,
  AddressRequestPost,
  Company,
  CompanyRequestPatch,
  CompanyRequestPost,
} from '../../utils/interfaces';
import { CompanyOnBoardingPost, LoadingStateType, RequestParams } from '../../utils/types';
import {
  createAddress,
  createCompany,
  createCompanyOnBoarding,
  editAddress,
  editCompany,
  getCompanies,
  getCompany,
} from '../../services/companyService';
import logger from '../../services/loggerService';
import { errorHandlerHelper, errorHandlerHelperV2 } from '../../utils/errorHandler';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';

interface CompanyResponse {
  data: Array<Company>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}
interface CompanyState {
  companiesList: Array<Company>;
  company?: Company | null;
  loadingCompanies: LoadingStateType;
  pages: number;
  total: number;
}

export const asyncListCompanies = createAsyncThunk<CompanyResponse, RequestParams>('company/getCompanies', async (params, { dispatch }) => {
  const response = await errorHandlerHelperV2<CompanyResponse>(() => getCompanies(params), dispatch);
  return response;
});

export const getCompanyData = createAsyncThunk<Company, string | number>('company/:id', async (id, { rejectWithValue, dispatch }) => {
  try {
    const company = await getCompany(id);
    return company;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return rejectWithValue(error);
  }
});

export const addCompanyOnBoarding = createAsyncThunk<AddCompanyResponse, CompanyRequestPost | CompanyOnBoardingPost>(
  'onboarding/company',
  async (companyData: CompanyRequestPost | CompanyOnBoardingPost, { rejectWithValue, dispatch }) => {
    try {
      const company = await createCompanyOnBoarding(companyData);
      return company;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const addCompany = createAsyncThunk<AddCompanyResponse, CompanyRequestPost | CompanyOnBoardingPost>(
  'company',
  async (companyData: CompanyRequestPost | CompanyOnBoardingPost, { rejectWithValue, dispatch }) => {
    try {
      const company = await createCompany(companyData);
      return company;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncPatchCompany = createAsyncThunk<AddCompanyResponse, CompanyRequestPatch>(
  'company',
  async (companyData: CompanyRequestPatch, { rejectWithValue, dispatch }) => {
    try {
      const company = await editCompany(companyData);
      return company;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncAddAddress = createAsyncThunk<AddAddressResponse, AddressRequestPost>(
  'address',
  async (addressData: AddressRequestPost, { rejectWithValue, dispatch }) => {
    try {
      const address = await createAddress(addressData);
      return address;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncPatchAddress = createAsyncThunk<AddAddressResponse, AddressRequestPatch>(
  'address',
  async (addressData: AddressRequestPatch, { rejectWithValue, dispatch }) => {
    try {
      const address = await editAddress(addressData);
      return address;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

const initialState: CompanyState = {
  companiesList: [],
  company: null,
  loadingCompanies: 'idle',
  pages: 1,
  total: 1,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      if (state.loadingCompanies === 'pending') {
        state.loadingCompanies = 'idle';
      }
      state.company = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loadingCompanies = action.payload;
    },
  },
  extraReducers: (builder) => {
    // LIST COMPANIES
    builder.addCase(asyncListCompanies.pending, (state, _action) => {
      state.loadingCompanies = 'pending';
    });
    builder.addCase(asyncListCompanies.rejected, (state, action) => {
      state.loadingCompanies = 'failed';
      logger.error('listCompanies.error', action.error);
    });
    builder.addCase(asyncListCompanies.fulfilled, (state, action: PayloadAction<CompanyResponse>) => {
      state.loadingCompanies = 'succeeded';
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
      state.companiesList = action.payload.data;
      logger.info('listCompanies.success', state.companiesList);
    });
    // ADD COMPANY
    builder.addCase(addCompany.pending, (state, _action) => {
      state.loadingCompanies = 'pending';
    });
    builder.addCase(addCompany.rejected, (state, action) => {
      state.loadingCompanies = 'failed';
      logger.error('addCompany.error', action.error);
      // segmentTrack(EVENTS.COMPANY.CREATE.FAILED, action.error);
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.loadingCompanies = 'succeeded';
      state.company = action.payload.data;
      logger.info('addCompany.success', state.companiesList);
      // segmentTrack(EVENTS.COMPANY.CREATE.SUCCESS, action.payload.data);
    });
    // ADD COMPANY ON BOARDING
    builder.addCase(addCompanyOnBoarding.pending, (state, _action) => {
      state.loadingCompanies = 'pending';
    });
    builder.addCase(addCompanyOnBoarding.rejected, (state, action) => {
      state.loadingCompanies = 'failed';
      logger.error('addCompanyOnBoarding.error', action.error);
      // segmentTrack(EVENTS.COMPANY.CREATE.FAILED, action.error);
    });
    builder.addCase(addCompanyOnBoarding.fulfilled, (state, action) => {
      state.loadingCompanies = 'succeeded';
      state.company = action.payload.data;
      logger.info('addCompanyOnBoarding.success', state.companiesList);
      // segmentTrack(EVENTS.COMPANY.CREATE.SUCCESS, action.payload.data);
    });
    // ADD ADDRESS
    builder.addCase(asyncAddAddress.pending, (state, _action) => {
      state.loadingCompanies = 'pending';
    });
    builder.addCase(asyncAddAddress.rejected, (state, action) => {
      state.loadingCompanies = 'failed';
      logger.error('asyncAddAddress.error', action.error);
      // segmentTrack(EVENTS.COMPANY.ADDRESS.CREATE.FAILED, action.error);
    });
    builder.addCase(asyncAddAddress.fulfilled, (state, _action) => {
      state.loadingCompanies = 'succeeded';
      logger.info('asyncAddAddress.success', state.companiesList);
      // segmentTrack(EVENTS.COMPANY.ADDRESS.CREATE.SUCCESS, { address: action.payload.document });
    });
    // GET COMPANY DATA
    builder.addCase(getCompanyData.pending, (state, _action) => {
      state.loadingCompanies = 'pending';
    });
    builder.addCase(getCompanyData.rejected, (state, action) => {
      state.loadingCompanies = 'failed';
      logger.error('getCompanyData.error', action.error);
    });
    builder.addCase(getCompanyData.fulfilled, (state, action) => {
      state.loadingCompanies = 'succeeded';
      state.company = action.payload;
      logger.info('getCompanyData.success', state.company);
    });
  },
});

export const { setCompany, setLoading } = companySlice.actions;
export default companySlice.reducer;
