import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoaderSpinner } from '..';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPageCount, paramsToObject, parseFilters } from '../../utils/helper';
import { Cashflow, Platform, RelatedPlatform } from '../../utils/interfaces';
import CashflowRow from '../Collections/Collections/CashflowRow';
import { getCashflowMovements, getCashflowSummaryByDayDetail } from '../../services/cashflowService';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import { currencyFormat, formatNumber } from '../../utils/stringUtils';
import errorHandlerHelper from '../../utils/errorHandler';
import { externalPlatformsBasicData } from '../../utils/constants';

interface IModalCalendar {
  day: string;
}

const IncomesCalendarModal: FC<IModalCalendar> = ({ day }) => {
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const [collectionsList, setCollectionsList] = useState<Cashflow[]>([]);
  const [platformList, setPlatformList] = useState<any>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    collectionsList.sort((a, b) => {
      if (a.movement.platformExternalCode < b.movement.platformExternalCode) {
        return -1;
      }
      if (a.movement.platformExternalCode > b.movement.platformExternalCode) {
        return 1;
      }
      return 0;
    });
  }, [collectionsList]);

  const getDayCollection = async (): Promise<void> => {
    if (activeCurrency?.code) {
      try {
        setLoading(true);
        const startDate = moment(day).startOf('day').utc().format();
        const endDate = moment(day).endOf('day').utc().format();
        const params = {
          'filter[date][between]': `${startDate},${endDate}`,
          pageSize: '30',
          'filter[currencyCode][eq]': activeCurrency.code,
          page: page.toString(),
        };
        const queryParams = new URLSearchParams(location.search);
        const queryObject = paramsToObject(queryParams);
        const filterableObject = parseFilters({ ...queryObject, ...params });
        const { data: response } = await getCashflowMovements(filterableObject);
        setCollectionsList(response.data);
        setTotal(response.meta.total);
        setPages(response.meta.pages);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const calculateTotalPerPlatform = async (): Promise<void> => {
    if (activeCurrency?.code) {
      const startDate = moment(day).startOf('day').utc().format();
      const endDate = moment(day).endOf('day').utc().format();
      const params = {
        'filter[date][between]': `${startDate},${endDate}`,
        'filter[currencyCode][eq]': activeCurrency.code,
      };
      const queryParams = new URLSearchParams(location.search);
      const queryObject = paramsToObject(queryParams);
      const filterableObject = parseFilters({ ...queryObject, ...params });

      try {
        const { data: response } = await getCashflowSummaryByDayDetail(filterableObject);
        setPlatformList(response.data[0].platforms);
      } catch (error) {
        errorHandlerHelper(error, dispatch);
      }
    }
  };

  useEffect(() => {
    getDayCollection();
    calculateTotalPerPlatform();
  }, [page, activeCurrency]);

  const handlePrevClick = (): void => {
    setPage(page - 1);
  };
  const handleNextClick = (): void => {
    setPage(page + 1);
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newPage = e.target.value;
    if (pages > parseInt(newPage, 10)) {
      setPage(Number(e.target.value));
    }
  };

  const show =
    collectionsList && collectionsList.length > 0 ? (
      collectionsList?.map((collection: Cashflow) => <CashflowRow key={collection.movement.id} cashflow={collection} />)
    ) : (
      <p>No hay ingresos para este día</p>
    );
  const iconShow = (code: string): React.ReactElement => {
    let res;
    if (externalPlatformsBasicData(code)?.logoImage) {
      res = externalPlatformIcon({ code, className: 'external-platform-logo-total' });
    } else {
      res = (
        <div className="">
          <div className="external-platform-logo-total-not-sopported">
            <p className="text-not-supported-incomes">{code.slice(0, 1)}</p>
          </div>
        </div>
      );
    }
    return res;
  };

  const showRelatedPlatforms = (value: Platform): React.ReactElement => {
    const mainPlatform = value?.relatedPlatforms?.find((item: RelatedPlatform) => item?.code === value.code);
    if (mainPlatform) {
      return (
        <>
          <div key={mainPlatform.amount} className="box-container mx-1">
            <div className="iconBox">{iconShow(mainPlatform?.code)}</div>
            <div className="amountBox font-weight-bold mt-2">
              {currencyFormat(activeCurrency?.code || '')} {formatNumber(mainPlatform?.amount, false, 0)}
            </div>
          </div>
          {value?.relatedPlatforms?.map(
            (item: RelatedPlatform) =>
              item.code !== mainPlatform.code && (
                <div key={item?.amount} className="box-container mx-1">
                  <div className="iconBox ms-2">
                    {iconShow(mainPlatform?.code)}
                    {externalPlatformIcon({ code: item?.code, className: 'external-platform-mini-logo-related mb-3' })}
                  </div>
                  <div className="amountBox font-weight-bold mt-2">
                    {currencyFormat(activeCurrency?.code || '')} {formatNumber(item?.amount, false, 0)}
                  </div>
                </div>
              ),
          )}
        </>
      );
    }
    return (
      <>
        {value?.relatedPlatforms?.map((item: RelatedPlatform) => (
          <div key={item?.amount} className="box-container mx-1">
            <div className="iconBox ms-3">
              {iconShow(value?.code)}
              {externalPlatformIcon({ code: item?.code, className: 'external-platform-mini-logo-related mb-3' })}
            </div>
            <div className="amountBox font-weight-bold mt-2">
              {currencyFormat(activeCurrency?.code || '')} {formatNumber(item?.amount, false, 0)}
            </div>
          </div>
        ))}
      </>
    );
  };
  const showTotalPlatform = (): void =>
    platformList.map((item: Platform) =>
      !item.relatedPlatforms ? (
        <div key={item?.amount} className="box-container mx-1">
          <div className="iconBox">{iconShow(item?.code)}</div>
          <div className="amountBox font-weight-bold mt-2">
            {currencyFormat(activeCurrency?.code || '')} {formatNumber(item.amount, false, 0)}
          </div>
        </div>
      ) : (
        <>{showRelatedPlatforms(item)}</>
      ),
    );

  return (
    <div className="modal-collections py-2 h-75 w-100 p-3">
      <h4>Ingresos {moment(day).format(' D MMMM YYYY')}</h4>
      {showTotalPlatform()}
      {!loading ? show : <LoaderSpinner className="loading-spinner" />}
      <div className="d-flex justify-content-between align-items-center">
        {}
        <p className="pt-3 page-count">{loading ? '' : getPageCount(10, total, +page, pages, collectionsList.length)}</p>

        <div className="d-flex justify-content-end">
          {!loading && collectionsList.length && (
            <div className="d-flex align-items-center my-2 pagination">
              <button
                type="button"
                className={`pagination-prev ${page === 1 && 'pagination-disabled'}`}
                disabled={page === 1}
                onClick={handlePrevClick}
              >
                <DropdownArrowIconSVG className="color-white" width={15} />
              </button>
              <input type="text" className="pagination-input" value={page} onChange={handleOnChange} />
              <span className="pagination-data">{`/ ${pages}`}</span>
              <button
                type="button"
                className={`pagination-next ${page === pages && 'pagination-disabled'}`}
                disabled={page === pages}
                onClick={handleNextClick}
              >
                <DropdownArrowIconSVG className="color-white" width={15} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default IncomesCalendarModal;
