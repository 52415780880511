import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../textComponents';
import variables from '../../scss/_exports.module.scss';
import { Branch, BranchRow } from '../../utils/interfaces';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import checkFilled from '../../assets/images/iconsMenu/checkmark_circle.png';
import checkEmpty from '../../assets/images/iconsMenu/checkEmpty_circle.png';
import edit from '../../assets/images/iconsMenu/edit.png';
import { externalPlatformsBasicData } from '../../utils/constants';

const BranchCardUnmergeAndEditComponent: FC<BranchRow> = ({ branch, selectedBranches, selectable, setSelectedBranch, editTypeAction }) => {
  const navigate = useNavigate();
  const isSelected = selectedBranches?.find((branchSelected: Branch) => branchSelected?.id === branch?.id);

  const handleOpenBranchEditModal = (): void => {
    navigate(`/edit-branch`, { state: { branch } });
  };
  const { name } = branch;
  const numberBranch = branch?.PlatformExternals[0]?.externalCode;

  const iconShow = (code: string): React.ReactElement => {
    let res;
    if (externalPlatformsBasicData(code)?.logoImage) {
      res = externalPlatformIcon({ code, className: 'external-platform-logo' });
    } else {
      res = (
        <div className="">
          <div className="external-platform-logo-not-supported">
            <p className="text-not-supported">{code.slice(0, 1)}</p>
          </div>
        </div>
      );
    }
    return res;
  };

  return (
    <div
      className="branch-component row-container mb-1"
      onClick={(): void => {
        // eslint-disable-next-line no-unused-expressions
        !branch?.relatedBranches?.length && setSelectedBranch(branch);
      }}
      role="none"
    >
      <div className="mx-sm-3 mx-lg-4 col-xs-2 col-sm-2 col-md-2 col-lg-2" data-tip="Plataforma">
        {iconShow(branch.PlatformExternals[0].platformExternalCode)}
      </div>
      <div className="flex-grow-1 col-xs-5 col-sm-4 col-md-4 col-lg-4">
        <div>
          <Paragraph className="text-break" color={variables.colorBlue} weight="bold" data-tip="Nombre">
            {name}
          </Paragraph>
          <div>{numberBranch ? <p className="numberBranchColor">{numberBranch}</p> : null}</div>
        </div>
      </div>
      {!editTypeAction ? (
        <>
          <div>
            {selectable &&
              !branch.relatedBranches?.length &&
              (isSelected ? (
                <img src={checkFilled} alt="Check" className="external-platform-mini-logo ms-2" />
              ) : (
                <img src={checkEmpty} alt="Check" className="external-platform-mini-logo ms-2" />
              ))}
          </div>
          <div>
            <div className="ms-2" onClick={(): void => handleOpenBranchEditModal()} role="none" data-tip="Editar Sucursal">
              <img src={edit} alt="Check" className="external-platform-mini-logo ms-2" />
            </div>
          </div>
        </>
      ) : null}
      <ReactTooltip />
    </div>
  );
};

export default BranchCardUnmergeAndEditComponent;
