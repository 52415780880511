import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FetchButton, Taxes } from '../../components';
import { ClientNameHeader, Title } from '../../components/textComponents';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { Filters } from '../../containers';
import { CloseIconSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { asyncListTaxesClient } from '../../redux/slices/taxesSlice';
import { asyncListClientCompanies, asyncListClientExternalPlatforms } from '../../redux/slices/clientsSlice';
import { FilterType } from '../../utils/types';

const TaxesPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { taxesList } = useAppSelector((stateSelector) => stateSelector.taxes);
  const { singleClient } = useAppSelector((stateSelector) => stateSelector.clients);
  const dispatch = useAppDispatch();
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  // const page = useQueryParams('page') || '1';
  const relTenantId = singleClient?.relTenantId;

  useEffect(() => {
    dispatch<void>(asyncListClientExternalPlatforms(id || ''));
    dispatch<void>(asyncListClientCompanies(id || ''));
  }, [id]);

  useEffect(() => {
    // if (page !== '1') segmentTrack(EVENTS.SALES.PAGINATE, { page });
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters(queryObject);
    dispatch<void>(asyncListTaxesClient({ id: Number(id), params: filterableObject }));
    if (id) {
      dispatch<void>(asyncListClientExternalPlatforms(id.toString()));
      dispatch<void>(asyncListClientCompanies(id.toString()));
    }
  }, [activeCurrency, location]);

  const goBack = (): void => {
    navigate(-1);
  };

  const handlePagination = (searchParams: Record<string, string>): void => {
    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  return (
    <div className="collections-page mb-4 mt-4">
      <div className="my-2 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-9">
        <ClientNameHeader clientId={Number(id)} />
        <CloseIconSVG className="close-icon color-blue" height={40} width={25} onClick={goBack} />
      </div>
      <div>
        <Title size="sm">Impuestos</Title>
      </div>
      <Filters containerClassName="mt-3 mb-3" typeFilter={FilterType.EXPENSE_REPORTS} />
      <div className="me-2 my-2 flex-grow-1 d-flex justify-content-end">
        <FetchButton dataType={4} tenantId={Number(id)} />
      </div>
      <Taxes taxesArray={taxesList} handlePaginationCallBack={handlePagination} id={id} relTenantId={relTenantId?.toString()} />
    </div>
  );
};
export default TaxesPage;
