import React, { FC } from 'react';
import { ButtonProps } from '../../../utils/interfaces';

const FormButtonComponent: FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  type = 'button',
  buttonTypeStyle,
  name,
  className,
}: ButtonProps) => (
  <button
    className={`form-button-component ${className} ${disabled && 'disabled'} ${buttonTypeStyle}`}
    name={name}
    disabled={disabled}
    // eslint-disable-next-line react/button-has-type
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);

export default FormButtonComponent;
