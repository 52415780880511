import React from 'react';
import { Cashflow } from '../../../../utils/interfaces';
import { CashflowType } from '../../../../utils/types';
import CashflowCollection from './CashflowCollection';
import CashflowPayment from './CashflowPayment';

type CashflowRowProps = {
  cashflow: Cashflow;
};
const CashflowRow = ({ cashflow }: CashflowRowProps): React.ReactElement => {
  const { type, movement } = cashflow;
  if (type === CashflowType.collection) {
    return <CashflowCollection collection={movement} />;
  }
  return <CashflowPayment payment={movement} />;
};
export default CashflowRow;
