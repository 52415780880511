import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { FormButtonComponent, LinkComponent, TextInputComponent, Paragraph } from '../../components';
import { useAppDispatch } from '../../redux/hooks';

import { FormFieldType } from '../../utils/types';
import appstoreButton from '../../assets/images/stores/appstore-button.png';
import gpButton from '../../assets/images/stores/google-play-button.png';

import { addModalToList, removeAllModalsFromList, removeModalFromList } from '../../redux/slices/modalSlice';
import { PasswordInputComponent } from '../inputs';
import { resetPassword, validateForgotPasswordToken } from '../../services/authService';
import errorImage from '../../assets/images/error.png';
import successImage from '../../assets/images/success.png';
import errorHandlerHelper, { errorHandlerHelperV2 } from '../../utils/errorHandler';
import { ErrorRecoverPassword, TypeErrorRecoverPassword, UserForgotPasswordHandler } from '../../utils/interfaces';
import { regexCodes } from '../../utils/constants';

interface FormEnterEmailProps {
  setSteps: (step: string) => void;
  email: string;
}

interface UserRecoverPasswordConfirm {
  newPassword: string;
  newPasswordConfirmation: string;
  codeValidation: string;
}
const FormRecoverPassword: FC<FormEnterEmailProps> = ({ email }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleRecover = async (values: UserRecoverPasswordConfirm): Promise<void> => {
    try {
      const res = await validateForgotPasswordToken(email, values.codeValidation);
      if (res.status === 200) {
        dispatch(removeAllModalsFromList());
        const response = await errorHandlerHelperV2<UserForgotPasswordHandler>(
          () => resetPassword({ email, password: values.newPassword, token: values.codeValidation }),
          dispatch,
        );
        if (!response?.error) {
          dispatch(
            addModalToList({
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => navigate('/login'),
              },
              pictureUrl: successImage,
              body: 'Se modificó la contraseña con éxito.',
            }),
          );
        }
      } else {
        dispatch(
          addModalToList({
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => dispatch(removeModalFromList()),
            },
            pictureUrl: errorImage,
            body: 'El código de confirmación ingresado no es válido',
          }),
        );
      }
    } catch (error) {
      if ((error as ErrorRecoverPassword)?.name === TypeErrorRecoverPassword.EMAIL_NOT_FOUND) {
        dispatch(
          addModalToList({
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
                navigate('/login');
              },
            },
            pictureUrl: errorImage,
            body: 'El usuario se encuentra bloqueado. Para desbloquearlo, comunicate con tu asesor comercial.',
          }),
        );
      } else {
        errorHandlerHelper(error, dispatch);
      }
    }
  };

  const initialValues: UserRecoverPasswordConfirm = { codeValidation: '', newPassword: '', newPasswordConfirmation: '' };

  const ForgotSchema = Yup.object().shape({
    codeValidation: Yup.string().required('Debes escribir un código.'),
    newPassword: Yup.string()
      .matches(regexCodes.password, 'Tu contraseña debe tener al menos 8 caracteres, 1 número y 1 carácter especial.')
      .max(16, 'Máximo 16 caracteres')
      .notOneOf([email], 'La contraseña no puede ser igual al email actual')
      .required('Este campo es requerido.')
      .min(8, 'Debe tener al menos 8 caracteres.'),
    newPasswordConfirmation: Yup.string()
      .matches(regexCodes.password, 'Tu contraseña debe tener al menos 8 caracteres, 1 número y 1 carácter especial.')
      .max(16, 'Máximo 16 caracteres')
      .notOneOf([email], 'La contraseña no puede ser igual al email actual')
      .required('Este campo es requerido.')
      .min(8, 'Debe tener al menos 8 caracteres.')
      .oneOf([Yup.ref('newPassword')], 'Las contraseñas no coinciden.'),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={handleRecover}>
      {({ errors, touched, isSubmitting }): ReactElement => (
        <Form autoComplete="new-password" className="inputs-container d-flex flex-column align-items-end">
          <Field name="codeValidation">
            {({ field }: FormFieldType): ReactElement => (
              <TextInputComponent
                required
                containerClassName="mt-2 w-100"
                className="input"
                type="text"
                errorMessage={touched.codeValidation && errors.codeValidation}
                placeholder="Ingresá tu código"
                autoComplete="new-password"
                label="Código de Confirmación"
                {...field}
              />
            )}
          </Field>
          <Field name="newPassword">
            {({ field }: FormFieldType): ReactElement => (
              <PasswordInputComponent
                required
                containerClassName="mt-2 w-100"
                className="input"
                errorMessage={touched.newPassword && errors.newPassword}
                placeholder="Ingresá tu contraseña"
                label="Contraseña"
                type="password"
                autoComplete="new-password"
                {...field}
              />
            )}
          </Field>
          <Field name="newPasswordConfirmation">
            {({ field }: FormFieldType): ReactElement => (
              <PasswordInputComponent
                required
                containerClassName="mt-2 w-100"
                className="input"
                errorMessage={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                placeholder="Ingresá tu contraseña nuevamente"
                label="Confirmar contraseña"
                autoComplete="new-password"
                type="password"
                {...field}
              />
            )}
          </Field>

          <div className="w-100 d-flex flex-column align-items-center mt-5">
            <FormButtonComponent disabled={isSubmitting} className="submit-button w-100" type="submit">
              {isSubmitting ? 'Validando codigo...' : 'Restaurar'}
            </FormButtonComponent>
            <Paragraph className="d-inline-block mt-4 w-100 mx-auto text-center">
              ¿Ya tenes cuenta?
              {/* TODO - CHANGE TO ONCLICK PROP FUNCTION OR WRAPPER ONCLICK IN THIS COMPONENT */}
              <LinkComponent
                className="d-inline-block ms-1 link-register"
                route={{ pathname: '/login', state: { fromdRecoverPassword: true } }}
              >
                ¡Inicia Sesión!
              </LinkComponent>
            </Paragraph>
          </div>
          <div className="d-flex mt-5 w-100 justify-content-center">
            <a href="https://apps.apple.com/ar/app/nubceo-cash/id1527840728">
              <img src={appstoreButton} alt="Ir a App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nubceo.app&hl=es_AR&gl=US">
              <img className="ms-2" src={gpButton} alt="Ir a Google Play" />
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormRecoverPassword;
