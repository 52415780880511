import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsPlusCircle } from 'react-icons/bs';
import { FaPencilAlt } from 'react-icons/fa';
import { LoaderScreen, PaginationWithCount } from '../../components';
import { Paragraph, Title } from '../../components/textComponents';
import { User } from '../../utils/interfaces';
import variables from '../../scss/_exports.module.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPermissions, paramsToObject, parseFilters } from '../../utils/helper';
import { getNameInitials } from '../../utils/stringUtils';
import { asyncListUsers } from '../../redux/slices/userListSlice';
import useQueryParams from '../../hooks/useQueryParams';
import { CloseIconSVG } from '../../assets/svgComponents';

// TODO move
type UserRowProps = {
  user: User;
  canEdit: boolean;
};
const UserRow = ({ user, canEdit }: UserRowProps) => {
  const navigate = useNavigate();
  const handleEditUser = (): void => {
    if (canEdit) navigate(`/users/${user.id}`);
  };
  return (
    <div className="company-component row-container" role="none" onClick={handleEditUser}>
      <div className={`initials-name-container ${!user.enabled && 'disabled-company-image'}`}>
        <Paragraph color={user.enabled ? variables.colorBlue : variables.colorGray300} size="lg">
          {getNameInitials(`${user.firstName} ${user.sureName}`).toUpperCase()}
        </Paragraph>
      </div>
      <div className="offset-1 col-xs-12 col-sm-6 col-lg-2">
        <Paragraph className="text-truncate" color={user.enabled ? variables.colorBlue : variables.colorGray300} weight="bold">
          {`${user.firstName} ${user.sureName}`}
        </Paragraph>
      </div>
      <div className="col d-flex justify-content-end">
        {canEdit && (
          <div className="icons-container">
            <FaPencilAlt className="col" color={user.enabled ? variables.colorBlue : variables.colorGray300} onClick={handleEditUser} />
          </div>
        )}
      </div>
      {/* Not confirmed design */}
      {/* {!user.enabled ? (
          <div className="d-none d-lg-block col col-lg-3 filter-tag px-3 py-1 my-auto inactive-badge justify-content-end">
            <Paragraph color={variables.colorGray300} size="xs">
              Deshabilitado
            </Paragraph>
          </div>
        ) : (
          <div className="d-none d-lg-block col-lg-3 filter-tag px-3 py-1 my-auto active-badge justify-content-end">
            <Paragraph color={variables.colorGray300} size="xs">
              Activa
            </Paragraph>
          </div>
        )} */}
    </div>
  );
};

const UsersPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { usersList: usersData, loading: loadingUsers, pages, total } = useAppSelector((state) => state.userList);
  const location = useLocation();
  const { user: currentUser } = useAppSelector((state) => state.userProfile);
  const [usersList, setUsersList] = React.useState<Array<User>>([]);
  const [canEdit, setCanEdit] = useState(false);
  const page = useQueryParams('page') || '1';

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({ ...queryObject });
    dispatch(asyncListUsers(filterableObject));
  }, [location]);

  const handlePagination = (searchParams: Record<string, string>): void => navigate(`?${new URLSearchParams(searchParams).toString()}`);

  useEffect(() => {
    const users = usersData
      .filter((user: User) => user.id !== currentUser?.id)
      .map((user: User) => (user.deletedAt?.length ? { ...user, enabled: false } : { ...user, enabled: true }));
    setUsersList([...users]?.sort((user) => (user?.enabled ? 1 : -1)));
  }, [usersData]);

  useEffect(() => {
    getPermissions()
      .then((res) => {
        setCanEdit(res.includes('user_update'));
      })
      .catch((err) => console.log(err));
  }, []);
  const handleNewUser = (): void => {
    navigate('/users/new');
  };

  const handleCloseIcon = () => {
    navigate('/companies');
  };

  if (loadingUsers === 'pending') return <LoaderScreen />;
  return (
    <div className="my-companies-page mb-4">
      <div className="my-5 w-100 d-flex justify-content-between align-items-center flex-wrap">
        <Title>Usuarios</Title>
        <div>
          {canEdit ? <BsPlusCircle className="me-3 cursor-pointer" color={variables.colorBlue} size={30} onClick={handleNewUser} /> : null}
          <CloseIconSVG className="close-icon color-blue cursor-pointer" height={24} width={24} onClick={(): void => handleCloseIcon()} />
        </div>
      </div>
      <div className="d-flex flex-wrap mt-2">
        {usersList?.length > 0 &&
          usersList
            ?.slice()
            .reverse()
            .map((data: User) => <UserRow key={data.id} user={data} canEdit={canEdit} />)}
        {usersList?.length === 0 && 'No tenes usuarios creados'}
      </div>
      <PaginationWithCount
        page={page}
        handlePagination={handlePagination}
        pageSize={25}
        pages={pages}
        length={usersData?.length || 0}
        loading={loadingUsers}
        total={total}
      />
    </div>
  );
};

export default UsersPage;
