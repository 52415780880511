import React, { FC } from 'react';

interface TiendaNubeDefaultLandingProps {
  response?: { title: string; message: string };
  error?: string | boolean;
}

const TiendaNubeDefaultLanding: FC<TiendaNubeDefaultLandingProps> = ({ response, error }) => (
  <div className="row content align-items-center ">
    <div className="container-content">
      {response && (
        <div className="text-center">
          <h2 className="color-gray-dark">{response.title}</h2>
          <p className={`font-lg ${error ? 'color-red' : 'color-gray-dark'} font-weight-medium mb-3`}>{response.message}</p>
          {error ? (
            <a className="font-md font-weight-medium" href="https://www.tiendanube.com/login">
              Volver a Tiendanube
            </a>
          ) : (
            <a className="font-md font-weight-medium" href="/">
              Ir a nubceo
            </a>
          )}
        </div>
      )}
    </div>
  </div>
);

export default TiendaNubeDefaultLanding;
