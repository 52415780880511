import React, { useEffect, useState } from 'react';
import { randomImages } from '../../utils/constants';

export const RandomImageComponent = (): React.ReactElement => {
  const [randomImage, setRandomImage] = useState(randomImages[0]);

  useEffect(() => {
    const data = randomImages;
    setRandomImage(data[Math.floor(Math.random() * (5 - 0))]);
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${randomImage})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        backgroundPosition: '15% 40%',
      }}
      className="col-xs-12 col-md-6 d-flex flex-column align-items-end"
    />
  );
};
