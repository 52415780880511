import React from 'react';
import moment from 'moment';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { currencyFormat, decimalFormat } from '../../../../utils/stringUtils';
import AccordionComponent from '../../../Accordion';
import externalPlatformIcon from '../../../../utils/externalPlatformIcon';
import paymentMethodIcon from '../../../../utils/paymentMethodIcon';
import { PlatformExternal, SettlementWithCollections } from '../../../../utils/interfaces';
import { EVENTS } from '../../../../utils/segment/events';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../../../redux/slices/modalSlice';
import { SalesBySettlementContainer } from '../../../../containers';
import { externalPlatformsBasicData, platformExternalCodesTrimmed } from '../../../../utils/constants';
import { ActionButtonComponent } from '../../..';
import variables from '../../../../scss/_exports.module.scss';
import { ExternalPlatformType } from '../../../../utils/types';

type SettlementPops = {
  settlement: SettlementWithCollections;
  businessEntityId: string | null | undefined;
};

enum SettlementsTags {
  INCLUDES_REENTERED_SALES = 'includes_reentered_sales',
}

const SettlementRow = ({ settlement, businessEntityId }: SettlementPops): React.ReactElement => {
  const { companiesList } = useAppSelector((state) => state.company);
  const isOca = (pec: PlatformExternal): boolean => externalPlatformsBasicData(pec.code).code === platformExternalCodesTrimmed.OCA;
  const isPrisma = (pec: string): boolean => externalPlatformsBasicData(pec).code === platformExternalCodesTrimmed.PRISMA;
  const dispatch = useAppDispatch();
  const validationPeriod = (): string => {
    let periodDate = '';
    if (settlement?.sourceDateFrom === settlement?.sourceDateUntil) {
      periodDate = moment(settlement?.sourceDateFrom).locale('es').format('L');
    } else {
      periodDate = `${moment(settlement?.sourceDateFrom).locale('es').format('L')} - ${moment(settlement?.sourceDateUntil)
        .locale('es')
        .format('L')}`;
    }

    return periodDate;
  };

  const percentAmount = (value: number): string | null => {
    const percentage = (value / settlement.grossAmount) * 100;
    const numberFormatRounded = decimalFormat(percentage);
    return settlement.grossAmount === 0 || value < 0 || !value ? '(0%)' : `(${numberFormatRounded}%)`;
  };

  const getCompany = (): string | undefined => {
    const business = companiesList.find((company) => company.id === settlement.companyId?.toString())?.name;
    return business;
  };

  const handleClick = (): void => {
    dispatch(
      addModalToList({
        body: <SalesBySettlementContainer id={settlement.id} grossAmount={settlement.grossAmount} businessEntityId={businessEntityId} />,
        required: true,
        okButton: {
          buttonText: 'Cerrar',
          onClick: async () => {
            dispatch(removeModalFromList());
          },
        },
        className: 'modal-to-display-rows',
      }),
    );
  };
  const estimated = !settlement.confirmedAmount || !settlement.confirmedDate;
  // const projected = settlement.isProjected;
  const businessNumber = settlement?.Branch?.PlatformExternals?.find(
    (item) => item.platformExternalCode === settlement.platformExternalCode,
  );
  const iconShow = (code: any) => {
    let res;
    if (externalPlatformsBasicData(code)?.logoImage) {
      res = externalPlatformIcon({ code, className: 'external-platform-logo' });
    } else {
      res = (
        <div className="">
          <div className="external-platform-logo-not-supported">
            <p className="text-not-supported">{code.slice(0, 1)}</p>
          </div>
        </div>
      );
    }
    return res;
  };

  const headerContent = (): React.ReactElement => (
    <>
      <div className={`d-inline-flex  ${settlement.relatedPlatformExternals.length > 1 ? `col-3` : 'col-2'}`} data-tip="Plataforma">
        {iconShow(settlement.PlatformExternal ? settlement.PlatformExternal.code : 'cash_ar')}
        <div className="align-self-start" data-tip={`Esta liquidación está relacionada a ${settlement.relatedPlatformExternals[0]?.name}`}>
          {settlement.relatedPlatformExternals?.length
            ? externalPlatformIcon({
                code: settlement.relatedPlatformExternals[0]?.code,
                className: 'external-platform-logo-related-row',
              })
            : null}
        </div>
        <div>
          {settlement.relatedPlatformExternals?.length > 1 ? (
            <span className="badge">{`${settlement.relatedPlatformExternals.length - 1}+`}</span>
          ) : null}
        </div>
      </div>
      <div className="d-none d-md-inline-flex col-1">{settlement.PlatformExternal.name ?? 'Efectivo'}</div>
      <div className="col-2 col-sm-4 col-md-4 col-lg-4" data-tip="Fecha de Liquidación">
        <span className="d-none d-lg-inline">{moment(settlement.date).locale('es').format('DD MMMM YYYY')}</span>
        <span className=" d-none d-md-inline d-lg-none">{moment(settlement.date).locale('es').format('DD/MM/YY')}</span>
        <span className="d-inline d-md-none">{moment(settlement.date).locale('es').format('D/MM')}</span>
        {!settlement.confirmedDate && <span className="font-xs font-weight-light border-indicator">2</span>}
        {!settlement.Collections?.length && isOca(settlement.PlatformExternal) && <span className="indicator" />}
      </div>

      <div className="d-none d-sm-inline col-2">
        {!externalPlatformsBasicData(settlement?.platformExternalCode)?.branchType ? (
          <div className="info-tooltip d-none d-sm-inline col-4 col-sm-1 col-md-2">
            <FaInfoCircle data-tip="Esta plataforma no liquida por sucursal." color={variables.colorBlue} />
          </div>
        ) : (
          <div className="d-none d-sm-inline col-4 col-sm-1 col-md-2" data-tip="Método de pago">
            {!(settlement?.platformExternalCode?.indexOf('rappi') > -1 || settlement?.platformExternalCode?.indexOf('pedidosya') > -1) &&
              settlement.cardBrand !== 'ADELANTO LIQUIDACION' &&
              settlement.cardBrand &&
              paymentMethodIcon({ brand: settlement.cardBrand, className: 'payment-method-logo' })}
            {!settlement.cardBrand &&
              settlement.platformExternalCode === 'mercadopago_ar' &&
              paymentMethodIcon({ brand: 'send_money', className: 'payment-method-logo' })}
            {settlement.cardBrand &&
              settlement.cardBrand === 'ADELANTO LIQUIDACION' &&
              settlement.platformExternalCode.slice(0, -3) === platformExternalCodesTrimmed.FISERV &&
              paymentMethodIcon({ brand: 'money_advance', className: 'payment-method-logo-2' })}
          </div>
        )}
      </div>
      <div
        data-tip="Monto"
        className={
          settlement.amount > 0
            ? 'text px-4 amount col-sm-3 col-5 col-md-2 col-lg-3 p-0 font-weight-medium text text-center'
            : 'text px-4 amount col-sm-3 col-5 col-md-2 col-lg-3 p-0 font-weight-medium text-negative text text-center'
        }
      >
        {(!settlement.confirmedAmount || !settlement.confirmedTaxDeduction) && (
          <span className="font-xs font-weight-light align-top border-indicator">1</span>
        )}
        {currencyFormat(settlement.currencyCode)}
        {decimalFormat(settlement.amount)}
      </div>
    </>
  );
  const getComissions = (): number =>
    settlement.Deductions.filter(
      (item) => item.deductionTypeCode === 'COM-AR' || item.deductionTypeCode === 'COM-UY' || item.deductionTypeCode === 'COM-CO',
    ).reduce((prev, { amount }) => prev + amount, 0);
  const getLocalCharges = (): number =>
    settlement.Deductions.filter(
      (item) => item.deductionTypeCode === 'COBCOM-AR' || item.deductionTypeCode === 'COBCOM-UY' || item.deductionTypeCode === 'COBCOM-CO',
    ).reduce((prev, { amount }) => prev + amount, 0);

  const getTagsSettlements = (settlementValue: SettlementWithCollections): React.ReactElement | null => {
    if (settlementValue?.SettlementTags?.length && isPrisma(settlementValue?.platformExternalCode)) {
      const tagsMessages = settlementValue.SettlementTags.map((tag) => {
        switch (tag.tag.trim()) {
          case SettlementsTags.INCLUDES_REENTERED_SALES:
            return 'Liquidación con ventas reingresadas.';
          default:
            return null;
        }
      }).filter(Boolean);

      return tagsMessages.length ? (
        <p className="offset-1 mt-2">
          {tagsMessages.map((message) => (
            <span className="font-sm font-weight-bold color-orange d-block">{message}</span>
          ))}
        </p>
      ) : null;
    }
    return null;
  };

  const mainContent = (): React.ReactElement => (
    <div className="w-100 d-flex flex-column flex-md-row">
      <div className="row flex-grow-1 offset-2 d-flex flex-column flex-wrap flex-md-row d-flex">
        {!settlement.confirmedAmount && (
          <p className="">
            <span className="font-sm font-weight-bold">1 - </span> El importe a cobrar es estimado
          </p>
        )}
        {!settlement.confirmedDate && (
          <p>
            <span className="font-sm font-weight-bold">2 - </span> La fecha de pago es estimada
          </p>
        )}
        {(!settlement.totalTaxes || !getComissions()) && (
          <p>
            <span className="font-sm font-weight-bold">3 - </span> Los importes de Comisiones/Dtos o impuestos no están definidos por la
            plataforma
          </p>
        )}
        {/* {projected && (
          <p className="text-projected ms-lg-5">
            Esta liquidación fue proyectada por <b>nubceo</b> en base a tus ventas sin liquidar
          </p>
        )} */}
        {(!settlement.confirmedAmount || !settlement.confirmedDate || !settlement.totalTaxes) && <hr style={{ width: '100%' }} />}
        <div className="col-12 col-md-5 col-lg-6 me-md-5" style={{ minWidth: '200px' }}>
          <div className="d-flex">
            <div className="fw-bold col-7 col-lg-6">Período: </div>
            <div className="col-5 col-lg-6">{validationPeriod()}</div>
          </div>
          {settlement?.Branch !== null || settlement?.Branch !== undefined ? (
            <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {settlement?.companyId ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold">Empresa:</div>
                  <div className="col-5 col-lg-7">{getCompany()}</div>
                </div>
              ) : null}
              {settlement?.externalReference !== null || settlement?.externalReference !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold">Referencia:</div>
                  <div className="col-5 col-lg-7">{settlement.externalReference || '--'}</div>
                </div>
              ) : null}
              {settlement?.Branch?.name !== null || settlement?.Branch?.name !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold">Sucursal:</div>
                  <div className="col-5 col-lg-7"> {settlement?.Branch?.name}</div>
                </div>
              ) : null}
              {businessNumber?.externalCode !== null || settlement?.externalCode !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold mb-2">N° Comercio:</div>
                  <div className="col-5 col-lg-7"> {businessNumber?.externalCode}</div>
                </div>
              ) : null}
              <div className="d-flex">
                <div className="col-7 col-md-5 col-lg-6 fw-bold">Pago:</div>
                <div className="col-5 col-md-6 col-lg-6">
                  {!settlement.Collections?.length && isOca(settlement?.PlatformExternal) && (
                    <div className="indicar-info">
                      <div>
                        <span className="indicator" />
                      </div>
                      <p className="label-payments">(Estamos recopilando información sobre la fecha de pago)</p>
                    </div>
                  )}
                  {!settlement.Collections?.length && !isOca(settlement.PlatformExternal) ? (
                    <p className="color-red">(No se realizó un pago. Saldo a favor de {settlement.PlatformExternal.name})</p>
                  ) : (
                    settlement.Collections.map((collection) => (
                      <div className="d-flex flex-row" key={collection.id}>
                        <div className="d-inline col-4 col-md-5 col-lg-4">{moment(collection?.date).locale('es').format('DD/MM/YY')} </div>
                        <div className="col-9 col-md-9 col-lg-8 ms-3">
                          {currencyFormat(settlement.currencyCode)}
                          {decimalFormat(collection?.amount)}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              {settlement?.cardBrand !== null || settlement?.cardBrand !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-7"> {settlement?.cardBrand}</div>
                </div>
              ) : null}
            </div>
          ) : (
            <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {settlement?.companyId !== null || settlement?.companyId !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-5 fw-bold">Empresa:</div>
                  <div className="col-5 col-lg-7">{getCompany()}</div>
                </div>
              ) : null}
              {settlement?.externalReference !== null || settlement?.externalReference !== undefined ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-5 fw-bold">Referencia:</div>
                  <div className="col-5 col-lg-7">{settlement.externalReference ?? '--'}</div>
                </div>
              ) : null}
              <div className="d-flex">
                <div className="col-7 col-md-5 col-lg-5 fw-bold">Pago:</div>
                <div className="col-5 col-md-10 col-lg-10">
                  {!settlement.Collections?.length && isOca(settlement.PlatformExternal) && (
                    <div className="indicar-info">
                      <div>
                        <span className="indicator" />
                      </div>
                      <p className="label-payments">(Estamos recopilando información sobre la fecha de pago)</p>
                    </div>
                  )}
                  {!settlement.Collections?.length && !isOca(settlement.PlatformExternal) ? (
                    <p className="color-red">(No se realizó un pago. Saldo a favor de {settlement.PlatformExternal.name})</p>
                  ) : (
                    settlement.Collections.map((collection) => (
                      <div className="d-flex flex-row" key={collection.id}>
                        <div className="d-inline col-4 col-md-5 col-lg-4">{moment(collection?.date).locale('es').format('DD/MM/YY')} </div>
                        <div className="col-9 col-md-9 col-lg-7 ms-1">
                          {currencyFormat(settlement.currencyCode)}
                          {decimalFormat(collection?.amount)}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center mt-3">
            {/* <div className="fw-bold">Ventas:</div> */}
            <ActionButtonComponent text="Ver ventas" onClick={handleClick} containerClassName="px-5 py-1" icon={null} />
          </div>
          <hr className="d-xs-inline d-md-none w-100" />
        </div>
        <div className="px-2 col-12 col-md-4 col-lg-4" style={{ minWidth: '200px' }}>
          {settlement?.grossAmount !== null || settlement?.grossAmount !== undefined ? (
            <div className="row">
              <div className="col-4 col-md-5 col-lg-6 col-xl-6">Bruto:</div>
              <div className="col-7 col-md-7 col-lg-6 col-xl-6 text">
                {currencyFormat(settlement.currencyCode)} {decimalFormat(settlement.grossAmount)}
              </div>
            </div>
          ) : null}
          {getComissions() ? (
            <div className={`total-deductions color-red ${settlement.totalDeductions <= 0 && 'error'}`}>
              <div className="row color-orange">
                <div className="col-6 col-md-6 col-lg-7">
                  Comisiones:
                  <span> {percentAmount(getComissions())}</span>
                  {getComissions() === 0 && <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>}
                </div>
                <div className="col-5 col-md-6 col-lg-5 text">
                  {currencyFormat(settlement.currencyCode)}
                  {decimalFormat(getComissions() * -1)}{' '}
                </div>
              </div>
            </div>
          ) : null}
          <div className={`total-deductions color-red ${settlement.totalDeductions <= 0 && 'error'}`}>
            <div className="row color-orange">
              {settlement?.deductionsDetails.others !== null || settlement?.deductionsDetails.others !== undefined ? (
                <>
                  <div className="col-6 col-md-6 col-lg-8">
                    Otros descuentos:
                    <span> {percentAmount(Math.abs(settlement.deductionsDetails?.others))}</span>
                    {!settlement.deductionsDetails.others && (
                      <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>
                    )}
                  </div>
                  <div className="col-5 col-md-6 col-lg-4 text">
                    {currencyFormat(settlement.currencyCode)} {decimalFormat(settlement.deductionsDetails.others * -1)}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {settlement.Deductions
            ? settlement.Deductions.map(
                (deduction) =>
                  deduction.name === 'Descuentos/Bonificaciones' && (
                    <div className="row" key={deduction.name}>
                      <div className="col-7 col-md-6 col-lg-8">
                        Dtos a cobrar:
                        {settlement.deductionsDetails?.others ? <span> {percentAmount(Math.abs(deduction.amount))}</span> : ' (0%)'}
                      </div>
                      <div className="col-5 col-md-6 col-lg-4 text">
                        {currencyFormat(settlement.currencyCode)} {decimalFormat(Math.abs(deduction.amount))}
                      </div>
                    </div>
                  ),
              )
            : null}
          {settlement?.totalTaxes !== null || settlement?.totalTaxes !== undefined ? (
            <div className={`total-deductions color-red ${settlement.totalTaxes <= 0 && 'error'}`}>
              <div className="row">
                <div className="col-6 col-md-6">
                  Impuestos:
                  <span> {percentAmount(Math.abs(settlement.totalTaxes))}</span>
                  {!settlement.totalTaxes && (
                    <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>
                  )}
                </div>
                <div className="col-5 col-md-6 text">
                  {currencyFormat(settlement.currencyCode)}
                  {decimalFormat(settlement.totalTaxes * -1)}{' '}
                </div>
              </div>
            </div>
          ) : null}
          {externalPlatformsBasicData(settlement.platformExternalCode)?.type === ExternalPlatformType.delivery && (
            <div className={`total-deductions color-red ${getLocalCharges() <= 0 && 'error'}`}>
              <div className="row">
                <div className="col-6 col-md-6">
                  Cobrado por el comercio:
                  <span> {percentAmount(Math.abs(getLocalCharges()))}</span>
                </div>
                <div className="col-5 col-md-6 text">
                  {currencyFormat(settlement.currencyCode)}
                  {decimalFormat(getLocalCharges())}
                </div>
              </div>
            </div>
          )}
          {settlement?.amount !== null || settlement?.amount !== undefined ? (
            <div className="net-amount">
              <div className="row">
                <div className="col-6 col-md-5 col-lg-7">Neto:</div>
                <div className={settlement.amount > 0 ? 'col-5 col-md-7 col-lg-5 text' : 'col-5 col-md-7 col-lg-5 text text-negative'}>
                  {currencyFormat(settlement.currencyCode)} {decimalFormat(settlement.amount)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {getTagsSettlements(settlement)}
      </div>
    </div>
  );

  return (
    <>
      <ReactTooltip />
      <AccordionComponent
        className="settlement-row"
        header={headerContent()}
        content={mainContent()}
        estimated={estimated}
        // projected={projected}
        analytics={{
          event: EVENTS.COLLECTIONS.ROW.EXPAND,
          data: {
            settlementId: settlement.id,
            externalReference: settlement.externalCode,
            platform: settlement.platformExternalCode,
            companyId: settlement.companyId,
            branchId: settlement.branchId,
            confirmed: settlement.Collections ? settlement.Collections[0]?.confirmed : null,
          },
        }}
      />
    </>
  );
};
export default SettlementRow;
