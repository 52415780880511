import React, { FC } from 'react';
import { TextItem } from '../../../utils/types';
import variables from '../../../scss/_exports.module.scss';

const TitleComponent: FC<TextItem> = ({ children, color = variables.colorBlack, weight, className }: TextItem) => (
  <h1 style={{ color }} className={`title-component font-xl ${weight} ${className}`}>
    {children}
  </h1>
);

export default TitleComponent;
