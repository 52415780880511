import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { URLSearchParams } from 'url';
import {
  approveClientAccess,
  deleteClientAccess,
  denyClientAccessRequest,
  getClientsAccess,
  getClientAccessRequests,
} from '../services/clientsService';
import { createAddress, editAddress, getCompany } from '../services/companyService';
import { regexCountryCodes } from './constants';
import errorHandlerHelper from './errorHandler';
import { AddAddressResponse, AddressRequestPatch, AddressRequestPost, Client, Company, Collection, CashflowSummary } from './interfaces';
// import { EVENTS } from './segment/events';
// import { segmentTrack } from './segment/hooks';
// import { arrayContains } from './stringUtils';
import { FilterFormikKeys, FilterParams, StorageVars as localStorageVariables } from './types';
import { setAuthMessages } from '../redux/slices/authSlice';
import { removeAllModalsFromList } from '../redux/slices/modalSlice';
import { deleteUserToken } from './firebaseHelper';
import { validateWalkthroughStorage } from '../components/Walkthrough';
import { clearStore } from '../redux/store';
import { logoutUserProfile } from '../redux/slices/userSlice';

// TODO: Added to maybe be used, if not needed simply remove this file or replace methods

export enum TierCodes {
  Trial7 = 'cash_trial_7',
  Accountant = 'cash_contador',
}

type TiersInToken = {
  trialExpiredDate: Date;
  extensionDays: number;
  trialExpired: boolean;
  platformCode: string;
  trialRemainingDays: number;
  Tier: {
    code: TierCodes;
    name?: string;
  };
};
// TODO modify this (add permissions)
type DecodedToken = {
  lastFeedbackAt: string | null;
  tiers: Array<TiersInToken>;
};

export const setTokens = (token: string, tokenRefresh: string): void => {
  try {
    localStorage.setItem('token', token);
    return localStorage.setItem('tokenRefresh', tokenRefresh);
  } catch (error) {
    return error('setTokens', { error });
  }
};

export const setDaysRemainingExpiredPassword = (daysRemaining: number): void => {
  try {
    return localStorage.setItem(localStorageVariables.daysRemainingExpiredPassword, `${daysRemaining}`);
  } catch (error) {
    return error(localStorageVariables.daysRemainingExpiredPassword, { error });
  }
};

export const getTrialStatus = async (): Promise<TiersInToken> => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken: DecodedToken = await jwtDecode(token);
    const userTier = decodedToken.tiers.find((tier) => tier.platformCode === 'cash');
    if (userTier) return userTier;
  }
  throw Error;
};
export const getPermissions = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken: any = await jwtDecode(token);
    return decodedToken?.permissions ?? [];
  }
  return [];
};
export const getTierCode = async (): Promise<TierCodes> => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken: DecodedToken = await jwtDecode(token);
    const userTier = decodedToken.tiers.find((tier) => tier.platformCode === 'cash');
    if (userTier) return userTier.Tier.code;
  }
  throw Error;
};

export const handleKeyDownDefault = (): void => {
  // do nothing
};

export const getChangedValues = (values: Record<string, unknown>, initialValues: Record<string, unknown>): Record<string, unknown> => {
  const modifiedValues: Record<string, unknown> = {};

  if (values) {
    Object.entries(values).forEach((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (value !== initialValues[key]) {
        modifiedValues[key] = value;
      }
    });
  }

  return modifiedValues;
};

export const getOS = (): string | null => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.includes(platform)) {
    os = 'Mac OS';
  } else if (iosPlatforms.includes(platform)) {
    os = 'iOS';
  } else if (windowsPlatforms.includes(platform)) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const asyncAddAddressHelper = async (
  addressData: AddressRequestPost,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<AddAddressResponse> => {
  try {
    const address = await createAddress(addressData);
    // segmentTrack(EVENTS.COMPANY.ADDRESS.CREATE.SUCCESS, { address });
    return address;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    // segmentTrack(EVENTS.COMPANY.ADDRESS.CREATE.FAILED, error);
    return error;
  }
};

export const asyncPatchAddressHelper = async (
  addressData: AddressRequestPatch,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<AddAddressResponse> => {
  try {
    const address = await editAddress(addressData);
    // segmentTrack(EVENTS.COMPANY.ADDRESS.UPDATE.SUCCESS, { address });
    return address;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    // segmentTrack(EVENTS.COMPANY.ADDRESS.UPDATE.FAILED, error);
    return error;
  }
};

export const asyncGetCompanyDataHelper = async (
  id: string | number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<Company> => {
  try {
    const company = await getCompany(id);
    return company;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const paramsToObject = (entries: URLSearchParams): Record<string, string> => {
  const result: Record<string, string> = {};
  entries.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const parseFilters = (values: Record<string, string>): FilterParams => {
  const filterableObject: FilterParams = {};
  Object.keys(values).forEach((key) => {
    if (values[key]) {
      switch (key) {
        case 'companies':
          filterableObject['filter[companyId][in]'] = JSON.stringify(values[key].split(',').map((value) => parseInt(value, 10)));
          break;
        case 'included_settlement':
          filterableObject['filter[included_settlement]'] = values[key];
          break;
        // case 'isProjected':
        //   filterableObject['filter[isProjected][eq]'] = values[key];
        //   break;
        case 'type':
          filterableObject['filter[type][in]'] = JSON.stringify(values[key].split(',').map((value) => value));
          break;
        case 'branches':
          filterableObject['filter[branchId][in]'] = JSON.stringify(values[key].split(',').map((value) => parseInt(value, 10)));
          break;
        case 'startDate':
          filterableObject['filter[date][gte]'] = moment(values[key]).startOf('day').utc().format();
          break;
        case 'startPaymentDate':
          filterableObject['filter[paymentDate][gte]'] = moment(values[key]).startOf('day').utc().format();
          break;
        case 'dateSince':
          filterableObject['filter[dateSince]'] = moment(values[key]).startOf('day').utc().format();
          break;
        case 'endDate':
          if (Object.keys(filterableObject).some((elem) => elem === 'filter[date][gte]')) {
            filterableObject['filter[date][between]'] = `${filterableObject['filter[date][gte]']},${moment(values[key])
              .endOf('day')
              .utc()
              .format()}`;
            delete filterableObject['filter[date][gte]'];
          } else {
            filterableObject['filter[date][lte]'] = moment(values[key]).endOf('day').utc().format();
          }
          break;
        case 'endPaymentDate':
          if (Object.keys(filterableObject).some((elem) => elem === 'filter[paymentDate][gte]')) {
            filterableObject['filter[paymentDate][between]'] = `${filterableObject['filter[paymentDate][gte]']},${moment(values[key])
              .endOf('day')
              .utc()
              .format()}`;
            delete filterableObject['filter[paymentDate][gte]'];
          } else {
            filterableObject['filter[paymentDate][lte]'] = moment(values[key]).endOf('day').utc().format();
          }
          break;
        case 'dateUntil':
          filterableObject['filter[dateUntil]'] = moment(values[key]).endOf('day').utc().format();
          break;
        case 'presentedStartDate':
          filterableObject['filter[presentedDate][gte]'] = moment(values[key]).startOf('day').utc().format();
          break;
        case 'presentedEndDate':
          filterableObject['filter[presentedDate][lte]'] = moment(values[key]).endOf('day').utc().format();
          break;
        case 'platformCode':
          filterableObject['filter[platform_external_code][eq]'] = values[key];
          break;
        case 'currencyCode':
          filterableObject['filter[currencyCode][eq]'] = values[key];
          break;
        case 'taxTypeCode':
          filterableObject['filter[taxTypeCode][in]'] = JSON.stringify(values[key].split(',').map((value) => value));
          break;
        case 'lite':
          filterableObject['filter[lite]'] = values[key];
          break;
        case 'presetDateFilters':
          //* Prevent sending this key into the filters object
          break;
        default:
          filterableObject[key as 'page'] = values[key];
      }
    }
  });
  return filterableObject;
};

export const cleanNullValues = (values: Record<string, string>): Record<string, string> => {
  const notNullObject: FormikValues = {};
  Object.keys(values).forEach((key) => {
    if (values[key] !== null && values[key] !== '' && values[key].length) notNullObject[key as FilterFormikKeys] = values[key];
  });
  return notNullObject;
};

export const asyncListClientsAccessRequestHelper = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Array<Client>> => {
  try {
    const accessRequests = await getClientAccessRequests();
    return accessRequests;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const asyncListClientsAccessHelper = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Array<Client>> => {
  try {
    const accesses = await getClientsAccess();
    return accesses;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const asyncDeleteAccessClient = async (
  id: number | string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<Client> => {
  try {
    const client = await deleteClientAccess(id);
    return client;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const asyncDeleteAccessClientRequest = async (
  id: number | string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<Client> => {
  try {
    const client = await denyClientAccessRequest(id);
    return client;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const asyncApproveAccessClientRequest = async (
  id: number | string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<Client> => {
  try {
    const client = await approveClientAccess(id);
    return client;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const formatTaxCode = (taxCode: string | undefined, countryCode: string | undefined): string | undefined => {
  if (taxCode && countryCode) {
    let value = taxCode.trim().replaceAll('-', '');
    regexCountryCodes[countryCode || 'default'].taxCodeHyphenPosition.forEach((position) => {
      if (value.length > position) value = `${value.slice(0, position)}-${value.slice(position)}`;
    });
    return value;
  }
  return taxCode;
};

export const isDateCurrentMonth = (date: Date): boolean => {
  const now = moment();
  const dateMoment = moment(date);
  return dateMoment.isSame(now, 'month');
};

interface ICollections {
  amount: number;
  icon: string[];
  confirmed: boolean;
  related?: string[];
  // projected: boolean;
}
export const removeItemFromArr = (arr: any[], items: any[]): any[] => {
  items.forEach((item) => {
    const i = arr.indexOf(item);
    arr.splice(i, 1);
  });

  return arr;
};

export const filterCollectionsByMonth = (collections: Array<Collection> | null, month: Date) =>
  collections?.filter((collection) => moment(collection.date).isSame(month, 'month'));

export const filterCollectionByDay = (collections: Array<Collection> | null, day: string) =>
  collections?.filter((collection) => moment(collection.date).isSame(day, 'day'));

export const getCollectionDataByDay = (
  collections: Array<CashflowSummary> | null,
  date: Date,
  totaledAmounts: CashflowSummary | undefined,
): any => {
  let collectionData: ICollections = {
    amount: 0,
    icon: [],
    confirmed: false,
    related: [],
    // projected: false,
  };
  collections?.map((item: CashflowSummary) => {
    if (moment(item.date).isSame(date, 'day')) {
      const totalAmount = item.platforms.reduce((acc, platform) => acc + platform.amount, 0);
      const icon = item.platforms.filter((itemPlatform) => itemPlatform).map((platform) => platform.code);
      const iconRelated = item.platforms.filter((itemPlatform) => itemPlatform.relatedPlatforms).map((platform) => platform.code);
      collectionData = {
        amount: totalAmount,
        confirmed: totaledAmounts?.unconfirmed === 0,
        icon,
        related: iconRelated,
        // projected: item.projected,
      };
    }
    return collectionData;
  });

  return collectionData;
};

export const getTotalAmount = (collections: Array<CashflowSummary> | null, date: string): any => {
  let total = 0;
  collections?.forEach((collection: CashflowSummary) => {
    if (moment(collection.summaryDate).isSame(date, 'month')) {
      total += collection.amountIn - collection.amountOut;
    }
  });

  return total;
};

export const dateIsToday = (date: Date): boolean => {
  const now = moment();
  const dateMoment = moment(date);
  return dateMoment.isSame(now, 'day');
};

export const getPageCount = (totalPageSize: number, total: number, page: number, pages: number, arrayLength: number): string => {
  const response = `
    ${page === 1 ? page : Math.ceil(totalPageSize * (page - 1))} - ${arrayLength < totalPageSize ? total : arrayLength * page} / ${total}
  `;

  return response;
};

type StorageVars = 'completePECCredentials' | 'walkthrough' | 'completePECCredentialsClient';

export const storageHelper = (
  action: 'getItem' | 'removeItem' | 'clear' | 'setItem' | 'key',
  key: StorageVars | number,
  value?: string,
): string | void | null => {
  switch (action) {
    case 'getItem':
      return localStorage.getItem(key as StorageVars);
    case 'setItem':
      if (value) {
        return localStorage.setItem(key as StorageVars, value);
      }
      return null;
    case 'clear':
      return localStorage.clear();
    case 'key':
      return localStorage.key(key as number);
    default:
      break;
  }
  return null;
};

export const logoutUser = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): void => {
  dispatch(setAuthMessages({ message: { logout: 'Saliendo...' } }));
  dispatch(removeAllModalsFromList());
  deleteUserToken()
    .catch((error) => errorHandlerHelper(error, dispatch))
    .finally(() => {
      validateWalkthroughStorage();
      dispatch(clearStore());
      dispatch(logoutUserProfile());
    });
};
