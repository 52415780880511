import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import ArrowIcon from '../../../assets/svgComponents/ArrowIcon';
import { EMAIL_PLATFORMS, externalPlatformsBasicData, platformExternalCodesTrimmed } from '../../../utils/constants';
import externalPlatformIcon from '../../../utils/externalPlatformIcon';
import { ExternalActivePlatform, FormTypeFiserv, InputWrapperProps } from '../../../utils/interfaces';
import InputWrapper from '../../inputs/InputWrapper';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';

interface PlatformRowProps extends InputWrapperProps {
  wrapperClassName?: string;
  className?: string;
  onClick?: () => void;
  platform: ExternalActivePlatform;
}
const moddoString = 'moddo_ar';

const PlatformRowComponent: FC<PlatformRowProps> = ({
  className = '',
  containerClassName,
  wrapperClassName,
  children,
  platform,
  onClick,
}: PlatformRowProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const validatePlatforms = !EMAIL_PLATFORMS.reduce((acc, prev) => acc || platform.platformExternalCode.includes(prev), false);
  const validateUsername = platform?.platformExternalCode !== moddoString && (platform?.username === null || platform?.username === '');
  const validateFailureAuth = platform.failureAuthCount || (validateUsername && validatePlatforms);
  const validateFailureAuthFiserv =
    (platform.failureAuthCount || !platform?.username) &&
    platform.platformExternalCode.includes(platformExternalCodesTrimmed.FISERV) &&
    platform?.codeAux !== FormTypeFiserv.FTP;
  const validateOldFiservUsername =
    platform.platformExternalCode.includes(platformExternalCodesTrimmed.FISERV) &&
    platform.username &&
    !platform?.username.startsWith('pec_') &&
    platform.failureAuthCount;
  const { companyId } = platform;
  const handleClickPlatform = (): void => {
    if (location.pathname.includes('clients')) {
      navigate(`${url}/external-platform/${platform.id}`, { state: { externalPlatform: platform } });
    } else {
      // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.UPDATE.CLICK, { platform });
      navigate(`/external-platform/${platform.id}`, { state: { company: companyId } });
    }
  };

  const colorArrow = (): string => {
    if (validateFailureAuthFiserv) {
      return 'fill-yellow';
    }
    if (validateFailureAuth || validateOldFiservUsername) {
      return 'fill-red';
    }
    return 'fill-blue';
  };

  const iconShow = (icon: any) => {
    let res;
    if (externalPlatformsBasicData(icon)?.logoImage) {
      res = externalPlatformIcon({
        code: icon === 'cash' ? 'cash_ar' : icon,
        className: 'platform-image',
      });
    } else {
      res = (
        <div className="p-1">
          <div className="external-platform-logo-not-supported">
            <p className="text-not-supported">{icon.slice(0, 1)}</p>
          </div>
        </div>
      );
    }
    return res;
  };

  return (
    <InputWrapper containerClassName={containerClassName}>
      <div
        onClick={onClick || handleClickPlatform}
        role="none"
        className={`
          ${wrapperClassName}
          platform-row-component-container
          ${className}
          ${(validateFailureAuth || validateOldFiservUsername) && 'failure-auth-count'}
          ${validateFailureAuthFiserv && 'warning-auth-count'}
        `}
        data-tip={validateFailureAuthFiserv ? 'Esperando el mail de confirmación.' : null}
      >
        {validateFailureAuth || validateFailureAuthFiserv || validateOldFiservUsername ? (
          <BsFillExclamationTriangleFill
            className={`ms-2 me-2 ${(validateFailureAuth || validateOldFiservUsername) && 'color-red'} ${
              validateFailureAuthFiserv && 'color-yellow'
            }`}
          />
        ) : null}
        {iconShow(platform.platformExternalCode)}
        {/* {externalPlatformIcon({ code: platform.platformExternalCode, className: 'platform-image' })} */}
        <p className="ms-2 color-gray-dark">{externalPlatformsBasicData(platform.platformExternalCode).name}</p>
        <p className="w-100d-flex align-items-center">{children}</p>
        <ArrowIcon className={`arrow ms-auto me-2 ${colorArrow()}`} />
      </div>
      <ReactTooltip />
    </InputWrapper>
  );
};

PlatformRowComponent.defaultProps = {
  wrapperClassName: '',
  className: '',
  onClick: undefined,
};

export default PlatformRowComponent;
