import React, { useEffect } from 'react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addNotificationPopUp } from '../../redux/slices/notificationSlice';
import errorHandlerHelper from '../../utils/errorHandler';
import { getTrialStatus } from '../../utils/helper';
import { CountryCode, PaymentUrl } from '../../utils/interfaces';
import { StorageVars } from '../../utils/types';

const NotificationsContainer: React.FC = () => {
  const { notificationsPopUpList } = useAppSelector((state) => state.notifications);
  const { user } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const daysRemainingExpiredPassword = localStorage.getItem(StorageVars.daysRemainingExpiredPassword);

  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialExpired, trialRemainingDays, trialExpiredDate } = cashTier;
        const tierCancelled = cashTier?.Tier?.name?.toLowerCase().includes('cancelled');
        if (trialExpired || tierCancelled) {
          // User expired notification
          dispatch(
            addNotificationPopUp({
              type: 'error',
              title: '¡Tu período de prueba ha vencido! Renovalo para seguir trabajando con normalidad.',
              body: 'Continúa usando nubceo',
              link: user?.Tenant.countryCode === CountryCode.AR ? PaymentUrl.AR : PaymentUrl.UY,
              absolute: true,
            }),
          );
        } else if (trialExpiredDate && trialRemainingDays >= 0 && trialRemainingDays <= 5) {
          dispatch(
            addNotificationPopUp({
              type: 'warning',
              title:
                trialRemainingDays > 0
                  ? `Tu período de prueba está por vencer en ${cashTier?.trialRemainingDays} días`
                  : 'Tu período de prueba esta por vencer.',
              body: 'No olvides de suscribirte para continuar usando la aplicación',
              link: 'https://www.nubceo.com/cash-ar/suscripcion-ar/',
              absolute: true,
            }),
          );
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  return (
    <div className="notifications-container center-notification">
      {notificationsPopUpList.map((notification) => (
        <Notification notification={notification} key={notification.title} />
      ))}
      {daysRemainingExpiredPassword !== null && Number(daysRemainingExpiredPassword) <= 5 ? (
        <>
          <div className="notification warning cursor-pointer" role="none" onClick={(): void => navigate('/change-password')}>
            <div className="notification-header">
              <BsFillExclamationTriangleFill />
            </div>
            <div className="notification-content">
              <div className="font-sm font-weight-medium">
                ¡Tu contraseña expira en {Number(daysRemainingExpiredPassword)} {Number(daysRemainingExpiredPassword) > 1 ? 'días' : 'día'}!
              </div>
              <div className="font-xs">Debés renovar tu contraseña para poder seguir usando nubceo.</div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NotificationsContainer;
