import * as React from 'react';
import { SVGProps } from '../../utils/types';

function MinusSVG({ fill, className, height, width, onClick }: SVGProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={className}
      style={{ height, width }}
      onClick={onClick}
      viewBox="0 0 16 16"
    >
      <path fill={fill || '#0C6BF4'} d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
    </svg>
  );
}

export default MinusSVG;
