const useDeviceInfo = (): any => {
  const ua = navigator.userAgent;
  const isMobile = {
    android: (): RegExpMatchArray | null => ua.match(/android/i),
    ios: (): RegExpMatchArray | null => ua.match(/iphone|ipad|ipod/i),
    windows: (): RegExpMatchArray | null => ua.match(/windows phone/i),
    any: (): RegExpMatchArray | null => isMobile.android() || isMobile.ios() || isMobile.windows(),
  };
  return isMobile;
};

export default useDeviceInfo;
