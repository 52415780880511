import React, { FC, ReactElement, useRef } from 'react';
import { handleKeyDownDefault } from '../../utils/helper';
import { useHandleMouseDownOutside } from '../../utils/hooks';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import LinkComponent from '../Link';

type ItemDropdownMenu = {
  icon: ReactElement;
  text: string;
  route: string;
  onClick?: () => void;
};

type PositionDropdownMenu = {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
};

const DropdownMenuComponent: FC<{
  items: Array<ItemDropdownMenu>;
  position?: PositionDropdownMenu;
  className?: string;
  toggle: ReactElement;
}> = ({ items, position, children, className, toggle }) => {
  const menuRef = useRef(null);
  const [opened, setOpened] = useHandleMouseDownOutside(menuRef);
  const handleOpenMenu = (): void => {
    // if (!opened) segmentTrack(EVENTS.LAYOUT.USER_MENU.EXPAND);
    setOpened(!opened);
  };

  return (
    <div ref={menuRef} className={`dropdown-menu-component position-relative ${className}`}>
      <div className="toggle" onClick={handleOpenMenu} role="none" onKeyDown={handleKeyDownDefault}>
        {toggle}
      </div>
      {opened && (
        <div
          style={{
            top: !position ? 32 : position?.top || 'none',
            right: !position ? 0 : position?.right || 'none',
            left: position?.left || 'none',
            bottom: position?.bottom || 'none',
          }}
          className="list-container"
          role="none"
        >
          {items.map((item) => (
            <div onClick={item.onClick} role="none" className="d-flex align-items-center dropdown-item background-white" key={item.text}>
              <LinkComponent route={item.route} className="d-flex align-items-center dropdown-link w-100">
                {item.icon}
                <p className="ms-2 color-gray-dark item-text" style={{ maxWidth: children ? '64%' : '80%' }}>
                  {item.text}
                </p>
                {children}
              </LinkComponent>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenuComponent;
