/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import PaginationComponent from '../PaginationComponent';

import { getPageCount } from '../../utils/helper';

interface IProps {
  pageSize: number;
  total: number;
  page: string;
  pages: number;
  length: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingClient?: 'idle' | 'pending' | 'succeeded' | 'failed';
  handlePagination: (searchParams: Record<string, string>) => void;
}
const PaginationWithCount: FC<IProps> = ({ page, pageSize, pages, total, length, loading, loadingClient, handlePagination }) => (
  <div className="d-flex justify-content-between align-items-center">
    <p className="pt-3 page-count">
      {loading === 'succeeded' || loadingClient === 'succeeded' ? getPageCount(25, total, +page, pages, length) : ''}
    </p>

    <PaginationComponent page={page} totalPages={pages} onClick={handlePagination} />
  </div>
);
export default PaginationWithCount;
