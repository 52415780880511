import React, { FC } from 'react';
import { TextItem } from '../../../utils/types';
import variables from '../../../scss/_exports.module.scss';

const ParagraphComponent: FC<TextItem> = ({
  children,
  className = '',
  color = variables.black,
  size = 'md',
  weight,
  ...props
}: TextItem) => (
  <p style={{ color }} className={`paragraph-component ${size} ${weight} ${className}`} {...props}>
    {children}
  </p>
);

export default ParagraphComponent;
