import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';
import { paramsToObject } from '../../utils/helper';
import { CallbackFunction } from '../../utils/types';

type PaginationType = {
  totalPages: number;
  page: string;
  onClick: CallbackFunction;
};

const PaginationComponent: React.FC<PaginationType> = ({ totalPages, page, onClick }) => {
  const searchParams = paramsToObject(new URLSearchParams(useLocation().search));
  searchParams.page = page;
  const [inputValue, setInputValue] = useState(page);

  useEffect(() => {
    setInputValue(page);
  }, [page]);

  const isValidNumber = (numberAsString: string): boolean => {
    const parsedNumber = Number(numberAsString);
    if (parsedNumber > totalPages) return false;
    return !Number.isNaN(parsedNumber);
  };

  const handlePrevClick = (): void => {
    searchParams.page = (Number(searchParams.page) - 1).toString();
    onClick(searchParams);
  };
  const handleNextClick = (): void => {
    searchParams.page = (Number(searchParams.page) + 1).toString();
    onClick(searchParams);
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (isValidNumber(e.target.value)) {
      setInputValue(Number(e.target.value).toString());
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      if (inputValue === '0') return;
      searchParams.page = inputValue;
      onClick(searchParams);
    }
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="d-flex align-items-center mt-2 pagination">
        <button
          type="button"
          className={`pagination-prev ${page === '1' && 'pagination-disabled'}`}
          disabled={page === '1'}
          onClick={handlePrevClick}
        >
          <DropdownArrowIconSVG className="color-white" width={15} />
        </button>
        <input type="text" className="pagination-input" value={inputValue} onChange={handleOnChange} onKeyDown={handleKeyDown} />
        <span className="pagination-data">{`/ ${totalPages}`}</span>
        <button
          type="button"
          className={`pagination-next ${page === totalPages?.toString() && 'pagination-disabled'}`}
          disabled={page === totalPages?.toString()}
          onClick={handleNextClick}
        >
          <DropdownArrowIconSVG className="color-white" width={15} />
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;
