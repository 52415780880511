import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

export async function tiendaNubeAuth(payload: { code: string; tenantId: string; id: string }): Promise<void> {
  await axiosV2.post('/platform_external/tiendanube-auth', payload);
}
export async function logTiendaNubeError(payload: {
  platformExternalCredentialId: number;
  tenantId: number;
  platformExternalCode: string;
  result: string;
  status: string;
  resultInfo: string;
}): Promise<void> {
  await axios.post(`/platform_external_attempt`, payload);
}
