import React, { FunctionComponent } from 'react';
import { HeaderProps } from 'react-big-calendar';

import { CalendarPropsHeader } from '../../utils/interfaces';
import { lettersUpperCase, removeDot } from '../../utils/stringUtils';

const CalendarHeader: FunctionComponent<HeaderProps> = ({ label }: CalendarPropsHeader) => (
  <p className="calendar-label font-sm ">{removeDot(lettersUpperCase(label))}</p>
);
export default CalendarHeader;
