import React from 'react';
import moment from 'moment';
import { BsCheck, BsFillExclamationTriangleFill, BsInfoCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { NotificationType } from '../../../redux/slices/notificationSlice';
import { TimeZoneByCountry, externalPlatformsBasicData } from '../../../utils/constants';
import { CountryCode, TypesOfNotifications } from '../../../utils/interfaces';
import errorImage from '../../../assets/images/error.png';
import { viewExternalPlatformCredential } from '../../../services/externalPlatformService';
import errorHandlerHelper from '../../../utils/errorHandler';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';

interface NotificationProps {
  item: NotificationType;
  containerClassName?: string;
}

const NotificationRowComponent = ({ item, containerClassName }: NotificationProps): React.ReactElement => {
  const { user } = useAppSelector((state) => state.userProfile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companiesList } = useAppSelector((state) => state.company);
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const countryCode: string = activeCurrency?.countryCode ? activeCurrency?.countryCode : CountryCode.AR;

  const getCompany = (): string | undefined => {
    const business = companiesList.find((company) => company.id === item.message);
    return business?.name ?? 'tus empresas,';
  };

  let typeStyle: string;
  let messageTitle: string;
  let messageCta: string;
  let messageBody: string | React.ReactElement;
  let messageIcon;
  let messageLink: string | undefined;

  const taxCode = user?.Tenant?.countryCode === 'UY' ? 'RUT' : 'CUIT';

  const bodyNotification = (data: any): React.ReactElement => {
    const messageData = JSON.parse(data?.message)[0];

    return (
      <div onClick={(): void => navigate(`/external-platform/${messageData?.companyId}`)} role="none" style={{ cursor: 'pointer' }}>
        <p>{`¡Acordate de configurar el ${taxCode} para ${getCompany()} así podemos mostrarte todos los datos!`}</p>
      </div>
    );
  };

  switch (item.typeNotification) {
    case TypesOfNotifications.DATA_REPORT:
      typeStyle = 'message-info';
      messageTitle = '¡Ya tenemos tu reporte!';
      messageBody = `${moment().diff(item.createdAt, 'days') <= 7 ? 'Presioná acá para descargarlo.' : ''} ${
        moment().diff(item.createdAt, 'days') >= 7 ? 'Nota: El link al reporte caducó, ya no podés descargarlo.' : ''
      }`;
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      messageLink = moment().diff(item.createdAt, 'days') <= 7 ? item.message : '';
      break;
    case TypesOfNotifications.ADMIN_API_NOTIFICATION:
      typeStyle = 'message-info';
      messageTitle = item.title;
      messageCta = item.cta ?? 'https://cash.nubceo.com/';
      messageBody = item.message;
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      messageLink = messageCta;
      break;
    case TypesOfNotifications.TAXCODE_EMPTY:
      typeStyle = 'message-info';
      messageTitle = 'nubceo';
      messageBody = bodyNotification(item);
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
    case TypesOfNotifications.PLATFORM_EXTERNAL_AUTH:
    case TypesOfNotifications.UNCONFIGURED_CREDENTIAL: {
      const jsonMsg = JSON.parse(item.message);
      typeStyle = 'message-error';
      messageTitle = '¡Ups!';
      messageBody = `Ha habido un error al intentar conectar con la plataforma de ${
        externalPlatformsBasicData(jsonMsg.platformExternalCode).name
      }. Por favor intentalo nuevamente más tarde.`;
      messageIcon = <BsFillExclamationTriangleFill className="message-icon" />;
      messageLink = `external-platform/${jsonMsg.platformExternalCredentialId}`;
      if (jsonMsg.status === 'SUCCESS') {
        typeStyle = 'message-success';
        messageTitle = '¡Bien!';
        messageBody = `Ya hemos logrado conectar con éxito la plataforma de ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        }`;
        messageIcon = <BsCheck className="message-icon" />;
      }
      if (jsonMsg.status === 'INVALID_CREDENTIALS') {
        typeStyle = 'message-error';
        messageTitle = 'Error';
        messageBody = `El usuario y/o contraseña de la plataforma ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        } son incorrectos`;
        messageIcon = <BsFillExclamationTriangleFill className="message-icon" />;
      }
      break;
    }
    case TypesOfNotifications.CASH_DAILY_SUMMARY: {
      const jsonMsg = JSON.parse(item.message);
      typeStyle = 'message-info';
      messageTitle = 'Notificación diaria';
      const dailyParsedMessage = JSON.parse(item.message)[0];
      const notificationMessage =
        parseInt(dailyParsedMessage.amount, 10) > 0
          ? `Hola ${user?.firstName} tus ingresos de hoy son $${dailyParsedMessage.amount}, en su mayoría provenientes de ${dailyParsedMessage.platformExternalName} (${dailyParsedMessage.largePlatformExternalPercent}%).`
          : 'No tienes ingresos hoy.';
      messageBody = notificationMessage;
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      // messageLink = item.message;
      if (jsonMsg.status === 'SUCCESS') {
        typeStyle = 'message-success';
        messageTitle = '¡Bien!';
        messageBody = `Ya hemos logrado conectar con éxito la plataforma de ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        }`;
        messageIcon = <BsCheck className="message-icon" />;
        // messageLink = '#';
      }
      if (jsonMsg.status === 'INVALID_CREDENTIALS') {
        typeStyle = 'message-error';
        messageTitle = 'Error';
        messageBody = `El usuario y/o contraseña de la plataforma ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        } son incorrectos`;
        messageIcon = <BsFillExclamationTriangleFill className="message-icon" />;
        // messageLink = '#';
      }
      break;
    }
    default:
      typeStyle = 'message-info';
      messageTitle = 'nubceo';
      messageBody = 'No podemos mostrar tu mensaje';
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
  }

  const handleClick = (): void | false => {
    if (item.typeNotification === TypesOfNotifications.DATA_REPORT) {
      window.open(messageLink);
      return;
    }
    viewExternalPlatformCredential(`${messageLink?.split('/')[1]}`)
      .then(() => item.typeNotification === TypesOfNotifications.PLATFORM_EXTERNAL_AUTH && navigate(messageLink || '/'))
      .catch((error) => {
        dispatch(
          addModalToList({
            pictureUrl: errorImage,
            body: `Las credenciales de la plataforma ya no estan disponibles en nubceo`,
            required: true,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async (): Promise<void> => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
        errorHandlerHelper(error, dispatch);
      });
  };

  return (
    <div
      className={`
        notification-row-component
        ${containerClassName}
        ${typeStyle}
        row-container
        my-1
        ${
          (item.typeNotification === TypesOfNotifications.PLATFORM_EXTERNAL_AUTH ||
            (item.typeNotification === TypesOfNotifications.DATA_REPORT && moment().diff(item.createdAt, 'days') <= 7)) &&
          'cursor-pointer'
        }
      `}
      onClick={handleClick}
      role="none"
    >
      {messageIcon}
      <div className="notification-row-component-title container-fluid w-100 p-1">
        <div className="d-flex flex-column mb-2 flex-md-row justify-content-md-between">
          <p className="font-weight-bold">{messageTitle}</p>
          <p className="font-xs">{moment(item.createdAt).tz(TimeZoneByCountry[countryCode])?.format('DD/MM/YY HH:mm')}</p>
        </div>
        <div>
          <p className="font-sm">{messageBody}</p>
        </div>
      </div>
    </div>
  );
};

NotificationRowComponent.defaultProps = {
  containerClassName: '',
};

export default NotificationRowComponent;
