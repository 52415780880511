import React, { FC, useEffect } from 'react';
import './scss/app.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useIdle } from 'react-use';
import { AdvanceSettlement, ExternalWebview, Layout, LoginPage, RecoverPassword, RegisterPage } from './pages/index'; // Importa tu nueva página aquí
import { interceptor } from './services/axiosInstance';
import { interceptor as interceptorV2 } from './services/axiosInstanceV2';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { getProfileDataAsync } from './redux/slices/userSlice';
import LoaderWrapper from './routing/LoaderWrapper';
import ModalContainer from './containers/ModalContainer';
import ProtectedRoute from './routing/ProtectedRoute';
import { ActivityCode, CountryCode } from './utils/interfaces';
import { StorageVars } from './utils/types';
import errorImage from './assets/images/error.png';
import MessageModalTokenExpired, { TimeZoneByCountry } from './utils/constants';
import { addModalToList, removeModalFromList } from './redux/slices/modalSlice';
import { logoutUser } from './utils/helper';
import { ENV_SESSION_INACTIVITY_IN_MINUTES } from './config';
import { authenticatedAccountantRoutes, authenticatedRegularRoutes, fullScreenRoutes, publicRoutes } from './routing/routes';
import PrivateRoute from './routing/PrivateRoute';

interceptor();
interceptorV2();

const App: FC = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.userProfile);
  const { user } = useAppSelector((state) => state.userProfile);
  const countryCode: string = user?.Tenant?.countryCode ? user?.Tenant?.countryCode : CountryCode.AR;
  const location = useLocation();
  const isTiendaNubeRedirect = location.pathname.includes('tiendanube/auth');
  moment.tz.setDefault(TimeZoneByCountry[countryCode]);
  if (isTiendaNubeRedirect) {
    localStorage.setItem(StorageVars.redirectFromTiendanube, 'true');
  }

  // ON APP FIRST RENDER
  useEffect(() => {
    dispatch<void>(getProfileDataAsync());
  }, []);

  const isIdle = useIdle(1000 * 60 * Number(ENV_SESSION_INACTIVITY_IN_MINUTES));
  const isExcludedPath = (currentLocation: any, paths: string[]): boolean =>
    paths.some((path) => currentLocation?.pathname?.includes(path));

  const excludedPaths = ['/login', '/register', '/forgot-password'];

  // Logs out the user when the user is inactive for more than 30 minutes
  useEffect(() => {
    if (isIdle && !isExcludedPath(location, excludedPaths)) {
      logoutUser(dispatch);
      setTimeout(() => {
        dispatch(
          addModalToList({
            body: <MessageModalTokenExpired />,
            required: true,
            pictureUrl: errorImage,
            okButton: {
              buttonText: 'Iniciar sesión',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      }, 2000);
    }
  }, [isIdle]);

  return (
    <LoaderWrapper>
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedRoute>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRoute>
              <RegisterPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRoute>
              <RecoverPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invite/:referral"
          element={
            <ProtectedRoute>
              <RegisterPage />
            </ProtectedRoute>
          }
        />
        {publicRoutes.map((route) => (
          <Route path={route.path} element={<route.component />} key={route.path} />
        ))}
        <Route path="/webview-page" element={<ExternalWebview />} />
        <Route path="/advance-settlement" element={<AdvanceSettlement />} />
        {fullScreenRoutes.find((route) => route.path === location.pathname) ? (
          fullScreenRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <ProtectedRoute>
                  <route.component />
                </ProtectedRoute>
              }
              key={route.path}
            />
          ))
        ) : (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            {activity === ActivityCode.CONTADOR
              ? authenticatedAccountantRoutes.map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <route.component />
                      </PrivateRoute>
                    }
                    key={route.path}
                  />
                ))
              : authenticatedRegularRoutes.map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <route.component />
                      </PrivateRoute>
                    }
                    key={route.path}
                  />
                ))}
          </Route>
        )}
      </Routes>
      <ModalContainer />
    </LoaderWrapper>
  );
};

export default App;
