import moment from 'moment';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { currencyFormat, decimalFormat } from '../../../../utils/stringUtils';
import AccordionComponent from '../../../Accordion';
import externalPlatformIcon from '../../../../utils/externalPlatformIcon';
import { EVENTS } from '../../../../utils/segment/events';
import { Payment } from '../../../../utils/interfaces';
import { externalPlatformsBasicData, platformExternalCodesTrimmed } from '../../../../utils/constants';
import paymentMethodIcon from '../../../../utils/paymentMethodIcon';
// import paymentMethodIcon from '../../../../utils/paymentMethodIcon';

type CashflowPaymentProps = {
  payment: Payment;
};
const CashflowPayment = ({ payment }: CashflowPaymentProps): React.ReactElement => {
  const paymentHeaderContent = (): React.ReactElement => (
    <>
      <div className="d-none d-sm-inline-flex col-3" data-tip="Plataforma">
        {externalPlatformIcon({
          code: payment.platformExternalCode ? payment?.platformExternalCode : 'cash_ar',
          className: 'external-platform-logo',
        })}
        <ReactTooltip />
        <span className="d-none d-md-inline-flex ms-4 align-items-center">
          {payment.platformExternalCode ? externalPlatformsBasicData(payment.platformExternalCode).name : 'Cash'}
        </span>
      </div>
      <div className="d-inline col-2 offset-1 d-sm-none p-0 d-flex flex-column">
        {externalPlatformIcon({
          code: payment.platformExternalCode ? payment?.platformExternalCode : 'cash_ar',
          className: 'external-platform-logo mini-logo',
        })}
      </div>
      <div className="col-4 col-sm-4 offset-1" data-tip="Fecha">
        <span className="d-none d-lg-inline">{moment(payment.date).locale('es').format('DD MMMM YYYY')}</span>
        <span className=" d-none d-md-inline d-lg-none">{moment(payment.date).locale('es').format('DD/MM/YY')}</span>
        <span className="d-inline d-md-none">{moment(payment.date).locale('es').format('D/MM')}</span>
      </div>
      <div className="d-none d-sm-inline col-2" data-tip="Tarjeta">
        {!(payment?.platformExternalCode?.indexOf('rappi') > -1 || payment?.platformExternalCode?.indexOf('pedidosya') > -1) &&
          payment?.cardBrand !== 'ADELANTO LIQUIDACION' &&
          payment?.cardBrand &&
          paymentMethodIcon({ brand: payment?.cardBrand, className: 'payment-method-logo' })}
        {!payment?.cardBrand &&
          payment?.platformExternalCode === 'mercadopago_ar' &&
          paymentMethodIcon({ brand: 'send_money', className: 'payment-method-logo' })}
        {payment.cardBrand &&
          payment.cardBrand === 'ADELANTO LIQUIDACION' &&
          payment.platformExternalCode.slice(0, -3) === platformExternalCodesTrimmed.FISERV &&
          paymentMethodIcon({ brand: 'money_advance', className: 'payment-method-logo-2' })}
      </div>
      <div className="amount col-2 p-0 font-weight-medium text-right" data-tip="Monto">
        <span>
          {currencyFormat(payment?.currencyCode)}-{decimalFormat(payment.amount)}{' '}
        </span>
      </div>
    </>
  );

  const paymentMainContent = (): React.ReactElement => (
    <div className="w-100 d-flex flex-column flex-md-row m-auto">
      {externalPlatformIcon({
        code: payment?.platformExternalCode ? payment?.platformExternalCode : 'cash_ar',
        className: 'details-images m-auto d-block p-3',
      })}
      <div className="flex-grow-1 d-flex flex-column flex-wrap flex-md-row" style={{ alignContent: 'center' }}>
        <div className="px-2 col-12 col-md-6" style={{ minWidth: '200px' }}>
          <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <div className="fw-bold">Proveedor: </div> {payment?.BusinessEntity?.name}
          </div>
        </div>
        <div className="px-2 col-12 col-md-6" style={{ minWidth: '200px' }}>
          <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <div className="fw-bold">Empresa:</div> {payment.Company?.name}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <AccordionComponent
      className="collection-row"
      header={paymentHeaderContent()}
      content={paymentMainContent()}
      estimated={false}
      analytics={{
        event: EVENTS.COLLECTIONS.ROW.EXPAND,
        data: {
          collectionId: payment.id,
          externalReference: payment.externalReference,
          platform: payment.platformExternalCode,
          companyId: payment?.companyId,
          branchId: payment?.branchId,
          confirmed: null,
        },
      }}
    />
  );
};
export default CashflowPayment;
