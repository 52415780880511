import { AxiosPromise } from 'axios';
import { DailySummaries, EditEmail, UpdatePassword, User } from '../utils/interfaces';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

export type SelfGenerateReferrerCodeResponse = {
  referrerCode: string;
  referrerInviteLink: string;
};

export const selfGenerateReferrerCode = async (): Promise<SelfGenerateReferrerCodeResponse> => {
  const { data } = await axiosV2.post<{ data: SelfGenerateReferrerCodeResponse }>('/self/generate-referrer-code');
  return data.data;
};

export const selfChangePassword = (data: UpdatePassword): AxiosPromise<{ data: { data: Record<string, unknown> } }> =>
  axiosV2.post('/self/change-password', data);

export const selfChangeEmail = ({ email }: EditEmail): AxiosPromise<{ data: { data: User } }> =>
  axios.post('/self/change-email', { email });

export const getDailySummaries = (): AxiosPromise => axios.get('/self/settings/cash');

export const setDailySummaries = (params: DailySummaries): AxiosPromise => axios.post('/self/settings/cash', params);

export const updateDailySummaries = (params: DailySummaries): AxiosPromise => axios.patch('/self/settings/cash', params);

export default { selfGenerateReferrerCode };
