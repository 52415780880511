import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, FormUpdateEmail } from '../../components';
import { CloseIconSVG } from '../../assets/svgComponents';

const ChangeEmailPage: FC = () => {
  const navigate = useNavigate();
  const handleCloseIcon = (): void => {
    navigate('/profile');
  };
  return (
    <>
      <div className="mt-3 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-8">
        <div>
          <Title>Cambiar Email</Title>
        </div>
        <CloseIconSVG className="close-icon color-blue cursor-pointer" height={24} width={24} onClick={(): void => handleCloseIcon()} />
      </div>
      <div className="d-flex flex-column">
        <FormUpdateEmail />
      </div>
    </>
  );
};
export default ChangeEmailPage;
