import React, { FC } from 'react';
import { QuestionCircleSVG } from '../../../assets/svgComponents';
// import { segmentTrack } from '../../../utils/segment/hooks';

type NeedHelpLinkComponentProps = {
  className?: string;
  paragraphColor?: string; // see colors.scss
  iconFillColor?: string;
  // analytics: string;
};

const NeedHelpLink: FC<NeedHelpLinkComponentProps> = ({ className, paragraphColor, iconFillColor }): React.ReactElement => {
  const goToHelpPage = (): void => {
    // segmentTrack(analytics);
    window.open('https://www.nubceo.com/comunidad-ar/', '_blank');
  };
  return (
    <div className={className} onClick={goToHelpPage} role="none" style={{ cursor: 'pointer' }}>
      <p className={`font-sm font-weight-light ${paragraphColor}`}>
        ¿Necesitas ayuda?
        <QuestionCircleSVG className="question-circle" fill={iconFillColor} />
      </p>
    </div>
  );
};
NeedHelpLink.defaultProps = {
  className: '',
  paragraphColor: '',
  iconFillColor: '',
};
export default NeedHelpLink;
