import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LoginImageSVG } from '../../assets/svgComponents';
import { FormButtonComponent, NeedHelpLink } from '../../components';
import { CheckBoxRowComponent, TextInputComponent } from '../../components/inputs';
import { Title } from '../../components/textComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addCompanyOnBoarding } from '../../redux/slices/companySlice';
import { FormFieldType, ExternalPlatformType, StorageVars } from '../../utils/types';
import { asyncAddExternalPlatform, asyncListExternalPlatforms } from '../../redux/slices/externalPlatformSlice';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import { errorHandlerHelper } from '../../utils/errorHandler';
import { asyncCompleteOnBoarding, setUser } from '../../redux/slices/authSlice';
import { getProfileDataAsync } from '../../redux/slices/userSlice';
import { ActivityCode, ExternalPlatformCredentials } from '../../utils/interfaces';
// import { EVENTS } from '../../utils/segment/events';
import { refreshToken } from '../../services/authService';
import { platformExternalOrder } from '../../utils/constants';
import { parseActivity } from '../../utils/stringUtils';

interface CompanyOnBoarding {
  name: string;
  activity: string;
  externalPlatforms: Array<string>;
}

const CompanyOnBoardingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, activity } = useAppSelector((state) => state.userProfile);
  const { externalPlatformList } = useAppSelector((state) => state.externalPlatform);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const isAccountant = activity === ActivityCode.CONTADOR;
  const redirectFromTiendanube = localStorage.getItem(StorageVars.redirectFromTiendanube);

  useEffect(() => {
    if (user?.onboardingComplete) navigate(isAccountant ? '/clients' : '/', { replace: true });
  }, [user]);

  useEffect(() => {
    dispatch(asyncListExternalPlatforms({ 'filter[countryCode][like]': user?.Tenant.countryCode || 'AR' })).catch((error) =>
      errorHandlerHelper(error, dispatch),
    );
  }, [user?.Tenant.countryCode]);

  const CreateCompanySchema = Yup.object().shape({
    name: Yup.string().required('Este campo es requerido.'),
    externalPlatforms: Yup.array()
      .of(Yup.string())
      .min(1, 'Tenes que seleccionar al menos un medio de cobro.')
      .required('Campo requerido.'),
  });

  const handleSubmit = (values: CompanyOnBoarding): void => {
    setLoadingRegister(true);
    dispatch(addCompanyOnBoarding({ name: values.name, category: values.activity, countryCode: user?.Tenant.countryCode || 'AR' }))
      .then(({ meta, payload }: any) => {
        if (meta.requestStatus === 'fulfilled') {
          refreshToken().catch((error) => errorHandlerHelper(error, dispatch));
          values.externalPlatforms.forEach((ep) => {
            const platformCredentials = {
              platformExternalCode: ep,
              companyId: payload.id || 0,
              omitReason: 'Prefiero cargarlas más tarde',
              username: '',
              password: '',
            };
            dispatch<void>(asyncAddExternalPlatform(platformCredentials));
          });
          dispatch(asyncCompleteOnBoarding())
            .then(() => {
              dispatch(getProfileDataAsync())
                .then((response) => {
                  dispatch(setUser(response.payload));
                })
                .catch((error) => errorHandlerHelper(error, dispatch));
            })
            .catch((error) => errorHandlerHelper(error, dispatch));
        }
      })
      .catch((error) => {
        errorHandlerHelper(error, dispatch);
        setLoadingRegister(false);
      });
  };

  const platforms = externalPlatformList?.slice().sort((a: ExternalPlatformCredentials, b: ExternalPlatformCredentials) => {
    const aCode = a.code.trim();
    const bCode = b.code.trim();
    const aOrder = platformExternalOrder[aCode] ?? Infinity;
    const bOrder = platformExternalOrder[bCode] ?? Infinity;
    return aOrder - bOrder;
  });

  return (
    <div className="company-on-boarding-page container-fluid row justify-content-center p-0">
      <div className="section d-flex flex-column justify-content-center col-xs-12 col-md-6">
        <Title>Tu negocio</Title>
        <Formik
          initialValues={{
            name: '',
            externalPlatforms: !redirectFromTiendanube ? [] : ['tiendanube_ar'],
            activity: parseActivity(user?.Tenant?.activityCode),
          }}
          validationSchema={CreateCompanySchema}
          onSubmit={(values): void => {
            handleSubmit(values);
          }}
        >
          {({ errors, isValid, dirty }): ReactElement => (
            <Form className="mt-4">
              <Field name="name">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    label="Indicanos el nombre de tu negocio"
                    required
                    placeholder="Ingresá la razón social"
                    errorMessage={errors.name}
                    {...field}
                  />
                )}
              </Field>
              <div className="mt-4">
                {platforms?.map(
                  (item, index) =>
                    item.type !== ExternalPlatformType.erp &&
                    item.source !== 'email' && (
                      <Field type="checkbox" name="externalPlatforms" key={item.code} value={item.code.trim()}>
                        {({ field }: FormFieldType): ReactElement => (
                          <CheckBoxRowComponent
                            errorMessage={index === platforms.length - 1 && errors.externalPlatforms}
                            label={index === 0 ? '¿Qué medios de pago usás para cobrar?' : null}
                            wrapperClassName={`mt-2 ${errors.externalPlatforms ? 'error' : ''}`}
                            {...field}
                          >
                            {item.name}
                            {externalPlatformIcon({ code: item.code, className: 'platform-image ms-auto' })}
                          </CheckBoxRowComponent>
                        )}
                      </Field>
                    ),
                )}
              </div>
              <FormButtonComponent type="submit" className="mt-4" disabled={!(isValid && dirty) || loadingRegister}>
                {loadingRegister ? 'Confirmando...' : 'Confirmar'}
              </FormButtonComponent>
            </Form>
          )}
        </Formik>
      </div>
      <div className="section col-xs-12 col-md-6 d-flex flex-column align-items-end">
        <NeedHelpLink
          paragraphColor="color-white"
          // analytics={EVENTS.ONBOARDING.HELP}
        />
        <LoginImageSVG className="login-image" />
        <p className="reserved-text">© Nubceo {moment().year()}, todos los derechos reservados.</p>
      </div>
    </div>
  );
};

export default CompanyOnBoardingPage;
