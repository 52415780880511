import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../textComponents';
import variables from '../../scss/_exports.module.scss';
import { Company } from '../../utils/interfaces';
import { getNameInitials, handleTaxCode } from '../../utils/stringUtils';
import { asyncListBranches } from '../../redux/slices/branchSlice';
import { useAppDispatch } from '../../redux/hooks';

interface CompanyRow {
  company: Company;
  onClick?: (company?: Company) => void;
}

const CompanyCardComponent: FC<CompanyRow> = ({ company, onClick }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, name, Address, taxCode, enabled, countryCode } = company;

  const handleClick = (): void => {
    if (onClick) return onClick(company);
    dispatch<void>(asyncListBranches({ pageSize: '25', 'filter[companyId][in]': `[${id}]` }));
    return navigate(`/companies/${id}`);
  };

  const handleKeyDownDefault = (): void => {
    // do nothing
  };

  return (
    <div className="company-component row-container mb-1" onKeyDown={handleKeyDownDefault} role="none" onClick={handleClick}>
      <div
        className={`initials-name-container ${!enabled && 'disabled-company-image'} mx-sm-3 mx-lg-4 col-xs-2 col-sm-2 col-md-2 col-lg-2`}
      >
        <Paragraph color={enabled ? variables.colorBlue : variables.colorGray300} size="lg" data-tip="Siglas de tu empresa">
          {getNameInitials(name).toUpperCase()}
        </Paragraph>
      </div>
      <div className="flex-grow-1 col-xs-5 col-sm-4 col-md-4 col-lg-4">
        <Paragraph
          className="text-break"
          color={enabled ? variables.colorBlue : variables.colorGray300}
          weight="bold"
          data-tip="Razón social"
        >
          {name}
        </Paragraph>
      </div>
      <div className="d-none d-lg-block col-xs-5 col-sm-4 col-md-2 col-lg-2">
        <Paragraph color={enabled ? variables.colorBlue : variables.colorGray300} weight="bold" data-tip="Dirección">
          {Address?.street}
        </Paragraph>
      </div>
      <div className="d-lg-block col-xs-5 col-sm-6 col-md-2 col-lg-2" data-tip={handleTaxCode(countryCode)}>
        <Paragraph color={variables.colorGray200} weight="light">
          {taxCode}
        </Paragraph>
      </div>
      {!enabled ? (
        <div className="d-none d-lg-block col-auto filter-tag px-3 py-1 my-auto inactive-badge col-md-2 col-lg-2">
          <Paragraph color={variables.colorGray300} size="xs" data-tip="Empresa Inactiva">
            Inactiva
          </Paragraph>
        </div>
      ) : (
        <div className="d-none d-lg-block filter-tag px-3 py-1 my-auto active-badge col-md-2 col-lg-2">
          <Paragraph color={variables.colorGray300} size="xs" data-tip="Empresa Activa">
            Activa
          </Paragraph>
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

export default CompanyCardComponent;
