import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlusSVG } from '../../../assets/svgComponents';
import { ActionButtonComponent, PaginationWithCount, Title } from '../../../components';
import ClientRow from '../../../components/ClientRow';
import logger from '../../../../src/services/loggerService';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { removeClientData } from '../../../redux/slices/clientsSlice';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';
import { getClientExternalPlatformsV2 } from '../../../services/clientsService';
import { externalPlatformsBasicData } from '../../../utils/constants';
import { storageHelper } from '../../../utils/helper';
import { Client, ActivityCode, ExternalActivePlatform } from '../../../utils/interfaces';
import { StorageVars } from '../../../utils/types';
import useQueryParams from '../../../hooks/useQueryParams';

const ClientsContainer: React.FC<{ clientsList: Client[]; pages: number; total: number }> = ({ clientsList, pages, total }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const dispatch = useAppDispatch();
  const { singleClient, loading } = useAppSelector((state) => state.clients);
  const handleOnClickCreateClient = (): void => navigate(`${url}/new`);
  const { activity } = useAppSelector((state) => state.userProfile);
  const isAccountant = activity === ActivityCode.CONTADOR;
  const page = useQueryParams('page') || '1';

  const handlePagination = (searchParams: Record<string, string>): void => navigate(`?${new URLSearchParams(searchParams).toString()}`);

  useEffect(() => {
    if (singleClient) {
      dispatch(removeClientData());
    }
  }, [singleClient]);

  const platformsListName = (platformList: any, pE: ExternalActivePlatform, key: any) => {
    if (key === 0) {
      return <p>{externalPlatformsBasicData(pE?.PlatformExternal?.code).name}</p>;
    }
    if (key === 2) {
      const morePlatforms = platformList.length - 3 === 1 ? 'y 1 plataforma mas' : `y ${platformList.length - 3} plataformas mas`;
      return <p style={{ marginLeft: 5 }}>{morePlatforms}</p>;
    }
    if (key < 2 && platformList.length === key + 1) {
      return <p style={{ marginLeft: 5 }}>{` y ${externalPlatformsBasicData(pE?.PlatformExternal?.code).name}`}</p>;
    }
    if (platformList.length > key + 1 && key < 3) {
      return <p>{` , ${externalPlatformsBasicData(pE?.PlatformExternal?.code).name}`}</p>;
    }
    return '';
  };

  const desactivateModal = () => {
    dispatch(removeModalFromList());
    storageHelper('setItem', StorageVars.completePECCredentialsClient, moment().format());
  };

  const bodyClientsCredentials = (clientsWhitCredentialsFailure: any): React.ReactElement => (
    <div className="mt-2">
      <p>No te olvides de cargar las siguientes claves. Tenés que ir a las carpetas del cliente y hacer click en Configuración:</p>
      <div className="w-100  mt-4">
        <div className="d-flex flex-row justify-content-between border-style">
          <div className="w-50 d-flex flex-column text-start">
            <p style={{ fontWeight: 'bold' }}>Cliente</p>
          </div>
          <div className="w-50 d-flex flex-column text-start">
            <p style={{ fontWeight: 'bold' }}>Plataforma</p>
          </div>
        </div>
        <div className="d-flex flex-column">
          {clientsWhitCredentialsFailure.map((item: any) => (
            <div className="d-flex flex-row justify-content-between mt-2 border-style">
              <p>{item.client.name}</p>
              <div className="w-50 d-flex flex-row">
                <div className="w-100 d-flex flex-column flex-md-row m-auto text-start mr-10">
                  {item?.platformsCredentialFailure.map((pE: ExternalActivePlatform, key: number) =>
                    platformsListName(item?.platformsCredentialFailure, pE, key),
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    navigate(`${item.client.id}`);
                    desactivateModal();
                  }}
                  className="modal-ok"
                >
                  Ir
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const getClientsCredentialsFailure = async (clients: Client[]) => {
    const clientsWhitCredentialsFailure: { client: Client; platformsCredentialFailure: ExternalActivePlatform[] }[] = [];
    try {
      const promisePlatformsClients = await Promise.allSettled(
        clients.map(async (client) => {
          const response = await getClientExternalPlatformsV2(client.id);
          const platformsCredentialFailure: ExternalActivePlatform[] = [];
          response.forEach((pec) => {
            if (pec.failureAuthCount > 0) {
              platformsCredentialFailure.push(pec);
            }
          });
          if (platformsCredentialFailure.length) {
            clientsWhitCredentialsFailure.push({ client, platformsCredentialFailure });
          }
        }),
      );
      if (promisePlatformsClients && clientsWhitCredentialsFailure.length) {
        dispatch(
          addModalToList({
            body: bodyClientsCredentials(clientsWhitCredentialsFailure),
            handleCloseButton: () => storageHelper('setItem', StorageVars.completePECCredentialsClient, moment().format()),
          }),
        );
      }
    } catch (error) {
      logger.error('getClientsCredentialsFailure.error', error);
    }
  };

  useEffect(() => {
    const completePECCredentialsClient = storageHelper('getItem', StorageVars.completePECCredentialsClient);
    if (
      clientsList &&
      isAccountant &&
      ((completePECCredentialsClient && moment().diff(completePECCredentialsClient, 'hours') >= 2) || !completePECCredentialsClient)
    ) {
      getClientsCredentialsFailure(clientsList);
    }
  }, []);

  return (
    <div>
      <div className="clients-page">
        <div className="mt-5 w-100 d-flex justify-content-between align-items-center">
          <Title>Mis Clientes</Title>
          <ActionButtonComponent
            text="Nuevo cliente"
            onClick={handleOnClickCreateClient}
            icon={<PlusSVG width={24} height={24} className="color-white" />}
          />
        </div>
        <div className="d-flex flex-wrap">
          {clientsList.map((client: Client) => {
            if (client.isClient) return <ClientRow key={client.id} client={client} />;
            return null;
          })}
          {clientsList.length === 0 && 'No tenes clientes creados'}
        </div>
        <PaginationWithCount
          page={page}
          handlePagination={handlePagination}
          pageSize={25}
          pages={pages}
          length={clientsList?.length}
          loading={loading}
          total={total}
        />
      </div>
    </div>
  );
};

export default ClientsContainer;
