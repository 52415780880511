import React, { FC, useRef } from 'react';
import { InputWrapperProps, CheckBoxProps } from '../../../utils/interfaces';
import { Paragraph } from '../../textComponents';
import InputWrapper from '../InputWrapper';

interface CheckBoxRowProps extends CheckBoxProps, InputWrapperProps {
  value: number | string;
  wrapperClassName?: string;
}

const CheckBoxRowComponent: FC<CheckBoxRowProps> = ({
  label,
  errorMessage,
  name,
  className = '',
  containerClassName,
  wrapperClassName,
  required,
  requiredOnlyOption,
  disabled,
  children,
  checked,
  value,
  onChange = (): void => {
    // do nothing
  },
}: CheckBoxRowProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
      <label htmlFor={`input-${value}`} className={`${checked ? 'selected' : null} ${wrapperClassName} checkbox-row-component-container`}>
        <input
          id={`input-${value}`}
          ref={inputRef}
          className={`checkbox-row-component ${className}`}
          required={required}
          disabled={disabled}
          name={name}
          type="checkbox"
          checked={checked}
          value={value}
          onChange={onChange}
        />
        <Paragraph className="w-100 d-flex align-items-center">
          {children} {requiredOnlyOption && <span className="required">*</span>}
        </Paragraph>
      </label>
    </InputWrapper>
  );
};

CheckBoxRowComponent.defaultProps = {
  wrapperClassName: '',
};

export default CheckBoxRowComponent;
