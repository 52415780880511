import * as React from 'react';
import variables from '../../scss/_exports.module.scss';

type NubceoCashIsoType = {
  className?: string;
  fillCloud?: string;
  fillBars?: string;
};

function NubceoCashIsoSVG({ fillCloud = '#0c6bf4', fillBars = '#fff', className = '' }: NubceoCashIsoType): React.ReactElement {
  return (
    <svg
      id="prefix__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 76.51 51.67"
      xmlSpace="preserve"
      className={className}
    >
      <path
        d="M63.09 50.67H13.42C6.56 50.67 1 45.11 1 38.25c0-5.66 3.8-10.43 8.99-11.93-.07-.75-.11-1.5-.11-2.26C9.88 11.32 20.2 1 32.93 1c9.59 0 17.81 5.86 21.29 14.19 5.88 0 10.64 4.77 10.64 10.64 0 .04-.01.1-.01.14 6.02.86 10.65 6.02 10.65 12.28.01 6.86-5.55 12.42-12.41 12.42"
        fill={fillCloud}
      />
      <path fill={fillBars} d="M21.95 21.54h6.15v20.69h-6.15zM34.18 25.83h6.15v16.4h-6.15zM46.41 30.43h6.15v11.8h-6.15z" />
    </svg>
  );
}

NubceoCashIsoSVG.defaultProps = {
  className: '',
  fillCloud: variables.colorBlue,
  fillBars: variables.colorWhite,
};

export default NubceoCashIsoSVG;
