/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// TODO: investigate this 2 because logger service can be a special case.
import { ENV } from '../config';
import { loggerLevels } from '../utils/constants';

const logger = (level: string, message: string, additionalInfo: any | unknown): void => {
  if (ENV === 'production') return;
  const finalMessage = `[${level}] ${message}`;
  let consoleFunctionToCall = console.log;
  switch (level) {
    case loggerLevels.DEBUG:
      consoleFunctionToCall = console.debug;
      break;
    case loggerLevels.INFO:
      consoleFunctionToCall = console.info;
      break;
    case loggerLevels.WARN:
      consoleFunctionToCall = console.warn;
      break;
    case loggerLevels.ERROR:
      consoleFunctionToCall = console.error;
      break;
    default:
      break;
  }
  if (additionalInfo) {
    let infoToLog = additionalInfo;
    // check for Axios error response
    if (infoToLog.response && infoToLog.response.data) {
      infoToLog = { response: infoToLog.response.data };
    }
    consoleFunctionToCall(finalMessage, infoToLog);
  } else {
    consoleFunctionToCall(finalMessage);
  }
};

const debug = (message: string, additionalInfo: any | unknown): void => logger(loggerLevels.DEBUG, message, additionalInfo);
const info = (message: string, additionalInfo: any | unknown): void => logger(loggerLevels.INFO, message, additionalInfo);
const log = (message: string, additionalInfo: any | unknown): void => info(message, additionalInfo);
const warn = (message: string, additionalInfo: any | unknown): void => logger(loggerLevels.WARN, message, additionalInfo);
const error = (message: string, additionalInfo: any | unknown): void => logger(loggerLevels.ERROR, message, additionalInfo);

const typesOfLogs = { debug, info, error, log, warn };
export default typesOfLogs;
