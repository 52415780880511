import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegShareSquare, FaRegClock } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Client } from '../../utils/interfaces';
import logger from '../../services/loggerService';
import { Paragraph } from '../textComponents';
import variables from '../../scss/_exports.module.scss';
import { setSingleClient } from '../../redux/slices/clientsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ActionButtonComponent } from '../buttons';
import { PlusSVG } from '../../assets/svgComponents';
import { getTrialStatus } from '../../utils/helper';
import errorHandlerHelper from '../../utils/errorHandler';
import { addModalToList } from '../../redux/slices/modalSlice';
import { sendInvitationClient } from '../../services/clientsService';
import { handleTaxCode } from '../../utils/stringUtils';

const ClientRow: FC<{ client: Client }> = ({ client }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, surname, taxCode, phone, email, id, relTenantId } = client;
  const { trialRemainingDays, tierCode } = client.RelTenant?.subscription ?? { trialRemainingDays: null };
  const [fullName, setFullName] = useState(name);
  const { clientsList } = useAppSelector((state) => state.clients);
  const data = clientsList.find((cl) => cl.id === Number(id));
  const [trialExpiredStatus, setTrialExpiredStatus] = useState<boolean>(false);
  const [trialExpiredMessage, setTrialExpiredMessage] = useState<string>('');

  const start = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const end = moment().add(1, 'days').format('YYYY-MM-DD');

  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialExpired, trialRemainingDays: trialRemainingDaysTier, trialExpiredDate } = cashTier;
        if (trialExpired && tierCode && tierCode.indexOf('trial') > -1 && trialRemainingDays === 0) {
          setTrialExpiredStatus(true);
        } else if (
          (trialExpiredDate && trialRemainingDaysTier >= 0 && trialRemainingDaysTier <= 5) ||
          (tierCode && tierCode.indexOf('trial') > -1 && trialRemainingDays && trialRemainingDays <= 5)
        ) {
          setTrialExpiredMessage(`Quedan ${cashTier?.trialRemainingDays} días de uso`);
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  /*
    El cliente NO existe en nubceo => RelCompany === null
    El cliente SI existe en nubceo y está vinculado => RelCompany !== null && relCompanyId !== null && relTenantId !== null
    El cliente SI existe en nubceo pero no está vinculado: RelCompany !== null && relCompanyId === null && relTenantId === null
  */

  useEffect(() => {
    if (surname !== null) {
      setFullName(`${name} ${surname}`);
    }
  }, []);
  const handleWorkPapers = (): void => {
    navigate(`/work-papers/${id}?dateSince=${start}&dateUntil=${end}`, { state: { client: data } });
  };
  const handleTaxesClient = (): void => {
    navigate(`/clients/${id}/taxes-client?startDate=${start}&endDate=${end}`, { state: { client: data } });
    dispatch<void>(setSingleClient(client));
  };
  const renderInitials = (): string => {
    let newName = '';
    const arrayName = fullName.includes(' ') ? fullName.split(' ') : null;
    if (arrayName) {
      for (let index = 0; index < arrayName.length; index += 1) {
        const el = arrayName[index];
        newName = `${newName}${el.charAt(0)}`;
      }
    } else {
      newName = name.charAt(0);
    }
    return newName.slice(0, 2);
  };
  const handleClickClient = (): void => {
    navigate(`${id}`, { state: { client } });
    dispatch<void>(setSingleClient(client));
  };
  const handleShareClient = (): void => {
    dispatch(
      addModalToList({
        body: `¿Deseás enviarle la invitación a ${data?.name} ${data?.surname} para que comience a utilizar nubceo?`,
        okButton: {
          buttonText: 'Aceptar',
          onClick: (): Promise<void> =>
            sendInvitationClient(id.toString() || '')
              .then((res) => {
                logger.info('sendInvitation.success', res.data);
              })
              .catch((ex) => {
                logger.error('sendInvitation.error', ex?.message);
              }),
        },
      }),
    );
  };
  const getStatusClient = (): React.ReactElement => {
    if (trialExpiredStatus) {
      return <p className="color-red">La suscripción no está activa</p>;
    }
    if (trialExpiredMessage !== '') {
      return <p className="color-warning">{trialExpiredMessage}</p>;
    }
    return (
      <Paragraph color={variables.colorBlack} weight="regular" className="text-truncate" data-tip={handleTaxCode(client?.countryCode)}>
        {client.countryCode === 'AR' ? 'CUIT:' : 'RUT:'} {taxCode}
      </Paragraph>
    );
  };
  return (
    <div
      className={`
        business-entity-row-component
        container-client
        ${trialExpiredStatus && 'border-expired'}
        ${trialExpiredMessage !== '' && 'border-trialStatus'}
        link mt-3
        row-container
      `}
    >
      <div
        className={`business-entity-name-container ${trialExpiredStatus && 'bg-danger'} ${
          trialExpiredMessage !== '' && 'bg-warning'
        } col-xs-12 col-sm-6 col-lg-2`}
        onClick={handleClickClient}
        role="none"
      >
        <Paragraph
          data-tip="Iniciales"
          // eslint-disable-next-line no-nested-ternary
          color={trialExpiredStatus ? variables.colorWhite : trialExpiredMessage !== '' ? variables.colorYellow200 : variables.colorBlue}
          size="lg"
          weight="bold"
          className="business-entity-name-initials"
        >
          {renderInitials().toUpperCase()}
        </Paragraph>
      </div>
      <div className="col-xs-12 col-sm-6 col-lg-4 col-6 d-inline-block full-name-container ms-3" onClick={handleClickClient} role="none">
        <Paragraph color={variables.colorBlack} weight="bold" data-tip="Nombre">
          {fullName}
        </Paragraph>
        {getStatusClient()}
      </div>
      <div className="d-none col-xs-12 col-sm-6 col-lg-3 d-lg-block" onClick={handleClickClient} role="none">
        <Paragraph color={variables.colorBlack} weight="regular" className="text-truncate" data-tip="Email">
          {email}
        </Paragraph>
        <Paragraph color={variables.colorBlack} weight="regular" className="text-truncate" data-tip="Teléfono">
          {phone}
        </Paragraph>
      </div>
      {relTenantId && (
        <div className="d-none col-xs-12 col-sm-6 col-lg-2 d-lg-block" onClick={handleShareClient} role="none" data-tip="Icono">
          <FaRegShareSquare size={25} className="icon-invite gift-icon" data-tip="Invitar a nubceo cash" />
        </div>
      )}
      {!relTenantId && (
        <div className="d-none col-xs-12 col-sm-6 col-lg-2 d-lg-block" role="none" data-tip="Icono">
          <FaRegClock size={25} className="icon-invite gift-icon" data-tip="Estamos esperando confirmación del cliente" />
        </div>
      )}
      {relTenantId && (
        <div className="d-flex flex-column text-end col-xs-12 col-lg-2 align-items-end">
          <ActionButtonComponent
            text="Papeles de Trabajo"
            onClick={handleWorkPapers}
            icon={<PlusSVG fill={variables.colorWhite} />}
            containerClassName="me-2 get-platform-button mb-1 justify-content-center"
          />
          <ActionButtonComponent
            text="Impuestos"
            onClick={handleTaxesClient}
            icon={<PlusSVG fill={variables.colorWhite} />}
            containerClassName="me-2 get-platform-button mb-1 justify-content-center"
          />
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};
export default ClientRow;
