import moment from 'moment';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { currencyFormat, decimalFormat } from '../../../../utils/stringUtils';
import AccordionComponent from '../../../Accordion';
import externalPlatformIcon from '../../../../utils/externalPlatformIcon';
import paymentMethodIcon from '../../../../utils/paymentMethodIcon';
import { Collection, SettlementsType } from '../../../../utils/interfaces';
import { EVENTS } from '../../../../utils/segment/events';
import { externalPlatformsBasicData, platformExternalCodesTrimmed } from '../../../../utils/constants';
import { ExternalPlatformType } from '../../../../utils/types';
import variables from '../../../../scss/_exports.module.scss';
import { useAppSelector } from '../../../../redux/hooks';

type CashflowCollectionProps = {
  collection: Collection;
};

const CashflowCollection = ({ collection }: CashflowCollectionProps): React.ReactElement => {
  const { companiesList } = useAppSelector((state) => state.company);
  const collectionValidationPeriod = (): string => {
    let periodDate = '';
    if (collection?.Settlement?.sourceDateFrom === collection?.Settlement?.sourceDateUntil) {
      periodDate = moment(collection?.Settlement?.sourceDateFrom).locale('es').format('L');
    } else {
      periodDate = `${moment(collection?.Settlement?.sourceDateFrom).locale('es').format('L')} a ${moment(
        collection?.Settlement?.sourceDateUntil,
      )
        .locale('es')
        .format('L')}`;
    }
    return periodDate;
  };

  const businessNumber = collection?.Branch?.PlatformExternals?.find(
    (item) => item.platformExternalCode === collection.platformExternalCode,
  );
  const collectionEstimated = !collection.confirmed || !collection.confirmedAmount;
  const collectionPercentAmount = (value: number): React.ReactElement => {
    const percentage = (value / collection?.grossAmount) * 100;
    const numberFormatRounded = new Intl.NumberFormat('es-ES', { maximumFractionDigits: 2 }).format(percentage);
    return (
      <>{collection?.grossAmount === 0 || !value ? ' (0%)' : <span className="percent-amount color-red"> ({numberFormatRounded}%)</span>}</>
    );
  };
  const getCompany = (): string | undefined => {
    const business = companiesList.find((company) => company.id === collection.companyId?.toString())?.name;
    return business;
  };
  const getComissions = (): number =>
    collection.Deductions.filter((item) => item.deductionTypeCode === 'COM-AR' || item.deductionTypeCode === 'COM-UY').reduce(
      (prev, { amount }) => prev + amount,
      0,
    );
  const getLocalCharges = (): number =>
    collection.Deductions.filter((item) => item.deductionTypeCode === 'COBCOM-AR' || item.deductionTypeCode === 'COBCOM-UY').reduce(
      (prev, { amount }) => prev + amount,
      0,
    );
  const iconShow = (icon: any) => {
    let res;
    if (externalPlatformsBasicData(icon)?.logoImage) {
      res = externalPlatformIcon({
        code: icon === 'cash' ? 'cash_ar' : icon,
        className: 'external-platform-logo',
      });
    } else {
      res = (
        <div className="external-platform-logo-not-supported">
          <p className="text-not-supported">{icon.slice(0, 1)}</p>
        </div>
      );
    }
    return res;
  };

  const collectionHeaderContent = (): React.ReactElement => (
    <>
      <div className="d-none d-sm-inline-flex col-3" data-tip="Plataforma">
        {iconShow(collection?.platformExternalCode ?? 'cash_ar')}
        {collection.relatedPlatformExternals?.length
          ? externalPlatformIcon({
              code: collection.relatedPlatformExternals[0].code,
              className: 'external-platform-logo-related-row',
            })
          : null}
        {collection.relatedPlatformExternals?.length > 1 ? (
          <div className="badge">
            <span>{`${collection.relatedPlatformExternals.length - 1}+`}</span>
          </div>
        ) : null}
        <span className="d-none d-md-inline-flex ms-4 align-items-center">
          {externalPlatformsBasicData(collection.platformExternalCode)?.name ?? 'Efectivo'}
        </span>
      </div>
      <div className="d-inline-flex col-4 col-md-4 d-sm-none p-0 d-block flex-row align-items-center">
        {!(collection.platformExternalCode?.indexOf('rappi') > -1 || collection.platformExternalCode?.indexOf('pedidosya') > -1) &&
          collection.cardBrand !== 'ADELANTO LIQUIDACION' &&
          collection.cardBrand &&
          paymentMethodIcon({ brand: collection.cardBrand, className: 'payment-method-logo' })}
        {!collection.cardBrand &&
          collection.platformExternalCode === 'mercadopago_ar' &&
          paymentMethodIcon({ brand: 'send_money', className: 'payment-method-logo' })}
        {collection.cardBrand &&
          collection.cardBrand === 'ADELANTO LIQUIDACION' &&
          collection.platformExternalCode.slice(0, -3) === platformExternalCodesTrimmed.FISERV &&
          paymentMethodIcon({ brand: 'money_advance', className: 'payment-method-logo-2' })}
      </div>
      <div className="col-4 col-sm-4 col-md-4 offset-1" data-tip="Fecha de Liquidación">
        <span className="d-none d-lg-inline">{moment(collection.date).locale('es').format('DD MMMM YYYY')}</span>
        <span className=" d-none d-md-inline d-lg-none">{moment(collection.date).locale('es').format('DD/MM/YY')}</span>
        <span className="d-inline d-md-none">{moment(collection.date).locale('es').format('D/MM')}</span>
        {!collection.confirmed && <span className="font-xs font-weight-light border-indicator ms-1">2</span>}
      </div>
      <div className="d-none d-sm-inline col-4 col-sm-1 col-md-2" data-tip="Método de pago">
        {!(collection.platformExternalCode?.indexOf('rappi') > -1 || collection.platformExternalCode?.indexOf('pedidosya') > -1) &&
          collection.cardBrand !== 'ADELANTO LIQUIDACION' &&
          collection.cardBrand &&
          paymentMethodIcon({ brand: collection.cardBrand, className: 'payment-method-logo' })}
        {!collection.cardBrand &&
          collection.platformExternalCode === 'mercadopago_ar' &&
          paymentMethodIcon({ brand: 'send_money', className: 'payment-method-logo' })}
        {collection.cardBrand &&
          collection.cardBrand === 'ADELANTO LIQUIDACION' &&
          collection.platformExternalCode.slice(0, -3) === platformExternalCodesTrimmed.FISERV &&
          paymentMethodIcon({ brand: 'money_advance', className: 'payment-method-logo-2' })}
        {!externalPlatformsBasicData(collection.platformExternalCode)?.branchType && (
          <div className="info-tooltip">
            <FaInfoCircle data-tip="Esta plataforma no liquida por sucursal." color={variables.colorBlue} />
          </div>
        )}
      </div>
      <div
        data-tip="Monto"
        className={
          collection.amount > 0
            ? 'text amount col-sm-2 col-4 col-md-2 col-lg-2 p-0 font-weight-medium text'
            : 'text amount col-sm-2 col-4 col-md-2 col-lg-2 p-0 font-weight-medium text-negative text'
        }
      >
        {(!collection.confirmedAmount || !collection.confirmedTaxDeduction) && (
          <span className="font-xs font-weight-light align-top border-indicator">1</span>
        )}
        {currencyFormat(collection.currencyCode)}
        {decimalFormat(collection.amount)}
      </div>
    </>
  );

  const collectionMainContent = (): React.ReactElement => (
    <div className="d-flex flex-column w-100">
      <div>
        {(!collection.confirmedAmount || !collection.confirmedTaxDeduction) && (
          <p className="text-start">
            <span className="font-sm font-weight-bold">1 - </span> El importe a cobrar es estimado
          </p>
        )}
        {!collection.confirmed && (
          <p className="text-start">
            <span className="font-sm font-weight-bold">2 - </span> La fecha de pago es estimada
          </p>
        )}
        {(!collection.totalTaxes || !getComissions()) && (
          <p className="text-start">
            <span className="font-sm font-weight-bold">3 - </span> Los importes de Comisiones/Dtos o impuestos no están definidos por la
            plataforma
          </p>
        )}
        {(!collection.confirmedAmount || !collection.confirmed || !collection.totalTaxes) && <hr style={{ width: '100%' }} />}
        <div className="w-100 d-flex flex-column flex-md-row m-auto" />
        <div className="row flex-grow-1 d-flex flex-column flex-wrap flex-md-row text-start">
          <div className="col-12 col-md-5 col-lg-5" style={{ minWidth: '200px' }}>
            <div className="d-flex">
              <div className="fw-bold col-7 col-lg-5">Período: </div>
              <div className="col-5 col-lg-8">{collectionValidationPeriod()}</div>
            </div>
            {collection.Branch ? (
              <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {collection?.companyId ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold">Empresa:</div>
                    <div className="col-5 col-lg-7">{getCompany()}</div>
                  </div>
                ) : null}
                {collection?.externalReference ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold">Referencia:</div>
                    <div className="col-5 col-lg-7">{collection.externalReference || '--'}</div>
                  </div>
                ) : null}
                {collection?.Branch?.name ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold">Sucursal:</div>
                    <div className="col-5 col-lg-7"> {collection.Branch.name}</div>
                  </div>
                ) : null}
                {businessNumber?.externalCode ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold mb-2">N° Comercio:</div>
                    <div className="col-5 col-lg-7"> {businessNumber?.externalCode}</div>
                  </div>
                ) : null}
                <div className="d-flex">
                  <div className="col-7 col-md-5 col-lg-5 fw-bold">Pago:</div>
                  <div className="col-5 col-md-10 col-lg-10">
                    {!collection.Settlement ? (
                      <p className="color-red">
                        (No se realizó un pago. Saldo a favor de {externalPlatformsBasicData(collection?.platformExternalCode)?.name ?? ''})
                      </p>
                    ) : (
                      <div className="d-flex flex-row" key={collection.id}>
                        <div className="d-inline col-4 col-md-5 col-lg-4">{moment(collection?.date).locale('es').format('DD/MM/YY')} </div>
                        <div className="col-9 col-md-9 col-lg-7 ms-1">
                          {currencyFormat(collection?.currencyCode)}
                          {decimalFormat(collection?.amount)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {collection?.Settlement?.type === SettlementsType.RECUPERO ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-7">LIQUIDACIÓN DE RECUPERO</div>
                  </div>
                ) : null}
                {collection?.cardBrand ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-7"> {collection?.cardBrand}</div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {collection?.companyId ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold">Empresa:</div>
                    <div className="col-5 col-lg-7">{getCompany()}</div>
                  </div>
                ) : null}
                {collection?.externalReference ? (
                  <div className="d-flex">
                    <div className="col-7 col-lg-5 fw-bold">Referencia:</div>
                    <div className="col-5 col-lg-7">{collection.externalReference || '--'}</div>
                  </div>
                ) : null}
                <div className="d-flex">
                  <div className="col-7 col-md-5 col-lg-5 fw-bold">Pago:</div>
                  <div className="col-5 col-md-10 col-lg-10">
                    {!collection.Settlement ? (
                      <p className="color-red">
                        (No se realizó un pago. Saldo a favor de {externalPlatformsBasicData(collection?.platformExternalCode)?.name ?? ''})
                      </p>
                    ) : (
                      // settlement.Collections.map((collection) => (
                      <div className="d-flex flex-row" key={collection.id}>
                        <div className="d-inline col-4 col-md-5 col-lg-4">{moment(collection?.date).locale('es').format('DD/MM/YY')} </div>
                        <div className="col-9 col-md-9 col-lg-7 ms-1">
                          {currencyFormat(collection.currencyCode)}
                          {decimalFormat(collection?.amount)}
                        </div>
                      </div>
                      // ))
                    )}
                  </div>
                </div>
              </div>
            )}
            <hr className="d-xs-inline d-md-none w-100" />
          </div>
          <div className="px-2 col-12 col-md-6 offset-md-2 col-lg-5" style={{ minWidth: '200px' }}>
            {collection?.grossAmount ? (
              <div className="row">
                <div className="col-4 col-md-5 col-lg-6 col-xl-7 text-start">Bruto:</div>
                <div className="col-7 col-md-7 col-lg-6 col-xl-5 text text-end">
                  {currencyFormat(collection.currencyCode)} {decimalFormat(collection.grossAmount)}
                </div>
              </div>
            ) : null}
            <div className={`total-deductions color-red ${collection.totalDeductions <= 0 && 'error'}`}>
              {collection.Deductions ? (
                <div className="row color-orange">
                  <div className="col-6 col-md-6 col-lg-7 text-start">
                    Comisiones:
                    <span> {collectionPercentAmount(getComissions())}</span>
                    {getComissions() === 0 && (
                      <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>
                    )}
                  </div>
                  <div className="col-5 col-md-6 col-lg-5 text text-end">
                    {currencyFormat(collection.currencyCode)}
                    {decimalFormat(getComissions() * -1)}{' '}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={`total-deductions color-red ${collection.totalDeductions <= 0 && 'error'}`}>
              <div className="row color-orange">
                {collection.deductionsDetails.others !== 0 && (
                  <>
                    <div className="col-6 col-md-6 col-lg-8 text-start">
                      Otros descuentos:
                      <span> {collectionPercentAmount(Math.abs(collection.deductionsDetails?.others))}</span>
                      {!collection.deductionsDetails.others && (
                        <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>
                      )}
                    </div>
                    <div className="col-5 col-md-6 col-lg-4 text text-end">
                      {currencyFormat(collection.currencyCode)} {decimalFormat(collection.deductionsDetails.others * -1)}
                    </div>
                  </>
                )}
              </div>
            </div>
            {collection.Deductions &&
              collection.Deductions.map(
                (deduction) =>
                  deduction.name === 'Descuentos/Bonificaciones' && (
                    <div className="row" key={deduction.name}>
                      <div className="col-7 col-md-6 col-lg-8 text-start">
                        Dtos a cobrar:
                        {collection.deductionsDetails?.others ? (
                          <span> {collectionPercentAmount(Math.abs(deduction.amount))}</span>
                        ) : (
                          ' (0%)'
                        )}
                      </div>
                      <div className="col-5 col-md-6 col-lg-4 text text-end">
                        {currencyFormat(collection.currencyCode)} {decimalFormat(Math.abs(deduction.amount))}
                      </div>
                    </div>
                  ),
              )}
            <div className={`total-deductions color-red ${collection?.totalTaxes <= 0 && 'error'}`}>
              {collection?.totalTaxes ? (
                <div className="row">
                  <div className="col-6 col-md-6 text-start">
                    Impuestos:
                    <span> {collectionPercentAmount(Math.abs(collection.totalTaxes))}</span>
                    {!collection.totalTaxes && (
                      <span className="color-black font-xs font-weight-thin align-top ms-1 border-indicator">3</span>
                    )}
                  </div>
                  <div className="col-5 col-md-6 text text-end">
                    {currencyFormat(collection.currencyCode)}
                    {decimalFormat(collection.totalTaxes * -1)}{' '}
                  </div>
                </div>
              ) : null}
            </div>
            {externalPlatformsBasicData(collection.platformExternalCode)?.type === ExternalPlatformType.delivery && (
              <div className={`total-deductions color-red ${getLocalCharges() <= 0 && 'error'}`}>
                <div className="row">
                  <div className="col-6 col-md-9 text-start">
                    Cobrado por el comercio:
                    <span> {collectionPercentAmount(Math.abs(getLocalCharges()))}</span>
                  </div>
                  <div className="col-5 col-md-3 text text-end">
                    {currencyFormat(collection.currencyCode)}
                    {decimalFormat(getLocalCharges())}
                  </div>
                </div>
              </div>
            )}

            {collection?.amount ? (
              <div className="net-amount">
                <div className="row">
                  <div className="col-6 col-md-5 col-lg-7 text-start">Neto:</div>
                  <div
                    className={
                      collection.amount > 0
                        ? 'col-5 col-md-7 col-lg-5 text text-end'
                        : 'col-5 col-md-7 col-lg-5 text text-negative text-end'
                    }
                  >
                    {currencyFormat(collection.currencyCode)} {decimalFormat(collection.amount)}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <div>
              {collection.relatedPlatformExternals?.length ? (
                <i className="d-sm-block font-weight-light font-sm d-lg-none">
                  Esta liquidación está relacionada a{' '}
                  {collection.relatedPlatformExternals.map((item) => (
                    <span key={item.code}>
                      {externalPlatformIcon({
                        code: item.code,
                        className: 'external-platform-logo-relatedPlatform ',
                      })}
                    </span>
                  ))}
                </i>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <ReactTooltip />
      <AccordionComponent
        className="collection-row"
        header={collectionHeaderContent()}
        content={collectionMainContent()}
        estimated={collectionEstimated}
        analytics={{
          event: EVENTS.COLLECTIONS.ROW.EXPAND,
          data: {
            collectionId: collection?.id,
            externalReference: collection?.externalReference,
            platform: collection?.platformExternalCode,
            companyId: collection?.companyId,
            branchId: collection?.branchId,
            confirmed: collection?.confirmed,
          },
        }}
      />
    </>
  );
};
export default CashflowCollection;
