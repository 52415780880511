import { AxiosPromise } from 'axios';
import { User } from '../utils/interfaces';
import axiosV2 from './axiosInstanceV2';

type fcmData = {
  fcmToken?: string;
  platformCode: string;
  osName: string | null;
  appVersion: string;
  appClientMode: string;
};

export const updateFcmToken = async (data: fcmData): Promise<AxiosPromise<{ data: User }>> => {
  const response = await axiosV2.patch<{ data: User }>('/users/fcm-token', data);
  return response;
};

export const deleteFcmToken = async (data: fcmData): Promise<AxiosPromise<void>> => {
  const response = await axiosV2.delete('/users/fcm-token', { data });
  return response;
};
