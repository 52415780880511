import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Title } from '../textComponents';

interface CardSmartDataProps {
  title: string | null;
  route: string;
  disable: boolean;
  icon: ReactElement;
}
const CardSmartData = ({ title, icon, route, disable }: CardSmartDataProps): React.ReactElement => (
  <Link
    data-tip={title}
    to={{ pathname: route }}
    onClick={(event): void | boolean => disable && event.preventDefault()}
    className="text-decoration-none"
  >
    <div className={`card-smart-data ${disable && 'bg-secondary border border-secondary'}`} role="none">
      <div className="card-body">
        <div className="title-container">
          <Title color="white">{title}</Title>
        </div>
        <div className="icon-container mt-2">
          <div>{icon}</div>
        </div>
      </div>
    </div>
    <ReactTooltip />
  </Link>
);
export default CardSmartData;
