import 'bootstrap/dist/css/bootstrap.min.css';
import React, { FC, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, LinearScale, Tooltip, CategoryScale, BarElement } from 'chart.js';
import { useLocation } from 'react-router-dom';
import { FetchButton, LoaderScreen, Subtitle, Title } from '../../components';
import { Filters } from '../../containers';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { asyncListExpenseReports } from '../../redux/slices/reportsSlice';
import TableComponent from '../../components/Table';
import { ExpenseReportsType, FilterType, PieGraphDataType } from '../../utils/types';
import { colorsGraphicsExpenseReports } from '../../utils/constants';
import { LoadingStateType } from '../../utils/interfaces';

ChartJS.register(LineElement, BarElement, Tooltip, LinearScale, CategoryScale);
const ExpenseReportsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, expenseReportsList } = useAppSelector((state) => state.reports);
  const { activeCurrency } = useAppSelector((state) => state.currencies);

  const isTax = (item: ExpenseReportsType): boolean => item.entity === 'tax';
  const isDeduction = (item: ExpenseReportsType): boolean => item.entity === 'deduction';

  const taxes = expenseReportsList.filter((item) => isTax(item)).sort((a, b) => a.typeName.length - b.typeName.length);
  const deduction = expenseReportsList.filter((item) => isDeduction(item)).sort((a, b) => a.typeName.length - b.typeName.length);
  const location = useLocation();
  const summary = [
    {
      typeName: 'Impuestos',
      amount: 0,
      entity: 'tax',
      typeCode: '',
    },
    {
      typeName: 'Descuentos',
      amount: 0,
      entity: 'deduction',
      typeCode: '',
    },
    {
      typeName: 'Comisiones',
      amount: 0,
      entity: 'commission',
      typeCode: '',
    },
  ];

  expenseReportsList.forEach((item) => {
    switch (item.entity) {
      case 'tax':
        summary[0].amount += item.amount;
        summary[0].typeCode = item.typeCode;
        break;
      case 'deduction':
        if (!item.typeCode.startsWith('COM')) {
          summary[1].amount += item.amount;
          summary[1].typeCode = item.typeCode;
        } else {
          summary[2].amount += item.amount;
          summary[2].typeCode += item.typeCode;
        }
        break;
      default:
        break;
    }
  });

  const dataPie = (data: ExpenseReportsType[]): PieGraphDataType => ({
    labels: data?.map((item) => item?.typeName),
    datasets: [
      {
        data: data?.map((item) => item?.amount),
        backgroundColor: colorsGraphicsExpenseReports,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({ ...queryObject, currencyCode: activeCurrency?.code || '' });
    dispatch<void>(asyncListExpenseReports(filterableObject));
  }, [activeCurrency?.code, location]);

  return (
    <div>
      <div className="mt-5">
        <Title>Reporte de Gastos</Title>
      </div>
      <div className="step-filter-settlements">
        <Filters containerClassName="mt-3 mb-5" typeFilter={FilterType.EXPENSE_REPORTS} />
      </div>
      <div className="step-download-report me-2 my-2 flex-grow-1 d-flex justify-content-end">
        <FetchButton dataType={7} />
      </div>

      {loading === LoadingStateType.PENDING ? (
        <LoaderScreen />
      ) : (
        <>
          <div className="mt-5">
            <Subtitle>Resumen</Subtitle>
          </div>
          <div className="row d-flex flex-row mb-3">
            <div className="d-flex align-items-center justify-content-center mb-3 col-12 col-xl-6 col-lg-6">
              <Bar width={500} height={500} data={dataPie(Object.values(summary))} />
            </div>
            <div className="d-flex align-items-center justify-content-center col-12 col-xl-6 col-lg-6">
              <TableComponent summaryList={Object.values(summary)} currency={activeCurrency?.code || null} />
            </div>
          </div>

          <div className="mt-5">
            <Subtitle>Impuestos</Subtitle>
          </div>
          <div className="row d-flex flex-row mb-3">
            <div className="d-flex align-items-center justify-content-center mb-3 col-12 col-xl-6 col-lg-6">
              <Bar width={500} height={500} data={dataPie(taxes)} />
            </div>
            <div className="d-flex align-items-center justify-content-center col-12 col-xl-6 col-lg-6">
              <TableComponent reportList={taxes} currency={activeCurrency?.code || null} />
            </div>
          </div>

          <div className="mt-5">
            <Subtitle>Descuentos</Subtitle>
          </div>
          <div className="row d-flex flex-row mb-3">
            <div className="d-flex align-items-center justify-content-center mb-3 col-12 col-xl-6 col-lg-6">
              <Bar width={500} height={500} data={dataPie(deduction)} />
            </div>
            <div className="d-flex align-items-center justify-content-center col-12 col-xl-6 col-lg-6">
              <TableComponent reportList={deduction} currency={activeCurrency?.code || null} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ExpenseReportsPage;
