/* eslint-disable no-param-reassign */
import { Action, configureStore, ThunkAction, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import salesSlice from './slices/salesSlice';
import authSlice from './slices/authSlice';
import settlementSlice from './slices/settlementSlice';
import collectionsSlice from './slices/collectionsSlice';
import companySlice from './slices/companySlice';
import userSlice from './slices/userSlice';
import externalPlatformSlice from './slices/externalPlatformSlice';
import collectionReportSlice from './slices/collectionReportSlice';
import taxesSlice from './slices/taxesSlice';
import deductionsSlice from './slices/deductionsSlice';
import clientsSlice from './slices/clientsSlice';
import notificationSlice from './slices/notificationSlice';
import modalSlice from './slices/modalSlice';
import branchSlice from './slices/branchSlice';
import reportsSlice from './slices/reportsSlice';
import userListSlice from './slices/userListSlice';
import currencySlice from './slices/currencySlice';
import cashflowSlice from './slices/cashflowSlice';
import messagesSlice from './slices/messagesSlice';

export const clearStore = (): Action => ({
  type: 'RESET',
});

const appReducer = combineReducers({
  auth: authSlice,
  settlement: settlementSlice,
  collections: collectionsSlice,
  sales: salesSlice,
  company: companySlice,
  userProfile: userSlice,
  externalPlatform: externalPlatformSlice,
  collectionReport: collectionReportSlice,
  taxes: taxesSlice,
  deductions: deductionsSlice,
  clients: clientsSlice,
  notifications: notificationSlice,
  modal: modalSlice,
  branch: branchSlice,
  reports: reportsSlice,
  userList: userListSlice,
  currencies: currencySlice,
  cashflow: cashflowSlice,
  messages: messagesSlice,
});

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'RESET') {
      state = undefined;
    }
    return appReducer(state, action);
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      isSerializable: () => true,
      ignoredActions: ['modals/activateModal'],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
