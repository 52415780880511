import React, { FC, useState } from 'react';
// import { segmentTrack } from '../../utils/segment/hooks';

export type AccordionComponentProps = {
  header: React.ReactElement;
  content: React.ReactElement;
  className: string;
  estimated?: boolean;
  // projected?: boolean;
  analytics: {
    event: string;
    data: unknown;
  };
  onExpandAcc?: () => void;
};
const AccordionComponent: FC<AccordionComponentProps> = ({
  header,
  content,
  className,
  // analytics,
  estimated,
  // projected,
  onExpandAcc,
}: AccordionComponentProps) => {
  const [visible, setVisible] = useState(false);
  const handleVisible = (): void => {
    // if (!visible) segmentTrack(analytics.event, analytics.data);
    if (onExpandAcc) onExpandAcc();
    setVisible(!visible);
  };
  return (
    <div className={`accordion-component ${className} border-row-radius row-container`}>
      <div className="w-100">
        {/* <div className={`accordion-component-item ${projected && 'accordion-projected'}`}> */}
        <div
          // className={`container-fluid ${estimated && 'estimated'} ${visible && 'collapsed'}`}
          className={`container-fluid ${estimated && 'estimated'} ${visible && 'collapsed'} p-2`}
          // className={`accordion-component-title container-fluid ${estimated && !projected && 'estimated'}
          //  ${projected && !visible && 'projected'} ${visible && 'collapsed'}`}
          onClick={handleVisible}
          role="none"
        >
          <div className="row align-items-center text-center m-0">{header}</div>
        </div>
        {/* <div
          className={`accordion-component-content flex-row flex-wrap ${estimated && 'estimated'} ${projected && 'projected'} ${
            visible && 'collapsed'
          }`}
        > */}
        <div className={`accordion-component-content flex-row flex-wrap ${estimated && 'estimated'} ${visible && 'collapsed'}`}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
