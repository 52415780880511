import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { ActionButtonComponent } from '../../buttons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { asyncAddExternalPlatform, asyncEditExternalPlatform } from '../../../redux/slices/externalPlatformSlice';
import errorHandlerHelper from '../../../utils/errorHandler';
import LoaderScreen from '../../LoaderScreen';
import { Subtitle } from '../../textComponents';
import fiservImageAr from '../../../assets/images/illustrations/fiservAddUserAr.png';
import fiservImageUy from '../../../assets/images/illustrations/fiservAddUserUy.png';
import { CountryCode, ExternalActivePlatform, FormTypeFiserv } from '../../../utils/interfaces';
import ToggleSwitch from '../../inputs/ToggleSwitch';
import FiservCredentialsFormFTP from './FiservCredentialsFormFTP';
import { config } from '../../../utils/constants';

interface FiservCredentialsFormProps {
  selectedPlatform: string;
  companyId: string;
  handleDeletePlatform: () => void;
  currentValues: ExternalActivePlatform | undefined;
  existingPlatform: ExternalActivePlatform | undefined;
}

const FiservCredentialsForm: FC<FiservCredentialsFormProps> = ({
  selectedPlatform,
  companyId,
  handleDeletePlatform,
  currentValues,
  existingPlatform,
}: FiservCredentialsFormProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [enableUnsettledSales, setEnableUnsettledSales] = useState(false);
  const [enableButtonSave, setEnableButtonSave] = useState(false);
  const [usernameAndPassword, setUsernameAndPassword] = useState();
  const [pecId, setPecId] = useState('');
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const { user } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const urlFiserv = activeCurrency?.countryCode === CountryCode.AR ? 'https://www.fiserv.com.ar/' : 'https://www.fiserv.com/es-uy';
  const [toggleSwitch, setToggleSwitch] = useState(existingPlatform && existingPlatform.codeAux === FormTypeFiserv.FTP);
  const toggler = (): void => setToggleSwitch(!toggleSwitch);
  const navigate = useNavigate();
  const msg = `Hola! Necesito ayuda.`;
  const newString = 'new';

  useEffect(() => {
    if (currentValues && existingPlatform) {
      setEnableUnsettledSales(existingPlatform.codeAux2 === '1');
    }
  }, []);

  const createEmptyPec = (): void => {
    setLoading(true);
    if (pecId === '' && !toggleSwitch) {
      dispatch(
        asyncAddExternalPlatform({
          platformExternalCode: selectedPlatform,
          username: '',
          password: '',
          companyId,
        }),
      )
        .then(async (res: any) => {
          setLoading(false);
          setPecId(res?.payload?.document?.id);
          setUsernameAndPassword(res?.payload?.document);
        })
        .catch((error) => {
          errorHandlerHelper(error, dispatch);
          setLoading(false);
        });
    }
  };

  const editEmptyPec = (): void => {
    setLoading(true);
    dispatch(
      asyncEditExternalPlatform({
        id: pecId || id || '',
        username: '',
        password: '',
        codeAux2: enableUnsettledSales ? '1' : '0',
      }),
    )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        errorHandlerHelper(error, dispatch);
      })
      .finally(() => setLoading(false));
  };

  const handleCheckUnsettledSales = (): void => {
    setEnableButtonSave(true);
    setEnableUnsettledSales(!enableUnsettledSales);
  };

  return loading ? (
    <LoaderScreen label="Iniciando vinculación" />
  ) : (
    <>
      {!usernameAndPassword && id === newString ? (
        <div className="mt-4 mb-2 fw-bold">
          <ToggleSwitch label="Formulario SFG" name={FormTypeFiserv.FTP} onChange={(): void => toggler()} checked={toggleSwitch} />
        </div>
      ) : null}
      {!toggleSwitch ? (
        <>
          <div className="container-input background-red-30 my-3 w-75">
            <div className="notification warning">
              <div className="notification-content">
                <div className="font-xs mx-4">
                  Durante el mes de agosto el portal de <b>Fiserv</b> se dará de baja, de no tener acceso por medio de FTP,
                </div>
                <div className="font-xs mx-4">
                  no podremos incorporar la información a nubceo.{' '}
                  <div className="link-read-more d-inline" role="none" onClick={(): void => navigate('/info-fiserv')}>
                    Leer más
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 w-100 d-flex justify-content-start align-items-center flex-wrap">
            <ActionButtonComponent
              text={id !== newString || pecId !== '' ? 'Vinculación Iniciada' : 'Iniciar Vinculación'}
              onClick={(): void => createEmptyPec()}
              removeIcon
              inactive={id !== newString || pecId !== ''}
            />
            {id !== newString ? (
              <ActionButtonComponent containerClassName="ms-3" text="Eliminar" onClick={(): void => handleDeletePlatform()} removeIcon />
            ) : null}
          </div>
          {id !== newString || pecId !== '' ? (
            <div className="mt-5">
              <div className="my-4 fw-bold">
                <ToggleSwitch
                  label="Habilitar ventas sin liquidar desde el portal"
                  name="FiservSales"
                  onChange={(): void => handleCheckUnsettledSales()}
                  checked={enableUnsettledSales}
                />
              </div>
              {enableUnsettledSales ? (
                <>
                  <div className="container-input background-red-30 my-3 w-75">
                    <div className="notification warning">
                      <div className="notification-header ms-4">
                        <BsFillExclamationTriangleFill />
                      </div>
                      <div className="notification-content">
                        <div className="font-xs mx-3">
                          <p>
                            Es posible que algunas ventas sin liquidar no se incluyan en la liquidación debido a que la procesadora cambia
                            algunos de los datos (cupón, lote, etc).
                          </p>
                          <a
                            href={`https://wa.me/${
                              user?.Tenant.countryCode === 'AR' ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy
                            }?text=${msg}`}
                            target="_blank"
                            className="text-decoration-none ms-4 color-black fw-bold"
                            rel="noreferrer"
                          >
                            <p className="font-weight-bold">Consulte este tema con los asesores de Nubceo.</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {enableButtonSave ? (
                <div className="my-3 w-100 d-flex justify-content-start align-items-center flex-wrap">
                  <ActionButtonComponent text="Guardar" onClick={(): void => editEmptyPec()} removeIcon inactive={false} />
                </div>
              ) : null}
              <Subtitle className="mb-3">¡Bien! Hemos iniciado el proceso de vinculación. </Subtitle>
              <Subtitle size="md">
                Ahora debés ingresar a{' '}
                <a className="text-decoration-none" href={urlFiserv} target="blank">
                  Fiserv
                </a>{' '}
                para crear un nuevo usuario.
              </Subtitle>
              <Subtitle size="md" className="mb-3">
                Dirígete a la sección de <b>Administración de Usuarios {'>'} Nuevo Usuario</b>
              </Subtitle>
              <Subtitle className="mt-5">Te mostramos que opciones debés marcar:</Subtitle>
              {activeCurrency?.countryCode === CountryCode.AR ? (
                <img src={fiservImageAr} alt="Fiserv" className="img-fluid my-3 w-75" />
              ) : (
                <img src={fiservImageUy} alt="Fiserv" className="img-fluid my-3 w-75" />
              )}

              <Subtitle size="md">
                Recordá que debés usar la siguiente dirección de email: <b>pec_{id !== newString ? id : pecId}@asistente.nubceo.com</b>
              </Subtitle>
              <Subtitle size="md" className="mt-3">
                Permisos requeridos para el nuevo usuario:
              </Subtitle>
              <div className="container-input background-blue-10 mt-2 mb-5 w-75">
                <div className="notification warning">
                  <div className="notification-content">
                    <div className="mx-4">
                      <Subtitle size="xs">
                        <b>.</b> Visualizar liquidaciones
                      </Subtitle>
                      <Subtitle size="xs">
                        <b>.</b> Visualizar ventas y ajustes
                      </Subtitle>
                      <Subtitle size="xs">
                        <b>.</b> Visualizacion Débito Automático
                      </Subtitle>
                      {activeCurrency?.countryCode === CountryCode.AR && (
                        <>
                          <Subtitle size="xs">
                            <b>.</b> Visualizar reportes
                          </Subtitle>
                          <Subtitle size="xs">
                            <b>.</b> Liquidación Electrónica
                          </Subtitle>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <FiservCredentialsFormFTP
          currentValues={currentValues}
          companyId={companyId}
          selectedPlatform={selectedPlatform}
          handleDeletePlatform={handleDeletePlatform}
        />
      )}
    </>
  );
};

export default FiservCredentialsForm;
