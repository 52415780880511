import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './redux/store';
import 'moment/locale/es';

if (process.env.REACT_APP_AG_GRID_KEY) {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);
}

const root = document.getElementById('root')!;

ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
reportWebVitals();
