/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCollections, getSummaryCollectionByPe } from '../../services/collectionService';
import logger from '../../services/loggerService';
import { Collection, SummaryCollecionsByPeResponse, SummaryCollection } from '../../utils/interfaces';
import { FilterParams, LoadingStateType } from '../../utils/types';

interface CollectionResponse {
  // TODO: maybe this is not ok 100%
  documents: {
    docs: Array<Collection>;
    pages: number;
    total: number;
  };
}

interface IdAndParams {
  id: number;
  params?: FilterParams;
}
interface CollectionsState {
  collectionsList: Array<Collection> | null;
  loadingCollections: LoadingStateType;
  loadingFutureIncomes: LoadingStateType;
  loadingPastIncomes: LoadingStateType;
  loadingSummaryCollection: LoadingStateType;
  summaryCollections: Array<SummaryCollection> | null;
  pages: number;
  futureIncomesList: Array<Collection> | null;
  pastIncomesList: Array<Collection> | null;
  total: number;
}
export const fetchCollections = createAsyncThunk<CollectionResponse, FilterParams>('collection/getCollections', async (params) => {
  const { data: response } = await getCollections(params);
  return response.data;
});

export const fetchFutureCollections = createAsyncThunk<CollectionResponse, FilterParams>(
  'collection/getFutureCollections',
  async (params) => {
    const { data: response } = await getCollections(params);
    return response.data;
  },
);

export const fetchPastCollections = createAsyncThunk<CollectionResponse, FilterParams>('collection/getPastCollections', async (params) => {
  const { data: response } = await getCollections(params);
  return response.data;
});

export const asyncSummaryCollectionsByPe = createAsyncThunk<SummaryCollecionsByPeResponse, IdAndParams>(
  'summary-collections',
  async ({ id, params }) => {
    const { data: response } = await getSummaryCollectionByPe(id, params || {});
    return response;
  },
);

const initialState: CollectionsState = {
  collectionsList: [],
  loadingCollections: 'idle',
  loadingSummaryCollection: 'idle',
  summaryCollections: [],
  loadingFutureIncomes: 'idle',
  loadingPastIncomes: 'idle',
  pages: 1,
  futureIncomesList: [],
  pastIncomesList: [],
  total: 0,
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCollections.pending, (state, _action) => {
      state.loadingCollections = 'pending';
    });
    builder.addCase(fetchCollections.rejected, (state, action) => {
      state.loadingCollections = 'failed';
      logger.error('fetchCollections.error', action.error);
    });
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.loadingCollections = 'succeeded';
      state.collectionsList = action.payload.documents.docs;
      state.pages = action.payload.documents.pages;
      state.total = action.payload.documents.total;
      logger.info('fetchCollections.success', action.payload.documents);
    });
    builder.addCase(asyncSummaryCollectionsByPe.pending, (state, _action) => {
      state.loadingSummaryCollection = 'pending';
    });
    builder.addCase(asyncSummaryCollectionsByPe.rejected, (state, action) => {
      state.loadingSummaryCollection = 'failed';
      logger.error('fetchCollections.error', action.error);
    });
    builder.addCase(asyncSummaryCollectionsByPe.fulfilled, (state, action) => {
      state.loadingSummaryCollection = 'succeeded';
      state.summaryCollections = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.count;
      logger.info('fetchCollections.success', action.payload.data);
    });
    builder.addCase(fetchFutureCollections.pending, (state, _action) => {
      state.loadingFutureIncomes = 'pending';
    });
    builder.addCase(fetchFutureCollections.rejected, (state, action) => {
      state.loadingFutureIncomes = 'failed';
      logger.error('fetchFutureCollections.error', action.error);
    });
    builder.addCase(fetchFutureCollections.fulfilled, (state, action) => {
      state.loadingFutureIncomes = 'succeeded';
      state.futureIncomesList = action.payload.documents.docs;
      logger.info('fetchFutureCollections.success', action.payload.documents);
    });

    builder.addCase(fetchPastCollections.pending, (state, _action) => {
      state.loadingPastIncomes = 'pending';
    });
    builder.addCase(fetchPastCollections.rejected, (state, action) => {
      state.loadingPastIncomes = 'failed';
      logger.error('fetchPastCollections.error', action.error);
    });
    builder.addCase(fetchPastCollections.fulfilled, (state, action) => {
      state.loadingPastIncomes = 'succeeded';
      state.pastIncomesList = action.payload.documents.docs;
      logger.info('fetchPastCollections.success', action.payload.documents);
    });
  },
});

export default collectionsSlice.reducer;
