/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { InputWrapperProps, TextInputProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';

const TextInput: FC<TextInputProps & InputWrapperProps> = ({
  label,
  type,
  onChange,
  value,
  errorMessage,
  placeholder,
  name,
  className = '',
  containerClassName,
  required,
  disabled,
  readOnly = false,
  autoComplete = 'off',
}: TextInputProps & InputWrapperProps) => (
  <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
    <div className={`position-relative d-flex ${errorMessage && 'error'}`}>
      <input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`text-input-component ${errorMessage && 'error'} ${className}`}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
    </div>
  </InputWrapper>
);

export default TextInput;
