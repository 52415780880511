/* eslint-disable react/no-array-index-key */
import React, { FC, forwardRef } from 'react';
import { InputWrapperProps } from '../../../utils/interfaces';

const InputWrapper: FC<InputWrapperProps> = forwardRef<HTMLDivElement, InputWrapperProps>(
  ({ required, label, children, errorMessage, containerClassName, style }, ref) => (
    <div className={`input-wrapper-component ${containerClassName}`} style={style} ref={ref}>
      {label && (
        <div className="label-container">
          <p className="label font-sm">{label}</p>
          {required && <p className="required">*</p>}
        </div>
      )}
      {children}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  ),
);

export default InputWrapper;
