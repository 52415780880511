import { Moment } from 'moment';
import React, { ButtonHTMLAttributes, CSSProperties, ReactNode, SetStateAction } from 'react';
import {
  CallbackFunction,
  CompanyOnBoardingPost,
  InputComponent,
  SVGProps,
  ExternalPlatformType,
  CashflowType,
  ExpenseReportsType,
  ExternalPlatformCredentialsForm,
} from './types';
// For more info about data models refer to: https://github.com/nubceo/nubceo-core-model/tree/develop/src/models
export interface MeliProps {
  code: null | string;
  state: null | string;
  error: null | string;
}
export interface ButtonProps extends ButtonHTMLAttributes<ButtonProps> {
  name?: string;
  children: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  buttonTypeStyle?: 'back' | 'delete';
}

export interface TextInputProps extends InputComponent {
  type?: 'number' | 'text' | 'textarea' | 'password' | 'email' | 'tel';
  placeholder?: string;
  readOnly?: boolean;
}

export interface CustomInputProps extends TextInputProps {
  placeholder?: string;
  readOnly?: boolean;
  countryCode?: string;
  customChange: (...args: any[]) => void;
  maxLength?: number;
  notFormatValue?: boolean;
}

export interface OptionInterface {
  id?: string | number;
  code?: string;
  name: string;
  value: string | number | any;
  index?: number;
  icon?: ReactNode;
  optionLabel?: string;
  image?: ReactNode;
  pill?: ReactNode;
  externalCode?: string | number;
}
export interface SelectInputProps extends InputComponent {
  placeholder?: string;
  multiple?: boolean;
  options: OptionInterface[];
  image?: ReactNode;
  onOptionClick: CallbackFunction;
}

export interface SearchInputProps extends InputComponent {
  placeholder?: string;
  options: OptionInterface[];
  onOptionClick: CallbackFunction;
  setInputValueSearch?: React.Dispatch<SetStateAction<string>>;
  setCountCheck?: React.Dispatch<SetStateAction<number>>;
  countCheck?: number;
}

export interface SelectOptionProps {
  onClick: CallbackFunction;
  name?: string;
  value?: string | number | undefined;
  className?: string;
  icon?: React.ElementType<SVGProps>;
  option: {
    name: string;
    value: string | number | '';
    icon?: ReactNode;
    optionLabel?: string;
    image?: ReactNode;
    pill?: ReactNode;
  };
}
export interface platformExternalsDetail {
  id: string;
  externalCode: string;
  platformExternalCode: string;
}
export interface SearchOptionProps {
  onCheck: CallbackFunction;
  onUncheck: CallbackFunction;
  checked?: boolean;
  name?: string;
  value?: string | number | undefined;
  className?: string;
  icon?: React.ElementType<SVGProps>;
  option: {
    name: string;
    value: string | number | '';
    icon?: ReactNode;
    optionLabel?: string;
    image?: ReactNode;
    pill?: ReactNode;
    externalCode?: string | number | undefined;
    platformExternals?: platformExternalsDetail[];
  };
}

export interface CheckBoxProps extends InputComponent {
  children?: ReactNode;
  checked?: boolean;
  requiredOnlyOption?: boolean;
}

export interface InputWrapperProps {
  label?: string | null;
  children?: ReactNode;
  errorMessage?: string | string[] | boolean;
  containerClassName?: string;
  required?: boolean;
  style?: CSSProperties;
  ref?: any;
  setEndOfScroll?: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}

export interface LinkComponentProps {
  children: ReactNode;
  route: string | Record<string, unknown>;
  size?: 'sm' | 'md' | 'lg' | 'xs';
  className?: string;
}

export interface User {
  createdAt: string;
  date: string;
  deletedAt: string | null;
  email: string;
  employeeNumber: number;
  fcmToken?: string | null;
  firstName: string;
  id: string;
  language: string;
  lastFeedbackAt: string | null;
  onboardingComplete: boolean | null;
  phone: string | null;
  sureName: string;
  tenantId: number;
  updatedAt: string;
  username: string;
  verified: boolean;
  verifiedExpiry: boolean | null;
  verifiedToken: boolean | null;
  referrerCode: string | null;
  referrerInviteLink: string | null;
  enabled: boolean;
}

export interface UserCredentials {
  email: string;
  password: string;
}
export interface UserRecoverPassword {
  email: string;
}

export interface UserRegisterData {
  fullName?: string;
  sureName?: string;
  firstName?: string;
  password: string;
  passwordConfirm: string;
  email: string;
  countryCode: string;
  phone?: string;
  referral?: string | null;
  terms?: boolean;
  activityCode: null | string;
  operationType: string;
  countryCodeNumber?: string;
  userName: string;
}

export interface UserResponse {
  tierCode?: string;
  tokenRefresh: string;
  token: string;
  daysRemaining: number;
  user: User;
}

export interface UpdateUserData {
  firstName: string;
  sureName: string;
  phone: string;
}
export interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
  passwordConfirm: string;
}

export interface UpdateEmail {
  oldEmail: string;
  newEmail: string;
  emailConfirm: string;
}

export interface UserForgotPasswordHandler {
  error?: {
    message: string;
    name: string;
  };
  payload: Record<string, unknown> | undefined;
  type: string;
}

export interface LoggerData {
  level: string;
  message: string;
  additionalInfo: any | null;
}
export interface RecoverPassword {
  password: string;
  email: string;
  token: string;
}
export interface UserRecoverPasswordConfirm {
  password: string;
  passwordConfirmation: string;
  code: string;
}
export interface Collection {
  id: string;
  tenantId: number;
  platformExternalCode: string;
  platformExternalCredentialId: number;
  companyId: string;
  Collections: Array<Collection>;
  branchId: string;
  settlementId: string;
  currencyCode: string;
  externalCode: string;
  externalReference: string;
  amount: number;
  notes: string;
  date: string;
  confirmed: boolean;
  cardBrand: string;
  confirmedTaxDeduction: boolean;
  confirmedAmount: boolean;
  createdAt: string;
  updatedAt: string;
  Tenant: Tenant;
  PlatformExternal: PlatformExternal;
  Taxes: Array<Tax>;
  Deductions: Array<Deduction>;
  Settlement: Settlement;
  Branch: Branch;
  deductionsDetails: DeductionDetail;
  totalDeductions: number;
  totalTaxes: number;
  grossAmount: number;
  relatedPlatformExternals: Array<{
    code: string;
    name: string;
  }>;
}
export interface SettlementResponse {
  rows: Array<SettlementWithCollections>;
  pages: number;
  total: number;
}

interface Tag {
  tag: string;
}
export interface SettlementWithCollections {
  branchId: string;
  Collections: Array<Collection>;
  Deductions: Array<Deduction>;
  PlatformExternal: PlatformExternal;
  Taxes: Array<Tax>;
  Tenant: Tenant;
  Branch: Branch;
  SettlementTags?: Array<Tag>;
  amount: number;
  cardBrand: string;
  date: string;
  confirmedAmount: boolean;
  confirmedDate: boolean;
  confirmedTaxDeduction: boolean;
  companyId: string;
  createdAt: string;
  currencyCode: string;
  // isProjected: boolean;
  externalCode: string;
  externalReference: string;
  filePath: string;
  grossAmount: number;
  id: string;
  platformExternalCode: string;
  platformExternalCredentialId: number;
  relatedPlatformExternals: Array<{
    code: string;
    name: string;
  }>;
  sourceDateFrom: string;
  sourceDateUntil: string;
  deductionsDetails: DeductionDetail;
  totalDeductions: number;
  totalTaxes: number;
  tenantId: number;
  updatedAt: string;
}
interface Deduction {
  amount: number;
  name: string;
  deductionTypeCode: string | null;
}
interface DeductionDetail {
  comissions: number;
  others: number;
}
export interface PlatformExternal {
  code: string;
  name: string;
}

export interface ErrorRecoverPassword {
  code: string;
  name: string;
  status: number;
}

export enum TypesOfNotifications {
  DATA_REPORT = 'data-report',
  ADMIN_API_NOTIFICATION = 'admin-api-notification',
  TAXCODE_EMPTY = 'TAXCODE_EMPTY',
  CASH_DAILY_SUMMARY = 'cash-daily-summary',
  PLATFORM_EXTERNAL_AUTH = 'platform_external_auth',
  UNCONFIGURED_CREDENTIAL = 'unconfigured_credential',
  LOCAL_NOTIFICATION = 'local-notification',
}

export enum TypeErrorRecoverPassword {
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
}

export enum ActivityCode {
  'CONTADOR' = 'contador',
  'SERVICIOS' = 'prestserv',
  'FABRICANTE' = 'fabricante',
  'VENTA' = 'ventprod',
}

export enum CountryCode {
  AR = 'AR',
  UY = 'UY',
  CO = 'CO',
}

export enum LoadingStateType {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEDED = 'succeeded',
  FAILED = 'failed',
}

export interface Countries {
  [key: string]: string;
}

export enum CurrencyCode {
  AR = 'ARS',
  UY = 'UYU',
  CO = 'COP',
  USD = 'USD',
}

export enum CurrencySymbol {
  AR = '$',
  UY = '$',
  USD = 'USD',
}

export enum PaymentUrl {
  AR = 'https://www.nubceo.com/cash-ar/suscripcion-ar/',
  UY = 'https://www.nubceo.com/cash-uy/suscripcion-uy/',
}

export interface Tenant {
  countryCode: string;
  operationType: 'online' | 'store' | 'no_store';
  activityCode: ActivityCode;
}

interface Settlement {
  amount: number;
  branchId: string;
  cardBrand: string;
  companyId: string;
  createdAt: string;
  currencyCode: string;
  externalCode: string;
  externalReference?: string;
  filePath: string;
  id: string;
  platformExternalCode: string;
  platformExternalCredentialId: number;
  sourceDateFrom: string;
  sourceDateUntil: string;
  tenantId: number;
  updatedAt: string;
  date: string;
  grossAmount: number;
  type: string | null;
}
export interface Branch {
  id: string;
  tenantId: string;
  companyId: string;
  addressId: string;
  name: string;
  branchRelatedId: string;
  relatedBranches: Array<Branch>;
  relatedPlatformExternalCodes: string[];
  PlatformExternals: Array<{
    branchId: number;
    createdAt: string;
    externalCode: number;
    id: number;
    platformExternalCode: string;
    tenantId: number;
    updatedAt: string;
  }>;
}
interface PlatformExternalCredential {
  id: number;
  tenantId: number;
  companyId: number;
  branchId: number;
  platformExternalCode: string;
  username: string;
  password: string;
  codeAux: string;
  failureCount: number;
  failureAuthCount: number;
  omitReason: string;
  initialized: boolean;
  externalReference?: string;
}

export interface SummaryTax {
  taxTypeName: string;
  platformExternalName: string;
  totalAmount: number;
}
export interface SummaryDeduction {
  deductionTypeName: string;
  platformExternalName: string;
  totalAmount: number;
}
export interface SummarySale {
  platformExternalName: string;
  totalAmount: number;
  totalCount: number;
}

export interface SummaryCollection {
  platformExternalName: string;
  totalAmount: number;
  totalCount: number;
}

export interface Tax {
  amount: number;
  countryCode: string;
  taxTypeCode: string;
  currencyCode: string;
  platformExternalCode: string;
  date: string;
  name: string;
  Settlement: Settlement;
  id: string;
}

export interface TaxType {
  code: string;
  countryCode: string;
  name: string;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface UserFeedback {
  score: number | null;
  featureRequest: string | null;
  platformVariant: string;
}

export interface Sale {
  Branch: Branch;
  Collections: Array<Collection>;
  Settlement: Array<Settlement>;
  SaleTags?: Array<Tag>;
  amount: number;
  branchId: string;
  cardBrand: string;
  cardNumber: string;
  companyId: string;
  createdAt: string;
  currencyCode: string;
  date: string;
  externalCode: string;
  externalReference: string;
  id: string;
  platformExternalCode: string;
  platformExternalCredentialId: number;
  presentedDate: Date;
  SalePlatformExternals: Array<PlatformExternalCredential>;
  tenantId: number;
  type: string;
  saleDocumentReference: string;
}
export interface PlatformList {
  code: string;
  amount: number;
}
export interface Address {
  city: string;
  createdAt: string;
  id: number;
  number: string;
  postalCode: string;
  state: string;
  street: string;
  tenantId: number;
}
export interface Company {
  Address: Address;
  addressId: number;
  category: string;
  countryCode: string;
  createdAt: string;
  enabled: boolean;
  id: string;
  name: string;
  taxCode: string;
  tenantId: number;
}
export interface ExpenseReportsResponse {
  data: Array<ExpenseReportsType>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}
export interface UsersListResponse {
  data: Array<User>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}

export interface SaleResponse {
  documents: {
    docs: Array<Sale>;
    pages: number;
    total: number;
  };
}
export interface SalesByClientResponse {
  rows: Array<Sale>;
  pages: number;
  total: number;
}
export interface SummarySalesByPeResponse {
  data: Array<SummarySale>;
  meta: {
    count: number;
    pages: number;
  };
}

export interface SummaryCollecionsByPeResponse {
  data: Array<SummaryCollection>;
  meta: {
    count: number;
    pages: number;
  };
}
export interface SummaryTaxesByPeResponse {
  data: Array<SummaryTax>;
  meta: {
    count: number;
    pages: number;
  };
}
export interface SummaryDeductionsByPeResponse {
  data: Array<SummaryDeduction>;
  meta: {
    count: number;
    pages: number;
  };
}
export interface TaxesByClientResponse {
  data: Array<Tax>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}
export interface TaxTypesByClientResponse {
  data: Array<TaxType>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}

export interface SettlementsByClientResponse {
  rows: Array<SettlementWithCollections>;
  pages: number;
  total: number;
}

export interface ExternalPlatformCredentials {
  id: number;
  code: string;
  countryCode: string;
  name: string;
  source?: string;
  type: ExternalPlatformType;
  retryMax: number;
  retryAuthMax: number;
  retryIntervalSec: number;
  enabled: boolean;
  order: number;
  lastExecutionDate: string;
  executionExpression: string;
  createdAt: string | null;
  updatedAt: string;
  codeAux?: string;
}

export interface ParamsSmartData {
  rowGroupCols: Col[];
  valueCols: ValueCol[];
  pivotCols: Col[];
  pivotMode: boolean;
  groupKeys: string[];
  filterModel: FilterModel;
  sortModel: SortModel[];
  successCallback?: () => void;
}

export interface AmountCellParams {
  value: number | string | null | undefined;
  colDef: {
    headerName: string;
  };
}

export interface FilterModel {
  [key: string]: {
    filter?: string | number;
    filterType: string;
    type?: string;
    dateFrom?: string;
    dateTo?: string;
    operator?: string;
    condition1?: FilterModel;
    condition2?: FilterModel;
  };
}
export interface FilterConditions {
  filterType: string;
  type: string;
  filter: string;
}
export interface Col {
  id: string;
  displayName: string;
  field: string;
  aggFunc?: string;
}

export interface SortModel {
  sort: string;
  colId: string;
}

export interface ValueCol {
  id: string;
  aggFunc: string;
  displayName: string;
  field: string;
}

export interface DataSmartDataSettlement {
  id: string;
  amount: number;
  branchReference: string;
  cardBrand: string;
  currencyCode: string;
  date: Date;
  externalReference: string;
  grossAmount: number;
  isProjected: boolean;
  sourceDateFrom: Date;
  sourceDateUntil: Date;
  monthYear: string;
  settlementPeriod: string;
  relatedPlatform: string;
  'company.name': string;
  'compant.taxCode': string;
  'platformExternal.name': string;
  'branch.name': string;
  deductionsAmount: string;
  taxesAmount: string;
}

export interface MapName {
  [key: string]: string | undefined;
}

export interface ExternalActivePlatform {
  platformExternalCode: string;
  id: number;
  username: string;
  codeAux?: string;
  codeAux2?: string;
  codeAux3?: string;
  codeAux4?: string;
  failureCount: number;
  failureAuthCount: number;
  companyId: number;
  setupCompleted: number;
  createdAt: string;
  updatedAt: string;
  PlatformExternal: {
    type: ExternalPlatformType;
    name: string;
    code: string;
    source: string;
  };
}

export enum SettlementsType {
  DIARIA = 'DIARIA',
  ADELANTO = 'ADELANTO',
  RECUPERO = 'RECUPERO',
  QR = 'QR',
}

export enum FormTypeFiserv {
  FTP = 'ftp',
}

export enum FormTypePrisma {
  FTP = 'ftp',
}

export enum FormTypeVisanet {
  API = 'api',
  BASIC = 'basic',
}

export interface VisanetCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
}
export interface ExternalPlatformByBranch {
  code: string;
  name: string;
  countryCode: string;
}

export interface ExternalPlatform {
  code: string;
  countryCode: string;
  name: string;
  type: ExternalPlatformType;
  retryMax: number;
  retryAuthMax: number;
  retryIntervalSec: number;
  enabled: boolean;
  order: number;
  lastExecutionDate: string;
  executionExpression: string;
  source: string;
  createdAt: string;
  updatedAt: string;
}

export interface ListExternalPlatformsResponse {
  data: ExternalPlatformCredentials[];
  meta: {
    count: number;
    pages: number;
    total: number;
  };
  status: number;
}

export interface AddExternalPlatformResponse {
  document: ExternalPlatformCredentials;
}

export interface ListExternalActivePlatformsResponse {
  documents: {
    docs: Array<ExternalActivePlatform>;
    pages: number;
    total: number;
  };
}

export interface ListExternalUsedPlatformsResponse {
  data: Array<ExternalActivePlatform>;
}

export interface AddCompanyResponse {
  data: Company;
}

export interface AddAddressResponse {
  document: Address;
}
export interface CollectionReport {
  [key: string]: any | number;
  total: number;
  date: string;
  unconfirmed: number;
}
export interface CashflowSummary {
  [key: string]: any | number;
  date: string;
  amountIn: number;
  amountOut: number;
  unconfirmed: number;
  platforms: Array<Platform>;
  // projected: boolean;
}
export interface Platform {
  code: string;
  amount: number;
  relatedPlatforms?: RelatedPlatform[];
}

export interface RelatedPlatform {
  code: string;
  amount: number;
}

export interface BranchRow {
  branch: Branch;
  selectedBranches: Branch[];
  handlePaginationCallBack?: ((searchParams: Record<string, string>) => void) | null;
  selectable: boolean;
  setSelectedBranch: (branch?: Branch) => void;
  showUnlinkIcon: boolean;
  editTypeAction?: boolean;
  isAdmin?: boolean;
  ungroupTypeAction?: boolean;
  onClick?: (branch?: Branch) => void;
}

export interface ExternalPlatformIcon {
  platformExternalCode: string;
  externalCode: string;
}

export interface ExternalPlatforms {
  branchId: number;
  createdAt: string;
  externalCode: number;
  id: number;
  platformExternalCode: string;
  tenantId: number;
  updatedAt: string;
}

export interface RelatedBranches {
  id: string;
  tenantId: string;
  companyId: string;
  addressId: null;
  branchRelatedId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  PlatformExternals: PlatformExternalRelatedBranches[];
}

export interface PlatformExternalRelatedBranches {
  platformExternalCode: string;
  externalCode: string;
}

export interface ParamsEditBranch {
  id: string;
  name: string;
  tenantId: string;
  companyId: string;
  addressId?: null;
  branchRelatedId?: null;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Cashflow {
  type: CashflowType;
  movement: Collection & Payment;
}
export interface Payment {
  id: string;
  tenantId: number;
  platformExternalCode: string;
  platformExternalCredentialId: number;
  companyId: number;
  branchId: number;
  businessEntityId: number;
  currencyCode: string;
  externalCode: string;
  externalReference: string;
  cardBrand: string;
  date: Date;
  amount: number;
  grossAmount: number;
  createdAt: Date;
  updatedAt: Date;
  Taxes: Tax[];
  Deductions: Deduction[];
  PlatformExternal: PlatformExternal;
  PlatformExternalCredential: PlatformExternalCredential;
  Tenant: Tenant;
  Company: Company;
  Currency: Currency;
  Settlements: Settlement[];
  Branch: Branch;
  BusinessEntity: BusinessEntity;
}
export interface BusinessEntity {
  countryCode: string;
  createdAt: string;
  deletedAt: string;
  email: string;
  id: string | number;
  isClient: boolean;
  isOrganization: boolean;
  isProvider: boolean;
  name: string;
  phone: string;
  relCompanyId: string | number;
  relTenantId: string | number;
  surname: string;
  taxCode: string;
  tenantId: number;
  updatedAt: string;
}
export interface TaxBalance {
  date: string;
  taxes: Array<Tax>;
}

export interface RouteParams {
  id: string;
}

export interface CompanyRequestPost extends CompanyOnBoardingPost {
  taxCode: string;
  category: string;
  addressId: string | number | null;
  enabled?: boolean;
}

export interface CompanyRequestPatch {
  id: string | number;
  name: string;
  taxCode: string;
  category: string;
  addressId?: string;
  enabled: boolean;
}

export interface AddressRequestPatch extends AddressRequestPost {
  id: number | string;
}
export interface AddressRequestPost {
  street: string;
  number: string;
  postalCode: string;
  state: string;
  city: string;
  tenantId: number;
}

export interface EditExternalPlatformCredentials {
  id: string;
  password: string;
  username?: string;
  codeAux?: string;
  codeAux2?: string;
  codeAux3?: string;
  codeAux4?: string;
}

export interface EditEmail {
  email: string;
  username: string;
}

interface BusinessEntityTenantRequestI {
  id: string;
  relCompanyId: string;
  relTenantId: string;
  userId: string;
}

export interface Client {
  BusinessEntityTenantRequests: BusinessEntityTenantRequestI[];
  countryCode: string;
  deletedAt: boolean;
  email: string;
  id: number;
  isClient: boolean;
  isOrganization: boolean;
  isProvider: boolean;
  name: string;
  phone: string;
  relCompanyId: number;
  relTenantId: number;
  surname: string;
  taxCode: string;
  tenantId: number;
  RelCompany: {
    id: number;
    tenantId: number;
    taxCode: string;
    countryCode: string;
    name: string;
  };
  RelTenant?: {
    activityCode: string;
    countryCode: string;
    enabled: false;
    id: string | number;
    name: string;
    operationType: null | number | string;
    subscription: {
      tierCode: string;
      trialRemainingDays: number;
    };
  };
}
export interface AddClientExternalPlatform {
  id: string;
  platformCredentials: ExternalPlatformCredentialsForm;
}
export interface EditClientExternalPlatform {
  id: string;
  platformCredentials: EditExternalPlatformCredentials;
}
export interface Currency {
  code: string;
  countryCode: string;
  name: string;
}

export interface CalendarPropsHeader {
  date: Date;
  label: string;
}

export interface DailySummaries {
  summaryNotifHour: string;
  reportIncludeSettlements: boolean;
  reportIncludeCollections?: boolean;
  reportIncludeSales: boolean;
  reportMediumIsPush: boolean;
  reportMediumIsEmail: boolean;
  reportFrequency: string;
  reportEmailRecipients: string;
  reportChargebackEnabled?: boolean;
}

export interface Pagination {
  count: number;
  total: number;
  pages: number;
}
export interface CalendarCardProps {
  loading: string;
  value: Date;
  collection: any;
  key: string;
  selectMonth: Moment;
  onClick: (date: Date) => void;
  isInternalCalendar?: boolean;
}

export interface CashflowMovementResponse {
  data: Array<Cashflow>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}

export interface ListsAxiosPromise<T = any> {
  data: T;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
  status: number;
}
