import React from 'react';
// import { segmentTrack } from '../../../utils/segment/hooks';
import { CallbackFunction } from '../../../utils/types';

type ToggleSwitchType = {
  checked?: boolean;
  onChange: CallbackFunction;
  label?: string;
  name: string;
  className?: string;
  // analytics?: {
  //   event: string;
  //   data: unknown;
  // };
};

const ToggleSwitch: React.FC<ToggleSwitchType> = ({ checked, label, name, className, onChange }) => {
  const handleClick = (): void => {
    // if (analytics) {
    //   segmentTrack(analytics.event, analytics.data);
    // }
  };
  return (
    <div className="toggle-wrapper">
      {label && <span className="toggle-label">{label}</span>}
      <label htmlFor={name} className="d-flex align-items-center">
        <input type="checkbox" checked={checked} className="toggle-input" name={name} id={name} onChange={onChange} onClick={handleClick} />
        <span role="none" className={`toggle-switch ${checked ? 'active ' : ''}${className}`} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
