import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Filters } from '../../containers';
import { ClientNameHeader, Title } from '../../components/textComponents';
import FetchButton from '../../components/buttons/FetchButton';
import { SalesList } from '../../components';
// import { EVENTS } from '../../utils/segment/events';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { CloseIconSVG } from '../../assets/svgComponents';
import { asyncListClientCompanies, asyncListClientExternalPlatforms } from '../../redux/slices/clientsSlice';
// import { segmentTrack } from '../../utils/segment/hooks';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { asyncListSalesClient } from '../../redux/slices/salesSlice';
import { FilterType } from '../../utils/types';

const SalesClientPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { salesList } = useAppSelector((stateSelector) => stateSelector.sales);
  const dispatch = useAppDispatch();
  // const page = useQueryParams('page') || '1';

  useEffect(() => {
    dispatch<void>(asyncListClientExternalPlatforms(id || ''));
    dispatch<void>(asyncListClientCompanies(id || ''));
  }, [id]);

  useEffect(() => {
    // if (page !== '1') segmentTrack(EVENTS.COLLECTIONS.PAGINATE, { page });
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({ ...queryObject });
    dispatch<void>(asyncListSalesClient({ id: Number(id), params: filterableObject }));
  }, [location]);

  const goBack = (): void => {
    navigate(-1);
  };

  const handlePagination = (searchParams: Record<string, string>): void => navigate(`?${new URLSearchParams(searchParams).toString()}`);

  return (
    <div className="saleByClient-page mb-4 mt-4">
      <div className="my-2 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-9">
        <ClientNameHeader clientId={Number(id)} />
        <CloseIconSVG className="close-icon color-blue" height={40} width={40} onClick={goBack} />
      </div>
      <div>
        <Title size="sm">Ventas</Title>
      </div>
      <Filters containerClassName="mt-3 mb-3" typeFilter={FilterType.SALES} />
      <div className="me-2 my-2 flex-grow-1 d-flex justify-content-end">
        <FetchButton dataType={6} tenantId={Number(id)} />
      </div>
      <SalesList salesArray={salesList} handlePaginationCallBack={handlePagination} />
    </div>
  );
};
export default SalesClientPage;
