import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoaderScreen, NotificationRowComponent, PaginationComponent } from '../../components';
import { Title } from '../../components/textComponents';
import useQueryParams from '../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { asyncListNotifications } from '../../redux/slices/notificationSlice';
import { paramsToObject, parseFilters } from '../../utils/helper';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';

const NotificationsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const page = useQueryParams('page') || '1';
  const location = useLocation();

  const { notificationsList, loading, pages } = useAppSelector((state) => state.notifications);
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const { externalActivePlatformList } = useAppSelector((state) => state.externalPlatform);

  const topRef = useRef<HTMLDivElement>(null);
  const fiservLinkingNotFtp = externalActivePlatformList.find(
    (platform) => platform?.platformExternalCode.includes('firstdata') && platform?.codeAux !== 'ftp',
  );
  useEffect(() => {
    // if (page !== '1') segmentTrack(EVENTS.NOTIFICATIONS.PAGINATE, { page });
    asyncListNotifications({ 'sort[createdAt][desc]': '' });
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({ ...queryObject });
    dispatch<void>(asyncListNotifications({ ...filterableObject, 'sort[createdAt][desc]': '' }));
  }, [location, activeCurrency?.countryCode]);

  const content = (): ReactElement | ReactElement[] => {
    if (loading === 'pending') {
      return <LoaderScreen />;
    }
    if (notificationsList.length) {
      return notificationsList.map((notification) => <NotificationRowComponent item={notification} />);
    }

    return <div>No tenés notificaciones todavía</div>;
  };

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (topRef.current) {
      topRef.current.focus({ preventScroll: true });
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  return (
    <div className="collections-page mb-4 mt-5">
      <div tabIndex={-1} ref={topRef} style={{ width: 0, height: 0 }} />
      {fiservLinkingNotFtp ? (
        <div className="container-input background-red-30 mb-3 w-100">
          <div className="notification warning">
            <div className="notification-content">
              <div className="font-xs mx-4">
                Durante el mes de agosto el portal de <b>Fiserv</b> se dará de baja, de no tener acceso por medio de FTP,
              </div>
              <div className="font-xs mx-4">
                no podremos incorporar la información a nubceo.{' '}
                <div className="link-read-more d-inline" role="none" onClick={(): void => navigate('/info-fiserv')}>
                  Leer más
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Title>Notificaciones</Title>
      {content()}
      {notificationsList?.length ? <PaginationComponent page={page} totalPages={pages} onClick={handlePagination} /> : null}
    </div>
  );
};

export default NotificationsPage;
