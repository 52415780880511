import { AxiosPromise } from 'axios';
import {
  AddExternalPlatformResponse,
  ExternalActivePlatform,
  EditExternalPlatformCredentials,
  ListExternalActivePlatformsResponse,
  ListExternalPlatformsResponse,
  ExternalPlatformByBranch,
  ListExternalUsedPlatformsResponse,
} from '../utils/interfaces';
import { ExternalPlatformCredentialsForm, RequestParams } from '../utils/types';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

// TODO: Create a type with filters, to add to REQUEST PARAMS
export const getExternalPlatforms = async (params?: RequestParams): Promise<ListExternalPlatformsResponse> => {
  const { data } = await axiosV2.get<ListExternalPlatformsResponse>('/platform_external', { params });
  return data;
};

export const getExternalActivePlatforms = async (params?: RequestParams): Promise<ListExternalActivePlatformsResponse> => {
  const { data } = await axios.get<{ data: ListExternalActivePlatformsResponse }>('/self/platform-external-active', { params });
  return data.data;
};

export const getExternalUsedPlatforms = async (params?: RequestParams): Promise<ListExternalUsedPlatformsResponse> => {
  const { data } = await axiosV2.get<ListExternalUsedPlatformsResponse>('/self/platform-external-used', { params });
  return data;
};

export const getPlatformsByBranch = (params?: RequestParams): AxiosPromise<{ data: ExternalPlatformByBranch[] }> =>
  axiosV2.get('/self/platform-external/by-branch', { params });

export const addPlatformsCredential = async (
  platformCredentials: ExternalPlatformCredentialsForm,
): Promise<AddExternalPlatformResponse> => {
  const { data } = await axios.post<{ data: AddExternalPlatformResponse }>('/platform_external_credential', platformCredentials);
  return data.data;
};

export const viewExternalPlatformCredential = (platformId: string): AxiosPromise<{ data: ExternalActivePlatform }> =>
  axiosV2.get(`/platform_external_credential/${platformId}`);

export const editPlatformCredential = async (platform: EditExternalPlatformCredentials): Promise<ExternalActivePlatform> => {
  const { data } = await axios.patch<{ data: ExternalActivePlatform }>(`/platform_external_credential/${platform.id}`, platform);
  return data.data;
};

export const deletePlatformCredential = async (id: string | undefined): Promise<ExternalActivePlatform> => {
  const { data } = await axios.delete(`/platform_external_credential/${id}`);
  return data.data;
};
