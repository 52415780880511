import React, { useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeModalFromList } from '../../redux/slices/modalSlice';
import { handleKeyDownDefault } from '../../utils/helper';
import { CloseIconSVG } from '../../assets/svgComponents';
import { config } from '../../utils/constants';
import { joyrideStyles, STEPS_DASHBOARD } from '../Walkthrough';

const ModalHelp: React.FC = () => {
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userProfile);

  const msg = `Hola! Necesito ayuda.`;

  const handleJoyrideCallback = (data: { action?: any; status?: any }) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      window.localStorage.removeItem('walkthrough');
      window.localStorage.removeItem('walkthroughCompanies');
      window.localStorage.removeItem('walkthroughPlatforms');
      navigate('/calendar');
      dispatch(removeModalFromList());
    } else if (data.action === ACTIONS.CLOSE) {
      window.localStorage.setItem('walkthrough', 'true');
      window.localStorage.setItem('walkthroughCompanies', 'true');
      window.localStorage.setItem('walkthroughPlatforms', 'true');
      window.localStorage.removeItem('runManualWalkthrough');
      window.location.reload();
      dispatch(removeModalFromList());
    }
  };

  const walkthrough = (): void => {
    setRunWalkthrough(true);
  };

  const handleTutorial = (): void => {
    window.localStorage.setItem('runManualWalkthrough', 'true');
    navigate('/');
    walkthrough();
  };

  return (
    <>
      <div className="background-gray-60 modal-wrapper-help" onKeyDown={handleKeyDownDefault} role="none" id="modal">
        {runWalkthrough ? (
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            run
            disableCloseOnEsc
            disableOverlayClose
            steps={STEPS_DASHBOARD}
            styles={joyrideStyles}
          />
        ) : null}
        <div className={`modal-popUp border-round d-flex flex-column position-relative ${runWalkthrough && 'd-none'}`}>
          <div className="container-help">
            <button className="align-self-end close-button" type="button" onClick={() => dispatch(removeModalFromList())}>
              <CloseIconSVG className="me-1 color-blue" />
            </button>
            <div className="titleText ms-3">
              <p className="font-lg">¿Necesitás ayuda?</p>
            </div>
            <hr className="line-through" />
            <div className="d-flex flex-column justify-content-around my-4">
              <div className="d-flex flex-row justify-content-between">
                <a
                  href="https://www.nubceo.com/comunidad-ar/"
                  target="_blank"
                  className="text-decoration-none ms-4 color-black fw-bold"
                  rel="noreferrer"
                >
                  Preguntas frecuentes
                </a>
                <p className="pointer">{'>'}</p>
              </div>
              <div className="d-flex flex-row justify-content-between my-3">
                <a
                  href={`https://wa.me/${
                    user?.Tenant.countryCode === 'AR' ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy
                  }?text=${msg}`}
                  target="_blank"
                  className="text-decoration-none ms-4 color-black fw-bold"
                  rel="noreferrer"
                >
                  Contáctanos
                </a>
                <p className="pointer">{'>'}</p>
              </div>
              <div className="d-flex flex-row justify-content-between" onClick={handleTutorial} role="none">
                <p className="ms-4 color-black fw-bold tutorial">Ver tutorial</p>
                <p className="pointer">{'>'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalHelp;
