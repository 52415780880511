import { AxiosPromise } from 'axios';
import { Branch, ParamsEditBranch } from '../utils/interfaces';
import { FilterParams } from '../utils/types';
import axios from './axiosInstanceV2';

export const listBranches = async (params?: FilterParams): Promise<Branch[]> => {
  const { data } = await axios.get('/branch', { params });
  return data;
};

export const editBranchService = (branch: ParamsEditBranch): AxiosPromise => axios.patch(`/branch/${branch.id}`, branch);

export const mergeBranches = (ids: Array<number>): AxiosPromise => axios.post('/branch/merge', { branchIds: ids });

export const unMergeBranches = (ids: Array<number>): AxiosPromise => axios.post('/branch/unmerge', { branchIds: ids });

export default { listBranches };
