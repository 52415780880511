import React, { useState } from 'react';
import { LoaderScreen } from '..';
import { CloseIconSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeModalFromList } from '../../redux/slices/modalSlice';
import logger from '../../services/loggerService';
import { handleKeyDownDefault } from '../../utils/helper';

const Modal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { modalList } = useAppSelector((state) => state.modal);
  const modal = modalList[0];

  const handleCancelButton = (): void => {
    if (modal.cancelButton?.onClick) {
      modal.cancelButton.onClick();
    }
    dispatch(removeModalFromList());
  };

  const handleClickEvent = (e: React.MouseEvent): void => {
    const modalDiv = document.getElementById('modal');
    if (modalDiv === e.target) dispatch(removeModalFromList());
  };

  const constructPromise = (): Promise<void> =>
    new Promise((resolve, reject) => {
      try {
        modal.okButton
          ?.onClick()
          .then(() => resolve())
          .catch(() => reject());
      } catch {
        reject();
      }
    });

  const handleOkButton = async (): Promise<void> => {
    try {
      setLoading(true);
      await constructPromise();
      dispatch(removeModalFromList());
    } catch (err) {
      logger.error('modalPromise.error', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDefaultButton = (): void => {
    if (!modal.okButton || !modal.cancelButton) {
      dispatch(removeModalFromList());
    }
  };

  return (
    <div
      className="background-gray-60 modal-wrapper"
      onClick={modal?.required ? undefined : handleClickEvent}
      onKeyDown={handleKeyDownDefault}
      role="none"
      id="modal"
      style={{ opacity: modalList.length > 0 ? 1 : 0 }}
    >
      <div
        className={`modal ${modal.className ? modal.className : ''} border-round d-flex flex-column position-relative modal-custom-style`}
      >
        <div className="modal-body flex-grow-1 d-flex justify-content-center align-items-center">
          {!modal.required && (
            <button
              className="align-self-end close-button position-absolute top-0 right-0"
              type="button"
              onClick={(): void => {
                if (modal.handleCloseButton) modal.handleCloseButton();
                dispatch(removeModalFromList());
              }}
            >
              <CloseIconSVG className="me-1 mt-2 color-blue" />
            </button>
          )}
          {loading ? (
            <div className="flex-grow-1 d-flex justify-content-center align-items-center custom-min-width">
              <LoaderScreen />
            </div>
          ) : (
            <>
              {modal.pictureUrl && <img src={modal.pictureUrl} alt={modal.body as string} className="modal-picture" />}
              {modal.body}
            </>
          )}
        </div>

        <div className="modal-buttons">
          {modal.okButton && (
            <button type="button" onClick={handleOkButton} className="modal-ok" disabled={loading}>
              <p className="centered-text">{modal.okButton.buttonText}</p>
            </button>
          )}
          {modal.cancelButton && (
            <button type="button" onClick={handleCancelButton} className="modal-cancel" disabled={loading}>
              <p className="centered-text">{modal.cancelButton.buttonText}</p>
            </button>
          )}
        </div>

        {!modal.okButton && !modal.cancelButton && !modal.hideModalAccept ? (
          <div className="modal-buttons">
            <button type="button" onClick={handleDefaultButton} className="modal-ok" disabled={loading}>
              <p className="centered-text">Aceptar</p>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
