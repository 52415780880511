import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { getExternalActivePlatforms, getExternalPlatforms } from '../services/externalPlatformService';
import errorHandlerHelper from './errorHandler';
import { ListExternalActivePlatformsResponse, ListExternalPlatformsResponse } from './interfaces';
import { RequestParams } from './types';

export const asyncListExternalPlatformsHelper = async (
  params: RequestParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<ListExternalPlatformsResponse> => {
  try {
    const externalPlatforms = await getExternalPlatforms(params);
    return externalPlatforms;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};

export const asyncListActiveExternalPlatformsHelper = async (
  params: RequestParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<ListExternalActivePlatformsResponse> => {
  try {
    const externalPlatforms = await getExternalActivePlatforms(params);
    return externalPlatforms;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return error;
  }
};
