import React, { FC } from 'react';
import { CloseIconSVG, TicIconSVG } from '../../../assets/svgComponents';
import { regexCountryCodes } from '../../../utils/constants';

type Extras = {
  id: string;
  name: string;
};

interface AccessRowProps {
  className?: string;
  row: {
    id: number;
    Tenant: Extras;
    RelCompany: {
      taxCode: string;
      countryCode: string;
      name: string;
    };
  };
  handleClose?: () => void;
  handleAccept?: () => void;
}

export const AccessRowComponent: FC<AccessRowProps> = ({ row, className, handleClose, handleAccept }: AccessRowProps) => (
  <div className={`access-row-component row-container p-3 m-1 d-flex flex-wrap ${className}`}>
    <div className="col-9 col-sm-10 d-flex flex-wrap">
      <div className="col-md-4 col-sm-6 col-12">
        <p className="label font-xs">{`Cliente ${handleAccept ? 'que solicita el' : 'con'} acceso:`}</p>
        <p className="font-sm mb-2">{row.Tenant?.name}</p>
      </div>
      <div className="col-md-4 col-sm-6 col-12">
        <p className="label font-xs ">{`${handleAccept ? 'Tendrá' : 'Tiene'} acceso a:`}</p>
        <p className="font-sm mb-2">{row.RelCompany?.name ? row.RelCompany?.name : 'Todas las compañías'}</p>
      </div>
      {row.RelCompany && (
        <div className="col-md-4 col-sm-6 col-12">
          <p className="label font-xs">{regexCountryCodes[row.RelCompany?.countryCode]?.name} de compañía:</p>
          <p className="font-sm">{row.RelCompany?.taxCode}</p>
        </div>
      )}
    </div>
    {(handleClose || handleAccept) && (
      <div className="buttons-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end col-3 col-sm-2">
        {handleClose && <CloseIconSVG onClick={handleClose} className="icon-button-error" />}
        {handleAccept && <TicIconSVG onClick={handleAccept} className="icon-button-success ms-1" />}
      </div>
    )}
  </div>
);

export default AccessRowComponent;

AccessRowComponent.defaultProps = {
  className: '',
  handleClose: undefined,
  handleAccept: undefined,
};
