import React, { FC, useState } from 'react';
import { InputWrapperProps, SearchOptionProps } from '../../../utils/interfaces';
import { externalPlatformsBasicData } from '../../../utils/constants';

const SearchOptionComponent: FC<SearchOptionProps & InputWrapperProps> = ({
  option,
  containerClassName = '',
  checked: checkedValue,
  onCheck,
  onUncheck,
  style,
}) => {
  const [checked, setChecked] = useState(checkedValue);

  const handleClick = (): void => {
    if (checked) {
      setChecked(false);
      onUncheck(option);
    } else {
      setChecked(true);
      onCheck(option);
    }
  };

  const showPlatforms = (): string | undefined => {
    if (option?.platformExternals && option?.platformExternals?.length > 1) {
      const platformNames = option?.platformExternals.map((platform) => externalPlatformsBasicData(platform.platformExternalCode)?.name);
      const uniquePlatformNames = Array.from(new Set(platformNames));
      return uniquePlatformNames.join(', ');
    }
    return option?.platformExternals && externalPlatformsBasicData(option?.platformExternals[0].platformExternalCode)?.name;
  };

  return (
    <div onClick={handleClick} role="none" className={`search-option-component ${containerClassName}`} style={style}>
      <input type="checkbox" checked={checked} className="option-checkbox" readOnly />
      <div style={{ flexGrow: 0, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {option.optionLabel && (
          <label className="label option-nowrap font-xs" htmlFor={option.name}>
            {option.optionLabel}
          </label>
        )}
        <p id={option.name} role="none" className="option-nowrap">
          {option.name}
        </p>
        {option?.externalCode ? (
          <label className="label option-nowrap font-xs" htmlFor={option.name}>
            {option?.externalCode} - {showPlatforms()}
          </label>
        ) : null}
      </div>
      {option.pill && <p style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginLeft: 'auto' }}>{option.pill}</p>}
    </div>
  );
};

export default SearchOptionComponent;
