/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSummaryDeductionsByPe } from '../../services/deductionsService';
import logger from '../../services/loggerService';
import { SummaryDeduction, SummaryDeductionsByPeResponse } from '../../utils/interfaces';
import { FilterParams } from '../../utils/types';

interface TaxesState {
  summaryDeductions: Array<SummaryDeduction>;
  loadingSummaryDeduction: 'idle' | 'pending' | 'succeeded' | 'failed';
  pages: number;
  count: number;
}

interface IdAndParams {
  id: number;
  params?: FilterParams;
}

export const asyncSummaryDeductionsByPe = createAsyncThunk<SummaryDeductionsByPeResponse, IdAndParams>(
  'summary-deductions',
  async ({ id, params }) => {
    const { data: response } = await getSummaryDeductionsByPe(id, params || {});
    return response;
  },
);

const initialState: TaxesState = {
  summaryDeductions: [],
  loadingSummaryDeduction: 'idle',
  pages: 1,
  count: 0,
};

export const deductionsSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncSummaryDeductionsByPe.pending, (state, _action) => {
      state.loadingSummaryDeduction = 'pending';
    });
    builder.addCase(asyncSummaryDeductionsByPe.rejected, (state, action) => {
      state.loadingSummaryDeduction = 'failed';
      logger.error('asyncListTaxTypesClient.error', action.error);
    });
    builder.addCase(asyncSummaryDeductionsByPe.fulfilled, (state, action) => {
      state.loadingSummaryDeduction = 'succeeded';
      state.summaryDeductions = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.count = action.payload.meta.count;
    });
  },
});

export default deductionsSlice.reducer;
