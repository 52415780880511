import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import moment from 'moment';
import useQueryParams from '../../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getTrialStatus, paramsToObject } from '../../../utils/helper';
import { LoadingStateType, SettlementWithCollections } from '../../../utils/interfaces';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';
import LoaderScreen from '../../LoaderScreen';
import PaginationWithCount from '../../PaginationWithCount';
import SettlementRow from './SettlementRow';
import errorHandlerHelper from '../../../utils/errorHandler';

type SettlementsProps = {
  settlementsArray?: SettlementWithCollections[] | null;
  handlePaginationCallBack?: ((searchParams: Record<string, string>) => void) | null;
  businessEntityId?: string | null;
};

const SettlementsComponent: FC<SettlementsProps> = ({ settlementsArray, handlePaginationCallBack, businessEntityId }: SettlementsProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const page = useQueryParams('page') || '1';
  const { settlementsList, loadingSettlements, loadingSettlementsClient, pages, total } = useAppSelector((state) => state.settlement);
  const runWalkthrough = window.localStorage.getItem('walkthrough');
  const [expiredTrialMessage, setExpiredTrialMessage] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const queryObject = paramsToObject(queryParams);

  const diffBetweenDatesNotHigherThan62 = (init: string, end: string): boolean => {
    if (!end) return false;
    if (!init) return false;
    return moment(init).add(62, 'day') >= moment(end);
  };
  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialExpired } = cashTier;
        const tierCancelled = cashTier?.Tier?.name?.toLowerCase().includes('cancelled');
        if (trialExpired || tierCancelled) {
          setExpiredTrialMessage('Tu período de prueba ha expirado. Suscribite para poder seguir viendo tu información.');
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (handlePaginationCallBack) return handlePaginationCallBack(searchParams);
    return navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  const getSettlementList = () => {
    if (loadingSettlements === LoadingStateType.PENDING || loadingSettlementsClient === LoadingStateType.PENDING) {
      return <LoaderScreen />;
    }
    if (loadingSettlements === LoadingStateType.SUCCEDED || loadingSettlementsClient === LoadingStateType.SUCCEDED) {
      return settlementsList?.length || settlementsArray?.length ? (
        <>
          <div style={{ overflow: !runWalkthrough ? 'hidden' : 'visible' }} className="settlements-component">
            {settlementsArray?.length
              ? settlementsArray?.map((row: SettlementWithCollections) => (
                  <SettlementRow key={row.id} settlement={row} businessEntityId={businessEntityId} />
                ))
              : null}
          </div>
          <PaginationWithCount
            page={page}
            handlePagination={handlePagination}
            pageSize={25}
            pages={pages}
            length={settlementsArray?.length || settlementsList?.length || 0}
            loading={loadingSettlements}
            total={total}
          />
        </>
      ) : null;
    }
    if (!settlementsList?.length || !settlementsArray?.length) {
      return <div>No tiene liquidaciones para mostrar</div>;
    }
    return null;
  };

  const showMessage = () => {
    if (
      expiredTrialMessage === '' &&
      (loadingSettlements === LoadingStateType.FAILED || loadingSettlementsClient === LoadingStateType.FAILED)
    ) {
      return (
        <div className="container-input background-yellow-30 m-3">
          <div className="notification warning">
            <div className="notification-header">
              <BsFillExclamationTriangleFill />
            </div>
            <div className="notification-content">
              <div className="font-xs">
                {diffBetweenDatesNotHigherThan62(queryObject.startDate, queryObject.endDate)
                  ? 'Estamos teniendo dificultades para mostrarte la información solicitada. Por favor, volvé a intentar en unos minutos.'
                  : '¡Tu consulta es muy grande! Usá los filtros de fechas u otros para traer la información resumida. Seleccioná un período no mayor a 62 días.'}
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (expiredTrialMessage !== '') {
      return (
        <div className="container-input background-yellow-30 m-3">
          <div className="notification warning">
            <div className="notification-header">
              <BsFillExclamationTriangleFill />
            </div>
            <div className="notification-content">
              <div className="font-xs">{expiredTrialMessage}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {getSettlementList()}
      {showMessage()}
    </>
  );
};

SettlementsComponent.defaultProps = {
  settlementsArray: [],
  handlePaginationCallBack: null,
  businessEntityId: null,
};

export default SettlementsComponent;
