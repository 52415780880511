import React, { FC, useEffect, useState } from 'react';
import { PlatformRowComponent, Subtitle } from '../../components';
import CompanyCardComponent from '../../components/CompanyCard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList } from '../../redux/slices/modalSlice';
import { addPlatformsCredential } from '../../services/externalPlatformService';
import { platformExternalCodesTrimmed } from '../../utils/constants';
import errorHandlerHelper from '../../utils/errorHandler';
import { Company, ExternalActivePlatform } from '../../utils/interfaces';

interface TiendaNubeEndLinkingProps {
  handleSubmitButton: (tiendanubeCredentialId: number) => void;
  tiendaNubeList: ExternalActivePlatform[] | null;
  companiesList: Company[] | null;
}

const TiendaNubeEndLinking: FC<TiendaNubeEndLinkingProps> = ({ handleSubmitButton, tiendaNubeList, companiesList }) => {
  const [connectedCorrectlyTNList, setConnectedCorrectlyTNList] = useState<ExternalActivePlatform[]>([]);
  const [connectedUncorrectlyTNList, setConnectedUncorrectlyTNList] = useState<ExternalActivePlatform[]>([]);

  const { user } = useAppSelector((state) => state.userProfile);

  useEffect(() => {
    if (tiendaNubeList?.length) {
      const connectedCorrectlyTNListArray: React.SetStateAction<ExternalActivePlatform[]> = [];
      const connectedUncorrectlyTNListArray: React.SetStateAction<ExternalActivePlatform[]> = [];

      tiendaNubeList.forEach((tiendanubeListItem: ExternalActivePlatform) => {
        if (tiendanubeListItem.failureAuthCount === 0 && tiendanubeListItem.setupCompleted)
          connectedCorrectlyTNListArray.push(tiendanubeListItem);
        if (tiendanubeListItem.failureAuthCount !== 0 || !tiendanubeListItem.setupCompleted)
          connectedUncorrectlyTNListArray.push(tiendanubeListItem);
      });

      if (connectedCorrectlyTNListArray.length) setConnectedCorrectlyTNList(connectedCorrectlyTNListArray);
      if (connectedUncorrectlyTNListArray.length) setConnectedUncorrectlyTNList(connectedUncorrectlyTNListArray);
    }
  }, [tiendaNubeList]);

  const dispatch = useAppDispatch();
  const handleSaveCredentials = (company?: Company, tiendanubeCredentialId?: number) => {
    if (tiendanubeCredentialId) {
      return dispatch(
        addModalToList({
          body: `Asignar la cuenta de Tiendanube a la empresa ${company?.name}?`,
          cancelButton: { buttonText: 'No' },
          okButton: {
            onClick: async () => handleSubmitButton(tiendanubeCredentialId),
            buttonText: 'Si',
          },
        }),
      );
    }
    if (company) {
      return dispatch(
        addModalToList({
          body: `Asignar la cuenta de Tiendanube a la empresa ${company?.name}?`,
          cancelButton: { buttonText: 'No' },
          okButton: {
            onClick: async () => {
              addPlatformsCredential({
                username: '',
                password: '',
                platformExternalCode: `${platformExternalCodesTrimmed.TIENDANUBE}_${user?.Tenant.countryCode.toLowerCase()}`,
                companyId: company.id,
              })
                .then(({ document: response }) => {
                  handleSubmitButton(response.id);
                })
                .catch((error) => errorHandlerHelper(error, dispatch));
            },
            buttonText: 'Si',
          },
        }),
      );
    }
    return false;
  };

  return (
    <div className="m-4">
      <div className="row content align-items-center ">
        {/* eslint-disable-next-line no-nested-ternary */}
        {tiendaNubeList?.length && companiesList?.length ? (
          <div className="container-content col-12 flex-column mt-4">
            <p>Hacé click en alguna de tus cuentas previamente vinculadas de Tiendanube para asociarle tu cuenta actual:</p>
            {!!connectedCorrectlyTNList.length && (
              <>
                <p className="my-3">Estas son tu(s) empresa(s) que tienen conectado Tiendanube correctamente:</p>
                {connectedCorrectlyTNList.map((item: ExternalActivePlatform) => (
                  <div key={item.id} className="my-2">
                    <div className="ms-3">
                      <p className="mb-2">
                        Empresa:{' '}
                        <strong>{companiesList?.find((company: Company) => Number(company.id) === Number(item.companyId))?.name}</strong>
                      </p>
                      <PlatformRowComponent
                        onClick={() =>
                          handleSaveCredentials(
                            companiesList?.find((company: Company) => Number(company.id) === Number(item.companyId)),
                            item.id,
                          )
                        }
                        platform={item}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
            {!!connectedUncorrectlyTNList.length && (
              <>
                <p className="my-3">Estas son tu(s) empresa(s) que tienen conectado Tiendanube pero necesitan volver a conectarse:</p>
                {connectedUncorrectlyTNList.map((item: ExternalActivePlatform) => (
                  <div key={item.id} className="my-2">
                    <div className="ms-3">
                      <p className="mb-2">
                        Empresa:{' '}
                        <strong>{companiesList?.find((company: Company) => Number(company.id) === Number(item.companyId))?.name}</strong>
                      </p>
                      <PlatformRowComponent
                        onClick={() =>
                          handleSaveCredentials(
                            companiesList?.find((company: Company) => Number(company.id) === Number(item.companyId)),
                            item.id,
                          )
                        }
                        platform={item}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : tiendaNubeList || companiesList ? (
          <div className="container-content col-12 flex-column">
            <Subtitle>
              Parece que no tenés ninguna empresa con Tiendanube asociado. Elegí a la que quieras agregarle esta plataforma:
            </Subtitle>
            {companiesList?.length &&
              companiesList.map((company: Company) => (
                <CompanyCardComponent onClick={handleSaveCredentials} key={company.id} company={company} />
              ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TiendaNubeEndLinking;
