import React, { FC } from 'react';
import { TextItem } from '../../../utils/types';
import variables from '../../../scss/_exports.module.scss';

const SubtitleComponent: FC<TextItem> = ({ children, color = variables.black, weight = 'regular', className, size = 'lg' }: TextItem) => (
  <h2 style={{ color }} className={`subtitle-component font-${size} ${weight} ${className}`}>
    {children}
  </h2>
);

export default SubtitleComponent;
