import React, { FC } from 'react';
import { InputWrapperProps, SelectOptionProps } from '../../../utils/interfaces';

const SelectOptionComponent: FC<SelectOptionProps & InputWrapperProps> = ({
  option,
  containerClassName,
  onClick,
  style,
}: SelectOptionProps & InputWrapperProps) => (
  <div onClick={onClick} role="none" className="select-option-component" style={style}>
    {option?.image}
    {option.optionLabel && (
      <label className="label font-xs" htmlFor={option.name}>
        {option.optionLabel}
      </label>
    )}
    <p id={option.name} role="none" className={`${containerClassName && containerClassName}`} onClick={onClick}>
      {option.name}
    </p>
    <p style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>{option?.pill}</p>
  </div>
);

export default SelectOptionComponent;
