import React, { FC, ReactElement } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { PayloadAction } from '@reduxjs/toolkit';
import { ExternalActivePlatform } from '../../../utils/interfaces';
import { FormFieldType } from '../../../utils/types';
import { PasswordInputComponent, TextInputComponent } from '../../inputs';
import { useAppDispatch } from '../../../redux/hooks';
import { asyncAddExternalPlatform, asyncEditExternalPlatform } from '../../../redux/slices/externalPlatformSlice';
import errorHandlerHelper from '../../../utils/errorHandler';
import successImage from '../../../assets/images/success.png';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';

interface NaranjaCredentialsFormProps {
  selectedPlatform: string;
  handleDeletePlatform: () => void;
  currentValues: ExternalActivePlatform | undefined;
  companyId: string;
}

const NaranjaCredentialsFormSTA: FC<NaranjaCredentialsFormProps> = ({
  selectedPlatform,
  handleDeletePlatform,
  currentValues,
  companyId,
}: NaranjaCredentialsFormProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const validateForm = Yup.object().shape({
    codeAux: Yup.string().required('Ingresá un usuario válido.'),
    password: Yup.string().required('Tenés que escribir una contraseña.'),
  });

  const initialValues = {
    codeAux: currentValues?.codeAux || '',
    password: '',
  };

  const handleFormSTA = (values: typeof initialValues): Promise<PayloadAction<any>> => {
    if (id !== 'new') {
      return dispatch(
        asyncEditExternalPlatform({
          id: id || '',
          codeAux: values.codeAux,
          password: values.password,
          // companyId,
        }),
      );
    }
    return dispatch(
      asyncAddExternalPlatform({
        platformExternalCode: selectedPlatform,
        codeAux: values.codeAux,
        password: values.password,
        companyId,
      }),
    );
  };

  const handleSubmitSTA = (values: typeof initialValues): void => {
    if (values) {
      handleFormSTA(values)
        .then(async () => {
          dispatch(
            addModalToList({
              body: 'Este proceso puede tardar unos momentos. Recibirás una notificación cuando termine.',
              pictureUrl: successImage,
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch(removeModalFromList());
                  navigate(-1);
                },
              },
            }),
          );
        })
        .catch((error) => {
          errorHandlerHelper(error, dispatch);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validateForm}
        onSubmit={(values): void => {
          handleSubmitSTA(values);
        }}
      >
        {({ errors }): ReactElement => (
          <Form className="row mb-5 d-flex flex-column">
            <div className="container-input col-12 col-xl-6">
              <Field name="codeAux">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.codeAux}
                    placeholder="Ingresá el usuario"
                    label="Usuario"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="container-input col-12 col-xl-6">
              <Field name="password">
                {({ field }: FormFieldType): ReactElement => (
                  <PasswordInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.password}
                    placeholder="Ingresá la contraseña"
                    label="Contraseña"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="col-12">
              <PlatformCredentialsFooterButtons handleDeletePlatform={handleDeletePlatform} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NaranjaCredentialsFormSTA;
