import * as React from 'react';
import { SVGProps } from '../../utils/types';

const DropdownArrowIcon = ({ className = 'color-blue', width = 11, height = 17 }: SVGProps): React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M10.246.29a1 1 0 00-1.41 0l-3.59 3.54L1.706.29a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0l4.29-4.24a1 1 0 000-1.42z" />
  </svg>
);

export default DropdownArrowIcon;
