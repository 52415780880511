import { Navigate } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { ActivityCode } from '../utils/interfaces';

const ProtectedComponent: React.FC = ({ children }) => {
  const { activity } = useAppSelector((state) => state.userProfile);
  const { user } = useAppSelector((state) => state.userProfile);
  const isAccountant = activity === ActivityCode.CONTADOR;
  const currentUrl = window.location.href;
  const urlsToCheck = ['tiendanube/auth', 'mercadopago/auth'];
  const externalLinkingUrl = urlsToCheck.some((url) => currentUrl.includes(url));

  return user && !externalLinkingUrl ? <Navigate to={isAccountant ? '/clients' : '/'} /> : <>{children}</>;
};

export default ProtectedComponent;
