import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, ReactElement, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FaUserCheck, FaUserMinus } from 'react-icons/fa';
import { CloseIconSVG } from '../../assets/svgComponents';
import {
  FormButtonComponent,
  Title,
  TextInputComponent,
  LoaderScreen,
  CheckBoxRowComponent,
  Paragraph,
  SwitchInputComponent,
  Subtitle,
  PaginationWithCount,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import successImage from '../../assets/images/success.png';
import { userAdd, userUpdate, userEnabled, userDelete, getUsers } from '../../services/userService';
import { Company, User } from '../../utils/interfaces';
import { FormFieldType } from '../../utils/types';
import logger from '../../services/loggerService';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import errorHandlerHelper from '../../utils/errorHandler';
import useQueryParams from '../../hooks/useQueryParams';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { asyncListCompanies } from '../../redux/slices/companySlice';

type UserCompany = {
  Company: Company[];
  companyId: string;
  userId: string;
};
type UserFormData = {
  email: string;
  username: string;
  fullName: string; // TODO this field is asked by "backend" only in POST, needs refactor in both sides
  firstName: string; // TODO this field is asked by "backend" only in PATCH,  needs refactor in both sides
  sureName?: string;
  companys: string[];
  enabled?: boolean | null;
};

const UserPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { companiesList, pages, total, loadingCompanies } = useAppSelector((state) => state.company);
  const companyListSorted = [...companiesList]?.sort((company) => (company.enabled ? -1 : 1));
  const [userData, setUserData] = useState<(User & UserCompany) | null>(null);
  const [userBusinessList, setUserBusinessList] = useState<UserCompany[]>([]);
  const { id: paramId } = useParams();
  const [loading, setLoading] = useState(false);
  const [enabledUser, setEnabledUser] = useState(true);
  const page = useQueryParams('page') || '1';
  const topRef = useRef<HTMLDivElement>(null);

  const handleEffect = async (): Promise<void> => {
    if (paramId !== 'new') {
      try {
        setLoading(true);
        const { data: res } = await getUsers({ pageSize: '1000' });
        const singleUserData = res.data.find((user: User) => user.id === paramId);
        setUserData(
          singleUserData.deletedAt
            ? {
                ...singleUserData,
                enabled: false,
              }
            : {
                ...singleUserData,
                enabled: true,
              },
        );
        setUserBusinessList(singleUserData.UserCompany);
        setEnabledUser(singleUserData.deletedAt === null ?? true);
        setLoading(false);
      } catch (error) {
        logger.error('getUsers.error', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({ ...queryObject });
    dispatch(asyncListCompanies(filterableObject));
  }, [location]);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>): Promise<void> => {
    // TODO look => values here is doing wrong type validation
    // TODO look => actions here is doing wrong type validation, requires the same type as the Schema.
    // when fields get well normalized this(UserFormData)may work well

    const data: UserFormData = {
      email: values.email,
      username: values.email,
      fullName: `${values.name} ${values.sureName}`,
      sureName: values.sureName,
      firstName: values.name,
      companys: userBusinessList.map(({ companyId }) => companyId),
      enabled: enabledUser,
    };
    try {
      if (paramId === 'new') {
        const response = await userAdd(data);
        logger.info('addUser.success', response);
        dispatch(
          addModalToList({
            body: 'Se guardó el usuario correctamente.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
        actions.resetForm();
        goBack();
      } else if (!enabledUser) {
        const response = await userDelete(userData?.id ?? '');
        logger.info('userDelete.success', response);
        dispatch(
          addModalToList({
            body: 'Se deshabilitó el usuario correctamente.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
        actions.resetForm();
        goBack();
      } else {
        if (!userData?.enabled) {
          await userEnabled(userData?.id || '');
        }
        const response = await userUpdate(userData?.id || '', data);
        logger.info('userUpdate.success', response);
        dispatch(
          addModalToList({
            body: 'Se modificó el usuario correctamente.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
        actions.resetForm();
        goBack();
      }
    } catch (ex) {
      errorHandlerHelper(ex, dispatch);
      logger.error('addUser.error', ex);
    }
  };
  const validationScheme = Yup.object().shape({
    name: Yup.string().required('Este campo es requerido'),
    email: Yup.string().email('Ingresá un email válido').required('Este campo es requerido'),
  });

  const goBack = (): void => {
    navigate(-1);
  };

  const goUsers = (): void => {
    navigate('/users');
  };

  useEffect(() => {
    handleEffect();
  }, []);

  const initialValues = {
    name: userData?.firstName || '',
    sureName: userData?.sureName || '',
    email: userData?.email || '',
    businessList: userBusinessList,
    enabled: userData?.enabled,
  };

  const existsInUserBusinessList = (company: Company): boolean =>
    userBusinessList?.some((item: UserCompany) => item.companyId === company.id);

  const userBusinessListChanged = (company: Company) => {
    if (existsInUserBusinessList(company)) {
      setUserBusinessList(userBusinessList.filter((business: UserCompany) => business.companyId !== company.id));
    } else {
      setUserBusinessList([
        {
          Company: [company],
          companyId: company.id,
          userId: userData?.id ?? '',
        },
        ...userBusinessList,
      ]);
    }
  };
  const handlePagination = (searchParams: Record<string, string>): void => {
    if (topRef.current) {
      topRef.current.focus({ preventScroll: true });
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };
  if (loading) return <LoaderScreen />;
  return (
    <div className="company-page w-100 h-100">
      <div className="mt-5 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-9">
        <div>
          <Title>{paramId === 'new' ? 'Nuevo Usuario' : `${userData?.firstName} ${userData?.sureName}`}</Title>
        </div>
        <CloseIconSVG className="close-icon color-blue" height={40} width={40} onClick={goUsers} />
      </div>
      <Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={handleSubmit}>
        {({ values, isSubmitting, touched, errors }): ReactElement => (
          <div className="col-12 col-md-10 col-lg-9 d-flex flex-column">
            <Form className="d-flex flex-column flex-wrap mt-4 ">
              <Field name="name">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    errorMessage={touched.name && errors.name}
                    containerClassName="input w-100"
                    label="Nombre"
                    placeholder="Nombre del usuario"
                    disabled={!enabledUser}
                    {...field}
                    required
                  />
                )}
              </Field>

              <Field name="sureName">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    errorMessage={touched.sureName && errors.sureName}
                    containerClassName="input w-100"
                    label="Apellido"
                    placeholder="Apellido del usuario"
                    disabled={!enabledUser}
                    {...field}
                    required
                  />
                )}
              </Field>

              <Field name="email" type="email">
                {({ field }: FormFieldType): React.ReactNode => (
                  <TextInputComponent
                    errorMessage={touched.email && errors.email}
                    containerClassName="input w-100"
                    label="Email"
                    required
                    placeholder="Email del usuario"
                    disabled={!enabledUser}
                    {...field}
                    value={values.email ?? ''}
                  />
                )}
              </Field>
              {/*  TODO this needs a new endpoint (delete user) actually deletes the user from user_company: */}
              {/* The spected behaviour is disable the user */}
              {paramId !== 'new' && (
                <div className="w-100 mb-4 mt-3">
                  <Field type="checkbox" name="enabled">
                    {({ field }: FormFieldType): ReactElement => (
                      <div className="w-100 row align-items-center d-flex">
                        <div className="col col-lg-4">
                          <div className="align-items-center d-flex">
                            {!enabledUser ? (
                              <FaUserMinus size={25} color="red" className="m-1" />
                            ) : (
                              <FaUserCheck size={25} color="blue" className="m-1" />
                            )}
                            <Paragraph className="text-truncate" color={`${enabledUser ? 'blue' : 'red'}`} weight="bold">
                              {`Usuario ${enabledUser ? 'activo' : 'inactivo'}`}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="col d-flex justify-content-end">
                          <SwitchInputComponent
                            {...field}
                            checked={!enabledUser}
                            onChange={() => {
                              setEnabledUser(!enabledUser);
                              // setFormUpdated(!formUpdated);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              )}
              {enabledUser && (
                <div ref={topRef} className=" d-flex flex-column justify-content-between col-12 col-md-10 col-lg-9">
                  <Subtitle size="sm" weight="bold">
                    Empresas a las que tiene acceso:
                  </Subtitle>
                  {loadingCompanies === 'pending' ? (
                    <LoaderScreen />
                  ) : (
                    companyListSorted.length &&
                    companyListSorted.map((company) => (
                      <Field type="checkbox" name="businessList" key={company.id} value={company.name}>
                        {({ field }: FormFieldType): ReactElement => (
                          <CheckBoxRowComponent
                            checked={userBusinessList.length > 0 ? existsInUserBusinessList(company) : false}
                            onChange={() => userBusinessListChanged(company)}
                            containerClassName="input w-100"
                            value={company.id.trim()}
                          >
                            {field.value}
                          </CheckBoxRowComponent>
                        )}
                      </Field>
                    ))
                  )}
                  <PaginationWithCount
                    page={page}
                    handlePagination={handlePagination}
                    pageSize={25}
                    pages={pages}
                    length={companiesList?.length || 0}
                    loading={loadingCompanies}
                    total={total}
                  />
                </div>
              )}
              <div>
                <FormButtonComponent type="submit" className="mt-4" disabled={isSubmitting}>
                  {`${paramId !== 'new' ? 'Guardar' : 'Crear Usuario'} `}
                </FormButtonComponent>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default UserPage;
