import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../textComponents';
import variables from '../../scss/_exports.module.scss';
import { Branch, BranchRow } from '../../utils/interfaces';
import { getNameInitials } from '../../utils/stringUtils';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import checkFilled from '../../assets/images/iconsMenu/checkmark_circle.png';
import checkEmpty from '../../assets/images/iconsMenu/checkEmpty_circle.png';
import unlink from '../../assets/images/iconsMenu/unlink.png';
import edit from '../../assets/images/iconsMenu/edit.png';

const BranchCardComponent: FC<BranchRow> = ({ branch, selectedBranches, selectable, setSelectedBranch, showUnlinkIcon, isAdmin }) => {
  const navigate = useNavigate();
  const isSelected = selectedBranches?.find((branchSelected: Branch) => branchSelected?.id === branch?.id);

  const platformListIconsBranch = (itemBranch: Branch): React.ReactElement => {
    const filteredArray = itemBranch?.relatedPlatformExternalCodes?.filter(
      (ele, pos) => itemBranch?.relatedPlatformExternalCodes?.indexOf(ele) === pos,
    );

    return (
      <div>
        {filteredArray?.length < 4 ? (
          <div className="d-flex flex-row">
            {filteredArray?.map((icon: string) =>
              externalPlatformIcon({
                code: icon,
                className: 'external-platform-mini-logo',
              }),
            )}
          </div>
        ) : null}
        {filteredArray?.length >= 4 ? (
          <div className="d-flex flex-row">
            {externalPlatformIcon({
              code: filteredArray[0],
              className: 'external-platform-mini-logo me-2',
            })}
            {externalPlatformIcon({
              code: filteredArray[1],
              className: 'external-platform-mini-logo me-5',
            })}
            <div className="badge-branches">
              <p className="badge-text">+{filteredArray?.length - 2}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleOpenBranchListPage = (value: Branch[]): void => {
    navigate(`/ungroup-branch`, { state: { relatedBranches: value, mainBranch: branch, listBranchesAfterUnmerge: true } });
  };

  const handleOpenBranchEditModal = (): void => {
    navigate(`/edit-branch`, { state: { branch } });
  };
  const { name } = branch;

  return (
    <div
      className="branch-component row-container mb-1"
      onClick={(): void => {
        setSelectedBranch(branch);
      }}
      role="none"
    >
      <div className="initials-name-container mx-sm-3 mx-lg-4 col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <Paragraph color={variables.colorBlue} size="lg" data-tip="Siglas de tu sucursal">
          {getNameInitials(name).toUpperCase()}
        </Paragraph>
      </div>
      <div className="flex-grow-1 col-xs-5 col-sm-4 col-md-4 col-lg-4">
        <div>
          <Paragraph className="text-break" color={variables.colorBlue} weight="bold" data-tip="Nombre">
            {name}
          </Paragraph>
          <div>
            {!branch.relatedBranches?.length ? <p className="numberBranchColor">{branch?.PlatformExternals[0]?.externalCode}</p> : null}
          </div>
        </div>
      </div>
      <div className="flex-grow-1 col-xs-5 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-end" data-tip="Plataforma">
        <div>{platformListIconsBranch(branch)}</div>
      </div>
      <div>
        {selectable &&
          !branch?.relatedBranches?.length &&
          (isSelected ? (
            <img src={checkFilled} alt="Check" className="external-platform-mini-logo ms-2" />
          ) : (
            <img src={checkEmpty} alt="Check" className="external-platform-mini-logo ms-2" />
          ))}
      </div>
      {isAdmin ? (
        <>
          <div>
            {showUnlinkIcon && branch?.relatedBranches?.length ? (
              <div
                className="ms-2"
                onClick={(): void => handleOpenBranchListPage(branch?.relatedBranches)}
                role="none"
                data-tip="Suc. Agrupadas"
              >
                <img src={unlink} alt="Check" className="external-platform-mini-logo ms-2" />
              </div>
            ) : null}
          </div>
          <div>
            <div className="ms-2" onClick={(): void => handleOpenBranchEditModal()} role="none" data-tip="Editar Sucursal">
              <img src={edit} alt="Check" className="external-platform-mini-logo ms-2" />
            </div>
          </div>
        </>
      ) : null}
      <ReactTooltip />
    </div>
  );
};

export default BranchCardComponent;
