import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionButtonComponent } from '../../../buttons';
import { useAppDispatch } from '../../../../redux/hooks';
import { asyncAddExternalPlatform } from '../../../../redux/slices/externalPlatformSlice';
import errorHandlerHelper from '../../../../utils/errorHandler';
import LoaderScreen from '../../../LoaderScreen';
import { formatAccordingToPlatform } from '../../../../utils/constants';

interface EmailTypePlatformFormProps {
  selectedPlatform: string;
  companyId: string;
  handleDeletePlatform: () => void;
}

const EmailTypePlatformForm: FC<EmailTypePlatformFormProps> = ({ handleDeletePlatform, companyId, selectedPlatform }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pecId, setPecId] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const createEmptyPec = (): void => {
    setLoading(true);
    if (pecId === '') {
      dispatch(
        asyncAddExternalPlatform({
          platformExternalCode: selectedPlatform,
          username: '',
          password: '',
          companyId,
        }),
      )
        .then(async (res: any) => {
          setLoading(false);
          setPecId(res?.payload?.document?.id);
        })
        .catch((error) => {
          errorHandlerHelper(error, dispatch);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (id !== 'new' || pecId !== '') {
      navigate(`/external-platform/${pecId}`, { state: { company: companyId } });
    }
  }, [pecId]);

  const platform: string = selectedPlatform || '';
  const typeFormat = formatAccordingToPlatform(platform);

  return loading ? (
    <LoaderScreen label="Iniciando vinculación" />
  ) : (
    <>
      <div className="container-input background-yellow-30 my-3 w-75">
        <div className="notification warning">
          <div className="notification-content">
            <div className="font-xs mx-4">Asegúrate que el contenido y formato de los archivos del mail es soportado por nubceo.</div>
            <p className="font-xs mx-4">
              Formato admitido: <b>{typeFormat}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-3 w-100 d-flex justify-content-start align-items-center flex-wrap">
        <ActionButtonComponent
          text={id !== 'new' || pecId !== '' ? 'Vinculación Iniciada' : 'Iniciar Vinculación'}
          onClick={(): void => createEmptyPec()}
          removeIcon
          inactive={id !== 'new' || pecId !== ''}
        />
        {id !== 'new' ? (
          <ActionButtonComponent containerClassName="ms-3" text="Eliminar" onClick={(): void => handleDeletePlatform()} removeIcon />
        ) : null}
      </div>
    </>
  );
};

export default EmailTypePlatformForm;
