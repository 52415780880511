import React, { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { NavComponent, TopBarComponent, WhatsAppFloatingButton } from '../../components';
import { MessagesContainer, NotificationsContainer } from '../../containers';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { asyncListCompanies } from '../../redux/slices/companySlice';
import { asyncListActiveExternalPlatforms, asyncListUsedExternalPlatforms } from '../../redux/slices/externalPlatformSlice';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { storageHelper } from '../../utils/helper';
import { asyncListClients } from '../../redux/slices/clientsSlice';
import errorImage from '../../assets/images/error.png';
import { ExternalPlatformType, StorageVars } from '../../utils/types';
import { ENV_PAGE_SIZE_COMPANIES } from '../../config';
import { externalPlatformsBasicData } from '../../utils/constants';

const Layout: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { externalActivePlatformList } = useAppSelector((state) => state.externalPlatform);
  const { companiesList } = useAppSelector((state) => state.company);
  const omitedCompleteCredentials = localStorage.getItem('omitedCompleteCredentials');
  const typesOfErpAndEcommerce: string[] | null = [ExternalPlatformType.erp, ExternalPlatformType.ecommerce];
  const walkthrough = storageHelper('getItem', 'walkthrough');

  useEffect(() => {
    // First render
    dispatch<void>(asyncListClients({}));
    dispatch<void>(asyncListCompanies({ pageSize: ENV_PAGE_SIZE_COMPANIES }));
    dispatch<void>(asyncListActiveExternalPlatforms());
    dispatch<void>(asyncListUsedExternalPlatforms());
  }, []);

  useEffect(() => {
    const completePECStorage = storageHelper('getItem', 'completePECCredentials');
    const redirectTiendanube = localStorage.getItem(StorageVars.redirectFromTiendanube);
    const companiesEnabled = companiesList.map((company) => company.enabled && Number(company.id));
    const platformsWithoutUsername = externalActivePlatformList.filter(
      (platform) => (!platform.username || platform.username === '') && companiesEnabled.includes(platform.companyId),
    );
    const platformFromTiendaNube = platformsWithoutUsername.filter((platform) => platform?.platformExternalCode === 'tiendanube_ar');
    if (redirectTiendanube && walkthrough) {
      localStorage.removeItem(StorageVars.redirectFromTiendanube);
      navigate(`/external-platform/${platformFromTiendaNube[0]?.id}`);
    }
    if (walkthrough && ((completePECStorage && moment().diff(completePECStorage, 'hours') >= 2) || !completePECStorage)) {
      if (externalActivePlatformList?.length && companiesList.length) {
        if (companiesEnabled) {
          const MessageModal = () => (
            <p>
              No te olvides de cargar las claves de <b>{platformsWithoutUsername[0].PlatformExternal.name}</b>. Podés hacerlo en un solo
              paso haciendo click acá.
            </p>
          );
          if (platformsWithoutUsername.length && !omitedCompleteCredentials) {
            const companyFiltered = companiesList.filter(
              (company) => platformsWithoutUsername[0]?.companyId.toString() === company?.id.toString(),
            );
            dispatch(
              addModalToList({
                body: <MessageModal />,
                okButton: {
                  buttonText: `Ir a editar ${
                    !typesOfErpAndEcommerce.includes(
                      externalPlatformsBasicData(platformsWithoutUsername[0].platformExternalCode).type || '',
                    )
                      ? 'medio de cobro'
                      : 'sistema de gestión'
                  }`,
                  onClick: async () => {
                    if (companyFiltered[0]?.taxCode) {
                      navigate(`/external-platform/${platformsWithoutUsername[0].id}`);
                    } else {
                      navigate(`/companies/${companyFiltered[0]?.id}`, {
                        state: {
                          completeCompanyDetails: true,
                          platformsWithoutUsername: platformsWithoutUsername[0]?.id,
                        },
                      });
                    }
                    removeModalFromList();
                    storageHelper('setItem', 'completePECCredentials', moment().format());
                  },
                },
                pictureUrl: errorImage,
                cancelButton: {
                  buttonText: 'Ahora no',
                },
                handleCloseButton: () => storageHelper('setItem', 'completePECCredentials', moment().format()),
              }),
            );
            localStorage.setItem('omitedCompleteCredentials', moment().format('DD/MM/YYYY, h:mm'));
          }
        }
      }
    }
  }, [pathname, externalActivePlatformList, companiesList]);

  useEffect(() => {
    const showMessageFiservStorage = localStorage.getItem(StorageVars.showMessageFiservStorage);
    const fiservLinkingNotFtp = externalActivePlatformList.find(
      (platform) => platform?.platformExternalCode.includes('firstdata') && platform?.codeAux !== 'ftp',
    );
    const MessageModal = (): React.ReactElement => (
      <>
        <p className="center font-lg text-modal-red">
          <b>¡IMPORTANTE!</b>
        </p>
        <p>
          Durante el mes de agosto el portal de <b>Fiserv</b> se dará de baja,
        </p>
        <p> de no tener acceso por medio de FTP, no podremos incorporar la información a nubceo.</p>
      </>
    );
    if (
      fiservLinkingNotFtp &&
      walkthrough &&
      ((showMessageFiservStorage && moment().diff(showMessageFiservStorage, 'hours') >= 2) || !showMessageFiservStorage)
    ) {
      localStorage.setItem(StorageVars.showMessageFiservStorage, moment().format('DD/MM/YYYY, h:mm'));
      dispatch(
        addModalToList({
          body: <MessageModal />,
          okButton: {
            buttonText: 'Leer más',
            onClick: async () => {
              navigate('/info-fiserv');
              removeModalFromList();
            },
          },
          pictureUrl: errorImage,
          cancelButton: {
            buttonText: 'Ahora no',
          },
          handleCloseButton: () => {
            removeModalFromList();
          },
        }),
      );
    }
  }, [externalActivePlatformList]);

  return (
    <div className="layout-page container-fluid justify-content-center p-0 d-flex">
      <NavComponent />
      <div className="d-flex flex-column col layout-content">
        <div className="me-3">
          <TopBarComponent />
        </div>
        <NotificationsContainer />
        <div className="px-4">
          <Outlet />
        </div>
      </div>
      <MessagesContainer />
      <WhatsAppFloatingButton />
    </div>
  );
};

export default Layout;
