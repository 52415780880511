import { initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken, isSupported } from 'firebase/messaging';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { deleteFcmToken, updateFcmToken } from '../services/firebaseService';
import { User } from './interfaces';
import { ENV_FIREBASE_CONFIG, ENV_VAPID_KEY } from '../config';
import errorHandlerHelper from './errorHandler';
import logger from '../services/loggerService';
import { getOS } from './helper';
import { VERSION_APP } from './constants';

let messaging: Messaging | null = null;

const initializeFirebase = async () => {
  if (await isSupported()) {
    const app = initializeApp(ENV_FIREBASE_CONFIG);
    messaging = getMessaging(app);
  }
};

initializeFirebase();

export const setUserToken = async (token: string): Promise<User> => {
  const tokenData = {
    fcmToken: token,
    platformCode: 'cash',
    osName: getOS(),
    appVersion: VERSION_APP,
    appClientMode: 'web',
  };
  const { data: response, status } = await updateFcmToken(tokenData);
  if (status === 200) {
    logger.info('[FCM TOKEN]', 'Has been updated');
  }
  return response?.data;
};

export const deleteUserToken = async (): Promise<void> => {
  const token = localStorage.getItem('fcmToken');
  if (!token) throw new Error('Token was not found on localStorage');
  const tokenData = {
    platformCode: 'cash',
    osName: getOS(),
    appClientMode: 'web',
    appVersion: VERSION_APP,
    fcmToken: token,
  };
  const { status } = await deleteFcmToken(tokenData);
  if (status === 200) {
    logger.info('[FCM TOKEN]', 'Has been deleted');
  }
};

export const registerUserToken = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
  if (await isSupported()) {
    setTimeout(async () => {
      try {
        const { vapidKey } = ENV_VAPID_KEY;
        if (!vapidKey) {
          throw new Error('VAPID key is not defined');
        }
        if (messaging && vapidKey) {
          const token = await getToken(messaging, { vapidKey });
          localStorage.setItem('fcmToken', token);
          await setUserToken(token);
        } else {
          throw new Error('Firebase messaging is not initialized');
        }
      } catch (error) {
        errorHandlerHelper(error, dispatch);
      }
    }, 6000);
  }
};
