import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import LoaderScreen from '../../components/LoaderScreen';
import { AccessRowComponent, ActionButtonComponent, CompanyCard, TextInputComponent } from '../../components';
import { Subtitle, Title } from '../../components/textComponents';
import { Client, Company } from '../../utils/interfaces';
import { PlusSVG } from '../../assets/svgComponents';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  asyncApproveAccessClientRequest,
  asyncDeleteAccessClient,
  asyncDeleteAccessClientRequest,
  asyncListClientsAccessHelper,
  asyncListClientsAccessRequestHelper,
  getPermissions,
  paramsToObject,
  parseFilters,
} from '../../utils/helper';
import { asyncListCompanies } from '../../redux/slices/companySlice';
import { ENV_PAGE_SIZE_COMPANIES } from '../../config';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';

type Extras = {
  id: string;
  name: string;
};

interface ClientsResponse extends Client {
  id: number;
  Tenant: Extras;
}

const MyCompaniesPage: FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleOnClickCreateCompany = (): void => {
    // segmentTrack(EVENTS.COMPANY.CREATE.CLICK);
    navigate('/companies/new');
  };
  const { companiesList, loadingCompanies: loading } = useAppSelector((state) => state.company);
  const [listAccess, setListAccess] = useState<Array<ClientsResponse> | null>(null);
  const [listAccessRequest, setListAccessRequest] = useState<Array<ClientsResponse> | null>(null);
  const companyListSorted = [...companiesList]?.sort((company) => (company.enabled ? -1 : 1));
  const [canView, setCanView] = useState(false);
  const topRef = useRef<HTMLDivElement>(null);
  const [filtered, setFiltered] = useState<Array<Company> | null>();
  const [inputSearchValue, setInputSearchValue] = useState<string>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({
      ...queryObject,
      pageSize: `${ENV_PAGE_SIZE_COMPANIES}`,
    });
    dispatch(asyncListCompanies(filterableObject));
  }, [location]);

  const listAccessHandler = (): void => {
    asyncListClientsAccessHelper(dispatch).then((response: Array<ClientsResponse> | any) => {
      if (response.response?.status) {
        return false;
      }
      return setListAccess(response);
    });
  };

  const showTypeOperation = (): ReactElement => {
    let typeOperationShown = '';
    switch (user?.Tenant?.operationType) {
      case 'online':
        typeOperationShown = 'Venta solo por internet.';
        break;
      case 'store':
        typeOperationShown = 'Tiene comercio en la calle.';
        break;
      case 'no_store':
        typeOperationShown = 'No tiene comercio.';
        break;
      default:
        break;
    }
    return <Subtitle>Tipo de operación: {typeOperationShown}</Subtitle>;
  };

  useEffect(() => {
    getPermissions()
      .then((res) => {
        setCanView(res.includes('user_list'));
      })
      .catch((err) => console.log(err));
    asyncListClientsAccessRequestHelper(dispatch).then((response: Array<ClientsResponse> | any) => {
      if (response.response?.status) {
        return false;
      }
      return setListAccessRequest(response);
    });
    listAccessHandler();
  }, []);

  const handleDeleteAccess = (id: number | string, isRequest: boolean): void => {
    // eslint-disable-next-line no-restricted-globals
    const bool = isRequest ? confirm('¿Querés cancelar el acceso?') : confirm('¿Querés revocar el acceso?');
    if (bool) {
      if (isRequest) {
        asyncDeleteAccessClientRequest(id, dispatch).then((response: Client | any) => {
          if (response !== 1) {
            return false;
          }
          const deleteFromList = listAccessRequest ? listAccessRequest.filter((item) => item.id !== id) : null;
          return setListAccessRequest(deleteFromList);
        });
      } else {
        asyncDeleteAccessClient(id, dispatch).then((response: Client | any) => {
          if (response.response?.status) {
            return false;
          }
          const deleteFromList = listAccess ? listAccess.filter((item) => item.id !== response.id) : null;
          return setListAccess(deleteFromList);
        });
      }
    }
  };

  const handleApproveAccess = (id: number): void => {
    // eslint-disable-next-line no-restricted-globals
    const bool = confirm('¿Querés aceptar la solicitud de acceso?');
    if (bool) {
      asyncApproveAccessClientRequest(id, dispatch).then((response: Client | any) => {
        if (response.response?.status) {
          return false;
        }
        const deleteFromList = listAccessRequest ? listAccessRequest.filter((item) => item.id !== id) : null;
        setListAccessRequest(deleteFromList);
        return listAccessHandler();
      });
    }
  };

  const showTextAccess = !listAccessRequest?.length && !listAccess?.length;

  const searchCompany = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e?.target.value;
    setInputSearchValue(inputValue);
    const filteredCompanies = companyListSorted.filter((company) => company.name.toLowerCase().includes(inputValue.toLowerCase()));
    setFiltered(filteredCompanies);
  };

  return (
    <div className="my-companies-page mb-4">
      <div tabIndex={-1} ref={topRef} style={{ width: 0, height: 0 }} />
      <div className="my-4 w-100 d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-column">
          <Title>Mis empresas</Title>
          {user?.Tenant?.operationType && showTypeOperation()}
        </div>
        <ActionButtonComponent
          text="Nueva Empresa"
          onClick={handleOnClickCreateCompany}
          icon={<PlusSVG width={24} height={24} className="color-white" />}
        />
      </div>
      <div>
        <TextInputComponent
          label="Buscar"
          placeholder="Ingresá el nombre de la empresa"
          className="w-50"
          onChange={(e): void => searchCompany(e)}
        />
      </div>
      {canView && (
        <div className="my-5 w-100 d-flex justify-content-between align-items-center flex-wrap">
          <ActionButtonComponent text="Usuarios" onClick={() => navigate('/users')} icon={<FaUsers size={25} color="white" />} />
        </div>
      )}
      <div className="d-flex flex-wrap mt-2">
        {(loading === 'idle' || loading === 'pending') && <LoaderScreen />}
        {companyListSorted?.length > 0 &&
          !filtered?.length &&
          !inputSearchValue &&
          companyListSorted?.map((company: Company) => <CompanyCard key={company.id} company={company} />)}
        {filtered && filtered?.length > 0 && filtered?.map((company: Company) => <CompanyCard key={company.id} company={company} />)}
        {inputSearchValue && inputSearchValue !== '' && !filtered?.length ? <p>No hay coincidencias</p> : null}
        {loading === 'succeeded' && companyListSorted?.length === 0 && 'No tenes compañías creadas'}
      </div>

      {showTextAccess ? (
        <>
          <div className="mt-5 w-100 d-flex justify-content-between align-items-center">
            <Title>Permisos</Title>
          </div>
          <p className="description-access">
            En esta sección podrás administrar los permisos que le otorgarás a otras personas para ver o modificar la información de tu
            empresa.
          </p>
          <p className="description-access">Vas a poder agregar, cambiar o quitar accesos a tu cuenta de nubceo.</p>
        </>
      ) : (
        <>
          <div className="mt-5 w-100 d-flex justify-content-between align-items-center">
            <Title>Permisos de acceso</Title>
          </div>
          {listAccessRequest && listAccessRequest.length > 0 && <Subtitle>Solicitados</Subtitle>}
          <div className="d-flex flex-wrap">
            {listAccessRequest &&
              listAccessRequest.map((item) => (
                <AccessRowComponent
                  row={item}
                  handleAccept={(): void => handleApproveAccess(item.id)}
                  handleClose={(): void => handleDeleteAccess(item.id, true)}
                />
              ))}
          </div>
          {listAccess && <Subtitle className="mt-5">Otorgados</Subtitle>}
          <div className="d-flex flex-wrap">
            {listAccess &&
              listAccess.map((item) => <AccessRowComponent row={item} handleClose={(): void => handleDeleteAccess(item.id, false)} />)}
          </div>
        </>
      )}
    </div>
  );
};

export default MyCompaniesPage;
