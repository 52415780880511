import React from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { removeModalFromList } from '../../redux/slices/modalSlice';
import { handleKeyDownDefault } from '../../utils/helper';
import { FormButtonComponent } from '../buttons';

const ModalDownload: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="background-gray-60 modal-wrapper-download" onKeyDown={handleKeyDownDefault} role="none" id="modal">
      <div className="modal-download border-round d-flex flex-column position-relative">
        <div className="modal-body-download flex">
          <span className="close-modal mt-4" aria-hidden="true" onClick={() => dispatch(removeModalFromList())} />
          <h3 className="download-title mt-4">nubceo es mejor en la app</h3>
          <h3 className="download-subtitle mt-3">Descargate nuestra app y accedé a una mejor experiencia para gestionar tu negocio</h3>
          <FormButtonComponent className="mt-5" onClick={() => window.open('https://nubceocash.page.link/NUBCASHWEB')}>
            Descargar la APP de nubceo
          </FormButtonComponent>
          <button className="cancel-button mt-4" type="button" onClick={() => dispatch(removeModalFromList())}>
            Ahora no
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalDownload;
