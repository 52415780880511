import React, { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import AccordionComponent from '../../../Accordion';
import { Sale } from '../../../../utils/interfaces';
import externalPlatformIcon from '../../../../utils/externalPlatformIcon';
import { currencyFormat, decimalFormat } from '../../../../utils/stringUtils';
import { externalPlatformsBasicData, platformExternalCodesTrimmed, saleType } from '../../../../utils/constants';
import { EVENTS } from '../../../../utils/segment/events';
import { useAppSelector } from '../../../../redux/hooks';
import { LoaderSpinner } from '../../..';
import { TypeName } from '../../../../utils/smartDataUtils';

type SaleProps = {
  sale: Sale;
  isModal?: boolean;
  handleExpandAcc?: (id: string) => void;
};

enum SaleTags {
  PREPAYMENT = 'prepayment',
  PAID_DIFFERENT_DATE = 'paid_different_date',
}

const SaleRow: React.FC<SaleProps> = ({ sale, handleExpandAcc }) => {
  const [companyName, setCompanyName] = useState<string | undefined>();
  const { companiesList } = useAppSelector((state) => state.company);
  const { loadingExpandedSale } = useAppSelector((state) => state.sales);
  const isPrisma = (pec: string): boolean => externalPlatformsBasicData(pec).code === platformExternalCodesTrimmed.PRISMA;

  const typeOfSale = (): string => {
    let type = '';
    if (sale.type === saleType.ORDER) {
      type = 'Venta';
    } else if (sale.type === saleType.CHARGEBACK) {
      type = 'Contracargo';
    } else if (sale.type === saleType.RETURN) {
      type = 'Devolución';
    } else if (sale.type === saleType.ADJUSTMENT) {
      type = 'Ajuste';
    }
    return type;
  };

  const iconShow = (code: any) => {
    let res;
    if (externalPlatformsBasicData(code)?.logoImage) {
      res = externalPlatformIcon({ code, className: 'external-platform-logo ms-0 ms-md-3' });
    } else {
      res = (
        <div className="">
          <div className="external-platform-logo-not-supported ms-0 ms-md-3">
            <p className="text-not-supported">{code.slice(0, 1)}</p>
          </div>
        </div>
      );
    }
    return res;
  };

  const showReference = (): string => {
    if (sale.externalReference) {
      return sale.externalReference;
    }
    return '--';
  };

  const showRelatedReference = () => {
    if (sale?.SalePlatformExternals?.length >= 2) {
      return sale.SalePlatformExternals.map((item) => (item.externalReference !== sale.externalReference ? item.externalReference : null));
    }
    return '--';
  };

  const showDocumentReference = (): string => {
    if (sale.saleDocumentReference) {
      return sale.saleDocumentReference;
    }
    return '--';
  };

  useMemo(() => setCompanyName(companiesList.find((company) => company.id === sale?.companyId)?.name), [companiesList, sale]);

  const salePlatformExternalsWithoutMain = sale.SalePlatformExternals?.filter(
    (spe) => spe.platformExternalCode !== sale.platformExternalCode,
  );

  const getTagsSales = (saleValue: Sale): React.ReactElement | null => {
    if (saleValue?.SaleTags?.length && isPrisma(saleValue?.platformExternalCode)) {
      const tagsMessages = saleValue.SaleTags.map((tag) => {
        switch (tag.tag.trim()) {
          case SaleTags.PREPAYMENT:
            return 'Venta PEX (venta acelerada).';
          case SaleTags.PAID_DIFFERENT_DATE:
            return 'Esta venta se paga una fecha distinta a la que muestra el portal.';
          default:
            return null;
        }
      }).filter(Boolean);

      return tagsMessages.length ? (
        <p className="offset-1 mt-2">
          {tagsMessages.map((message) => (
            <span className="font-sm font-weight-bold color-orange d-block">{message}</span>
          ))}
        </p>
      ) : null;
    }
    return null;
  };

  const headerContent = (): React.ReactElement => (
    <>
      <div className={`d-inline-flex ${salePlatformExternalsWithoutMain?.length > 1 ? `col-3` : 'col-2'}`} data-tip="Plataforma">
        {iconShow(sale.platformExternalCode ?? 'cash_ar')}
        {salePlatformExternalsWithoutMain?.length
          ? externalPlatformIcon({
              code: salePlatformExternalsWithoutMain[0]?.platformExternalCode,
              className: 'external-platform-logo-related-row',
            })
          : null}
        {salePlatformExternalsWithoutMain?.length > 1 ? (
          <span className="badge">{`${salePlatformExternalsWithoutMain?.length - 1}+`}</span>
        ) : null}
        <ReactTooltip />
      </div>
      <div className="d-sm-none d-md-inline-flex col-1 col-sm-1" data-tip="Fecha">
        <div className="text-type">
          <p>{TypeName(sale?.type)}</p>
        </div>
      </div>
      <div className="col-4 col-sm-4" data-tip="Fecha">
        <span className="d-none d-lg-inline">{moment(sale.date).locale('es').format('DD MMMM YYYY')}</span>
        <span className=" d-none d-md-inline d-lg-none">{moment(sale.date).locale('es').format('DD/MM/YY')}</span>
        <span className="d-inline d-md-none">{moment(sale.date).locale('es').format('D/MM')}</span>
        {!sale?.Settlement?.length ? <span className="indicator align-top ms-1" /> : null}
      </div>
      <div className="d-none d-sm-flex col-2 col-lg-2 text-break text-start" data-tip="Referencia">
        {sale.externalReference ?? ''}
      </div>
      <div className="amount col-2 p-0 font-weight-medium text-right" data-tip="Monto">
        {currencyFormat(sale.currencyCode)}
        {decimalFormat(sale.amount)}
      </div>
    </>
  );

  const mainContent = (): React.ReactElement => (
    <div className="w-100 d-flex flex-column flex-md-row m-auto">
      <div className="row flex-grow-1 d-flex flex-column flex-wrap flex-md-row d-flex align-items-center">
        <div className="offset-md-1 col-12 col-md-5 col-lg-6" style={{ minWidth: '200px' }}>
          {sale?.Settlement?.length ? (
            <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="d-flex">
                <div className="text-center mb-3 col-12 text-md-start mb-md-0 col-lg-12 fw-bold fs-5">Liquidación</div>
              </div>
              <div className="d-flex">
                <div className="col-7 col-lg-6 fw-bold text-start">{sale.Branch && sale.Branch.name}</div>
              </div>
              {sale?.Settlement ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold text-start">Fecha:</div>
                  <div className="col-5 col-lg-4 text-end">
                    {sale.Settlement ? moment(sale.Settlement[0]?.date).utc().format('DD/MM/YYYY') : null}
                  </div>
                </div>
              ) : null}
              {sale?.presentedDate ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold text-start">Fecha de presentación:</div>
                  <div className="col-5 col-lg-4 text-end">
                    {`${sale.presentedDate ? moment(sale.presentedDate).utc().format('DD/MM/YYYY') : 'Fecha no indicada'}`}
                  </div>
                </div>
              ) : null}
              {sale.Settlement[0]?.amount ? (
                <div className="d-flex">
                  <div className="col-7 col-lg-6 fw-bold text-start">Monto Liquidado:</div>
                  <div className="col-5 col-lg-4 text-end">
                    {currencyFormat(sale.currencyCode)}
                    {sale.Settlement ? decimalFormat(sale.Settlement[0]?.amount) : null}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="sale-content-section no-collection m-auto">
              <h3 className="settlement-title-error">
                <span className="indicator" />
                Esta venta no fue considerada en ninguna liquidación
              </h3>
            </div>
          )}
        </div>
        <div className="mt-4 px-2 col-12 col-md-6 col-lg-4 mt-lg-4" style={{ minWidth: '200px' }}>
          <hr className="d-md-none" style={{ width: '100%' }} />
          {companyName ? (
            <div className="d-flex">
              <div className="col-6 col-lg-6 fw-bold text-start">Empresa:</div>
              <div className="col-6 col-lg-6 text-end">
                <span>{companyName}</span>
              </div>
            </div>
          ) : null}
          {sale?.Branch?.name ? (
            <div className="d-flex">
              <div className="col-6 col-lg-6 fw-bold text-start">Sucursal / Comercio:</div>
              <div className="col-6 col-lg-6 text-end">{sale.Branch?.name}</div>
            </div>
          ) : null}
          {sale?.externalReference ? (
            <div className="d-flex">
              <div className="col-5 col-lg-5 fw-bold text-start">Referencia:</div>
              <div className="col-7 col-lg-7 text-break text-end">({showReference()})</div>
            </div>
          ) : null}
          {sale?.SalePlatformExternals?.length >= 2 ? (
            <div className="d-flex">
              <div className="col-5 col-lg-5 fw-bold text-start">Ref Relacionada:</div>
              <div className="col-7 col-lg-7 text-break text-end">({showRelatedReference()})</div>
            </div>
          ) : null}
          {sale?.saleDocumentReference ? (
            <div className="d-flex">
              <div className="col-6 col-lg-6 fw-bold text-start">Nº de factura:</div>
              <div className="col-6 col-lg-6 text-end">({showDocumentReference()})</div>
            </div>
          ) : null}
          {sale?.type ? (
            <div className="d-flex">
              <div className="col-6 col-lg-6 fw-bold text-start">Tipo:</div>
              <div className="col-6 col-lg-6 text-end">{typeOfSale()}</div>
            </div>
          ) : null}
          {sale?.amount ? (
            <div className="d-flex">
              <div className="col-6 col-lg-6 fw-bold text-start">Monto:</div>
              <div className="col-6 col-lg-6 text-end">
                {currencyFormat(sale.currencyCode)}
                {decimalFormat(sale.amount)}
              </div>
            </div>
          ) : null}
        </div>
        {getTagsSales(sale)}
        <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <div>
            {salePlatformExternalsWithoutMain?.length > 1 && (
              <i className="d-block font-weight-light font-sm">
                Esta venta también proviene de
                <div className="d-inline ms-1">
                  {salePlatformExternalsWithoutMain?.length > 1 &&
                    salePlatformExternalsWithoutMain.map(
                      (icon, index, thisArray) =>
                        icon.platformExternalCode !== sale.platformExternalCode &&
                        `${externalPlatformsBasicData(icon.platformExternalCode)?.name}${index === thisArray.length - 1 ? '' : ', '}`,
                    )}
                </div>
              </i>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <AccordionComponent
      className="sale-row"
      header={headerContent()}
      content={loadingExpandedSale === 'pending' && !sale.Branch ? <LoaderSpinner className="mx-auto mb-3 color-blue" /> : mainContent()}
      onExpandAcc={handleExpandAcc && loadingExpandedSale !== 'pending' ? (): void => handleExpandAcc(sale.id) : undefined}
      analytics={{
        event: EVENTS.SALES.ROW.EXPAND,
        data: {
          saleId: sale.id,
          externalReference: sale.externalReference,
          platform: sale.platformExternalCode,
          companyId: sale.companyId,
          branchId: sale.branchId,
        },
      }}
    />
  );
};
export default SaleRow;
