/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import moment from 'moment';
// import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AmountCellParams, FilterModel, MapName, ValueCol } from './interfaces';
import { NotificationPopUpComponent } from '../redux/slices/notificationSlice';
import { ProfileResponse } from '../services/userService';

type TranslationKey = 'count' | 'avg';
type Translations = {
  [key in TranslationKey]: string;
};

export const parsePlatformName = (platform: string): string | undefined => {
  const platformsNames: MapName = {
    Fiserv: 'firstdata',
    'Fiserv / Posnet': 'firstdata',
    Oca: 'oca',
    PedidosYa: 'pedidosya',
    Italcred: 'italcred',
    Rappi: 'rappi',
    MercadoPago: 'mercadopago',
    Mercadopago: 'mercadopago',
    VisaNet: 'visanet',
    Contabilium: 'contabilium',
    Cash: 'cash',
    Accor: 'accor',
    Moddo: 'moddo',
    Getnet: 'getnet',
    Zoologic: 'zoologic',
    Naranja: 'naranja',
    'Billetera Santa Fe': 'bilsantafe',
    TiendaNube: 'tiendanube',
    'American Express': 'amex',
    Cabal: 'cabal',
    Maxirest: 'maxirest',
    Xubio: 'xubio',
    Sodexo: 'sodexo',
    Redlider: 'red lider',
    dLocal: 'dlocal',
    Passcard: 'passcard',
    Creditel: 'creditel',
    ANDA: 'anda',
    Edenred: 'edenred',
    Diners: 'diners',
    'Creditos Directos': 'cdirectos',
    'Prisma / LaPos': 'prisma',
  };

  return platformsNames[platform];
};

export const parseStatusName = (value: string): string | undefined => {
  const statusNames: MapName = {
    cancelled: 'Cancelada',
    delivered: 'Entregada',
    'order prepared': 'Orden preparada',
    'picked up': 'Recogida',
  };

  return statusNames[value];
};

export const settledName = (value: string): string | undefined => {
  const settledNames: MapName = {
    Yes: 'Si',
    No: 'No',
  };

  return settledNames[value];
};

export const paymentTypeName = (value: string): string | undefined => {
  const paymentTypeNames: MapName = {
    cash: 'Efectivo',
    credit: 'Crédito',
    debit: 'Débito',
  };

  return paymentTypeNames[value];
};

export const TypeName = (value: string): string | undefined => {
  const TypeNames: MapName = {
    order: 'Venta',
    chargeback: 'Contracargo',
    return: 'Devolución',
    adjustment: 'Ajuste',
  };

  return TypeNames[value];
};

const parseConditionFiltersText = (value: any) => {
  switch (value?.type) {
    case 'equals':
    case 'eq':
      value = {
        ...value,
        type: 'eq',
        filter: `${value.filter}`,
      };
      break;
    case 'notEquals':
    case 'ne':
      value = {
        ...value,
        type: 'ne',
        filter: `${value.filter}`,
      };
      break;
    case 'contains':
    case 'iLike':
      value = {
        ...value,
        type: 'iLike',
        filter: `%${value.filter}%`,
      };
      break;
    case 'notContains':
    case 'notILike':
      value = {
        ...value,
        type: 'notILike',
        filter: `%${value.filter}%`,
      };
      break;
    case 'startsWith':
      value = {
        ...value,
        type: 'startsWith',
        filter: `${value.filter}%`,
      };
      break;
    case 'endsWith':
      value = {
        ...value,
        type: 'endsWith',
        filter: `%${value.filter}`,
      };
      break;
    default:
      return value;
  }
  return value;
};

export const parseFilterModelSmartData = (filterModel: FilterModel): FilterModel => {
  if (Object.entries(filterModel).length !== 0) {
    const filterKeys = Object.keys(filterModel);
    filterKeys.forEach((filter) => {
      if (filterModel[filter].filterType === 'text') {
        if (filterModel[filter].condition1 && filterModel[filter].condition2) {
          // nesting of conditions
          filterModel[filter] = {
            ...filterModel[filter],
            condition1: parseConditionFiltersText(filterModel[filter].condition1),
            condition2: parseConditionFiltersText(filterModel[filter].condition2),
          };
        } else {
          switch (filterModel[filter].type) {
            case 'equals' || 'eq':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'eq',
                filter: `${filterModel[filter].filter}`,
              };
              break;
            case 'notEquals' || 'ne':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'ne',
                filter: `${filterModel[filter].filter}`,
              };
              break;
            case 'contains' || 'iLike':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'iLike',
                filter: `%${filterModel[filter].filter}%`,
              };
              break;
            case 'notContains' || 'notILike':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'notILike',
                filter: `%${filterModel[filter].filter}%`,
              };
              break;
            case 'startsWith':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'startsWith',
                filter: `${filterModel[filter].filter}%`,
              };
              break;
            case 'endsWith':
              filterModel[filter] = {
                ...filterModel[filter],
                type: 'endsWith',
                filter: `%${filterModel[filter].filter}`,
              };
              break;
            default:
              break;
          }
        }
      }
      if (filterModel[filter].filterType === 'date') {
        switch (filterModel[filter].type) {
          case 'equals' || 'eq':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'eq',
              dateFrom: moment(filterModel[filter].dateFrom).toISOString(),
            };
            break;
          case 'inRange' || 'between':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'between',
              dateFrom: moment(filterModel[filter].dateFrom).toISOString(),
              dateTo: moment(filterModel[filter].dateTo).endOf('day').toISOString(),
            };
            break;
          default:
            break;
        }
      }
      if (filterModel[filter].filterType === 'number') {
        switch (filterModel[filter].type) {
          case 'equals' || 'eq':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'eq',
              filter: `${filterModel[filter].filter}`,
            };
            break;
          case 'notEquals' || 'ne':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'ne',
              filter: `${filterModel[filter].filter}`,
            };
            break;
          case 'lessThan' || 'lt':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'lt',
              filter: `${filterModel[filter].filter}`,
            };
            break;
          case 'greaterThan' || 'gt':
            filterModel[filter] = {
              ...filterModel[filter],
              type: 'gt',
              filter: `${filterModel[filter].filter}`,
            };
            break;
          default:
            break;
        }
      }
    });
  }
  return filterModel;
};

export const formatNumber = (num: number): number | string => {
  let resNum = '';
  if (num !== null && num !== undefined && !Number.isNaN(num)) {
    resNum = num.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  if (resNum.length === 7) {
    resNum = `${resNum.substring(0, 1)}.${resNum.substring(1)}`;
  }
  return resNum;
};

export const parsePercentage = (params: { value: number }): string => {
  if (params.value || params.value === 0) {
    const number = Number(params.value);
    const parseNumber = `${number.toFixed(2)}${'%'}`;
    return parseNumber.replace('.', ',');
  }
  return '';
};

const translateHeaderName = (headerName: string): string => {
  const translations: Translations = {
    count: 'Total',
    avg: 'Promedio'
  }
  const regex = /^(\w+)\(([^)]+)\)$/;
  const contentInParts = headerName.match(regex) || [headerName, headerName];
  
  const translatedHeadername = translations[contentInParts[1] as TranslationKey] || contentInParts[1];
  return `${translatedHeadername}(${contentInParts[2]})`
};

export const amountCell = (params: AmountCellParams): string | number => {

  if (params.value && params.colDef.headerName.includes('(')) {
    // function to translate the headername
    params.colDef.headerName = translateHeaderName(params.colDef.headerName);
  }

  if (params.value && params.colDef.headerName.includes('Total')) {
    // If it is total, parsing with decimals is avoided
    return params?.value;
  }
  const parsedAmount = `${formatNumber(typeof params?.value === 'number' ? params?.value : parseInt(params?.value || '', 10))}`;
  if (parsedAmount) {
    return parsedAmount;
  }
  return '';
};

// filter types smart data
export const filterTypes = {
  filterForNumbers: {
    filterOptions: ['equals', 'notEquals', 'lessThan', 'greaterThan'],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
  },
  filterForText: {
    filterOptions: ['contains', 'notContains', 'equals', 'notEquals', 'startsWith', 'endsWith'],
    defaultOption: 'contains',
    suppressAndOrCondition: false,
  },
  filterForDate: {
    filterOptions: ['inRange', 'equals'],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
  },
  filterForId: {
    filterOptions: ['equals'],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
  },
  filtersForTranslatedValues: {
    filterOptions: ['equals', 'notEquals'],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
  },
};

export const handleFilterChanged = (params: GridReadyEvent, datasource: IServerSideDatasource): void => {
    params.api.setServerSideDatasource(datasource);
};

export const defaultColDefValues = {
  flex: 1,
  minWidth: 150,
  filter: 'agNumberColumnFilter',
  filterParams: filterTypes.filterForNumbers,
  enableValue: false,
  floatingFilter: true,
  enablePivot: true,
  enableRowGroup: true,
  resizable: true,
  sortable: true,
};

export const gridOptions = {
  enableCharts: true,
  enableRangeSelection: true,
  filter: true,
};

// define the new pivot columns
export const addPivotColDefs = (columnApi: ColumnApi, pivotColDefs: (ColDef<any> | ColGroupDef<any>)[]): void => {
  columnApi.setPivotResultColumns(pivotColDefs);
};

// handle percentage type filters
export const changeFilterItems = (setShowInFilters: any, valueCols: ValueCol[]): void => {
  let commissionsAmount = true;
  let percCommissions = true;
  let otherDiscountsAmount = true;
  let percOtherDiscounts = true;
  let taxesAmount = true;
  let collectionAmount = true;
  let percTaxes = true;

  valueCols.forEach((obj: ValueCol) => {
    if (obj.id === "commissionsAmount") {
        commissionsAmount = false;
    } else if (obj.id === "percCommissions") {
        percCommissions = false;
    } else if (obj.id === "percTaxes") {
        percTaxes = false;
    } else if (obj.id === "otherDiscountsAmount") {
        otherDiscountsAmount = false;
    } else if (obj.id === "percOtherDiscounts") {
        percOtherDiscounts = false;
    } else if (obj.id === "taxesAmount") {
        taxesAmount = false;
    } else if (obj.field === "collection.amount") {
        collectionAmount = false;
    }
  });

    return setShowInFilters({
      commissionsAmount,
      percCommissions,
      otherDiscountsAmount,
      percOtherDiscounts,
      taxesAmount,
      collectionAmount,
      percTaxes,
    })
}

export const buildDefaultDateFiltersAlert = (user: ProfileResponse | null, loading: string): NotificationPopUpComponent => {
  const today = moment
    .tz(moment().format(), 'YYYY-MM-DDTHH:mm:ss', moment.tz.zonesForCountry(user?.Tenant?.countryCode || '', true)[0].name)
    .startOf('day');

  const twoMonthsAgo = moment(today)
    .subtract(2, 'months')
    .tz(today.tz() || '')
    .format('DD-MM-YYYY');
  return {
    type: 'warning',
    title: 'Atención',
    body: `Si no utilizas un filtro de fechas, se establecerá un filtro por defecto ${
      loading !== 'succeeded' ? 'de dos meses' : `desde ${twoMonthsAgo} hasta ${today.format('DD-MM-YYYY')}`
    }`,
  };
};

export const handleGoBackSmartData = (navigate: NavigateFunction): void => {
  navigate('/smart-data');
};