import React, { FC, ReactElement } from 'react';
import { Form, Formik } from 'formik';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';

interface TiendaNubeCredentialsFormProps {
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
}

const TiendaNubeCredentialsForm: FC<TiendaNubeCredentialsFormProps> = ({ handleDeletePlatform, handleSubmitForm }) => (
  <Formik initialValues={{ password: '', username: '', companyId: '' }} onSubmit={handleSubmitForm}>
    {(): ReactElement => (
      <Form className="row mb-5 d-flex flex-column">
        <div className="col-12">
          <PlatformCredentialsFooterButtons handleDeletePlatform={handleDeletePlatform} redirectingPlatformExternal />
        </div>
      </Form>
    )}
  </Formik>
);

export default TiendaNubeCredentialsForm;
