import React, { FC, useState } from 'react';
import moment from 'moment';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';
import {
  ActionButtonComponent,
  FilterCalendar,
  FilterExpenseReports,
  FilterSales,
  FilterSettlement,
  FilterWorkPapers,
} from '../../components';
import { FilterType } from '../../utils/types';

type FiltersContainerProps = {
  containerClassName?: string;
  typeFilter: string;
  setSelectedMonth?: React.Dispatch<React.SetStateAction<moment.Moment>>;
  selectMonth?: moment.Moment;
};

const Filters: FC<FiltersContainerProps> = ({ containerClassName, typeFilter, setSelectedMonth, selectMonth }) => {
  const [openFilters, setOpenFilters] = useState(false);

  const showFilter = (): React.ReactElement | null => {
    switch (typeFilter) {
      case FilterType.CALENDAR:
        return <FilterCalendar setSelectedMonth={setSelectedMonth} selectMonth={selectMonth} />;
      case FilterType.EXPENSE_REPORTS:
        return <FilterExpenseReports />;
      case FilterType.SETTLEMENT:
        return <FilterSettlement />;
      case FilterType.SALES:
        return <FilterSales />;
      case FilterType.WORKPAPERS:
        return <FilterWorkPapers />;
      default:
        break;
    }
    return null;
  };

  return (
    <div className={`filters-container d-flex flex-column ${containerClassName}`}>
      <div className="d-flex w-75 justify-content-between">
        <ActionButtonComponent
          onClick={(): void => setOpenFilters(!openFilters)}
          text="Filtros"
          containerClassName="background-blue filter-action-button px-2"
          icon={<DropdownArrowIconSVG className={`action-button-icon color-white ${openFilters && 'open'}`} />}
        />
      </div>
      <div className="border-round flex-grow-1">{openFilters ? showFilter() : null}</div>
    </div>
  );
};

export default Filters;
