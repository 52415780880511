import moment from 'moment';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { externalPlatformsBasicData, platformExternalCodesByCountry } from '../../utils/constants';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import { dateIsToday } from '../../utils/helper';
import { CalendarCardProps } from '../../utils/interfaces';
import { formatNumber } from '../../utils/stringUtils';

const CalendarCard: FunctionComponent<CalendarCardProps> = ({
  value,
  collection,
  loading,
  key,
  onClick,
  selectMonth,
  isInternalCalendar = true,
}) => {
  const dontShowIcon = collection.icon.length === 0 && collection.related.length === 0;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const handleResize = (): void => {
    setIsMobile(window.innerWidth < 900);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isDateCurrentMonth = (date: Date): boolean => {
    const now = selectMonth;
    const dateMoment = moment(date);
    return dateMoment.isSame(now, 'month');
  };
  if (loading !== 'succeeded')
    return (
      <div
        key={key}
        className={`font-xl calendar-card ${isDateCurrentMonth(value) ? 'current-month-card' : ''}`}
        style={{ cursor: 'auto' }}
      >
        <p> </p>
        <p className="value font-sm font-weight-bold ">{value.getDate()}</p>
        <p className="amount"> </p>
      </div>
    );

  const getLeftSpace = (totalIcons: number, iconsRelated: []): string => {
    let classNameString = '';
    if (totalIcons === 2 && iconsRelated?.length) {
      classNameString = isMobile ? '' : 'ms-3';
    }
    if (totalIcons === 3 && iconsRelated?.length) {
      classNameString = isMobile ? '' : 'ms-4';
    }
    return classNameString;
  };

  const getIcons = (): React.ReactElement | undefined => {
    const icons = collection.icon?.map((icon: string) => icon);
    const iconsRelated = collection.related.map((icon: string) => icon);
    const totalIcons = icons?.length + iconsRelated?.length;
    const { PEDIDOSYA_UY } = platformExternalCodesByCountry;
    let result;
    const iconShow = (icon: string): ReactElement => {
      let res;
      if (externalPlatformsBasicData(icon)?.logoImage) {
        res = externalPlatformIcon({
          code: icon === 'cash' ? 'cash_ar' : icon,
          className: 'external-platform-logo',
        });
      } else {
        res = (
          <div className="external-platform-logo-not-supported">
            <p className="text-not-supported-calendar">{icon.slice(0, 1)}</p>
          </div>
        );
      }
      return res;
    };

    if (totalIcons < 4 && totalIcons >= 0) {
      result = (
        <div
          className={`d-flex flex-row justify-content-center ${getLeftSpace(totalIcons, iconsRelated)}`}
          style={{ marginLeft: `${isMobile ? '2px' : ''}` }}
        >
          <div>
            {collection.icon.filter((icon: string) => icon !== 'unconfirmed' && icon !== 'projected').map((icon: string) => iconShow(icon))}
          </div>
          {iconsRelated?.length > 0 && (
            <div>
              {externalPlatformIcon({
                code: iconsRelated[0] === 'cash' ? 'cash_ar' : iconsRelated[0],
                className: `external-platform-logo`,
              })}
              {externalPlatformIcon({
                code: PEDIDOSYA_UY,
                className: `external-platform-mini-logo`,
              })}
            </div>
          )}
        </div>
      );
    } else if (totalIcons >= 4) {
      result = (
        <div className="d-flex">
          {iconsRelated?.length > 0 ? (
            <div className="left-underhanded">
              {externalPlatformIcon({
                code: iconsRelated[0] === 'cash' ? 'cash_ar' : iconsRelated[0],
                className: `left-underhanded external-platform-logo`,
              })}
              {externalPlatformIcon({
                code: 'pedidosya_uy',
                className: 'external-platform-mini-logo',
              })}
            </div>
          ) : (
            <div className="left-underhanded">{iconShow(icons[0])}</div>
          )}
          <div>{iconShow(icons[1])}</div>
          <div data-tip={`Tienes ${totalIcons - 2} plataformas más vinculadas a este ingreso`} className="external-platform-logo">
            {/* eslint-disable-next-line no-nested-ternary */}
            <p className="text-badge">+{iconsRelated?.length ? totalIcons - 2 : totalIcons - 2}</p>
          </div>
          <ReactTooltip />
        </div>
      );
    }
    return result;
  };

  return (
    <div
      role="button"
      onKeyPress={(): void => {
        if (dontShowIcon) return;
        onClick(value);
      }}
      tabIndex={0}
      onClick={(): void => {
        if (dontShowIcon) return;
        onClick(value);
      }}
      key={key}
      className={`
        mb-4
        calendar-card
        position-relative
        ${isDateCurrentMonth(value) ? 'current-month-card' : ''}
        ${!isInternalCalendar && isDateCurrentMonth(value) ? 'current-month-card-webview' : ''}
        ${collection.amount && !collection.confirmed && isDateCurrentMonth(value) && 'not-confirmed'}
        ${dateIsToday(value) ? 'active' : ''}
      `}
      style={dontShowIcon ? { cursor: 'auto' } : undefined}
    >
      <p className="value font-sm font-weight-bold">{value.getDate()}</p>
      <p className="icon font-sm">{isDateCurrentMonth(value) && getIcons()}</p>
      <p className="amount font-sm font-weight-bold">
        {isDateCurrentMonth(value) &&
          (collection.icon.length > 0 || collection.related.length > 0) &&
          formatNumber(collection.amount, isMobile)}{' '}
      </p>
    </div>
  );
};
export default CalendarCard;
