/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: As soon as endpoints are used add as many types as possible.
import { AxiosPromise } from 'axios';
import { setTokens } from '../utils/helper';
import { RecoverPassword, UserCredentials, UserRegisterData, UserResponse } from '../utils/interfaces';
// import { segmentGroup, segmentIdentify } from '../utils/segment/hooks';
import { CompleteOnBoardingResponse } from '../utils/types';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

// Service request can be typed
export const authRegister = (userData: UserRegisterData): AxiosPromise<{ data: { data: UserResponse } }> =>
  axiosV2.post('/auth/register', userData);

// Or not
export const authLogin = (credentials: UserCredentials): AxiosPromise => axiosV2.post('/auth/login', credentials);

export const refreshToken = async () => {
  const tokenRefresh = localStorage.getItem('tokenRefresh');
  const options = {
    headers: {
      'Content-type': 'application/json',
      'refresh-token-nubceo': tokenRefresh,
    },
  };
  const response = await axios.post('/auth/refresh-token', {}, options);
  // segmentIdentify(response.data.data.user.id, {
  //   email: response.data.data.user.email,
  //   name: response.data.data.user.firstName,
  // });
  // segmentGroup(response.data.data.user.tenantId, {
  //   tenant: response.data.data.user.tenantId,
  // });
  setTokens(response.data.data.token, response.data.data.tokenRefresh);
  return response;
};

export const verifyAccount = (data: any) => axios.post('/self/verification', data);
export const reverifyAccount = () => axios.post('/self/resend-verification');
export const forgotPassword = (data: {
  email: string;
  isRenewalPasswordBoolean?: boolean;
}): AxiosPromise<{ data: { data: Record<string, unknown> } }> => axios.post('/auth/forgot-password', data);
export const resetPassword = (data: RecoverPassword): AxiosPromise<{ data: { data: Record<string, unknown> } }> =>
  axiosV2.post('/auth/reset-password', data);

export const validateForgotPasswordToken = (userData: string, code: string) =>
  axiosV2.post('auth/validate-forgot-password-token', { email: userData, token: code });

export const completeOnboarding = async (): Promise<CompleteOnBoardingResponse> => {
  const data = await axios.post<CompleteOnBoardingResponse>('/onboarding/onboarding-complete');
  return data.data;
};
