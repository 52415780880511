import 'bootstrap/dist/css/bootstrap.min.css';
import React, { FC, ReactElement, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CloseIconSVG } from '../../assets/svgComponents';
import { LoaderScreen, Subtitle, Title } from '../../components';
import { ClientNameHeader } from '../../components/textComponents';
import { Filters } from '../../containers';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { asyncListClientExternalPlatforms } from '../../redux/slices/clientsSlice';
import { asyncSummaryCollectionsByPe } from '../../redux/slices/collectionsSlice';
import { asyncSummaryDeductionsByPe } from '../../redux/slices/deductionsSlice';
import { asyncSummarySalesByPe } from '../../redux/slices/salesSlice';
import { asyncSummaryTaxesByPe } from '../../redux/slices/taxesSlice';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { SummaryDeduction, SummarySale, SummaryTax } from '../../utils/interfaces';
import { decimalFormat } from '../../utils/stringUtils';
import { FilterType } from '../../utils/types';

const WorkPapers: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { summarySales, loadingSummarySale } = useAppSelector((state) => state.sales);
  const { summaryCollections, loadingSummaryCollection } = useAppSelector((state) => state.collections);
  const { summaryTaxes, loadingSummaryTax } = useAppSelector((state) => state.taxes);
  const { summaryDeductions, loadingSummaryDeduction } = useAppSelector((state) => state.deductions);

  useEffect(() => {
    dispatch<void>(asyncListClientExternalPlatforms(id || ''));
  }, [id]);

  const manageLoading = (): boolean | undefined => {
    if (
      loadingSummarySale === 'pending' ||
      loadingSummaryTax === 'pending' ||
      loadingSummaryDeduction === 'pending' ||
      loadingSummaryCollection === 'pending'
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters(queryObject);
    dispatch<void>(asyncSummarySalesByPe({ id: Number(id), params: filterableObject }));
    dispatch<void>(asyncSummaryCollectionsByPe({ id: Number(id), params: filterableObject }));
    dispatch<void>(asyncSummaryTaxesByPe({ id: Number(id), params: filterableObject }));
    dispatch<void>(asyncSummaryDeductionsByPe({ id: Number(id), params: filterableObject }));
  }, [location]);

  const goBack = (): void => {
    navigate(-1);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const row = (data: SummarySale[] | null) => {
    const rows: ReactElement[] = [];
    if (data)
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        rows.push(
          <tr>
            <td className="text-start">{item.platformExternalName}</td>
            <td className="text-end">{item.totalCount}</td>
            <td className="text-end">$ {decimalFormat(item.totalAmount)}</td>
          </tr>,
        );
      });
    return rows;
  };

  const totalAmount = (data: any): string | number => decimalFormat(data?.reduce((acc: number, curr: any) => acc + curr?.totalAmount, 0));

  const totalCount = (data: any): string | number => data?.reduce((acc: number, curr: any) => acc + curr?.totalCount, 0);

  const rowData = (data: SummaryDeduction[] | SummaryTax[], type: string): ReactElement => {
    const summaryFilter: any = [];

    if (type === 'deduction') {
      data.forEach((item: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!summaryFilter.hasOwnProperty(item.deductionTypeName)) {
          summaryFilter[item.deductionTypeName] = {
            platformsExternal: [],
          };
        }

        summaryFilter[item.deductionTypeName].platformsExternal?.push({
          platformExternalName: item.platformExternalName,
          totalAmount: item.totalAmount,
        });
      });
    } else if (type === 'tax') {
      data.forEach((item: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!summaryFilter.hasOwnProperty(item.taxTypeName)) {
          summaryFilter[item.taxTypeName] = {
            platformsExternal: [],
          };
        }

        summaryFilter[item.taxTypeName].platformsExternal?.push({
          platformExternalName: item.platformExternalName,
          totalAmount: item.totalAmount,
        });
      });
    }

    const rows: any = [];
    if (Object.entries(summaryFilter).length > 0)
      // eslint-disable-next-line array-callback-return
      Object.entries(summaryFilter).map((item: any) => {
        rows.push(
          <tr>
            <td className="text-start col-lg-4" colSpan={2}>
              {item[0]}
            </td>
            <td>
              {item[1].platformsExternal.map((platform: any) => (
                <tr className="d-flex flex-column align-items-start">
                  <td colSpan={2}>{platform.platformExternalName}</td>
                </tr>
              ))}
            </td>
            <td className="d-flex flex-column align-items-end">
              {item[1].platformsExternal.map((platform: any) => (
                <tr>
                  <td>$ {decimalFormat(platform.totalAmount)}</td>
                </tr>
              ))}
            </td>
          </tr>,
          <tr className="table-secondary">
            <th className="text-start">Total</th>
            <th className="text-end" colSpan={3}>
              $ {totalAmount(item[1].platformsExternal)}
            </th>
          </tr>,
        );
      });
    return rows;
  };

  return manageLoading() ? (
    <LoaderScreen />
  ) : (
    <div className="d-flex flex-column mb-4 mt-4">
      <div className="my-2 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-9">
        <ClientNameHeader clientId={Number(id)} />
        <CloseIconSVG className="close-icon color-blue" height={40} width={25} onClick={goBack} />
      </div>
      <div>
        <Title size="sm">Papeles de Trabajo</Title>
      </div>
      <Filters containerClassName="mt-3 mb-5" typeFilter={FilterType.WORKPAPERS} />

      <Subtitle className="mt-4 mb-2">Ventas</Subtitle>
      <table className="table w-75 table-hover table-sm">
        <thead>
          <tr>
            <th className="text-start">Plataforma</th>
            <th className="text-end">Cantidad</th>
            <th className="text-end">Importe total</th>
          </tr>
        </thead>
        <tbody>{row(summarySales)}</tbody>
        <thead>
          <tr className="table-secondary">
            <th className="text-start">Total</th>
            <th className="text-end">{totalCount(summarySales)}</th>
            <th className="text-end">${totalAmount(summarySales)}</th>
          </tr>
        </thead>
      </table>
      <hr />

      <Subtitle className="mt-4 mb-2">Ingresos</Subtitle>
      <table className="table w-75 table-hover table-sm">
        <thead>
          <tr>
            <th className="text-start">Plataforma</th>
            <th className="text-end">Cantidad</th>
            <th className="text-end">Importe total</th>
          </tr>
        </thead>
        <tbody>{row(summaryCollections)}</tbody>
        <thead>
          <tr className="table-secondary">
            <th className="text-start">Total</th>
            <th className="text-end">{totalCount(summaryCollections)}</th>
            <th className="text-end">${totalAmount(summaryCollections)}</th>
          </tr>
        </thead>
      </table>
      <hr />

      <Subtitle className="mt-5 mb-2">Impuestos</Subtitle>
      <table className="table w-75 table-hover">
        <thead>
          <tr>
            <th className="text-start" colSpan={2}>
              Concepto
            </th>
            <th className="text-start">Plataforma</th>
            <th className="text-end">Importe total</th>
          </tr>
        </thead>
        <tbody>{rowData(summaryTaxes, 'tax')}</tbody>
      </table>
      <hr />

      <Subtitle className="mt-5 mb-2">Descuentos</Subtitle>
      <table className="table w-75 table-hover">
        <thead>
          <tr>
            <th className="text-start" colSpan={2}>
              Concepto
            </th>
            <th className="text-start">Plataforma</th>
            <th className="text-end">Importe total</th>
          </tr>
        </thead>
        <tbody>{rowData(summaryDeductions, 'deduction')}</tbody>
      </table>
    </div>
  );
};

export default WorkPapers;
