import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaSearch } from 'react-icons/fa';
import { InputWrapperProps, SearchInputProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';
import SearchOptionComponent from '../SearchOption';
import LoaderSpinner from '../../LoaderSpinner';

export const SearchInput: FC<SearchInputProps & InputWrapperProps> = ({
  label,
  countCheck,
  setCountCheck,
  errorMessage,
  setEndOfScroll,
  setInputValueSearch,
  loading,
  placeholder,
  name,
  className = '',
  containerClassName,
  required = false,
  options,
  value,
  onOptionClick,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const renderThumb = ({ style, ...p }: { style: React.CSSProperties }): React.ReactElement => {
    const thumbStyle: React.CSSProperties = {
      backgroundColor: '#aaa',
      borderRadius: '6px',
      width: '.8rem',
      right: '10px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...p} />;
  };

  useEffect(() => {
    const handleClickOutside = (e: any): void => {
      if (divRef.current && !divRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      const defaultInputText = options.find((option) => option.value === value)?.name;
      if (defaultInputText) setInputValue(defaultInputText);
    }
  }, [options, value]);

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    const element = e.target as HTMLElement;
    const scrollBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
    const scrollThreshold = 5;

    if (scrollBottom <= scrollThreshold && setEndOfScroll) {
      // Change the state when reaching the end of the scroll
      setEndOfScroll(true);
    } else if (setEndOfScroll) {
      setEndOfScroll(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Tab') setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
  };

  const handleCheck = (option: any): void => {
    if (setCountCheck && countCheck !== undefined) {
      setCountCheck(countCheck + 1);
    }
    onOptionClick(name, [...value, option.value]);
  };

  const handleUncheck = (option: any): void => {
    if (setCountCheck && countCheck !== undefined) {
      setCountCheck(countCheck - 1);
    }
    onOptionClick(
      name,
      value.filter((item: string) => item !== option.value),
    );
  };
  useEffect(() => {
    if (setInputValueSearch) {
      setInputValueSearch(inputValue);
    }
  }, [inputValue]);

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          option?.externalCode?.toString()?.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [inputValue, options],
  );

  const showTextValue = (): string => {
    if (value?.length === 1) {
      return '1 opción seleccionada';
    }
    if (value?.length > 1) {
      return `${value.length} opciones seleccionadas`;
    }
    return '';
  };

  const handleInputClick = (): void => {
    setOpen(true);
  };

  const handleOptionClick = (): void => {
    // setOpen(false);
  };

  const heightContainerValue = options.length * 80;

  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage} ref={divRef}>
      <div className="search-input-component-container" role="none" onClick={handleInputClick}>
        <input
          disabled={disabled}
          className={`selector search-input-component ${errorMessage && 'error'} ${className} ${open ? 'opened' : ''} ${
            disabled || !options.length ? 'disabled' : ''
          }`}
          name={name}
          placeholder={options.length ? placeholder : 'No hay opciones disponibles'}
          value={open ? inputValue : showTextValue()}
          autoComplete="off"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          ref={inputRef}
        />
        <FaSearch width={15} className="search-icon" style={{ pointerEvents: 'none' }} />
      </div>
      {open && (
        <div
          className="options-container"
          style={{ height: `${heightContainerValue}px`, minHeight: '50px' }}
          onMouseDown={handleOptionClick}
          role="none"
        >
          {options.length ? (
            <Scrollbars renderThumbVertical={renderThumb} onScroll={handleScroll}>
              {filteredOptions.length > 0
                ? filteredOptions.map((item) => (
                    <SearchOptionComponent
                      containerClassName={value?.includes(item.value) ? 'selected' : ''}
                      checked={value?.includes(item.value)}
                      key={item.id}
                      option={item}
                      onCheck={handleCheck}
                      onUncheck={handleUncheck}
                      label={item.optionLabel && `Empresa: ${item.optionLabel}`}
                      style={{ height: '75px' }}
                    />
                  ))
                : null}
              <div className="text-center mb-2">{loading ? <LoaderSpinner className="color-blue font-md" /> : null}</div>
            </Scrollbars>
          ) : (
            loading === false && (
              <div className="options-container">
                <div className="select-option-component">No hay opciones disponibles</div>
              </div>
            )
          )}
        </div>
      )}
    </InputWrapper>
  );
};
