import React, { useEffect, useState } from 'react';
import { getSingleClient } from '../../../services/clientsService';
import { useAppDispatch } from '../../../redux/hooks';
import { setSingleClient } from '../../../redux/slices/clientsSlice';
import { Client } from '../../../utils/interfaces';
import errorHandlerHelper from '../../../utils/errorHandler';
import Title from '../Title';

type ClientNameProps = {
  clientId: number;
};

const ClientNameHeader = ({ clientId }: ClientNameProps) => {
  const dispatch = useAppDispatch();
  const [clientSelected, setClientSelected] = useState<Client | null>(null);

  useEffect(() => {
    const getClientApiCall = async (): Promise<void> => {
      try {
        const response = await getSingleClient(clientId.toString());
        dispatch<void>(setSingleClient(response));
        return setClientSelected(response);
      } catch (error) {
        return errorHandlerHelper(error, dispatch);
      }
    };
    getClientApiCall();
  }, [clientId]);

  return (
    <Title size="sm">
      Cliente: {clientSelected?.name} ({clientSelected?.taxCode})
    </Title>
  );
};

export default ClientNameHeader;
