import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement, useState } from 'react';
import * as Yup from 'yup';
import { PasswordInputComponent, FormButtonComponent } from '..';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { selfChangePassword } from '../../services/selfService';
import { UpdatePassword } from '../../utils/interfaces';
import { FormFieldType, StorageVars } from '../../utils/types';
import successImage from '../../assets/images/success.png';
import LoaderScreen from '../LoaderScreen';
import logger from '../../services/loggerService';
import errorHandlerHelper from '../../utils/errorHandler';
import { regexCodes } from '../../utils/constants';
import { logoutUser } from '../../utils/helper';

const FormUpdatePassword: FC = () => {
  const initialValues: UpdatePassword = { oldPassword: '', passwordConfirm: '', newPassword: '' };
  const { user } = useAppSelector((state) => state.userProfile);
  const currentUserEmail = user?.email;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const ForgotSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, 'Debe tener al menos 8 caracteres.')
      .max(255, 'Máximo 255 caracteres')
      .required('Este campo es requerido.'),
    newPassword: Yup.string()
      .matches(regexCodes.password, 'Tu contraseña debe tener al menos 8 caracteres, 1 número y 1 carácter especial.')
      .max(255, 'Máximo 255 caracteres')
      .test('not-email', 'La contraseña no puede ser igual al email actual.', (value) => value !== currentUserEmail)
      .required('Este campo es requerido.')
      .notOneOf([Yup.ref('oldPassword'), null], 'La contraseña es igual a la antigua'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden.')
      .required('Este campo es requerido.'),
  });
  const handleSubmit = async (values: UpdatePassword): Promise<void> => {
    setLoading(true);
    selfChangePassword(values)
      .then((res) => {
        logger.info('Update password.info', res.data.data);
        dispatch(
          addModalToList({
            body: 'Se modificó la contraseña con éxito. Deberás iniciar sesión con tu nueva contraseña.',
            pictureUrl: successImage,
            required: true,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                window.localStorage.removeItem(StorageVars.daysRemainingExpiredPassword);
                logoutUser(dispatch);
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      })
      .catch((err) => {
        errorHandlerHelper(err, dispatch);
        logger.error('Update password.error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="d-flex flex-column">
      <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={handleSubmit}>
        {({ errors, isSubmitting, isValid, dirty }): ReactElement => {
          if (loading) return <LoaderScreen />;
          return (
            <Form autoComplete="account" className="row mb-5 d-flex flex-column">
              <div className="col-md-6">
                <Field name="oldPassword">
                  {({ field }: FormFieldType): ReactElement => (
                    <PasswordInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      type="text"
                      errorMessage={field.value.length > 0 && errors.oldPassword}
                      placeholder="Ingresá tu contraseña actual"
                      autoComplete="new-password"
                      label="Contraseña Actual"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-100">{}</div>
              <div className="col-md-6">
                <Field name="newPassword">
                  {({ field }: FormFieldType): ReactElement => (
                    <PasswordInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={field.value.length > 0 && errors.newPassword}
                      placeholder="Ingresá tu nueva contraseña"
                      label="Contraseña Nueva"
                      type="text"
                      autoComplete="new-password"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-100">{}</div>
              <div className="col-md-6">
                <Field name="passwordConfirm">
                  {({ field }: FormFieldType): ReactElement => (
                    <PasswordInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={field.value.length > 0 && errors.passwordConfirm}
                      placeholder="Ingresá tu contraseña nuevamente"
                      label="Confirmar Contraseña"
                      type="text"
                      autoComplete="new-password-confirm"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <FormButtonComponent
                  className="submit-button mt-4 w-100"
                  type="submit"
                  disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
                >
                  Cambiar Contraseña
                </FormButtonComponent>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormUpdatePassword;
