/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosPromise, AxiosResponse } from 'axios';
import logger from '../../services/loggerService';
import { getSales, getSalesClient, getSummarySalesByPe } from '../../services/salesService';
import { Sale, SaleResponse, SalesByClientResponse, SummarySale, SummarySalesByPeResponse } from '../../utils/interfaces';
import { FilterParams } from '../../utils/types';

interface SalesState {
  salesList: Array<Sale> | null;
  summarySales: Array<SummarySale> | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingExpandedSale: 'idle' | 'pending';
  loadingSummarySale: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingClient: 'idle' | 'pending' | 'succeeded' | 'failed';
  pages: number;
  total: number;
  count?: number;
}

interface IdAndParams {
  id: number;
  params?: FilterParams;
}

export const asyncListSales = createAsyncThunk('sales/getSales', async (params: FilterParams, { rejectWithValue }) => {
  try {
    const { data: sales } = await getSales(params);
    return sales;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncListSalesClient = createAsyncThunk<SalesByClientResponse, IdAndParams>('sales-clients', async ({ id, params }) => {
  const { data: response } = await getSalesClient(id, params || {});
  return response.data;
});

export const asyncSummarySalesByPe = createAsyncThunk<SummarySalesByPeResponse, IdAndParams>('summary-sales', async ({ id, params }) => {
  const { data: response } = await getSummarySalesByPe(id, params || {});
  return response;
});

const initialState: SalesState = {
  salesList: [],
  summarySales: [],
  loading: 'idle',
  loadingClient: 'idle',
  loadingExpandedSale: 'idle',
  loadingSummarySale: 'idle',
  pages: 1,
  total: 1,
  count: 1,
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setSales: (state, action) => {
      state.salesList = action.payload;
    },
    setLoadingExpandedSale: (state, action) => {
      state.loadingExpandedSale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncListSales.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListSales.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListSales.error', action.error);
    });
    builder.addCase(asyncListSales.fulfilled, (state, { payload }) => {
      state.loading = 'succeeded';
      state.salesList = payload.data;
      state.pages = payload.meta.pages;
      state.total = payload.meta.total;
    });
    builder.addCase(asyncListSalesClient.pending, (state, _action) => {
      state.loadingClient = 'pending';
    });
    builder.addCase(asyncListSalesClient.rejected, (state, action) => {
      state.loadingClient = 'failed';
      logger.error('asyncListSalesByClient.error', action.error);
    });
    builder.addCase(asyncListSalesClient.fulfilled, (state, action) => {
      state.loadingClient = 'succeeded';
      state.salesList = action.payload.rows;
      state.pages = action.payload.pages;
      state.total = action.payload.total;
    });
    builder.addCase(asyncSummarySalesByPe.pending, (state, _action) => {
      state.loadingSummarySale = 'pending';
    });
    builder.addCase(asyncSummarySalesByPe.rejected, (state, action) => {
      state.loadingSummarySale = 'failed';
      logger.error('asyncSummarySalesByPe.error', action.error);
    });
    builder.addCase(asyncSummarySalesByPe.fulfilled, (state, action) => {
      state.loadingSummarySale = 'succeeded';
      state.summarySales = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.count = action.payload.meta.count;
      logger.info('asyncListSalesByClient.success', action.payload.data);
    });
  },
});

export const { setSales, setLoadingExpandedSale } = salesSlice.actions;

export default salesSlice.reducer;
