import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement } from 'react';
import * as Yup from 'yup';
import { FormButtonComponent, LinkComponent, TextInputComponent, Paragraph } from '../../components';
import { useAppDispatch } from '../../redux/hooks';
import { UserForgotPasswordHandler, UserRecoverPassword } from '../../utils/interfaces';
import { FormFieldType } from '../../utils/types';
import appstoreButton from '../../assets/images/stores/appstore-button.png';
import gpButton from '../../assets/images/stores/google-play-button.png';
import { stepCodes } from '../../pages/RecoverPassword';
import { errorHandlerHelperV2 } from '../../utils/errorHandler';
import { forgotPassword } from '../../services/authService';

interface FormEnterEmailProps {
  setSteps: (step: string) => void;
  setSelectedEmail: (email: string) => void;
  isRenewalPasswordBoolean?: boolean;
}
const FormEnterEmail: FC<FormEnterEmailProps> = ({ setSelectedEmail, setSteps, isRenewalPasswordBoolean }) => {
  const dispatch = useAppDispatch();

  const handleEmailValidation = async (values: UserRecoverPassword): Promise<void> => {
    setSelectedEmail(values.email);
    const bodyForgotPassword = isRenewalPasswordBoolean
      ? { email: values.email, isRenewalPassword: isRenewalPasswordBoolean }
      : { email: values.email };
    const res = await errorHandlerHelperV2<UserForgotPasswordHandler>(() => forgotPassword(bodyForgotPassword), dispatch);
    if (!res.error) {
      setSteps(stepCodes.ENTER_NEW_PASSWORD);
    }
  };

  const initialValues: UserRecoverPassword = { email: '' };

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('Debes escribir un email válido.').required('Debes escribir un email.'),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={handleEmailValidation}>
      {({ errors, touched, isSubmitting }): ReactElement => (
        <Form className="inputs-container d-flex flex-column align-items-end">
          <Field name="email">
            {({ field }: FormFieldType): ReactElement => (
              <TextInputComponent
                required
                containerClassName="mt-2 w-100"
                className="input"
                type="email"
                errorMessage={touched.email && errors.email}
                placeholder="Ingresá tu email"
                label="Email"
                autoComplete="new-password"
                {...field}
              />
            )}
          </Field>

          <div className="w-100 d-flex flex-column align-items-center mt-5">
            <FormButtonComponent className="submit-button w-100" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Validando email...' : 'Continuar'}
            </FormButtonComponent>
            <Paragraph className="d-inline-block mt-4 w-100 mx-auto text-center">
              ¿Ya tenes cuenta?
              {/* TODO - CHANGE TO ONCLICK PROP FUNCTION OR WRAPPER ONCLICK IN THIS COMPONENT */}
              <LinkComponent
                className="d-inline-block ms-1 link-register"
                route={{ pathname: '/login', state: { fromdRecoverPassword: true } }}
              >
                ¡Inicia Sesión!
              </LinkComponent>
            </Paragraph>
          </div>
          <div className="d-flex mt-5 w-100 justify-content-center">
            <a href="https://apps.apple.com/ar/app/nubceo-cash/id1527840728">
              <img src={appstoreButton} alt="Ir a App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nubceo.app&hl=es_AR&gl=US">
              <img className="ms-2" src={gpButton} alt="Ir a Google Play" />
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormEnterEmail;
