import React from 'react';
import { Step } from 'react-joyride';

export const joyrideStyles = {
  options: {
    zIndex: 10000,
    arrowColor: '#fff',
    backgroundColor: '#ffffff0',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.8)',
    primaryColor: '#ffffff00',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#fff',
    width: undefined,
  },
};
const styles = {
  h2: {
    paddingTop: 30,
  },
  buttonNext: {
    borderRadius: 20,
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: 20,
    backgroundColor: '#fff',
    paddingRight: 20,
    paddingLeft: 20,
  },
  buttonClose: {
    width: 20,
    height: 20,
  },
  buttonCloseCompanies: {
    width: 20,
    height: 20,
    left: 500,
  },
  tooltipFooter: {
    marginTop: 0,
  },
};
export const STEPS_DASHBOARD: Step[] = [
  {
    content: <h2 style={styles.h2}>¡Hola! Este es un tutorial para ayudarte a usar la web de nubceo. </h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.first-step',
  },
  {
    content: <h2 style={styles.h2}>Acá vas a poder ver los pagos que te ingresan hoy 👆</h2>,
    placement: 'bottom',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    hideBackButton: true,
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.collections-cards-today',
  },
  {
    content: <h2 style={styles.h2}>Y acá los del próximo día 😉</h2>,
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonBack: {
        borderRadius: 20,
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: 20,
        backgroundColor: '#fff',
        paddingRight: 20,
        paddingLeft: 20,
        position: 'absolute',
        left: 0,
      },
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.collections-cards-next',
  },
  {
    content: (
      <>
        <h2 style={styles.h2}>Tarjeta con la carga impositiva mensual</h2>
        <h4>(Incluye retenciones y percepciones 👇)</h4>
      </>
    ),
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonBack: {
        borderRadius: 20,
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: 20,
        backgroundColor: '#fff',
        paddingRight: 20,
        paddingLeft: 20,
        position: 'absolute',
        left: 0,
      },
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.taxes-step',
  },
  {
    content: <h2 style={styles.h2}>Desde acá ingresás al calendario de ingresos 📅</h2>,
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonBack: {
        borderRadius: 20,
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: 20,
        backgroundColor: '#fff',
        paddingRight: 20,
        paddingLeft: 20,
        position: 'absolute',
        left: 0,
      },
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Siguiente',
    },
    target: '.my-calendar-step',
  },
];
export const STEPS_CALENDAR: Step[] = [
  {
    content: (
      <>
        <h2 style={styles.h2}>En el calendario vas a poder ver los ingresos diarios de cada medio de cobro.</h2>
        <h2>
          Al seleccionar una fecha se mostrarán los correspondientes a ese día, y presionando sobre uno de ellos, se desplegará el detalle
          del mismo 📅
        </h2>
      </>
    ),
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Terminar',
    },
    target: '.my-calendar-step-1',
  },
  {
    content: <h2 style={styles.h2}>Las liquidaciones y ventas se pueden ver en el menú de la izquierda 👈</h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Siguiente',
    },
    hideBackButton: true,
    target: '.calendar-step-settlements',
  },
];
export const STEPS_SETTLEMENTS: Step[] = [
  {
    content: <h2 style={styles.h2}>Liquidaciones $</h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.step-settlements',
  },
  {
    content: <h2 style={styles.h2}>El filtro permite elegir Empresa, Medio de Cobro, Sucursal/Comercio y fechas 🔍</h2>,
    placement: 'auto',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Terminar',
    },
    hideBackButton: true,
    target: '.step-filter-settlements',
  },
  {
    content: <h2 style={styles.h2}>Podés descargar un archivo Excel con toda esta información, filtrando por un período de fechas 📊</h2>,
    placement: 'auto',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Terminar',
    },
    target: '.step-download-report',
  },
  {
    content: <h2 style={styles.h2}>Para poder ver toda esta información, es necesario que completes los datos de tu empresa ✅</h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
      buttonClose: styles.buttonClose,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Siguiente',
    },
    target: '.step-settlement-go-companies',
  },
];
export const STEPS_COMPANIES: Step[] = [
  {
    content: <h2 style={styles.h2}>En esta pantalla vas a poder completarlos ✍</h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
    },
    target: '.step-companies',
  },
  {
    content: <h2 style={{ width: '150%' }}>Ingresá el CUIT y el resto de la información 👇</h2>,
    placement: 'auto',
    styles: {
      buttonNext: {
        display: 'none',
      },
      buttonClose: styles.buttonCloseCompanies,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: undefined,
    },
    hideBackButton: true,
    hideCloseButton: false,
    target: '.step-companies-data',
  },
];
export const STEPS_PLATFORMS_EXTERNALS: Step[] = [
  {
    content: <h2 style={styles.h2}>Ahora ingresá los datos de acceso en los medios de cobro que seleccionaste 👇</h2>,
    placement: 'center',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
    },
    locale: {
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Terminar',
    },
    target: '.step-platforms',
  },
  {
    content: (
      <h2 style={{ width: '150%' }}>
        Si el recuadro está de color rojo significa que falta vincular el medio de cobro, o los datos no son correctos
      </h2>
    ),
    placement: 'auto',
    styles: {
      buttonNext: styles.buttonNext,
      tooltipFooter: styles.tooltipFooter,
    },
    locale: {
      back: 'Anterior',
      last: 'Terminar 🥳',
    },
    hideBackButton: true,
    target: '.step-platforms-external',
  },
];
export const validateWalkthroughStorage = (): void => {
  const tourViewed = window.localStorage.getItem('walkthrough');
  if (tourViewed) {
    localStorage.clear();
    window.localStorage.setItem('walkthrough', 'true');
    window.localStorage.setItem('walkthroughCompanies', 'true');
    window.localStorage.setItem('walkthroughPlatforms', 'true');
  } else {
    localStorage.clear();
  }
};
