import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStateType } from '../../utils/types';
import { PushMessagesType } from '../../containers/MessagesContainer';

export type MessagesState = {
  messagesList: Array<PushMessagesType>;
  loading: LoadingStateType;
};

const initialState: MessagesState = {
  messagesList: [],
  loading: 'idle',
};

export const messagessSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<PushMessagesType>) => {
      const newMessagesList = state.messagesList.filter((message) => message.fcmMessageId !== action.payload.fcmMessageId);
      state.messagesList = [...newMessagesList, action.payload];
    },
    removeMessage: (state, action: PayloadAction<{ id: string }>) => {
      state.messagesList = state.messagesList.filter((messages) => messages.fcmMessageId !== action.payload.id);
    },
  },
});

export const { addMessage, removeMessage } = messagessSlice.actions;
export default messagessSlice.reducer;
