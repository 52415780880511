import React from 'react';
import { FormEnterEmail, FormRecoverPassword } from '../components';
import { stepCodes } from '../pages/RecoverPassword';

const switchRecoverForm = (
  step: string,
  selectedEmail: string,
  setSteps: (e: string) => void,
  setSelectedEmail: (e: string) => void,
  isRenewalPasswordBoolean?: boolean,
): React.ReactElement => {
  switch (step) {
    case stepCodes.ENTER_EMAIL:
      return <FormEnterEmail setSelectedEmail={setSelectedEmail} setSteps={setSteps} isRenewalPasswordBoolean={isRenewalPasswordBoolean} />;
    case stepCodes.ENTER_NEW_PASSWORD:
      return <FormRecoverPassword email={selectedEmail} setSteps={setSteps} />;

    default:
      return <FormEnterEmail setSelectedEmail={setSelectedEmail} setSteps={setSteps} />;
  }
};

export default switchRecoverForm;
