import { Field, Form, Formik } from 'formik';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { getDailySummaries, setDailySummaries, updateDailySummaries } from '../../services/selfService';
import { optionsHours, regexCodes, reportChargerbackOptions } from '../../utils/constants';
import { FormFieldType } from '../../utils/types';
import logger from '../../../src/services/loggerService';
import { CheckBoxComponent, SelectInputComponent } from '../inputs';
import { Title } from '../textComponents';
import ConfigureNotifications from './ConfigureNotifications';
import { ActionButtonComponent } from '../buttons';
import { PlusSVG } from '../../assets/svgComponents';
import variables from '../../scss/_exports.module.scss';
import LoaderScreen from '../LoaderScreen';

const DailySummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const [errorEmails, seterrorEmails] = useState({
    status: false,
    title: '',
  });
  const [isNewSetting, setIsNewSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  // reports
  const [reportIncludeSettlements, setReportIncludeSettlements] = useState(false);
  const [reportIncludeSales, setreportIncludeSales] = useState(false);
  const [reportMediumIsPush, setReportMediumIsPush] = useState(false);
  const [reportMediumIsEmail, setReportMediumIsEmail] = useState(false);
  const [reportFrequency, setReportFrequency] = useState('');
  const [reportChargebackEnabled, setReportChargebackEnabled] = useState(false);
  const [reportEmailAdd, setReportEmailAdd] = useState(false);
  const [reportEmailRecipients, setReportEmailRecipients] = useState('');

  const getSummaries = async () => {
    try {
      setIsLoading(true);
      const { data: result } = await getDailySummaries();
      const response = result.data.documents.docs[0];
      if (response) {
        setNotificationsActive(response);
        setReportIncludeSettlements(response.reportIncludeSettlements);
        setreportIncludeSales(response.reportIncludeSales);
        setReportMediumIsPush(response.reportMediumIsPush);
        setReportMediumIsEmail(response.reportMediumIsEmail);
        setReportFrequency(response.reportFrequency);
        setReportEmailAdd(response.reportEmailRecipients !== '' ? response.reportEmailRecipients : null);
        setReportEmailRecipients(response.reportEmailRecipients);
        setReportChargebackEnabled(response.reportChargebackEnabled);
      } else {
        setIsNewSetting(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        addModalToList({
          body: 'Error al conectar con tu información, por favor intentalo más tarde.',
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
    }
  };

  const [notificationsActive, setNotificationsActive] = useState({
    summaryNotifSun: false,
    summaryNotifMon: false,
    summaryNotifTue: false,
    summaryNotifWed: false,
    summaryNotifThu: false,
    summaryNotifFri: false,
    summaryNotifSat: false,
    summaryNotifHour: '0000',
  });

  useEffect(() => {
    getSummaries();
  }, []);

  useEffect(() => {
    setAlertSuccess(false);
  }, [notificationsActive]);

  const days = [
    { name: 'Lunes', value: 'summaryNotifMon', isActive: notificationsActive.summaryNotifMon },
    { name: 'Martes', value: 'summaryNotifTue', isActive: notificationsActive.summaryNotifTue },
    { name: 'Miércoles', value: 'summaryNotifWed', isActive: notificationsActive.summaryNotifWed },
    { name: 'Jueves', value: 'summaryNotifThu', isActive: notificationsActive.summaryNotifThu },
    { name: 'Viernes', value: 'summaryNotifFri', isActive: notificationsActive.summaryNotifFri },
    { name: 'Sábado', value: 'summaryNotifSat', isActive: notificationsActive.summaryNotifSat },
    { name: 'Domingo', value: 'summaryNotifSun', isActive: notificationsActive.summaryNotifSun },
  ];

  const renderDay = (): any =>
    days.map((day) => (
      <CheckBoxComponent
        containerClassName="mt-2"
        onChange={(): void => setNotificationsActive((prevState) => ({ ...prevState, [day.value]: !day.isActive }))}
        value={day.isActive}
        checked={day.isActive ?? false}
      >
        {day.name}
      </CheckBoxComponent>
    ));

  const saveChanges = async () => {
    if (reportEmailAdd === true) {
      const emailAddresses = reportEmailRecipients.split(',').map((email) => email.trim());
      const invalidEmails = emailAddresses.filter((email) => !regexCodes.email.test(email));

      if (invalidEmails.length > 0) {
        seterrorEmails((prevState) => ({
          ...prevState,
          status: true,
          title: 'El email es incorrecto.',
        }));
      } else if (emailAddresses.length === 0) {
        seterrorEmails((prevState) => ({
          ...prevState,
          status: true,
          title: 'El email no puede quedar en blanco',
        }));
      } else {
        seterrorEmails((prevState) => ({
          ...prevState,
          status: false,
          title: '',
        }));
        try {
          setIsLoading(true);
          if (isNewSetting) {
            await setDailySummaries({
              ...notificationsActive,
              summaryNotifHour: notificationsActive.summaryNotifHour,
              reportIncludeSettlements,
              reportIncludeSales,
              reportMediumIsPush,
              reportMediumIsEmail,
              reportFrequency,
              reportEmailRecipients,
              reportChargebackEnabled,
            })
              .then((res) => {
                logger.info('setDailySummaries.success', res.data);
              })
              .catch((ex) => {
                logger.error('setDailySummaries.error', ex?.message);
              });
          } else {
            seterrorEmails({ status: false, title: '' });
            setAlertSuccess(false);

            await updateDailySummaries({
              ...notificationsActive,
              summaryNotifHour: notificationsActive.summaryNotifHour,
              reportIncludeSettlements,
              reportIncludeSales,
              reportMediumIsPush,
              reportMediumIsEmail,
              reportFrequency,
              reportEmailRecipients,
              reportChargebackEnabled,
            })
              .then((res) => {
                logger.info('updateDailySummaries.success', res.data);
              })
              .catch((ex) => {
                logger.error('updateDailySummaries.error', ex?.message);
              });
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          dispatch(
            addModalToList({
              body: 'Error al conectar con tu información, por favor intentalo más tarde.',
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch(removeModalFromList());
                },
              },
            }),
          );
        }
      }
    } else {
      try {
        setIsLoading(true);
        if (isNewSetting) {
          await setDailySummaries({
            ...notificationsActive,
            summaryNotifHour: notificationsActive.summaryNotifHour,
            reportIncludeSettlements,
            reportIncludeSales,
            reportMediumIsPush,
            reportMediumIsEmail,
            reportFrequency,
            reportEmailRecipients,
            reportChargebackEnabled,
          })
            .then((res) => {
              logger.info('setDailySummaries.success', res.data);
            })
            .catch((ex) => {
              logger.error('setDailySummaries.error', ex?.message);
            });
        } else {
          setAlertSuccess(false);

          await updateDailySummaries({
            ...notificationsActive,
            summaryNotifHour: notificationsActive.summaryNotifHour,
            reportIncludeSettlements,
            reportIncludeSales,
            reportMediumIsPush,
            reportMediumIsEmail,
            reportFrequency,
            reportEmailRecipients,
            reportChargebackEnabled,
          })
            .then((res) => {
              logger.info('updateDailySummaries.success', res.data);
            })
            .catch((ex) => {
              logger.error('updateDailySummaries.error', ex?.message);
            });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        dispatch(
          addModalToList({
            body: 'Error al conectar con tu información, por favor intentalo más tarde.',
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      }
    }
  };

  const AlertMyInformation = (): void => {
    dispatch(
      addModalToList({
        body: 'Se guardaron los cambios exitosamente',
        okButton: {
          buttonText: 'Aceptar',
          onClick: async () => {
            dispatch(removeModalFromList());
          },
        },
      }),
    );
  };

  const initialValues = {
    optionsHours: optionsHours.map((item) => item.name),
    optionsReport: reportChargerbackOptions.map((item) => item.name),
  };
  const handleSubmit = (values: any): void => {
    setReportFrequency(values.optionsHours);
    setReportChargebackEnabled(values.optionsReport);
  };

  return isLoading ? (
    <div className="flex-grow-1 d-flex justify-content-center align-items-center">
      <LoaderScreen />
    </div>
  ) : (
    <>
      {alertSuccess && AlertMyInformation()}
      <p className="fw-light">Notificaciones diarias para informarte los ingresos que vas a recibir en el día.</p>
      <p className="mt-4 font-md">Seleccioná los días en los que te gustaría recibir las notificaciones</p>
      <div className="container-switch ms-2">{renderDay()}</div>

      <p className="mt-5 font-md">Seleccioná el horario en el que te gustaría recibir tus resúmenes.</p>
      <div className="mt-1">
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ setFieldValue }): ReturnType<FC> => (
            <>
              <Form>
                <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                  <>
                    <Field name="optionsHours">
                      {({ field }: FormFieldType): ReactNode => (
                        <SelectInputComponent
                          options={optionsHours.map((option) => ({
                            id: option.value,
                            value: option.value,
                            name: option.name,
                          }))}
                          onOptionClick={(name, value): void => {
                            setFieldValue('optionsHours', null);
                            setFieldValue(name, value);
                            setNotificationsActive((prevState) => ({ ...prevState, summaryNotifHour: value }));
                          }}
                          containerClassName="input"
                          placeholder="Elegí una opción"
                          {...field}
                          value={notificationsActive.summaryNotifHour ?? ''}
                        />
                      )}
                    </Field>
                  </>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>

      <Title className="mt-5">Reportes periódicos en Excel</Title>
      <p className="fw-light">Exportación de datos en Excel que generamos automáticamente y te enviamos con la frecuencia que definas.</p>

      <ConfigureNotifications
        reportIncludeSettlements={reportIncludeSettlements}
        setReportIncludeSettlements={setReportIncludeSettlements}
        reportIncludeSales={reportIncludeSales}
        setreportIncludeSales={setreportIncludeSales}
        reportMediumIsPush={reportMediumIsPush}
        setReportMediumIsPush={setReportMediumIsPush}
        reportMediumIsEmail={reportMediumIsEmail}
        setReportMediumIsEmail={setReportMediumIsEmail}
        reportEmailAdd={reportEmailAdd}
        setReportEmailAdd={setReportEmailAdd}
        reportFrequency={reportFrequency}
        setReportFrequency={setReportFrequency}
        setEmailAdd={setReportEmailRecipients}
        emailAdd={reportEmailRecipients}
        errorEmails={errorEmails}
      />

      <Title className="mt-5">Reporte de Contracargos</Title>
      <p className="fw-light">
        E-mail semanal con un resumen de los contracargos que se efectuaron en cada uno de los medios de cobro, si hubiera.
      </p>

      <div className="mt-1">
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ setFieldValue }): ReturnType<FC> => (
            <>
              <Form>
                <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                  <>
                    <Field name="optionsReport">
                      {({ field }: FormFieldType): ReactNode => (
                        <SelectInputComponent
                          options={reportChargerbackOptions.map((option) => ({
                            // id: option.value,
                            value: option.code,
                            name: option.name,
                          }))}
                          onOptionClick={(name, value): void => {
                            setFieldValue('optionsReport', null);
                            setFieldValue(name, value);
                            setReportChargebackEnabled(value);
                          }}
                          containerClassName="input"
                          placeholder="Elegí una opción"
                          {...field}
                          value={reportChargebackEnabled ?? ''}
                        />
                      )}
                    </Field>
                  </>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <ActionButtonComponent
        text="Guardar Cambios"
        onClick={(): any => saveChanges()}
        icon={<PlusSVG fill={variables.colorBlue} />}
        containerClassName="get-platform-button my-5"
      />
    </>
  );
};

export default DailySummary;
