/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, FC } from 'react';
import { regexCountryCodes } from '../../../utils/constants';
import { formatTaxCode } from '../../../utils/helper';
import { CustomInputProps, InputWrapperProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';

const TaxCodeInput: FC<CustomInputProps & InputWrapperProps> = ({
  countryCode,
  label,
  value,
  errorMessage,
  placeholder,
  name,
  className = '',
  containerClassName,
  required,
  disabled,
  readOnly,
  customChange,
  notFormatValue,
  maxLength,
  autoComplete = 'off',
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!Number(e.target.value.replaceAll('-', '')) && e.target.value !== '') return;
    customChange(name, !notFormatValue ? formatTaxCode(e.target.value, countryCode) : e.target.value);
  };
  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
      <input
        name={name}
        placeholder={placeholder || regexCountryCodes[countryCode || 'default'].taxCodePlaceholder}
        onChange={onChange}
        value={value}
        className={`taxCode-input-component ${errorMessage && 'error'} ${className}`}
        type="text"
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength || regexCountryCodes[countryCode || 'default'].taxCodeLength}
        autoComplete={autoComplete}
      />
    </InputWrapper>
  );
};

export default TaxCodeInput;
