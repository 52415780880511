import React from 'react';
import { CallbackFunction } from '../../../utils/types';
import InputWrapper from '../InputWrapper';

type DateInputComponentType = {
  min?: string;
  max?: string;
  label?: string;
  onChange: CallbackFunction;
  containerClassName?: string;
  value?: string;
  name?: string;
  type?: string;
  errorMessage?: string;
};
const DateInputComponent: React.FC<DateInputComponentType> = ({
  label,
  min,
  max,
  onChange,
  containerClassName,
  value,
  name,
  errorMessage,
  type,
}) => (
  <InputWrapper label={label} containerClassName={containerClassName} errorMessage={errorMessage}>
    <input
      type={type ?? 'date'}
      className={`date-input-component pe-2 ${value ? 'date-input-active' : 'date-input-inactive'}`}
      min={min}
      max={max}
      onChange={onChange}
      value={value}
      name={name}
      lang="es-AR"
    />
  </InputWrapper>
);

export default DateInputComponent;
