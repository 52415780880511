import React, { FC, useEffect, useState, ReactElement } from 'react';
import { Formik, Form, Field } from 'formik';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { FaChevronDown, FaChevronUp, FaRegShareSquare } from 'react-icons/fa';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { ExternalActivePlatform, Client } from '../../../utils/interfaces';
import {
  ActionButtonComponent,
  FormButtonComponent,
  LoaderScreen,
  PlatformRowComponent,
  LinkComponent,
  SelectInputComponent,
  Subtitle,
  TextInputComponent,
  TelephoneInputComponent,
  TaxCodeInputComponent,
  Title,
  Notification,
} from '../../../components';
import { ExternalPlatformType, FormFieldType } from '../../../utils/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import variables from '../../../scss/_exports.module.scss';
import { CloseIconSVG, PlusSVG } from '../../../assets/svgComponents';
import { asyncListClients } from '../../../redux/slices/clientsSlice';
import {
  asyncCreateClient,
  asyncUpdateClient,
  createClientCompany,
  getClientExternalPlatformsV2,
  getCompanyDataForAccessRequest,
  linkClientWithCompany,
  sendInvitationClient,
} from '../../../services/clientsService';
import { countriesApp, optionsIsOrganization, regexCountryCodes } from '../../../utils/constants';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';
import errorHandlerHelper, { errorHandlerHelperV2 } from '../../../utils/errorHandler';
import { formatTaxCode } from '../../../utils/helper';
import logger from '../../../services/loggerService';
// import { segmentTrack } from '../../../utils/segment/hooks';
// import { EVENTS } from '../../../utils/segment/events';
import { addMessage } from '../../../redux/slices/messagesSlice';

const SingleClientContainer: FC = () => {
  const { id } = useParams();
  const { loading: externalPlatformActiveLoading } = useAppSelector((state) => state.externalPlatform);
  const { clientsList, loading: clientsLoading } = useAppSelector((state) => state.clients);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const [externalActivePlatformList, setExternalActivePlatformList] = useState<ExternalActivePlatform[] | null>(null);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [visible, setVisible] = useState(!id);
  const dispatch = useAppDispatch();
  const data = clientsList.find((cl) => cl.id === Number(id));
  const isRadioSelected = (select: string): boolean => selectedRadio === select;
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => setSelectedRadio(e.currentTarget.value);

  const start = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const end = moment().add(1, 'days').format('YYYY-MM-DD');

  const handleEffect = async (): Promise<void> => {
    if (id) {
      try {
        const response = await getClientExternalPlatformsV2(id);
        setExternalActivePlatformList(response);
      } catch (error) {
        logger.error('clientExternalPlatform.error', error);
      }
    }
  };

  const handleClickCollapsed = (): void => {
    setVisible(!visible);
  };

  const handleSalesClient = (): void => {
    navigate(`${url}/sales-client?startDate=${start}&endDate=${end}`, { state: { client: data } });
  };
  const handleSettlementsClient = (): void => {
    navigate(`${url}/settlements-client?startDate=${start}&endDate=${end}`, { state: { client: data } });
  };
  const handleTaxesClient = (): void => {
    navigate(`${url}/taxes-client?startDate=${start}&endDate=${end}`, { state: { client: data } });
  };
  const handleWorkPapers = (): void => {
    navigate(`/work-papers/${id}?dateSince=${start}&dateUntil=${end}`, { state: { client: data } });
  };
  const handleAddPlatform = (): void => {
    navigate(`${url}/external-platform/new`, {
      state: {
        client: data,
        type: [ExternalPlatformType.card, ExternalPlatformType.wallet, ExternalPlatformType.delivery],
      },
    });
  };
  useEffect((): any => {
    handleEffect();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required('Ingresá un nombre válido').nullable(),
    surname: Yup.string().nullable(),
    email: Yup.string().email('Ingresá un email válido').required('Este campo es requerido'),
    countryCode: Yup.string().required('Seleccioná el país del cliente'),
    taxCode: Yup.string()
      .when('countryCode', (countryCode: string, schema: Yup.StringSchema) =>
        schema.matches(
          regexCountryCodes[countryCode]?.taxCodeRegExp,
          `Ingresá un ${regexCountryCodes[countryCode]?.name || 'CUIT / RUT / NIT'} válido, con el formato ${
            regexCountryCodes[countryCode]?.taxCodePlaceholder
          }`,
        ),
      )
      .when('countryCode', (countryCode: string, schema: Yup.StringSchema) =>
        schema.required(`Ingresá un ${regexCountryCodes[countryCode]?.name || 'CUIT / RUT / NIT'} válido`),
      ),
    phone: Yup.string()
      .when('countryCode', (countryCode: string, schema: Yup.StringSchema) =>
        schema.min(5, `Ingresá un teléfono válido, con el formato ${regexCountryCodes[countryCode]?.telephonePlaceholder}`),
      )
      .when('countryCode', (countryCode: string, schema: Yup.StringSchema) =>
        schema.required(`Ingresá un teléfono válido, con el formato ${regexCountryCodes[countryCode]?.telephonePlaceholder}`),
      ),
  });

  const initialValues = {
    name: data?.name || '',
    surname: data?.surname || '',
    email: data?.email || '',
    taxCode: formatTaxCode(data?.taxCode, data?.countryCode) || '',
    phone: data?.phone || '',
    isOrganization: data?.isOrganization || false,
    countryCode: data?.countryCode || '',
    isClient: true,
    isProvider: false,
  };

  const goExternalPlatform = (idClient: string): void => {
    navigate(`${idClient}/external-platform/new`, {
      state: {
        client: data,
        type: [ExternalPlatformType.card, ExternalPlatformType.wallet, ExternalPlatformType.delivery],
      },
    });
  };
  const MessageModal = (): ReactElement => (
    <>
      <p>Para que nubceo traiga los datos de los distintos medios</p>
      <p>de cobro sólo tenés que cargar el usuario y la contraseña de cada uno de ellos (Excepto Mercado Pago).</p>
    </>
  );

  const RequestPermissionMessageModal = (): ReactElement => (
    <>
      <p>Este cliente ya existe en nubceo</p>
      <p>Haz click en Aceptar para solicitarle permisos para ver su información, caso contrario haz click en Cancelar.</p>
    </>
  );

  const CompanyNotFoundMessageModal = (): ReactElement => (
    <>
      <p>No existe ninguna compañía en nubceo con el identificador indicado </p>
      <p>Por favor verifica con tu cliente esta información</p>
    </>
  );

  const handleRequestPermission = async (dataResp: any): Promise<void> => {
    try {
      const company = await getCompanyDataForAccessRequest({ username: dataResp.data.data.email, taxCode: dataResp.data.data.taxCode });
      const { id: companyId, tenantId } = company.data.data;
      await linkClientWithCompany(dataResp.data.data.id.toString(), { companyId: Number(companyId), tenantId });
      navigate(`/clients/${dataResp.data.data.id}`);
    } catch (error: any) {
      if (error.name === 'COMPANY_NOT_FOUND') {
        dispatch(
          addModalToList({
            body: <CompanyNotFoundMessageModal />,
            required: true,
            okButton: {
              buttonText: 'Aceptar',
              onClick: (): { payload: undefined; type: string } => dispatch(removeModalFromList()),
            },
          }),
        );
      }
    }
  };

  const handleSubmit = async (values: typeof initialValues): Promise<void> => {
    values.taxCode = values.taxCode.trim().replaceAll('-', '');
    if (values.isOrganization) values.surname = '';
    if (data && id) {
      await asyncUpdateClient(id, values).catch((e) => errorHandlerHelper(e, dispatch));
      dispatch(asyncListClients({})).catch((e) => errorHandlerHelper(e, dispatch));
      dispatch(
        addMessage({
          fcmMessageId: JSON.stringify(values),
          data: {
            type: 'local-notification',
            title: 'Actualizado con éxito',
            message: 'Se actualizó la información de tu cliente',
            typeStyle: 'message-success',
          },
        }),
      );
    } else {
      let dataResp: AxiosResponse<{ data: Client }>;
      try {
        dataResp = await asyncCreateClient(values);
        if (dataResp.data.data.id) {
          await errorHandlerHelperV2(() => createClientCompany(dataResp.data.data.id.toString()), dispatch).then(() => {
            dispatch(
              addModalToList({
                body: <MessageModal />,
                required: true,
                okButton: {
                  buttonText: 'Seguir...',
                  onClick: async () => goExternalPlatform(dataResp.data.data.id.toString()),
                },
                cancelButton: {
                  buttonText: 'No, en otro momento',
                  onClick: async () => navigate(`/clients`),
                },
              }),
            );
          });
          dispatch<void>(asyncListClients({}));
          dispatch(
            addMessage({
              fcmMessageId: JSON.stringify(values),
              data: {
                type: 'local-notification',
                title: 'Creado con éxito',
                message: 'Tu cliente se creó correctamente',
                typeStyle: 'message-success',
              },
            }),
          );
        }
      } catch (error: any) {
        if (error.name === 'EMAIL_USED')
          dispatch(
            addModalToList({
              body: <RequestPermissionMessageModal />,
              required: true,
              okButton: {
                buttonText: 'Aceptar',
                onClick: async (): Promise<void> => handleRequestPermission(dataResp),
              },
              cancelButton: {
                buttonText: 'Cancelar',
                onClick: async () => navigate(`/clients`),
              },
            }),
          );
        logger.error('createClientCompany.error', error);
        throw error;
      }
    }
  };
  const handleShareClient = (): void => {
    dispatch(
      addModalToList({
        body: `¿Deseás enviarle la invitación a ${data?.name} ${data?.surname} para que comience a utilizar nubceo?`,
        okButton: {
          buttonText: 'Aceptar',
          onClick: (): Promise<void> =>
            sendInvitationClient(id || '')
              .then((res) => {
                logger.info('sendInvitation.success', res.data);
              })
              .catch((ex) => {
                logger.error('sendInvitation.error', ex?.message);
              }),
        },
      }),
    );
  };
  const goBack = (): void => {
    // if (id === 'new') segmentTrack(EVENTS.CLIENT.CREATE.CANCEL);
    // if (id !== 'new') segmentTrack(EVENTS.CLIENT.UPDATE.CANCEL);
    navigate(-1);
  };
  return clientsLoading === 'pending' ? (
    <LoaderScreen />
  ) : (
    <div className="client-entity">
      <div className="mt-5 d-flex flex-row justify-content-between align-items-center col-12 col-md-10 col-lg-9">
        <div>
          <Title>{!data ? 'Nuevo cliente' : `${data.name ?? ''} ${data.surname ?? ''}`}</Title>
        </div>
        {data?.RelTenant && (
          <div className="d-flex flex-row shareButton" onClick={handleShareClient} role="none">
            <FaRegShareSquare size={25} className="color-blue gift-icon" />
            <p className="ms-2 color-blue">Invitar a Nubceo cash</p>
          </div>
        )}
        <CloseIconSVG className="close-icon color-blue" height={40} width={40} onClick={goBack} />
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, values, touched, isSubmitting, setFieldValue, dirty }): ReturnType<FC> => (
          <>
            <div className="accordion-component w-75">
              <div className="w-100">
                <div className="container-fluid collapsed" onClick={(): void => handleClickCollapsed()} role="none">
                  <div className="row align-items-center m-0">
                    <div className="d-flex p-3 justify-content-between">
                      {!visible ? <p>Mostrar datos del cliente</p> : <p>Ocultar datos del cliente</p>}
                      {!visible ? (
                        <FaChevronDown size={25} className="color-blue" />
                      ) : (
                        <FaChevronUp size={25} className="color-blue d-flex " />
                      )}
                    </div>
                  </div>
                </div>
                <div className={`accordion-component-content flex-row flex-wrap ${visible && 'collapsed'}`}>
                  <Form>
                    {optionsIsOrganization.map((item) => (
                      <Field type="checkbox" name="isOrganization">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <label htmlFor={item.id} className="me-4 cursor">
                            <input
                              id={item.id}
                              {...field}
                              className="me-1 cursor"
                              type="radio"
                              value={item.value}
                              checked={isRadioSelected(item.value)}
                              onChange={(e): void => {
                                handleRadioClick(e);
                                setFieldValue('isOrganization', null);
                                setFieldValue('isOrganization', item.value === 'isOrganization');
                              }}
                            />
                            {item.label ?? ''}
                          </label>
                        )}
                      </Field>
                    ))}
                    <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                      <Field name="name" type="text">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <TextInputComponent
                            errorMessage={touched.name && errors.name}
                            containerClassName="input"
                            label={values.isOrganization ? 'Empresa' : 'Nombre'}
                            placeholder={values.isOrganization ? 'Nombre de Empresa' : 'Nombre del cliente'}
                            required
                            {...field}
                            value={values.name ?? ''}
                          />
                        )}
                      </Field>
                      {!values.isOrganization && (
                        <Field name="surname" type="text">
                          {({ field }: FormFieldType): React.ReactNode => (
                            <TextInputComponent
                              errorMessage={touched.surname && errors.surname}
                              containerClassName="input"
                              label="Apellido"
                              placeholder="Apellido del cliente"
                              {...field}
                              value={values.surname ?? ''}
                            />
                          )}
                        </Field>
                      )}
                      <Field name="countryCode">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <SelectInputComponent
                            label="País"
                            options={countriesApp.map((company) => ({
                              id: company.code,
                              value: company.code,
                              name: company.name,
                            }))}
                            onOptionClick={setFieldValue}
                            containerClassName="input"
                            required
                            {...field}
                            value={values.countryCode ?? ''}
                            placeholder="Elegí el país de tu cliente"
                            errorMessage={touched.countryCode && errors.countryCode}
                          />
                        )}
                      </Field>
                      <Field name="taxCode" type="text">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <TaxCodeInputComponent
                            errorMessage={touched.taxCode && errors.taxCode}
                            containerClassName="input"
                            label={`${regexCountryCodes[values.countryCode || 'default']?.name || 'CUIT/RUT/NIT'}`}
                            placeholder={`${regexCountryCodes[values.countryCode || 'default']?.name || 'CUIT/RUT/NIT'} del cliente`}
                            required
                            countryCode={values.countryCode}
                            customChange={setFieldValue}
                            {...field}
                            value={values.taxCode ?? ''}
                          />
                        )}
                      </Field>
                      <Field name="email" type="email">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <TextInputComponent
                            errorMessage={touched.email && errors.email}
                            containerClassName="input"
                            label="Email"
                            required
                            placeholder="Email del cliente"
                            {...field}
                            value={values.email ?? ''}
                          />
                        )}
                      </Field>
                      <Field name="phone" type="tel">
                        {({ field }: FormFieldType): React.ReactNode => (
                          <TelephoneInputComponent
                            errorMessage={touched.phone && errors.phone}
                            containerClassName="input"
                            label="Teléfono"
                            placeholder="Teléfono del cliente"
                            required
                            {...field}
                            value={values.phone ?? ''}
                            countryCode={values.countryCode}
                            customChange={setFieldValue}
                          />
                        )}
                      </Field>
                      <div>
                        <p className="font-xs mt-3">
                          {`* Te pedimos el ${
                            regexCountryCodes[values.countryCode as string]?.name || 'CUIT/RUT/NIT'
                          } sólo para identificar tu empresa dentro de nubceo. No te preocupes que no vamos a compartir esta
                      información con nadie. `}
                          <LinkComponent route="#" className="font-xs d-inline" size="xs">
                            Politica de Privacidad
                          </LinkComponent>
                        </p>
                      </div>
                    </div>
                    <FormButtonComponent type="submit" className="mt-4 mb-2" disabled={isSubmitting || !dirty}>
                      Guardar
                    </FormButtonComponent>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
      {data && !data?.relTenantId && (
        <div className="notifications-container w-75 mt-2">
          <Notification
            notification={{
              type: 'warning',
              title: 'Atención',
              body: `Estamos esperando que el cliente acepte la solicitud de acceso a sus datos`,
            }}
          />
        </div>
      )}
      {data?.relTenantId && externalPlatformActiveLoading !== 'pending' && externalActivePlatformList && (
        <div className="mb-3">
          <div className="mt-2">
            <Subtitle>Liquidaciones y Ventas</Subtitle>
            <div className="d-flex">
              <ActionButtonComponent
                text="Ver Liquidaciones"
                onClick={handleSettlementsClient}
                icon={<PlusSVG fill={variables.colorWhite} />}
                containerClassName="me-2 add-platform-button"
              />
              <ActionButtonComponent
                text="Ver Ventas"
                onClick={handleSalesClient}
                icon={<PlusSVG fill={variables.colorWhite} />}
                containerClassName="me-2 add-platform-button"
              />
              <ActionButtonComponent
                text="Ver Impuestos"
                onClick={handleTaxesClient}
                icon={<PlusSVG fill={variables.colorWhite} />}
                containerClassName="me-2 add-platform-button"
              />
              <ActionButtonComponent
                text="Papeles de Trabajo"
                onClick={handleWorkPapers}
                icon={<PlusSVG fill={variables.colorWhite} />}
                containerClassName="me-2 add-platform-button"
              />
            </div>
          </div>
          <Title className="mt-5">Configuración</Title>
          <div className="col-12 col-md-10 col-lg-9 mt-2 d-flex flex-column justify-content-between">
            <Subtitle>Medios de cobro</Subtitle>
            <ActionButtonComponent
              text="Agregar medio de cobro"
              onClick={handleAddPlatform}
              icon={<PlusSVG fill={variables.colorWhite} />}
              containerClassName="me-2 add-platform-button"
            />
          </div>
          <div className="pb-5 col-12 col-md-10 col-lg-9 d-flex flex-wrap mt-4">
            {externalActivePlatformList?.length > 0
              ? externalActivePlatformList
                  ?.filter((p) => p.PlatformExternal.type !== ExternalPlatformType.erp)
                  ?.map((item: ExternalActivePlatform) => (
                    <PlatformRowComponent key={item.id} containerClassName="platform-row" platform={item} />
                  ))
              : 'No tiene medios de cobro asociados'}
          </div>
        </div>
      )}
      {/* TODO - IT IS HIDDEN UNTIL WE HAVE A LARGER AMOUNT OF ERP TO SHOW */}

      {/* {data?.relTenantId && externalPlatformActiveLoading !== 'pending' && externalActivePlatformList && (
        <div className="mb-3">
          <div className="col-12 col-md-10 col-lg-9 mt-5 d-flex flex-column justify-content-between">
            <Subtitle>Sistemas de Gestión (ERP)</Subtitle>
            <ActionButtonComponent
              text="Agregar Sistema de Gestión (ERP)"
              onClick={handleAddErpPlatform}
              icon={<PlusSVG fill={variables.colorWhite} />}
              containerClassName="me-2 add-platform-button"
            />
          </div>
          <div className="pb-5 col-12 col-md-10 col-lg-9 d-flex flex-wrap mt-4">
            {externalActivePlatformList?.length > 0
              ? externalActivePlatformList
                  ?.filter((p) => p.PlatformExternal.type === ExternalPlatformType.erp)
                  ?.map((item: ExternalActivePlatform) => (
                    <PlatformRowComponent key={item.id} containerClassName="platform-row" platform={item} />
                  ))
              : 'No tiene sistemas de gestión asociados'}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SingleClientContainer;
