/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { InputWrapperProps, TextInputProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';

const PasswordInput: FC<TextInputProps & InputWrapperProps> = ({
  label,
  onChange,
  value,
  errorMessage,
  placeholder,
  name,
  className = '',
  containerClassName,
  required,
  disabled,
  readOnly,
  autoComplete = 'off',
}) => {
  const [hidden, setHidden] = useState(true);
  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
      <div className={`password-input-component position-relative d-flex ${errorMessage && 'error'}`}>
        <input
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={`flex-grow-1 ${className}`}
          type={hidden ? 'password' : 'text'}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
        <button type="button" onClick={(): void => setHidden(!hidden)}>
          {!hidden ? <FaEye /> : <FaEyeSlash />}
        </button>
      </div>
    </InputWrapper>
  );
};

export default PasswordInput;
