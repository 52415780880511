import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import AccordionComponent from '../../../Accordion';
import { Tax } from '../../../../utils/interfaces';
import externalPlatformIcon from '../../../../utils/externalPlatformIcon';
import { decimalFormat } from '../../../../utils/stringUtils';
import { EVENTS } from '../../../../utils/segment/events';

type TaxProps = {
  tax: Tax;
  isModal?: boolean;
};

const TaxRow: React.FC<TaxProps> = ({ tax }) => {
  const headerContent = (): React.ReactElement => (
    <>
      <div className="col-2 offset-sm-0 p-0" data-tip="Plataforma">
        {externalPlatformIcon({ code: tax.platformExternalCode, className: 'external-platform-logo' })}
        <ReactTooltip />
      </div>
      <div className="col-3 col-sm-3" data-tip="Fecha">
        <span className="d-none d-lg-inline">{moment(tax.date).locale('es').format('DD MMMM YYYY')}</span>
        <span className="d-none d-md-inline d-lg-none">{moment(tax.date).locale('es').format('DD/MM/YY')}</span>
        <span className="d-inline d-md-none">{moment(tax.date).locale('es').format('D/MM')}</span>
        <ReactTooltip />
      </div>
      <div className="col-3 d-sm-inline" data-tip="Descripción / Nombre">
        {tax.name}
        <ReactTooltip />
      </div>
      <div className="amount col-3 col-lg-3 p-0 font-weight-medium text-right" data-tip="Monto">
        ${decimalFormat(tax.amount)}
        <ReactTooltip />
      </div>
    </>
  );

  const mainContent = (): React.ReactElement => (
    <>
      <div className="w-100 d-flex flex-column flex-md-row m-auto">
        <div className="mt-4 px-4 offset-lg-3 col-12 offset-md-2 col-md-8 col-lg-6 align-items-center" style={{ minWidth: '200px' }}>
          <div className="d-flex">
            <div className="col-6 col-lg-6 col-12 fw-bold">Datos de la liquidación</div>
          </div>
          <div className="d-flex">
            <div className="col-6 col-lg-6 fw-bold text-start">Fecha:</div>
            <span className="col-6 col-lg-6 text-end">{moment(tax.Settlement?.date).locale('es').format('DD MMMM YYYY')}</span>
          </div>
          <div className="d-flex">
            <div className="col-6 col-lg-6 fw-bold text-start">Referencia:</div>
            <div className="col-6 col-lg-6 text-end">({tax.Settlement.externalReference ? tax.Settlement.externalReference : '--'})</div>
          </div>
          <div className="d-flex">
            <div className="col-6 col-lg-6 fw-bold text-start">Monto Bruto:</div>
            <div className="col-6 col-lg-6 text-end">
              {tax.currencyCode === 'USD' ? 'USD' : '$'}
              {decimalFormat(tax.Settlement?.grossAmount)}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <AccordionComponent
      className="tax-row"
      header={headerContent()}
      content={mainContent()}
      analytics={{
        event: EVENTS.TAXES.ROW.EXPAND,
        data: {
          taxId: tax.id,
          externalReference: tax.Settlement.externalReference,
          platform: tax.platformExternalCode,
        },
      }}
    />
  );
};
export default TaxRow;
