/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { BsCheck, BsFillExclamationTriangleFill, BsInfoCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { CloseIconSVG } from '../../assets/svgComponents';
import { PushMessagesType } from '../../containers/MessagesContainer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removePEC } from '../../redux/slices/externalPlatformSlice';
import { externalPlatformsBasicData } from '../../utils/constants';
import { handleTaxCode } from '../../utils/stringUtils';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { CallbackFunction } from '../../utils/types';
import { TypesOfNotifications } from '../../utils/interfaces';

const MessageNotification: React.FC<{ message: PushMessagesType; removeMessage: CallbackFunction }> = ({ message, removeMessage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userProfile);
  const { companiesList } = useAppSelector((state) => state.company);

  const getCompany = (): string | undefined => {
    const business = companiesList.find((company) => company.id === message.data.message);
    return business?.name ?? 'tus empresas,';
  };

  let typeStyle: string;
  let messageTitle: string;
  let messageCta: string;
  let messageBody: string | React.ReactElement;
  let messageIcon;
  let messageLink: string | undefined;

  const taxCode = handleTaxCode(user?.Tenant?.countryCode || '');

  const bodyNotification = (data: PushMessagesType): React.ReactElement => {
    const messageData = JSON.parse(data.data.message)[0];
    return (
      <div onClick={(): void => navigate(`/companies/${messageData?.companyId}`)} role="none" style={{ cursor: 'pointer' }}>
        <p>{`¡Acordate de configurar el ${taxCode} para ${getCompany()} así podemos mostrarte todos los datos!`}</p>
      </div>
    );
  };

  switch (message.data.type) {
    case TypesOfNotifications.DATA_REPORT:
      typeStyle = 'message-info';
      messageTitle = 'Ya tenemos tu reporte!';
      messageBody = 'Presiona acá para descargarlo';
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      messageLink = message.data.message;
      break;
    case TypesOfNotifications.ADMIN_API_NOTIFICATION:
      typeStyle = 'message-info';
      messageTitle = message.data.title;
      messageCta = message.data.cta ?? 'https://cash.nubceo.com/';
      messageBody = message.data.message;
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      messageLink = message.data?.cta;
      break;
    case TypesOfNotifications.TAXCODE_EMPTY:
      typeStyle = 'message-info';
      messageTitle = 'nubceo';
      messageBody = bodyNotification(message);
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
    case TypesOfNotifications.CASH_DAILY_SUMMARY: {
      const jsonMsg = JSON.parse(message.data.message)[0];
      typeStyle = 'message-info';
      messageTitle = 'Tu resumen diario';
      messageBody = parseInt(jsonMsg.amount, 10)
        ? `Hola ${user?.firstName}, 
      tus ingresos de hoy son $${jsonMsg.amount}, 
      en su mayoría provenientes de ${jsonMsg.platformExternalName} (${jsonMsg.largePlatformExternalPercent}%)`
        : 'No tenés ingresos hoy';
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
    }
    case TypesOfNotifications.PLATFORM_EXTERNAL_AUTH:
    case TypesOfNotifications.UNCONFIGURED_CREDENTIAL: {
      const jsonMsg = JSON.parse(message.data.message);
      typeStyle = 'message-error';
      messageTitle = '¡Ups!';
      messageBody = 'Ha habido un error al intentar conectar con esta plataforma. Por favor intentalo nuevamente más tarde.';
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      messageLink = message.data.message;
      if (jsonMsg.status === 'SUCCESS') {
        typeStyle = 'message-success';
        messageTitle = '¡Bien!';
        messageBody = `Ya hemos logrado conectar con éxito la plataforma de ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        }`;
        messageIcon = <BsCheck className="message-icon" />;
        messageLink = '#';
        // dispatch(removePEC(jsonMsg.platformExternalCredentialId));
      }
      if (jsonMsg.status === 'INVALID_CREDENTIALS') {
        typeStyle = 'message-error';
        messageTitle = 'Error';
        messageBody = `El usuario y/o contraseña de la plataforma ${
          externalPlatformsBasicData(jsonMsg.platformExternalCode).name
        } son incorrectos`;
        messageIcon = <BsFillExclamationTriangleFill className="message-icon" />;
        messageLink = `/external-platform/${jsonMsg.platformExternalCredentialId}`;
        // dispatch(removePEC(jsonMsg.platformExternalCredentialId));
      }
      break;
    }
    case TypesOfNotifications.LOCAL_NOTIFICATION: {
      typeStyle = message.data.typeStyle ?? 'message-info';
      messageTitle = message.data.title;
      messageBody = message.data.message;
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
    }
    default:
      typeStyle = 'message-info';
      messageTitle = 'nubceo';
      messageBody = 'No podemos mostrar tu mensaje';
      messageIcon = <BsInfoCircleFill className="message-icon" />;
      break;
  }

  const handleClick = (): void => {
    if (message.data.type === 'data-report') {
      // segmentTrack(EVENTS.REPORTS.OPEN.SUCCESS);
    }
  };

  return user ? (
    <div className={`border-round message-notification ${typeStyle}`}>
      <div className={`ps-2 ${typeStyle}`}>{messageIcon}</div>
      <a onClick={handleClick} className="message-content" href={messageLink}>
        <div className="font-sm font-weight-medium">{messageTitle}</div>
        <div className="font-xs">{messageBody}</div>
      </a>
      <button
        type="button"
        className="font-weight-light font-xs d-flex align-items-cemter message-close"
        onClick={(): void => removeMessage(message.fcmMessageId)}
      >
        <CloseIconSVG width={25} height={25} className="fill-gray-dark close-icon" />
      </button>
    </div>
  ) : null;
};
export default MessageNotification;
