import { Dispatch, SetStateAction } from 'react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { FormikHelpers, FormikValues } from 'formik';
import { AxiosError } from 'axios';
import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';
import { asyncListBranches } from '../redux/slices/branchSlice';
import { Branch, ExternalPlatformByBranch } from './interfaces';
import { cleanNullValues } from './helper';
import { THIS_MONTH_DATE_FILTER_OPTION, optionsDateValues } from './constants';
import { getPlatformsByBranch } from '../services/externalPlatformService';
import errorHandlerHelper from './errorHandler';

export enum ScreenType {
  SETTLEMENTS = 'settlements',
  SALES = 'sales',
  EXPENSE_REPORTS = 'expense-reports',
  CALENDAR = 'calendar',
  SETTLEMENTS_CLIENT = 'settlements-client',
  SALES_CLIENT = 'sales-client',
  TAXES_CLIENT = 'taxes-client',
  WORK_PAPERS = 'work-papers',
}

type ClearAllFiltersFunction = (
  screen: string,
  setFieldValue: FormikHelpers<any>['setFieldValue'],
  initialValues: Record<string, any>,
  navigate: NavigateFunction,
  dispatch?: ThunkDispatch<unknown, unknown, AnyAction>,
  setConcatenateBranches?: Dispatch<SetStateAction<Branch[]>>,
  clientID?: string,
) => void;

// Function to make the API call with the search filter
export const searchBranches = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setConcatenateBranches: Dispatch<SetStateAction<Branch[]>>,
  searchValue: string,
): void => {
  dispatch(asyncListBranches({ 'filter[name][iLike]': `%${searchValue}%` }))
    .then((res) => {
      if (res.payload.status === 200) {
        setConcatenateBranches([]);
        setConcatenateBranches(res.payload.data);
      }
    })
    .catch((err) => {
      errorHandlerHelper(err, dispatch);
    });
};

// Call the API with the value debounced
export const debouncedInputValueFunction = (
  debouncedInputValue: string,
  setConcatenateBranches: Dispatch<SetStateAction<Branch[]>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): void => {
  if (debouncedInputValue !== '') {
    setConcatenateBranches([]);
    searchBranches(dispatch, setConcatenateBranches, debouncedInputValue);
  } else {
    dispatch(asyncListBranches())
      .then((res) => {
        if (res.payload.status === 200) {
          setConcatenateBranches([]);
          setConcatenateBranches(res.payload.data);
        }
      })
      .catch((err) => {
        errorHandlerHelper(err, dispatch);
      });
  }
};

// Function to concatenate the branches when the scroll reaches the end
export const joinBranchesWhenPaginating = (
  endOfScroll: boolean,
  setConcatenateBranches: Dispatch<SetStateAction<Branch[]>>,
  setLoadingBranches: Dispatch<SetStateAction<boolean>>,
  branchesList: Branch[],
): void => {
  if (endOfScroll) {
    setConcatenateBranches((prevBranches) => {
      const uniqueBranches = Array.from(new Set([...prevBranches, ...branchesList].map((b) => b.id)))
        .map((id) => prevBranches.find((b) => b.id === id) || branchesList.find((b) => b.id === id))
        .filter(Boolean) as Branch[];
      return uniqueBranches;
    });
    setLoadingBranches(false);
  }
};

// Function to list branches depending on the filter applied
export const listBranchesAccordingToAppliedFilters = (
  selectedCompany: string[] | undefined,
  selectedPlatform: string | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setConcatenateBranches: Dispatch<SetStateAction<Branch[]>>,
): void => {
  // Query with active company and platform filters
  if (selectedCompany?.length && selectedPlatform) {
    dispatch(
      asyncListBranches({ 'filter[companyId][in]': `[${selectedCompany}]`, 'filter[platformExternalCode][eq]': `${selectedPlatform}` }),
    )
      .then((res) => {
        if (res.payload.status === 200) {
          setConcatenateBranches([]);
          setConcatenateBranches(res.payload.data);
        }
      })
      .catch((error) => {
        errorHandlerHelper(error, dispatch);
      });
  }
  // Query with active company filter
  if (selectedCompany?.length && !selectedPlatform) {
    dispatch(asyncListBranches({ 'filter[companyId][in]': `[${selectedCompany}]` }))
      .then((res) => {
        if (res.payload.status === 200) {
          setConcatenateBranches([]);
          setConcatenateBranches(res.payload.data);
        }
      })
      .catch((error) => {
        errorHandlerHelper(error, dispatch);
      });
  }
  // Query with active platform filter
  if (!selectedCompany?.length && selectedPlatform) {
    dispatch(asyncListBranches({ 'filter[platformExternalCode][eq]': `${selectedPlatform}` }))
      .then((res) => {
        if (res.payload.status === 200) {
          setConcatenateBranches([]);
          setConcatenateBranches(res.payload.data);
        }
      })
      .catch((error) => {
        errorHandlerHelper(error, dispatch);
      });
  }
};

// Function to list initial branches
export const listInitialBranches = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setConcatenateBranches: Dispatch<SetStateAction<Branch[]>>,
): void => {
  dispatch(asyncListBranches())
    .then((res) => {
      if (res.payload.status === 200) {
        setConcatenateBranches([]);
        setConcatenateBranches(res.payload.data);
      }
    })
    .catch((error) => {
      errorHandlerHelper(error, dispatch);
    });
};

// Function to page the branches
export const branchPagination = (
  actualPage: number,
  pages: number,
  endOfScroll: boolean,
  selectedCompany: string[] | undefined,
  selectedPlatform: string | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setLoadingBranches: Dispatch<SetStateAction<boolean>>,
  setActualPage: Dispatch<SetStateAction<number>>,
): void => {
  if (actualPage < pages && endOfScroll) {
    // Evaluates which filters are selected, and makes the query depending on each one
    if (selectedCompany?.length && selectedPlatform) {
      dispatch<void>(
        asyncListBranches({
          page: `${actualPage + 1}`,
          'filter[companyId][in]': `[${selectedCompany}]`,
          'filter[platformExternalCode][eq]': `${selectedPlatform}`,
        }),
      );
    }
    // Query with the company filter active
    if (selectedCompany?.length && !selectedPlatform) {
      dispatch<void>(asyncListBranches({ page: `${actualPage + 1}`, 'filter[companyId][in]': `[${selectedCompany}]` }));
    }
    // Query with active platform filter
    if (!selectedCompany?.length && selectedPlatform) {
      dispatch<void>(asyncListBranches({ page: `${actualPage + 1}`, 'filter[platformExternalCode][eq]': `${selectedPlatform}` }));
    }
    // Query without company or platform filters
    if (!selectedCompany?.length && !selectedPlatform) {
      dispatch<void>(asyncListBranches({ page: `${actualPage + 1}` }));
    }

    setLoadingBranches(true);
    setActualPage(actualPage + 1);
  }
};

// Handle input search
export const handleSearchInput = (
  setActualPage: Dispatch<SetStateAction<number>>,
  setDebouncedInputValue: Dispatch<SetStateAction<string>>,
  inputValueSearch: string | undefined,
): (() => void) => {
  setActualPage(1);
  // Debounce the value of inputValueSearch
  const debounceTimeout = setTimeout(() => {
    setDebouncedInputValue(inputValueSearch || '');
  }, 1000);
  return (): void => {
    // Clear the timer if the value changes before it activates
    clearTimeout(debounceTimeout);
  };
};

const handlePresetDateFiltersValues = (values: FormikValues): FormikValues => {
  const start = optionsDateValues[values.startDate]?.[0] || values.startDate;
  const end = optionsDateValues[values.endDate]?.[1] || values.endDate;
  values.startDate = start;
  values.endDate = end;
  return values;
};

// Function to apply filters
export const handleSubmit = (values: FormikValues, navigate: NavigateFunction, url: string, isTaxesPage?: boolean): void => {
  const newValues = handlePresetDateFiltersValues(values);
  const notNullValues = cleanNullValues(isTaxesPage ? newValues : values);
  const params = new URLSearchParams(notNullValues).toString();
  navigate(params ? `?${params}` : url);
};

// Declare initial values
export const getInitialValues = (URLFilters: Record<string, string>, isExpenseReportsPage: boolean) => {
  const initialValues = {
    companies: URLFilters.companies?.split(',') || null,
    platformCode: URLFilters.platformCode || null,
    included_settlement: URLFilters.included_settlement || null,
    type: URLFilters.type || null,
    startDate: URLFilters.startDate || null,
    startPaymentDate: URLFilters.startPaymentDate || null,
    endDate: URLFilters.endDate || null,
    endPaymentDate: URLFilters.endPaymentDate || null,
    dateSince: URLFilters.dateSince || null,
    dateUntil: URLFilters.dateUntil || null,
    branches: URLFilters.branches?.split(',') || null,
    presentedEndDate: URLFilters.presentedEndDate || null,
    presentedStartDate: URLFilters.presentedStartDate || null,
    taxTypeCode: URLFilters.taxTypeCode?.split(',') || null,
    presetDateFilters: URLFilters.presetDateFilters || isExpenseReportsPage ? THIS_MONTH_DATE_FILTER_OPTION : null,
  };
  return initialValues;
};

// Alphabetical order of the platforms
export const sortPlatforms = (
  value: { id: string | number; name: string; value: string }[],
): { id: string | number; name: string; value: string }[] =>
  value.slice().sort((a, b) => {
    if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
      return -1;
    }
    if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
      return 1;
    }
    return 0;
  });

// Function to remove filters individually
export const deleteFilterTab = (values: FormikValues, property: string, navigate: NavigateFunction, url: string): void => {
  const newValues = { ...values, [property]: null };
  const notNullValues = cleanNullValues(newValues);
  const params = new URLSearchParams(notNullValues).toString();
  navigate(params ? `?${params}` : url);
};

export const handlePlatformsList = async (
  branchIds: string,
  setPlatformExternalArrayByBranch: Dispatch<SetStateAction<ExternalPlatformByBranch[]>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<void> => {
  try {
    const { data: response } = await getPlatformsByBranch({ 'filter[branchId][in]': `[${branchIds}]` });
    return setPlatformExternalArrayByBranch(response.data);
  } catch (error) {
    return errorHandlerHelper(error as AxiosError, dispatch);
  }
};

// Function to clean all filters
export const clearAllFilters: ClearAllFiltersFunction = (
  screen,
  setFieldValue,
  initialValues,
  navigate,
  dispatch,
  setConcatenateBranches,
  clientID,
) => {
  const keys = Object.keys(initialValues);
  keys.map((item) => setFieldValue(item, null));
  if (dispatch && setConcatenateBranches) {
    listInitialBranches(dispatch, setConcatenateBranches);
  }
  // Two-month default screen filter
  const startFilterDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
  const endFilterDate = moment().add(1, 'days').format('YYYY-MM-DD');
  const dateSince = moment().subtract(1, 'month').format('YYYY-MM-DD');
  const dateUntil = moment().add(1, 'days').format('YYYY-MM-DD');
  // Current month default screen filter
  const startExpenseReports = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][0];
  const endExpenseReports = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][1];
  let newUrl = `/${screen}?startDate=${startFilterDate}&endDate=${endFilterDate}`;

  if (screen === ScreenType.WORK_PAPERS) {
    newUrl = `/${screen}/310?dateSince=${dateSince}&dateUntil=${dateUntil}`;
  }
  if (screen === ScreenType.EXPENSE_REPORTS) {
    newUrl = `/${screen}?startDate=${startExpenseReports}&endDate=${endExpenseReports}`;
  }
  if (screen === ScreenType.CALENDAR) {
    newUrl = `/${screen}`;
  }
  if (clientID && (screen === ScreenType.SETTLEMENTS_CLIENT || screen === ScreenType.SALES_CLIENT || screen === ScreenType.TAXES_CLIENT)) {
    newUrl = `${clientID}/${screen}?startDate=${startExpenseReports}&endDate=${endExpenseReports}`;
  }
  navigate(newUrl);
};

// set default date filter
export const defaultDateFilter = (setFieldValue: (field: string, value: Date | string) => void, screenType: string): void => {
  switch (screenType) {
    case ScreenType.SETTLEMENTS:
    case ScreenType.SETTLEMENTS_CLIENT:
    case ScreenType.SALES:
    case ScreenType.SALES_CLIENT:
    case ScreenType.EXPENSE_REPORTS:
      setFieldValue('startDate', moment().subtract(60, 'days').format('YYYY-MM-DD'));
      setFieldValue('endDate', moment().add(1, 'days').format('YYYY-MM-DD'));
      break;
    case ScreenType.TAXES_CLIENT:
      setFieldValue('startDate', moment().subtract(30, 'days').format('YYYY-MM-DD'));
      setFieldValue('endDate', moment().add(1, 'days').format('YYYY-MM-DD'));
      break;

    default:
      break;
  }
};
