import React, { FC, useState, ReactElement } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Subtitle } from '../../textComponents';
import { ExternalActivePlatform, FormTypeFiserv } from '../../../utils/interfaces';
import { FormFieldType } from '../../../utils/types';
import { PasswordInputComponent, TextInputComponent } from '../../inputs';
import { useAppDispatch } from '../../../redux/hooks';
import { asyncAddExternalPlatform } from '../../../redux/slices/externalPlatformSlice';
import errorHandlerHelper from '../../../utils/errorHandler';
import successImage from '../../../assets/images/success.png';
import errorImage from '../../../assets/images/error.png';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';

interface FiservCredentialsFormProps {
  selectedPlatform: string;
  handleDeletePlatform: () => void;
  currentValues: ExternalActivePlatform | undefined;
  companyId: string;
}

const FiservCredentialsFormFTP: FC<FiservCredentialsFormProps> = ({
  selectedPlatform,
  handleDeletePlatform,
  currentValues,
  companyId,
}: FiservCredentialsFormProps): React.ReactElement => {
  const [fileWithoutLineBreaks, setFileWithoutLineBreaks] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validateForm = Yup.object().shape({
    codeAux2: Yup.string().required('Ingresá la URL'),
    codeAux3: Yup.number().required('Ingresá el puerto'),
    username: Yup.string().required('Ingresá un usuario válido.'),
    password: Yup.string().required('Tenés que escribir una contraseña.'),
  });

  const initialValues = {
    codeAux: FormTypeFiserv.FTP,
    codeAux2: currentValues?.codeAux2 || '',
    codeAux3: currentValues?.codeAux3 || '',
    codeAux4: currentValues?.codeAux4 || '',
    username: currentValues?.username || '',
    password: '',
  };

  const handleSubmitFTP = (values: typeof initialValues): void => {
    if (values && fileWithoutLineBreaks) {
      dispatch(
        asyncAddExternalPlatform({
          platformExternalCode: selectedPlatform,
          username: values.username,
          password: values.password,
          codeAux: FormTypeFiserv.FTP,
          codeAux2: `${values.codeAux2}:${values.codeAux3}`,
          codeAux4: fileWithoutLineBreaks,
          companyId,
        }),
      )
        .then(async () => {
          dispatch(
            addModalToList({
              body: 'Este proceso puede tardar unos momentos. Recibirás una notificación cuando termine.',
              pictureUrl: successImage,
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch(removeModalFromList());
                  navigate(-1);
                },
              },
            }),
          );
        })
        .catch((error) => {
          errorHandlerHelper(error, dispatch);
        });
    }
  };

  // Function to read the PPK file and replace line breaks with \n
  const processPPKFile = (file: File): void => {
    const reader = new FileReader();
    reader.onload = (event: any): void => {
      const fileContent = event.target.result as string;
      const fileContentWithNewlines = fileContent.replace(/\n/g, '\\n');
      setFileWithoutLineBreaks(fileContentWithNewlines);
    };

    reader.readAsText(file);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validateForm}
        onSubmit={(values): void => {
          handleSubmitFTP(values);
        }}
      >
        {({ errors }): ReactElement => (
          <Form className="row mb-5 d-flex flex-column">
            <div className="container-input col-12 col-xl-6">
              <Field name="username">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.username}
                    placeholder="Ingresá el usuario"
                    label="Usuario"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="container-input col-12 col-xl-6">
              <Field name="password">
                {({ field }: FormFieldType): ReactElement => (
                  <PasswordInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.password}
                    placeholder="Ingresá la contraseña"
                    label="Contraseña"
                    {...field}
                  />
                )}
              </Field>
            </div>

            <div className="container-input col-12 col-xl-6">
              <Field name="codeAux2">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.codeAux2}
                    placeholder="Ingresá la URL"
                    label="Url"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="container-input col-12 col-xl-6">
              <Field name="codeAux3">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    type="number"
                    required
                    containerClassName="mt-2 w-100"
                    className="input w-100"
                    errorMessage={errors.codeAux3}
                    placeholder="Ingresá el puerto"
                    label="Puerto"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="container-input col-12 col-xl-6 mt-4">
              <Subtitle size="sm">Clave privada .ppk</Subtitle>
              <input
                className="form-control form-control-lg"
                id="formFileLg"
                type="file"
                onChange={(e): void => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    processPPKFile(file);
                  } else {
                    dispatch(
                      addModalToList({
                        body: '¡Ups! El archivo no es válido.',
                        pictureUrl: errorImage,
                        okButton: {
                          buttonText: 'Aceptar',
                          onClick: async () => {
                            dispatch(removeModalFromList());
                          },
                        },
                      }),
                    );
                  }
                }}
              />
            </div>
            <div className="col-12">
              <PlatformCredentialsFooterButtons disabled={fileWithoutLineBreaks === ''} handleDeletePlatform={handleDeletePlatform} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FiservCredentialsFormFTP;
