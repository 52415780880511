import React from 'react';
import logoMasterCard from '../assets/images/paymentMethods/mastercard.png';
import logoOca from '../assets/images/externalPlatforms/card/oca.png';
import logoMasterCardDebit from '../assets/images/paymentMethods/mastercard-debit.png';
import logoCabal from '../assets/images/paymentMethods/cabalImg.png';
import logoVisa from '../assets/images/paymentMethods/visa.png';
import logoAmex from '../assets/images/paymentMethods/amex.png';
import logoNaranja from '../assets/images/paymentMethods/naranja.png';
import logoSendMoney from '../assets/images/paymentMethods/sendMoney.png';
import logoMoneyAdvance from '../assets/images/paymentMethods/moneyAdvance.png';
import logoVisaDebito from '../assets/images/paymentMethods/visaDebito.png';
import logoMaestro from '../assets/images/paymentMethods/maestro.png';
import logoVisaNet from '../assets/images/paymentMethods/visanet.png';
import logoSodexo from '../assets/images/externalPlatforms/card/sodexo.png';
import logoRedLider from '../assets/images/externalPlatforms/card/red-lider.png';
import logoMercadoPago from '../assets/images/externalPlatforms/wallet/mercadopago.png';
import logoDiners from '../assets/images/paymentMethods/diners.png';
import logoAnda from '../assets/images/paymentMethods/anda.png';
import unknownCard from '../assets/images/paymentMethods/unknown.png';
import { paymentMethodsCodes } from './constants';

type paymentMethodIconType = {
  brand: string;
  className?: string;
};
const icons = {
  [paymentMethodsCodes.MASTERCARD]: { logoImage: logoMasterCard },
  [paymentMethodsCodes.MASTER]: { logoImage: logoMasterCard },
  [paymentMethodsCodes.OCA]: { logoImage: logoOca },
  [paymentMethodsCodes.CABAL_DEBIT]: { logoImage: logoCabal },
  [paymentMethodsCodes.MASTERCARD_PREPAGA]: { logoImage: logoMasterCard },
  [paymentMethodsCodes.MASTERCARD_DEBIT]: { logoImage: logoMasterCardDebit },
  [paymentMethodsCodes.MASTER_DEBIT]: { logoImage: logoMasterCardDebit },
  [paymentMethodsCodes.CABAL]: { logoImage: logoCabal },
  [paymentMethodsCodes.VISA]: { logoImage: logoVisa },
  [paymentMethodsCodes.AMEX]: { logoImage: logoAmex },
  [paymentMethodsCodes.AMERICAN_EXPRESS]: { logoImage: logoAmex },
  [paymentMethodsCodes.NARANJA]: { logoImage: logoNaranja },
  [paymentMethodsCodes.SEND_MONEY]: { logoImage: logoSendMoney },
  [paymentMethodsCodes.MONEY_ADVANCE]: { logoImage: logoMoneyAdvance },
  [paymentMethodsCodes.VISA_DEBIT]: { logoImage: logoVisaDebito },
  [paymentMethodsCodes.MAESTRO]: { logoImage: logoMaestro },
  [paymentMethodsCodes.MERCADOPAGO_CC]: { logoImage: logoMercadoPago },
  [paymentMethodsCodes.MERCADOPAGO_AR]: { logoImage: logoMercadoPago },
  [paymentMethodsCodes.VISANET_UY]: { logoImage: logoVisaNet },
  [paymentMethodsCodes.SODEXO]: { logoImage: logoSodexo },
  [paymentMethodsCodes.RED_LIDER]: { logoImage: logoRedLider },
  [paymentMethodsCodes.ANDA]: { logoImage: logoAnda },
  [paymentMethodsCodes.DINERS]: { logoImage: logoDiners },
};
const paymentMethodIcon = ({ brand, className }: paymentMethodIconType): React.ReactElement => (
  <img
    className={className}
    src={brand && icons[brand.toLowerCase().trim()] ? icons[brand.toLowerCase().trim()].logoImage : unknownCard}
    alt=""
  />
);
export default paymentMethodIcon;
