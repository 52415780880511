import { AxiosPromise } from 'axios';
import { ParamsSmartData } from '../utils/interfaces';
import axiosV2 from './axiosInstanceV2';

export const getSmartDataSettlements = (body: ParamsSmartData, paramsRequest: string): AxiosPromise =>
  axiosV2.post(`/report/smart-data/settlements${paramsRequest}`, body);
export const getSmartDataSales = (body: ParamsSmartData, paramsRequest: string): AxiosPromise =>
  axiosV2.post(`/report/smart-data/sales${paramsRequest}`, body);
export const getSmartDataExpenses = (body: ParamsSmartData, paramsRequest: string): AxiosPromise =>
  axiosV2.post(`/report/smart-data/expenses${paramsRequest}`, body);
