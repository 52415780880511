import { AxiosPromise } from 'axios';
import axiosV2 from './axiosInstanceV2';
import { FilterParams } from '../utils/types';
import { ListsAxiosPromise, SettlementWithCollections } from '../utils/interfaces';

export const getSettlement = (params: FilterParams): AxiosPromise<ListsAxiosPromise<Array<SettlementWithCollections>>> =>
  axiosV2.get('/settlement/with-collections?', { params: { ...params } });

export const getSettlementClient = (clientId: number, params?: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${clientId}/settlements`, { params });

export const getSalesBySettlement = (id: string, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/sale/by-settlement/${id}`, { params });
export const getSettlementsXLSX = (params: FilterParams, addSalesToReport: boolean): AxiosPromise =>
  axiosV2.get('/settlement/generate-report/xlsx', { params: { ...params, add_sales_to_report: addSalesToReport } });
