import React from 'react';
import { BsInfoCircleFill, BsFillExclamationTriangleFill, BsFillDashCircleFill, BsCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { CloseIconSVG } from '../../assets/svgComponents';
import { useAppDispatch } from '../../redux/hooks';
import { removeNotification, NotificationPopUpComponent } from '../../redux/slices/notificationSlice';

const Notification: React.FC<{ notification: NotificationPopUpComponent }> = ({ notification }) => {
  let notificationIcon;
  switch (notification.type) {
    case 'warning':
      notificationIcon = <BsFillExclamationTriangleFill />;
      break;
    case 'error':
      notificationIcon = <BsFillDashCircleFill />;
      break;
    case 'success':
      notificationIcon = <BsCheck />;
      break;
    default:
      notificationIcon = <BsInfoCircleFill />;
      break;
  }
  const dispatch = useAppDispatch();
  const handleClick = (): void => {
    dispatch(removeNotification(notification));
  };
  return (
    <div className={`notification ${notification.type}`}>
      <div className="notification-header">{notificationIcon}</div>
      {notification.link && notification.absolute && (
        <a className="notification-content" href={notification.link} target="blank">
          <div className="font-sm font-weight-medium">{notification.title}</div>
          <div className="font-xs">{notification.body}</div>
        </a>
      )}
      {notification.link && !notification.absolute && (
        <Link className="notification-content" to={notification.link}>
          <div className="font-sm font-weight-medium">{notification.title}</div>
          <div className="font-xs">{notification.body}</div>
        </Link>
      )}
      {!notification.link && (
        <div className="notification-content">
          <div className="font-sm font-weight-medium">{notification.title}</div>
          <div className="font-xs">{notification.body}</div>
        </div>
      )}
      {notification.interactive && (
        <button type="button" className="font-weight-light font-xs notification-close fill-black" onClick={handleClick}>
          <CloseIconSVG className="fill-black" />
        </button>
      )}
    </div>
  );
};
export default Notification;
