import React from 'react';
import { FaDollarSign, FaPercent, FaCreditCard } from 'react-icons/fa';
import { Subtitle, Title } from '../../components';
import CardSmartData from '../../components/CardSmartData';

const SmartData = (): React.ReactElement => (
  <div>
    <Title>Smart Data (Beta)</Title>
    <Subtitle>Seleccioná una vista de datos</Subtitle>
    <div className="container-fluid d-flex flex-column flex-sm-row mt-5 align-items-center justify-content-evenly flex-wrap">
      <CardSmartData title="Ventas" icon={<FaCreditCard size={65} />} route="/data-flex-sales" disable={false} />
      <CardSmartData title="Liquidaciones" icon={<FaDollarSign size={65} />} route="/data-flex-settlements" disable={false} />
      <CardSmartData title="Gastos" icon={<FaPercent size={65} />} route="/data-flex-expenses" disable={false} />
    </div>
  </div>
);

export default SmartData;
