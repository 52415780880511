/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: As soon as this methods/functions are used add as many types as possible.
import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { ENV_API_URL } from '../config';
import { setTokens } from '../utils/helper';
import { Company } from '../utils/interfaces';
// import { segmentGroup, segmentIdentify } from '../utils/segment/hooks';
import { StorageVars } from '../utils/types';
import { TimeZoneByCountry } from '../utils/constants';
import { handleBalanceQuery, handleRefreshTokenExpired } from '../utils/helperAxios';

type Token = {
  companies: Company[];
  exp: number;
  iat: number;
  lastFeedbackAt: string | null;
  permissions: string[];
  tenantId: number;
  tiers: {
    Tenant: {countryCode: string};
    Tier: {name: string, code: string, trialDays: number};
    extensionDays: number;
    platformCode: string;
    trialActiveAt: string;
    trialExpired: boolean;
    trialRemainingDays: number;
    updatedAt: string;
  }[];
  userId: string;
  userRoles: number[];
  verified: boolean;
};

const API_URL = ENV_API_URL;
const tokenValue = localStorage.getItem(StorageVars.token);
let decodeToken;
if (tokenValue) {
  [decodeToken] = jwtDecode<Token>(tokenValue)?.tiers;
}

const timezoneOffset = decodeToken?.Tenant?.countryCode
  ? moment.tz(TimeZoneByCountry[decodeToken?.Tenant?.countryCode]).format('z')
  : moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Timezone-Offset': timezoneOffset,
  },
});

const getNewToken = async (rtoken: string | null) => {
  const { data: response } = await axios.post(
    `${API_URL}/auth/refresh-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${rtoken}`,
      },
    },
  );
  const tokens = {
    token: response.token,
    rtoken: response.tokenRefresh,
  };
  // segmentIdentify(response.user.id, {
  //   email: response.user.email,
  //   name: response.user.firstName
  // });
  // segmentGroup(response.user.tenantId, {
  //   tenant: response.user.tenantId,
  // });
  return tokens;
};
function updateInstanceHeader(accessToken: unknown) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}
function updateTokens(newTokens: { token: any; rtoken: any }) {
  return setTokens(newTokens.token, newTokens.rtoken);
}

export const interceptor = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem(StorageVars.token);
      const tokenRefresh = localStorage.getItem(StorageVars.tokenRefresh);
      const newConfig = config;
      handleBalanceQuery(config);
      if (config.url === '/auth/login' || config.url === '/auth/register' || config.url === '/auth/reset-password' || config.url === 'auth/forgot-password' ) {
        delete newConfig.headers.Authorization;
      }
      if (config.url === '/auth/refresh-token') {
        if (token) {
          const newDate = new Date();
          const expDate = new Date(jwtDecode<Token>(token).exp * 1000);
          if (newDate >= expDate) {
            try {
              const { data: response } = await axios.post(
                `${API_URL}/auth/refresh-token`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${tokenRefresh}`,
                  },
                },
              );
              localStorage.setItem(StorageVars.token, response.data.token);
              localStorage.setItem(StorageVars.tokenRefresh, response.data.tokenRefresh);
              newConfig.headers.Authorization = `${response.data.token}`;
            } catch (refreshError) {
              localStorage.removeItem(StorageVars.token);
              localStorage.removeItem(StorageVars.tokenRefresh);
            }
          } else {
            newConfig.headers.Authorization = `Bearer ${token}`;
          }
        } 
      } else if (token && config.url !== '/auth/login' && config.url !== '/auth/register' && config.url !== '/auth/reset-password' && config.url !== 'auth/forgot-password') {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
      return newConfig;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      // if login throws or the error is not 401, we have nothing to do here...
      if (error.config.url.includes('/auth/login') || error.response?.status !== 401) {
        return Promise.reject(error.response.data);
      }
      if (error.config.url === `${API_URL}/auth/refresh-token`) {
        await Promise.all([localStorage.removeItem(StorageVars.token), localStorage.removeItem(StorageVars.tokenRefresh)]);
        return Promise.reject(error.response.data);
      }
      try {
        const rtoken = localStorage.getItem(StorageVars.tokenRefresh);
        const newTokens = await getNewToken(rtoken);
        const { config } = error;
        config.headers.Authorization = `Bearer ${newTokens.token}`;
        updateInstanceHeader(newTokens.token);
        updateTokens(newTokens);
        return axiosInstance.request(config);
      } catch (err) {
        handleRefreshTokenExpired(err as AxiosError)
        return Promise.all([localStorage.removeItem(StorageVars.token), localStorage.removeItem(StorageVars.tokenRefresh)])
      }
    },
  );
};

export default axiosInstance;
