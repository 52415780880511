import React, { FC, useEffect, useRef, useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SettlementsList, Title, FetchButton } from '../../components';
import { Filters } from '../../containers';
import { joyrideStyles, STEPS_SETTLEMENTS } from '../../components/Walkthrough';
import { useAppSelector } from '../../redux/hooks';
import errorImage from '../../assets/images/error.png';
import errorHandlerHelper from '../../utils/errorHandler';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { getTrialStatus, paramsToObject, parseFilters } from '../../utils/helper';
import { fetchSettlements } from '../../redux/slices/settlementSlice';
import { FilterType } from '../../utils/types';
import { LoadingStateType } from '../../utils/interfaces';

const SettlementsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { settlementsList, loadingSettlements } = useAppSelector((state) => state.settlement);
  const { companiesList } = useAppSelector((state) => state.company);
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const topRef = useRef<HTMLDivElement>(null);
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  const [trialExpiredStatus, setTrialExpiredStatus] = useState<boolean>(false);

  const walkthrough = (): void => {
    const tourViewed = window.localStorage.getItem('walkthrough');
    if (!tourViewed) {
      setRunWalkthrough(true);
    }
  };

  useEffect(() => {
    walkthrough();
  }, []);

  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialExpired } = cashTier;
        setTrialExpiredStatus(trialExpired);
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  useEffect(() => {
    if (activeCurrency?.code) {
      const queryParams = new URLSearchParams(location.search);
      const queryObject = paramsToObject(queryParams);
      const filterableObject = parseFilters({ ...queryObject, currencyCode: activeCurrency?.code });
      dispatch(fetchSettlements(filterableObject));
    }
  }, [activeCurrency, location]);

  const clearTutorialData = (): void => {
    window.localStorage.removeItem('runManualWalkthrough');
    window.localStorage.setItem('walkthrough', 'true');
    window.localStorage.setItem('walkthroughCompanies', 'true');
    window.localStorage.setItem('walkthroughPlatforms', 'true');
    navigate('/');
  };

  const handleJoyrideCallback = (data: { action?: any; status?: any }): void => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && !trialExpiredStatus) {
      const foundCompany = companiesList.find((company) => !company?.taxCode);
      if (foundCompany) {
        navigate(`/companies/${foundCompany?.id}`);
      } else {
        const companyListSorted = [...companiesList].sort((company) => (company.enabled ? -1 : 1)).find((company) => company?.enabled);
        navigate(`/companies/${companyListSorted?.id}`);
      }
    } else if (data.action === ACTIONS.CLOSE) {
      clearTutorialData();
    }
  };

  const showModalTrialExpired = (): void | null => {
    if (trialExpiredStatus) {
      clearTutorialData();
      dispatch(
        addModalToList({
          body: 'Para continuar utilizando la plataforma, por favor, actualizá tu suscripción.',
          pictureUrl: errorImage,
          required: true,
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
    }
    return null;
  };

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  useEffect(() => {
    if (runWalkthrough) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [runWalkthrough]);

  return (
    <div
      style={{ overflow: runWalkthrough ? 'hidden' : 'visible' }}
      className="step-settlements step-settlement-go-companies collections-page mb-4 mt-5"
      ref={topRef}
    >
      {runWalkthrough && loadingSettlements !== LoadingStateType.PENDING ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run
          scrollToFirstStep
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          steps={STEPS_SETTLEMENTS}
          styles={joyrideStyles}
        />
      ) : (
        showModalTrialExpired()
      )}
      <Title size="sm">Liquidaciones</Title>
      <div className="step-filter-settlements">
        <Filters containerClassName="mt-3 mb-5" typeFilter={FilterType.SETTLEMENT} />
      </div>
      <div className="me-2 my-2 flex-grow-1 d-flex justify-content-end step-download-report">
        <FetchButton dataType={0} />
      </div>
      <SettlementsList handlePaginationCallBack={handlePagination} settlementsArray={settlementsList} />
    </div>
  );
};

export default SettlementsPage;
