import React, { FunctionComponent } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import ReactTooltip from 'react-tooltip';
import { LoaderSpinner, Title } from '..';
import DropdownArrowIcon from '../../assets/svgComponents/DropdownArrowIcon';
import { capitalizeFirstLetter, currencyFormat, formatNumber } from '../../utils/stringUtils';
import { useAppSelector } from '../../redux/hooks';
import { LoadingStateType } from '../../utils/interfaces';

interface ToolbarPropsExtended extends ToolbarProps {
  nextMonth: () => void;
  prevMonth: () => void;

  amount: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  labelDate: string;
  isInternalCalendar?: boolean;
}

const ToolBar: FunctionComponent<ToolbarPropsExtended> = ({
  labelDate,
  amount,
  loading,
  nextMonth,
  prevMonth,
  isInternalCalendar = true,
}) => {
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  return (
    <>
      <div className="calendar-toolbar">
        <div className="text-calendar d-flex align-items-center flex-wrap">
          <Title className="m-0 me-2">{capitalizeFirstLetter(labelDate)}</Title>
          <p className="font-xl color-blue">
            {loading === LoadingStateType.PENDING ? (
              <LoaderSpinner className="font-md" />
            ) : (
              `${activeCurrency ? currencyFormat(activeCurrency?.code) : '$'}${formatNumber(amount, false, 0)}`
            )}
          </p>
        </div>
        {isInternalCalendar ? (
          <div className="d-inline d-sm-flex flex-row container-reference">
            <div className="reference" data-tip="Algunos ingresos son estimados por nubceo">
              <span className="estimated" />
              <span className="ms-2">Estimados</span>
              <ReactTooltip />
            </div>
            <div className="reference" data-tip="Ingresos informados por el medio de cobro">
              <span className="to-credit" />
              <span className="ms-2">Confirmados</span>
              <ReactTooltip />
            </div>
          </div>
        ) : null}
      </div>
      <div className="calendar-arrows">
        <span tabIndex={0} onKeyPress={prevMonth} role="button" className="arrow-prev" onClick={prevMonth}>
          <DropdownArrowIcon width={15} />
        </span>

        <span role="button" onKeyPress={nextMonth} tabIndex={0} className="arrow-next" onClick={nextMonth}>
          <DropdownArrowIcon width={15} />
        </span>
      </div>
    </>
  );
};
export default ToolBar;
