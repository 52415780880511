import { Field, Form, Formik } from 'formik';
import React, { FC, ReactNode } from 'react';
import { FormFieldType } from '../../utils/types';
import { CheckBoxComponent, SelectInputComponent, TextInputComponent } from '../inputs';

const ConfigureNotifications = ({
  reportIncludeSettlements,
  setReportIncludeSettlements,
  reportIncludeSales,
  setreportIncludeSales,
  reportMediumIsPush,
  setReportMediumIsPush,
  reportMediumIsEmail,
  setReportMediumIsEmail,
  reportEmailAdd,
  setReportEmailAdd,
  setReportFrequency,
  reportFrequency,
  setEmailAdd,
  emailAdd,
  errorEmails,
}: any) => {
  const reportEmailAddDeleteMails = (): void => {
    if (reportEmailAdd) {
      setReportEmailAdd(false);
      setEmailAdd('');
    } else {
      setReportEmailAdd(true);
    }
  };

  const reportFrequencyOptions = [
    {
      code: 'M',
      name: 'Mensual',
    },
    {
      code: 'W',
      name: 'Semanal',
    },
  ];
  const initialValues = {
    optionsFrequency: reportFrequencyOptions.map((item) => item.name),
  };
  const handleSubmit = (values: any) => {
    setReportFrequency(values);
  };
  return (
    <>
      <div>
        <p className="mt-4 mb-1 font-md">Seleccioná el tipo de reporte que querés recibir</p>
        <CheckBoxComponent
          containerClassName="mt-2 ms-2"
          onChange={(): void => setReportIncludeSettlements(!reportIncludeSettlements)}
          value={reportIncludeSettlements}
          checked={reportIncludeSettlements ?? false}
        >
          <p>Reporte de Liquidaciones </p>
        </CheckBoxComponent>
      </div>
      <div>
        <CheckBoxComponent
          containerClassName="mt-2 ms-2"
          onChange={(): void => setreportIncludeSales(!reportIncludeSales)}
          value={reportIncludeSales}
          checked={reportIncludeSales ?? false}
        >
          <p>Reporte de Ventas </p>
        </CheckBoxComponent>
      </div>

      <p className="mt-5 mb-1 font-md">Seleccioná cómo querés que te enviemos el reporte</p>

      <div>
        <CheckBoxComponent
          containerClassName="mt-2 ms-2"
          onChange={(): void => setReportMediumIsPush(!reportMediumIsPush)}
          value={reportMediumIsPush}
          checked={reportMediumIsPush ?? false}
        >
          <p>Notificación en la app</p>
        </CheckBoxComponent>
      </div>

      <div>
        <CheckBoxComponent
          containerClassName="mt-3 ms-2"
          onChange={(): void => setReportMediumIsEmail(!reportMediumIsEmail)}
          value={reportMediumIsEmail}
          checked={reportMediumIsEmail ?? false}
        >
          <p>Notificación en Email</p>
        </CheckBoxComponent>
      </div>

      <div>
        <CheckBoxComponent
          containerClassName="mt-3 ms-2"
          onChange={(): void => reportEmailAddDeleteMails()}
          value={reportEmailAdd}
          checked={reportEmailAdd ?? false}
        >
          <p>Email adicionales</p>
        </CheckBoxComponent>
      </div>

      <div>
        {reportEmailAdd && (
          <>
            <div className="mt-3">
              <TextInputComponent
                value={emailAdd}
                required
                containerClassName="my-2 w-50 "
                className="input"
                type="text"
                placeholder="Ingresá las direcciones de e-mail separadas con “;”"
                autoComplete="new-firstname"
                onChange={(e): void => setEmailAdd(e.target.value)}
              />
            </div>
            {errorEmails.status && <p> {errorEmails.title}</p>}
          </>
        )}
      </div>
      <div className="mt-3">
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ setFieldValue }): ReturnType<FC> => (
            <>
              <Form>
                <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9 mt-3">
                  <>
                    <Field name="optionsFrequency">
                      {({ field }: FormFieldType): ReactNode => (
                        <SelectInputComponent
                          label="Seleccioná la frecuencia con la cual querés recibir el reporte"
                          options={reportFrequencyOptions.map((option) => ({
                            id: option.code,
                            value: option.code,
                            name: option.name,
                          }))}
                          onOptionClick={(name, value): void => {
                            setFieldValue('optionsFrequency', null);
                            setFieldValue(name, value);
                            setReportFrequency(() => value);
                          }}
                          containerClassName="input"
                          placeholder="(Seleccioná una opción)"
                          {...field}
                          value={reportFrequency ?? ''}
                        />
                      )}
                    </Field>
                  </>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>

      <div className="mt-3">
        <p className="fw-light">
          Los reportes semanales se envían los días lunes (incluye información desde el lunes anterior hasta el domingo).
        </p>
        <p className="fw-light">Los reportes mensuales se envían el primer día de cada mes e incluyen información del mes anterior.</p>
      </div>
    </>
  );
};
export default ConfigureNotifications;
