import moment from 'moment';
import React, { useState, FC } from 'react';
import { BsDownload } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import errorImage from '../../../assets/images/error.png';
import successImage from '../../../assets/images/success.png';
import { getSalesXLSX } from '../../../services/salesService';
import { getCollectionsXLSX } from '../../../services/collectionService';
import { paramsToObject, parseFilters } from '../../../utils/helper';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';
import { errorHandlerHelperV2 } from '../../../utils/errorHandler';
import { addModalToList, removeModalFromList } from '../../../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import logger from '../../../services/loggerService';
import { getClientSalesXLSX, getClientSettlementsXLSX } from '../../../services/clientsService';
import { getSettlementsXLSX } from '../../../services/settlementService';
import { getClientTaxesXLSX } from '../../../services/taxesService';
import { getCashflowXLSX } from '../../../services/cashflowService';
import { getReportExpenseXLSX } from '../../../services/reportsService';
import { externalPlatformsBasicData } from '../../../utils/constants';
import { CheckBoxComponent } from '../../inputs';

type ButtonProps = {
  dataType: number;
  tenantId?: number;
  date?: moment.Moment;
};

const FetchButton: FC<ButtonProps> = ({ dataType, tenantId, date }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const url = location.pathname;
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const { companiesList } = useAppSelector((state) => state.company);
  const { branchesList } = useAppSelector((state) => state.branch);
  const { taxTypes } = useAppSelector((state) => state.taxes);
  const isCalendarPage = url.includes('calendar');
  const startDateCalendar = moment(date).startOf('month').format();
  const endDateCalendar = moment(date).endOf('month').format();
  const queryParams = new URLSearchParams(useLocation().search);
  const queryObject = paramsToObject(queryParams);
  // const [addSalesToReport, setAddSalesToReport] = useState(false);
  let addSalesToReport = false;

  if (activeCurrency) {
    queryObject.currencyCode = activeCurrency.code;
  }
  const filterableObject = parseFilters(
    isCalendarPage ? { ...queryObject, startDate: startDateCalendar, endDate: endDateCalendar } : queryObject,
  );

  const diffBetweenDatesNotHigherThan62 = (init: string, end: string): boolean => {
    if (!end) return false;
    if (!init) return false;
    return moment(init).add(62, 'day') >= moment(end);
  };

  const handleOnChange = (): void => {
    addSalesToReport = !addSalesToReport;
  };

  const getMessage = (): React.ReactElement | string | number => {
    const filters = [];

    if (queryObject.companies) {
      const selectedCompanies = companiesList.filter((company) => queryObject.companies.includes(company.id));
      filters.push(
        <>
          <p className="text-start">
            <b>Empresa: </b> {selectedCompanies.map((company) => company.name).join(', ')}
          </p>
        </>,
      );
    }

    if (queryObject.branches) {
      const selectedBranches = branchesList?.filter((branch) => queryObject.branches.includes(branch.id));
      filters.push(
        <>
          <p className="text-start">
            <b>Sucursal: </b>
            {selectedBranches.map((branch) => branch.name).join(', ')}
          </p>
        </>,
      );
    }

    if (queryObject.startDate || queryObject.endDate) {
      filters.push(
        <>
          <p className="text-left">
            <b>Fecha: </b> {queryObject.startDate ? `Desde ${queryObject.startDate}` : ''}{' '}
            {queryObject.endDate ? `Hasta ${queryObject.endDate}` : ''}
          </p>
        </>,
      );
    }

    if (queryObject.startPaymentDate || queryObject.endPaymentDate) {
      filters.push(
        <>
          <p className="text-left">
            <b>Fecha de pago: </b> {queryObject.startPaymentDate ? `Desde ${queryObject.startPaymentDate}` : ''}{' '}
            {queryObject.endPaymentDate ? `Hasta ${queryObject.endPaymentDate}` : ''}
          </p>
        </>,
      );
    }

    if (queryObject.presentedStartDate || queryObject.presentedEndDate) {
      filters.push(
        <>
          <p className="text-left">
            <b>Presentación: </b>
            {queryObject.presentedStartDate ? `Desde ${queryObject.presentedStartDate}` : ''}{' '}
            {queryObject.presentedEndDate ? `Hasta ${queryObject.presentedEndDate}` : ''}
          </p>
        </>,
      );
    }

    if (queryObject.platformCode) {
      filters.push(
        <>
          <p className="text-left">
            <b>Plataforma: </b>
            {externalPlatformsBasicData(queryObject.platformCode).name}
          </p>
        </>,
      );
    }

    if (queryObject.included_settlement) {
      let incSetMessage = `Todas`;
      if (queryObject.included_settlement === 'not_included') {
        incSetMessage = 'No liquidadas';
      } else if (queryObject.included_settlement === 'included') {
        incSetMessage = 'Liquidadas';
      }

      filters.push(
        <>
          <p className="text-left">
            <b>Ventas: </b>
            {incSetMessage}
          </p>
        </>,
      );
    }

    if (queryObject.type) {
      let typeMessage = `Todas`;
      if (queryObject.type === 'order') {
        typeMessage = 'Venta';
      } else if (queryObject.type === 'chargeback') {
        typeMessage = 'Contracargo';
      } else if (queryObject.type === 'return') {
        typeMessage = 'Devolución';
      } else if (queryObject.type === 'adjustment') {
        typeMessage = 'Ajuste';
      }
      filters.push(
        <>
          <p className="text-left">
            <b>Tipo: </b>
            {typeMessage}
          </p>
        </>,
      );
    }

    if (queryObject.taxTypeCode) {
      const taxType = queryObject.taxTypeCode.split(',');
      if (taxType.length > 1) return filters.push(`(${taxType.length}) Impuestos seleccionados`);
      return filters.push(taxTypes.find((tax) => tax.code === queryObject.taxTypeCode)?.name);
    }

    return (
      <>
        <div className="d-flex flex-column">
          {filters?.length ? <div className="justify-content-center">Se generará un reporte con los siguientes filtros:</div> : null}
          <div className="justify-content-start container-filters">
            <ul className="p-0 d-flex flex-column justify-content-start mt-3">
              {filters.map((item) => (
                <li className="d-flex justify-content-start">
                  <div className=" d-flex justify-content-start">{item}</div>
                </li>
              ))}
            </ul>
          </div>
          {dataType === 0 && (
            <div className="justify-content-center my-2">
              <CheckBoxComponent containerClassName="justify-content-center" onChange={handleOnChange} value={addSalesToReport}>
                <p>Incluir una hoja con las Ventas de las Liquidaciones</p>
              </CheckBoxComponent>
            </div>
          )}
          <div className="justify-content-center">Te notificaremos cuando esté listo.</div>
        </div>
      </>
    );
  };

  const handleClick = async (dataRequired: number): Promise<void> => {
    // const reportRequested = {
    //   settlements: 'Settlements',
    //   incomes: 'Incomes',
    //   sales: 'Sales',
    //   taxes: 'Taxes',
    // };
    const { startDate, endDate, presentedStartDate, presentedEndDate, endPaymentDate, startPaymentDate } = queryObject;

    if (
      diffBetweenDatesNotHigherThan62(startDate, endDate) ||
      diffBetweenDatesNotHigherThan62(presentedStartDate, presentedEndDate) ||
      diffBetweenDatesNotHigherThan62(startPaymentDate, endPaymentDate) ||
      isCalendarPage
    ) {
      setLoading(true);
      try {
        switch (dataRequired) {
          case 0:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    await errorHandlerHelperV2(() => getSettlementsXLSX(filterableObject, !!addSalesToReport), dispatch);
                    addSalesToReport = false;
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    addSalesToReport = false;
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.settlements, filters: queryObject });
            break;
          case 1:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getCollectionsXLSX(filterableObject), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.incomes, filters: queryObject });
            break;
          case 2:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getSalesXLSX(filterableObject), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.sales, filters: queryObject });
            break;
          case 3:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getClientSettlementsXLSX(filterableObject, tenantId), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.settlements, filters: queryObject });
            break;
          case 4:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getClientTaxesXLSX(filterableObject, tenantId), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.taxes, filters: queryObject });
            break;
          case 5:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getCashflowXLSX(filterableObject), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.sales, filters: queryObject });
            break;
          case 6:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getClientSalesXLSX(filterableObject, tenantId), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            // segmentTrack(EVENTS.REPORTS.DOWNLOAD.SUCCESS, { reportRequested: reportRequested.settlements, filters: queryObject });
            break;
          case 7:
            dispatch(
              addModalToList({
                body: getMessage(),
                pictureUrl: successImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    // eslint-disable-next-line no-void
                    void errorHandlerHelperV2(() => getReportExpenseXLSX(filterableObject), dispatch);
                    dispatch(removeModalFromList());
                  },
                },
                cancelButton: {
                  buttonText: 'Cancelar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
            break;
          default:
            break;
        }
      } catch (err) {
        logger.error('fetchButton.error', err);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(
        addModalToList({
          body: 'Seleccioná un período no mayor a 62 días.',
          pictureUrl: errorImage,
          required: true,
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
    }
  };

  return (
    <button type="button" disabled={loading} onClick={(): Promise<void> => handleClick(dataType)} id="fetch-button">
      <BsDownload className="download-icon" />
      <span className="ms-2 d-none d-md-inline">Descargar Excel</span>
    </button>
  );
};

export default FetchButton;
