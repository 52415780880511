import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FormButtonComponent,
  LinkComponent,
  TextInputComponent,
  Paragraph,
  Subtitle,
  Title,
  PasswordInputComponent,
  RandomImageComponent,
  WhatsAppFloatingButton,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loginAsync } from '../../redux/slices/authSlice';
import variables from '../../scss/_exports.module.scss';
import errorImage from '../../assets/images/error.png';
import { UserCredentials } from '../../utils/interfaces';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { FormFieldType, StorageVars } from '../../utils/types';
import appstoreButton from '../../assets/images/stores/appstore-button.png';
import gpButton from '../../assets/images/stores/google-play-button.png';
import errorHandlerHelper from '../../utils/errorHandler';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import MessageModalTokenExpired, { MessageModalInvalidCredentials, config } from '../../utils/constants';

const LoginPage: FC = () => {
  // const history = useHistory<{ fromRegister: boolean; fromRecoverPassword: boolean }>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);
  const token = localStorage.getItem('fcmToken');
  const [countInvalidCredential, setCountInvalidCredential] = useState(1);
  const invalidCredentials = 'INVALID_CREDENTIALS';

  useEffect(() => {
    const tokenExpired = localStorage.getItem(StorageVars.tokenExpired);
    if (tokenExpired) {
      dispatch(
        addModalToList({
          body: <MessageModalTokenExpired />,
          required: true,
          pictureUrl: errorImage,
          okButton: {
            buttonText: 'Iniciar sesión',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
      localStorage.removeItem(StorageVars.tokenExpired);
    }
  }, []);

  // useEffect(() => {
  //   if (history.location.state?.fromRegister) {
  //     // segmentTrack(EVENTS.AUTH.REGISTER.LOGIN);
  //   } else if (history.location.state?.fromRecoverPassword) {
  //     // segmentTrack(EVENTS.AUTH.FORGOT_PASSWORD.LOGIN);
  //   }
  // }, []);

  const handleLogin = (values: UserCredentials): void => {
    dispatch(loginAsync({ email: values.email, password: values.password }))
      .then((res: any) => {
        if (res?.error?.name === invalidCredentials) {
          setCountInvalidCredential(countInvalidCredential + 1);
          if (countInvalidCredential === config.nubceo.limitCountInvalidCredentials && !token) {
            dispatch(
              addModalToList({
                body: <MessageModalInvalidCredentials />,
                required: true,
                pictureUrl: errorImage,
                okButton: {
                  buttonText: 'Aceptar',
                  onClick: async () => {
                    dispatch(removeModalFromList());
                  },
                },
              }),
            );
          }
        } else if (!res?.error) {
          setCountInvalidCredential(0);
        }
        const pathname = location.state?.referrer.pathname;
        const isTiendanube = location.state?.referrer.pathname.includes('tiendanube/auth');
        const search = location.state?.referrer.search;
        if (pathname && isTiendanube) {
          navigate(`${pathname}/${search}`);
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  };

  const initialValues: UserCredentials = { email: '', password: '' };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Debes escribir un email válido.').required('Debes escribir un email.'),
    password: Yup.string().min(8, 'Debe tener al menos 8 caracteres.').required('Este campo es requerido.'),
  });

  const redirectRegister = (): void => {
    navigate('/register');
  };

  return (
    <div className="login-page container-fluid row justify-content-center" style={{ height: '100%' }}>
      <div className="section d-flex flex-column justify-content-center col-xs-12 col-md-6">
        <Title color={variables.colorBlue}>¡Bienvenido a Nubceo!</Title>
        <Subtitle>Completá tus datos para ingresar</Subtitle>
        <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleLogin}>
          {({ errors, touched }): ReactElement => (
            <Form className="inputs-container d-flex flex-column align-items-end">
              <Field name="email">
                {({ field }: FormFieldType): ReactElement => (
                  <TextInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input"
                    type="email"
                    errorMessage={touched.email && errors.email}
                    placeholder="Ingresá tu email"
                    label="Email"
                    autoComplete="username"
                    {...field}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }: FormFieldType): ReactElement => (
                  <PasswordInputComponent
                    required
                    containerClassName="mt-2 w-100"
                    className="input"
                    errorMessage={touched.password && errors.password}
                    placeholder="Ingresá tu contraseña"
                    label="Contraseña"
                    type="password"
                    autoComplete="current-password"
                    {...field}
                  />
                )}
              </Field>
              {/* THIS FIELD IS LEFT COMMENTED WAITING FOR INCLUDING THIS FEATURE */}
              <Paragraph className="d-inline-block mt-2 w-100 mx-auto text-end font-xs">
                ¿Olvidaste tu contraseña?
                <LinkComponent className="d-inline-block ms-2 mt-3" route="/forgot-password">
                  Recuperala
                </LinkComponent>
              </Paragraph>
              <div className="w-100 d-flex flex-column align-items-center mt-5">
                <FormButtonComponent className="submit-button w-100" type="submit" disabled={loading === 'pending'}>
                  {loading === 'pending' ? 'Iniciando sesión...' : 'Ingresar'}
                </FormButtonComponent>
                <Paragraph className="d-inline-block mt-4 w-100 mx-auto text-center">
                  ¿No tenés cuenta?
                  <div className="d-inline-block ms-1 link-register" onClick={redirectRegister} role="none">
                    ¡Creala!
                  </div>
                </Paragraph>
              </div>
              <div className="d-flex mt-3 w-100 justify-content-center">
                <a href="https://apps.apple.com/ar/app/nubceo-cash/id1527840728">
                  <img src={appstoreButton} alt="Ir a App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.nubceo.app&hl=es_AR&gl=US">
                  <img className="ms-2" src={gpButton} alt="Ir a Google Play" />
                </a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <RandomImageComponent />
      <div>
        <WhatsAppFloatingButton />
      </div>
    </div>
  );
};

export default LoginPage;
