export const EVENTS = {
  SETTLEMENTS: {
    FILTER: 'settlementsFilter',
    TABS: {
      CHANGE: 'changeSettlementTabs',
    },
    ROW: {
      EXPAND: 'expandSettlement',
    },
    PAGINATE: 'paginateSettlements',
  },
  COLLECTIONS: {
    FILTER: 'collectionsFilter',
    TABS: {
      CHANGE: 'changeCollectionTabs',
    },
    ROW: {
      EXPAND: 'expandCollection',
    },
    PAGINATE: 'paginateCollections',
  },
  SALES: {
    FILTER: 'salesFilter',
    ROW: {
      EXPAND: 'expandSale',
    },
    PAGINATE: 'paginateSales',
  },
  TAXES: {
    FILTER: 'taxesFilter',
    ROW: {
      EXPAND: 'expandTax',
    },
    PAGINATE: 'paginateTaxes',
  },
  EXPENSE_REPORTS: {
    FILTER: 'expenseReports',
  },
  AUTH: {
    LOGOUT: {
      SUCCESS: 'logoutSuccess',
      FAILED: 'notificationsTokenDeleteFailed',
    },
    LOGIN: {
      SUCCESS: 'loginSuccess',
      FAILED: 'loginFailed',
      REGISTER: 'goToRegister.click',
      HELP: 'loginNeedHelp.click',
    },
    REGISTER: {
      HELP: 'registerNeedHelp.click',
      LOGIN: 'goToLogin.click',
      SUCCESS: 'registerSuccess',
      FAILED: 'registerFailed',
    },
    FORGOT_PASSWORD: {
      LOGIN: 'goToLogin.click',
    },
  },
  ONBOARDING: {
    HELP: 'onBoardingNeedHelp.click',
    COMPLETE: {
      SUCCESS: 'onBoardingSuccess',
      FAILED: 'onBoardingFailed',
    },
  },
  LAYOUT: {
    MENU: {
      EXPAND: 'expandMenu',
      REDUCE: 'reduceMenu',
    },
    USER_MENU: {
      EXPAND: 'expandUserMenu',
    },
  },
  REPORTS: {
    DOWNLOAD: {
      SUCCESS: 'downloadReportSuccess',
      FAILED: 'downloadReportFailed',
    },
    OPEN: {
      SUCCESS: 'openReportSuccess',
      FAILED: 'openReportFailed',
    },
  },
  CLIENT: {
    CREATE: {
      CANCEL: 'createClientCancelled',
      CLICK: 'createClient.click',
      SUCCESS: 'createClientSuccess',
      FAILED: 'createClientFailed',
    },
    UPDATE: {
      DISABLE: 'toggleEnableClient.click',
      CANCEL: 'updateClientCancelled',
      SUCCESS: 'updateClientSuccess',
      FAILED: 'updateClientFailed',
    },
  },
  COMPANY: {
    CREATE: {
      CANCEL: 'createCompanyCancelled',
      CLICK: 'createCompany.click',
      SUCCESS: 'createCompanySuccess',
      FAILED: 'createCompanyFailed',
    },
    UPDATE: {
      DISABLE: 'toggleEnableCompany.click',
      CANCEL: 'updateCompanyCancelled',
      SUCCESS: 'updateCompanySuccess',
      FAILED: 'updateCompanyFailed',
    },
    ADDRESS: {
      CREATE: {
        SUCCESS: 'createAddressSuccess',
        FAILED: 'createAddressFailed',
      },
      UPDATE: {
        SUCCESS: 'updateAddressSuccess',
        FAILED: 'updateAddressFailed',
      },
    },
    EXTERNAL_PLATFORM: {
      CREATE: {
        CLICK: 'createCompanyExternalPlatform.click',
        SUCCESS: 'createCompanyExternalPlatformSuccess',
        FAILED: 'createCompanyExternalPlatformFailed',
      },
      UPDATE: {
        CLICK: 'updateCompanyExternalPlatform.click',
        SUCCESS: 'updateCompanyExternalPlatformSuccess',
        FAILED: 'updateCompanyExternalPlatformFailed',
      },
      SELECT_PLATFORM: 'selectPlatform',
    },
  },
  PROFILE: {
    PAGE: 'profilePage.click',
    INVITE: 'inviteProfile.click',
  },
  NOTIFICATIONS: {
    PAGINATE: 'paginateNotifications',
  },
};

export default { EVENTS };
