import React, { FC, ReactElement, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import { NumberInputComponent, PasswordInputComponent, TextInputComponent } from '../../inputs';
import { FormFieldType } from '../../../utils/types';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalActivePlatform, FormTypeVisanet } from '../../../utils/interfaces';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';
import { regexCodes } from '../../../utils/constants';
import ToggleSwitch from '../../inputs/ToggleSwitch';
import VisanetApiModeCredentialsForm from './VisanetApiModeCredentialsForm';
import { Subtitle } from '../../textComponents';

export interface VisanetCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
}

const VisanetCredentialsForm: FC<VisanetCredentialsFormProps> = ({
  canEditPlatform,
  handleDeletePlatform,
  handleSubmitForm,
  currentValues,
}) => {
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const toggler = (): void => setToggleSwitch(!toggleSwitch);
  const [apiMode, setApiMode] = useState(currentValues?.codeAux2 === FormTypeVisanet.API);

  const apiModeText = 'Usar el modo API';
  const handleApiMode = (): void => setApiMode(!apiMode);

  const messageTooltip =
    'El modo API permite que podamos acceder con más exactitud a la información de tu negocio. Para activarlo tenés que solicitar el acceso a Visanet a través de https://acceso.vnet.uy';
  const urlVisanet = 'https://acceso.vnet.uy/';

  const validateForm = Yup.object().shape({
    codeAux4: Yup.string().matches(
      regexCodes.providers,
      'El formato que ingreses sólo pueden ser números, guiones y comas, y debe coincidir con el ejemplo',
    ),
    codeAux: Yup.number()
      .min(999, 'Ingresá mínimo 4 dígitos de tu comercio')
      .max(9999999999, 'Ingresá máximo 10 dígitos de tu comercio')
      .required('Ingresá tu número de comercio'),
    username: Yup.string().required('Ingresá un usuario válido.'),
    password: Yup.string().required('Tenés que escribir una contraseña.'),
  });

  const initialValues = {
    codeAux: currentValues?.codeAux || '',
    codeAux4: currentValues?.codeAux4 || '',
    codeAux2: apiMode ? FormTypeVisanet.API : FormTypeVisanet.BASIC || '',
    username: currentValues?.username || '',
    password: '',
  };

  return (
    <>
      <div className="d-flex flex-row w-50">
        <div className="mt-4 mb-2 fw-bold">
          <ToggleSwitch label={apiModeText} name={apiModeText} onChange={(): void => handleApiMode()} checked={apiMode} />
        </div>
        <div className="mt-4 mb-2 ms-3 text-link-container" data-tip={messageTooltip}>
          <Subtitle size="md">
            <a className="text-decoration-none" href={urlVisanet} target="blank">
              ¿Qué es esto?
            </a>
          </Subtitle>
        </div>
        <div>
          <ReactTooltip />
        </div>
      </div>
      {!apiMode ? (
        <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitForm}>
          {({ errors, isSubmitting, isValid, dirty, setFieldValue }): ReactElement => (
            <Form className="row mb-5 d-flex flex-column">
              <div className="container-input col-12 col-xl-6">
                <Field name="codeAux">
                  {({ field }: FormFieldType): ReactElement => (
                    <NumberInputComponent
                      containerClassName="mt-2 w-100"
                      label="Número de comercio"
                      placeholder="Ingresá el número de comercio"
                      errorMessage={field.value.length > 0 && errors.codeAux}
                      required
                      customChange={setFieldValue}
                      maxLength={10}
                      disabled={!canEditPlatform}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="container-input col-12 col-xl-6">
                <Field name="username">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={errors.username}
                      placeholder="Ingresá el usuario"
                      label="Usuario"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="container-input col-12 col-xl-6">
                <Field name="password">
                  {({ field }: FormFieldType): ReactElement => (
                    <PasswordInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={errors.password}
                      placeholder="Ingresá la contraseña"
                      label="Contraseña"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="mt-4 mb-2 fw-bold">
                <ToggleSwitch label="Pago a proveedores" name="proveedores" onChange={(): void => toggler()} checked={toggleSwitch} />
              </div>
              {toggleSwitch ? (
                <div className="container-input col-12 col-xl-6">
                  <Field name="codeAux4">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        containerClassName="mt-2 w-100"
                        className="input w-100"
                        errorMessage={field.value && errors.codeAux4}
                        placeholder="72-90,107,110-112"
                        label="Sucursales asignadas a proveedores"
                        {...field}
                      />
                    )}
                  </Field>
                  <span className="fst-italic mb-2">
                    {`Debes indicar los números de sucursales de VisaNet que corresponden a proveedores. Por ejemplo "72-90" significa: de la 72 a
                  la 90 inclusive, mientras que "107,110-112" significa: la 107, y desde la 110 a la 112 inclusive`}
                  </span>
                </div>
              ) : null}
              <p className="font-sm font-weight-light container-text mt-3 w-50">
                Para poder mostrarte la información en tiempo y forma vamos a necesitar hacer unos ajustes automáticos en tus
                configuraciones de notificación en Visanet.
              </p>
              <p className="font-sm font-weight-light container-text mt-3">
                Pero no te preocupes ya que no van a verse afectados tus flujos normales.
              </p>
              <div className="col-12">
                <PlatformCredentialsFooterButtons
                  disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
                  handleDeletePlatform={handleDeletePlatform}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <VisanetApiModeCredentialsForm
          canEditPlatform={canEditPlatform}
          handleDeletePlatform={handleDeletePlatform}
          handleSubmitForm={handleSubmitForm}
          currentValues={currentValues}
          apiMode={apiMode}
        />
      )}
    </>
  );
};

export default VisanetCredentialsForm;
