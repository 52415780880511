import React, { FC } from 'react';
import { ActivityCode } from '../../utils/interfaces';

type ActivityMedalType = {
  activity: string | null;
  className?: string;
};

const ActivityMedal: FC<ActivityMedalType> = ({ activity, className }) => {
  let activityShown = '';
  switch (activity) {
    case ActivityCode.CONTADOR:
      activityShown = 'Contador';
      break;
    case ActivityCode.FABRICANTE:
      activityShown = 'Fabricante';
      break;
    case ActivityCode.SERVICIOS:
      activityShown = 'Servicios';
      break;
    case ActivityCode.VENTA:
      activityShown = 'Ventas';
      break;
    default:
      break;
  }
  return <p className={`font-sm activity-medal ${className}`}>{activityShown}</p>;
};

export default ActivityMedal;
