import React, { FC, useEffect, useState } from 'react';
// import SaleRow from '../../components/Collections/Sales/SaleRow';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import logger from '../../services/loggerService';
import { getSalesBySettlement } from '../../services/settlementService';
import { errorHandlerHelperV2 } from '../../utils/errorHandler';
import { Pagination, Sale } from '../../utils/interfaces';
import { LoaderSpinner, PaginationComponent } from '../../components';
import SaleRow from '../../components/Collections/SalesList/SaleRow';
import { currencyFormat, decimalFormat } from '../../utils/stringUtils';
import { getSalesBySettlementClient } from '../../services/clientsService';

type SalesBySettlementContainerType = {
  id: string;
  grossAmount: number;
  businessEntityId?: string | null;
};

type SalesBySettlementResponse = {
  data: Sale[];
  meta: Pagination;
};

export const SalesBySettlementContainer: FC<SalesBySettlementContainerType> = ({ id, grossAmount, businessEntityId }) => {
  const dispatch = useAppDispatch();
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const [salesBySettlement, setSalesBySettlement] = useState<Sale[]>([]);
  const [pagination, setPagination] = useState({ total: 0, page: '1', sales: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    errorHandlerHelperV2<SalesBySettlementResponse>(
      () =>
        businessEntityId
          ? getSalesBySettlementClient(id, businessEntityId, { page: pagination.page })
          : getSalesBySettlement(id, { page: pagination.page }),
      dispatch,
    )
      .then((response) => {
        setSalesBySettlement(response.data);
        setPagination({ ...pagination, total: response.meta.pages, sales: response.meta.total });
      })
      .catch((err) => logger.error('getSalesBySettlement.error', err))
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.page]);

  const handlePagination = (searchParams: Record<string, string>): void => {
    setPagination({ ...pagination, page: searchParams.page });
  };

  return (
    <div style={{ overflow: 'auto', width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h4 className="mb-5">Ventas de la liquidación</h4>
      {loading && (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoaderSpinner />
        </div>
      )}
      {!loading && salesBySettlement.length ? (
        <div className="modal-sales-by-settlement">
          {salesBySettlement.map((sale) => (
            <SaleRow sale={sale} isModal />
          ))}
          <div className="d-flex justify-content-around flex-wrap">
            <div className="d-flex align-items-center justify-content-end flex-wrap">
              <p className="me-3">Ventas: {pagination.sales}</p>
              <p className="me-3">
                Total: {activeCurrency ? currencyFormat(activeCurrency?.code) : '$'} {decimalFormat(grossAmount)}
              </p>
            </div>
            <PaginationComponent totalPages={pagination.total} page={pagination.page} onClick={handlePagination} />
          </div>
        </div>
      ) : null}
      {!loading && !salesBySettlement.length && (
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">No hay ventas disponibles</div>
      )}
    </div>
  );
};
