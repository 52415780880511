import moment from 'moment';
import React, { ReactElement } from 'react';
import { BsDownload } from 'react-icons/bs';
import { useAppSelector } from '../../../redux/hooks';
import { externalPlatformsBasicData } from '../../../utils/constants';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';
import { ReportsType } from '../../../utils/types';

type ReportRowType = {
  report: ReportsType;
};
type Filters = {
  // eslint-disable-next-line camelcase
  platform_external_code?: {
    eq?: string;
    in?: string;
  };
  date?: {
    between?: string[];
    gte?: string;
    lte?: string;
    gt?: string;
    lt?: string;
  };
  paymentDate?: {
    between?: string[];
    gte?: string;
    lte?: string;
    gt?: string;
    lt?: string;
  };
  presentedDate?: {
    between?: string[];
    gte?: string;
    lte?: string;
    gt?: string;
    lt?: string;
  };
  companyId?: {
    eq?: string;
    in?: string[];
  };
  // eslint-disable-next-line camelcase
  included_settlement?: string;
  addSalesToReport?: boolean;
  type?: {
    eq?: string;
    in?: string[];
  };
  tenantId?: string;
  branchId?: {
    eq?: string;
    in?: string[];
  };
};
enum FilterParams {
  date = 'date',
  platform = 'platform_external_code',
  company = 'companyId',
  presentedDate = 'presentedDate',
  paymentDate = 'paymentDate',
  branch = 'branchId',
  includedSettlement = 'included_settlement',
  type = 'type',
  addSalesToReport = 'addSalesToReport',
}
const ReportRowComponent: React.FC<ReportRowType> = ({ report }) => {
  const { companiesList } = useAppSelector((state) => state.company);
  const { branchesList } = useAppSelector((state) => state.branch);
  const { clientsList } = useAppSelector((state) => state.clients);
  const notAvailable = moment(report.createdAt).add(7, 'days') < moment() || report.status !== 'completed';
  const statusParser = {
    completed: 'Completado',
    in_process: 'En Proceso',
    error: 'Error',
    new: 'Nueva',
  };
  const reportTypeParser = {
    sale: 'Ventas',
    collection: 'Ingresos',
    settlement: 'Liquidaciones',
    cashflow: 'Calendario',
    expense: 'Gastos',
    tax: 'Impuestos',
  };

  const handleDownloadClick = (): void => {
    try {
      window.open(report.fileUrl);
      // segmentTrack(EVENTS.REPORTS.OPEN.SUCCESS);
    } catch (error) {
      console.log(error);
      // segmentTrack(EVENTS.REPORTS.OPEN.FAILED, { error });
    }
  };
  const filters = JSON.parse(report.filters);

  const getClientName = () => {
    const client = clientsList.find((item) => item.relTenantId === filters.tenantId);
    const isArray = Array.isArray(filters?.companyId);
    if ((isArray && filters?.companyId?.length === 1) || (!isArray && filters?.companyId)) {
      const foundClient = clientsList.find((item) => item.relCompanyId === filters.companyId);
      return foundClient || client;
    }
    return client;
  };

  const translatedTypes: Record<string, string> = {
    order: 'Venta',
    chargeback: 'Contracargo',
    return: 'Devolución',
    adjustment: 'Ajuste',
  };

  const parseFilters = (f: Filters): React.ReactNode => {
    const keys = Object.keys(f) as Array<FilterParams>;
    return keys.map((key) => {
      switch (key) {
        case FilterParams.date:
          return (
            <>
              <span className="tag">{`Fecha desde: ${moment(f.date?.between?.[0] || f.date?.gt || f.date?.gte).format(
                'DD-MM-YYYY',
              )}`}</span>
              <span className="tag">{`Fecha hasta: ${moment(f.date?.between?.[1] || f.date?.lt || f.date?.lte).format(
                'DD-MM-YYYY',
              )}`}</span>
            </>
          );
        case FilterParams.paymentDate:
          return (
            <>
              <span className="tag">{`Fecha de pago desde: ${moment(
                f.paymentDate?.between?.[0] || f.paymentDate?.gt || f.paymentDate?.gte,
              ).format('DD-MM-YYYY')}`}</span>
              <span className="tag">{`Fecha de pago hasta: ${moment(
                f.paymentDate?.between?.[1] || f.paymentDate?.lt || f.paymentDate?.lte,
              ).format('DD-MM-YYYY')}`}</span>
            </>
          );
        case FilterParams.presentedDate:
          return (
            <>
              <span className="tag">{`Fecha de presentación desde: ${moment(
                f.presentedDate?.between?.[0] || f.presentedDate?.gt || f.presentedDate?.gte,
              ).format('DD-MM-YYYY')}`}</span>
              <span className="tag">{`Fecha de presentación hasta: ${moment(
                f.presentedDate?.between?.[1] || f.presentedDate?.lt || f.presentedDate?.lte,
              ).format('DD-MM-YYYY')}`}</span>
            </>
          );
        case FilterParams.company:
          if (f.companyId?.eq) {
            return (
              <span className="tag">
                {`Compañía: ${companiesList.find((company) => company.id === f.companyId?.eq?.toString())?.name}`}
              </span>
            );
          }
          if (f.companyId?.in) {
            const companies = f.companyId.in.map((id) => companiesList.find((company) => company.id === id.toString())?.name);
            return <span className="tag">{`Compañías: ${companies.join(', ')}`}</span>;
          }
          return '';
        case FilterParams.platform:
          return (
            <span className="tag">{`Plataforma: ${
              f.platform_external_code?.eq && externalPlatformsBasicData(f.platform_external_code?.eq).name
            }`}</span>
          );
        case FilterParams.addSalesToReport:
          return f.addSalesToReport ? <span className="tag">Ventas: Incluidas</span> : null;
        case FilterParams.branch:
          if (f.branchId?.eq) {
            return (
              <span className="tag">{`Sucursal/Comercio: ${
                branchesList.find((branch) => branch.id === f.branchId?.eq?.toString())?.name
              }`}</span>
            );
          }
          if (f.branchId?.in) {
            const branches = f.branchId.in
              .map((id) => branchesList.find((branch) => branch.id === id.toString())?.name)
              .filter((name) => name && name.trim() !== undefined);
            return <span className="tag">{`Sucursales/Comercios: ${branches.join(', ')}`}</span>;
          }
          return '';
        case FilterParams.includedSettlement:
          if (f.included_settlement) {
            return (
              <span className="tag">{`Ventas: ${f.included_settlement?.toString() === 'included' ? 'Liquidadas' : 'No Liquidadas'}`}</span>
            );
          }
          return '';
        case FilterParams.type:
          if (f.type?.eq) {
            switch (f.type?.eq) {
              case 'order':
              case 'chargeback':
              case 'return':
              case 'adjustment':
                return <span className="tag">{`Tipo de operación: ${translatedTypes[f.type?.eq]}`}</span>;

              default:
                return '';
            }
          }
          if (f.type?.in) {
            const types = f.type.in.map((type) => translatedTypes[type]);
            return <span className="tag">{`Tipo de operación: ${types.join(', ')}`}</span>;
          }
          return '';
        default:
          return null;
      }
    });
  };
  const showMsg = (): ReactElement => {
    let msg = <p className="me-1 d-none d-md-flex">Descargar</p>;
    if (statusParser[report.status] === 'Nueva') {
      msg = <p className="me-1 d-none d-md-flex">En Proceso</p>;
    }
    if (notAvailable && statusParser[report.status] !== 'Nueva') {
      msg = <p className="me-1 d-none d-md-flex">No Disponible</p>;
    }
    return msg;
  };

  return (
    <div className="report-row-component row-container d-flex flex-xs-column flex-md-row p-3" key={report.id}>
      <div className="col-xl-4 col-lg-5 col-md-5 col-5">
        <div className="d-flex flex-column mb-2 mt-2">
          {getClientName()?.name && <span className="nameClient mb-2">Cliente: {getClientName()?.name}</span>}
          <div className="labels">
            <span className="type">{reportTypeParser[report.type]}</span>
            <span className={`d-block d-md-inline d-xl-none mt-2 report-${report.status}`}>{statusParser[report.status]}</span>
          </div>
        </div>
        <div className="data">
          <span className="name">{`Solicitado por: ${report.User.firstName} ${report.User.sureName}`}</span>
          <span className="date">Fecha: {moment(report.createdAt).format('DD-MM-YYYY')}</span>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 col-4">
        {Object.keys(filters).length > 1 ? (
          <div className="filters">
            <div className="title">Filtros Aplicados</div>
            <div className="tag-container">{parseFilters(filters)}</div>
          </div>
        ) : null}
      </div>
      <div className="buttons col-xl-4 col-lg-3 col-md-3 col-2">
        <span className={`d-none d-xl-inline report-${report.status}`}>{statusParser[report.status]}</span>
        <button
          className="download"
          disabled={notAvailable}
          type="button"
          onClick={handleDownloadClick}
          title={notAvailable ? 'El reporte ya no está disponible.' : ''}
        >
          <div className="d-flex flex-row align-items-center">
            {showMsg()}
            <BsDownload />
          </div>
        </button>
      </div>
    </div>
  );
};

export default ReportRowComponent;
