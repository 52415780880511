import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from '../../utils/interfaces';
import { getCurrencies } from '../../services/currencyService';
import { RequestParams, LoadingStateType } from '../../utils/types';
import errorHandlerHelper from '../../utils/errorHandler';
import logger from '../../services/loggerService';

const initialState: CurrencyState = {
  currencyList: [],
  activeCurrency: null,
  loadingCurrencies: 'pending',
};

interface CurrencyState {
  currencyList: Currency[];
  activeCurrency?: Currency | null;
  loadingCurrencies: LoadingStateType;
}

export const listCurrencies = createAsyncThunk<Currency[], RequestParams>(
  'currencies/getCurrencies',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCurrencies();
      return response;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const currencySlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    setActiveCurrency: (state, action: PayloadAction<Currency>) => {
      state.activeCurrency = action.payload;
      logger.info('activeCurrency', state.activeCurrency);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loadingCurrencies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listCurrencies.pending, (state, _action) => {
      state.loadingCurrencies = 'pending';
    });
    builder.addCase(listCurrencies.rejected, (state, action) => {
      state.loadingCurrencies = 'failed';
      logger.error('currencyList.error', action.error);
    });
    builder.addCase(listCurrencies.fulfilled, (state, action) => {
      state.loadingCurrencies = 'succeeded';
      state.currencyList = action.payload;
      logger.info('currencyList.success', state.currencyList);
    });
  },
});
export const { setActiveCurrency, setLoading } = currencySlice.actions;
export default currencySlice.reducer;
