import React, { FC, ReactElement, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { PasswordInputComponent, TextInputComponent } from '../../inputs';
import { FormFieldType } from '../../../utils/types';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalActivePlatform, FormTypePrisma } from '../../../utils/interfaces';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';
import ToggleSwitch from '../../inputs/ToggleSwitch';

interface PrismaCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
  existingPlatform: ExternalActivePlatform | undefined;
}

const PrismaCredentialsForm: FC<PrismaCredentialsFormProps> = ({
  canEditPlatform,
  handleDeletePlatform,
  handleSubmitForm,
  currentValues,
  existingPlatform,
}) => {
  const { id } = useParams();
  const [toggleSwitch, setToggleSwitch] = useState(existingPlatform && existingPlatform.codeAux === FormTypePrisma.FTP);
  const newString = 'new';

  const validateForm = Yup.object().shape({
    password: Yup.string().required('Ingresá tu contraseña'),
    username: !toggleSwitch
      ? Yup.string().email('Ingresá un email válido').required('Ingresá tu usuario')
      : Yup.string()
          .matches(/^[a-zA-Z0-9-]+$/, 'Ingresá un usuario válido')
          .required('Ingresá tu usuario'),
  });

  const initialValues = {
    password: '',
    username: currentValues?.username || '',
    codeAux: toggleSwitch ? FormTypePrisma.FTP : '',
  };

  return (
    <>
      <div className="mt-4 mb-2 fw-bold">
        <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitForm}>
          {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }): ReactElement => (
            <Form className="row mb-5 d-flex flex-column">
              <ToggleSwitch
                label="Formulario FTP"
                name={FormTypePrisma.FTP}
                onChange={(): void => {
                  if (id === newString || !existingPlatform?.username) {
                    setToggleSwitch(!toggleSwitch);
                    setFieldValue('codeAux', !toggleSwitch ? FormTypePrisma.FTP : '');
                  }
                }}
                checked={values.codeAux === FormTypePrisma.FTP}
              />
              <div className="container-input col-12 col-xl-6">
                <Field name="username">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInputComponent
                      label="Usuario"
                      placeholder="Ingresá el usuario"
                      errorMessage={field.value && errors.username}
                      required
                      disabled={!canEditPlatform}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ field }: FormFieldType): ReactElement => (
                    <PasswordInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={field.value && errors.password}
                      placeholder="Ingresá la contraseña"
                      label="Contraseña"
                      disabled={!canEditPlatform}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-12">
                <PlatformCredentialsFooterButtons
                  disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
                  handleDeletePlatform={handleDeletePlatform}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PrismaCredentialsForm;
