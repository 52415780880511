import React, { FC, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { SelectCaretSVG } from '../../../assets/svgComponents';
import { useHandleMouseDownOutside } from '../../../utils/hooks';
import { InputWrapperProps, SelectInputProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';
import SelectOptionComponent from '../SelectOption';

const SelectInput: FC<SelectInputProps & InputWrapperProps> = ({
  label,
  errorMessage,
  placeholder,
  name,
  className = '',
  containerClassName,
  readOnly = true,
  required = false,
  options,
  multiple,
  value,
  image,
  onOptionClick,
  disabled,
  defaultValue = '',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const optionRef = useRef<HTMLInputElement>(null);
  const [opened, setOpened] = useHandleMouseDownOutside(inputRef);

  const renderThumb = ({ style, ...p }: { style: React.CSSProperties }): React.ReactElement => {
    const thumbStyle: React.CSSProperties = {
      backgroundColor: '#aaa',
      borderRadius: '6px',
      width: '.8rem',
      right: '10px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...p} />;
  };
  const heightContainerValue = options.length < 10 ? options.length * 50 : options.length * 10;

  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
      <div
        style={{ pointerEvents: disabled || !options.length ? 'none' : undefined }}
        ref={inputRef}
        className={`select-input-component-container ${image && 'select-input-component-custom'}`}
      >
        {image}
        <input
          className={`selector ${!image && 'select-input-component'} ${errorMessage && 'error'} ${className} ${opened ? 'opened' : ''} ${
            disabled || !options.length ? 'disabled' : ''
          }`}
          name={name}
          multiple={multiple}
          placeholder={options.length ? placeholder : 'No hay opciones disponibles'}
          readOnly={readOnly}
          onClick={(): void => setOpened(readOnly ? !opened : true)}
          value={
            (image
              ? options.find((option) => option.value === value)?.value || ''
              : options.find((option) => option.value === value)?.name || '') || defaultValue
          }
          {...props}
        />
        <SelectCaretSVG width={12} onClick={(): void => setOpened(!opened)} className={`caret ${opened ? 'opened' : ''}`} />
      </div>
      {opened && (
        <div ref={optionRef} style={{ height: `${heightContainerValue}px` }} className="options-container">
          <Scrollbars className="options-container custom-scrollbar-container" renderThumbVertical={renderThumb}>
            {options?.map((item) => (
              <SelectOptionComponent
                containerClassName={value === item.value ? 'selected' : ''}
                key={`${item.id}-${item.name}`}
                option={item}
                onClick={(): void => {
                  onOptionClick(name, item.value);
                  setOpened(false);
                }}
                label={item.optionLabel}
              >
                {item.pill}
              </SelectOptionComponent>
            ))}
          </Scrollbars>
        </div>
      )}
    </InputWrapper>
  );
};

export default SelectInput;
