import React, { FC } from 'react';
import { DailySummary, Title } from '../../components';

const SettingsPage: FC = () => (
  <>
    <div className="mt-3">
      <Title>Resumen de ingresos diarios</Title>
    </div>
    <div className="d-flex flex-column">
      <DailySummary />
    </div>
  </>
);
export default SettingsPage;
