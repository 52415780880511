import { AxiosPromise } from 'axios';
import { CompanyOnBoardingPost, FilterParams } from '../utils/types';
import {
  AddAddressResponse,
  AddCompanyResponse,
  AddressRequestPatch,
  AddressRequestPost,
  Company,
  CompanyRequestPatch,
  CompanyRequestPost,
} from '../utils/interfaces';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

export const getCompanies = (params?: FilterParams): AxiosPromise => axiosV2.get('/company?sort[name][desc]', { params });

export const getCompany = async (id: string | number): Promise<Company> => {
  const { data } = await axios.get<{ data: Company }>(`/company/${id}`);
  return data.data;
};

export const createCompanyOnBoarding = async (company: CompanyRequestPost | CompanyOnBoardingPost): Promise<AddCompanyResponse> => {
  const { data } = await axios.post<{ data: AddCompanyResponse }>('/onboarding/company', company);
  return data.data;
};

export const createCompany = (company: CompanyRequestPost | CompanyOnBoardingPost): AxiosPromise => axios.post('/company', company);

export const editCompany = (company: CompanyRequestPatch): AxiosPromise => axios.patch(`/company/${company.id}`, company);

export const createAddress = async (address: AddressRequestPost): Promise<AddAddressResponse> => {
  const { data } = await axios.post<{ data: { document: AddAddressResponse } }>('/address', address);
  return data.data.document;
};

export const editAddress = async (address: AddressRequestPatch): Promise<AddAddressResponse> => {
  const { data } = await axios.patch<{ data: { document: AddAddressResponse } }>(`/address/${address.id}`, address);
  return data.data.document;
};
