import React, { FC, useState } from 'react';
import { LoaderSpinner } from '..';
import { currencyFormat } from '../../utils/stringUtils';
import { LoadingStateType } from '../../utils/types';

interface CardCollectionProps {
  unconfirmed: number | undefined;
  date: string;
  amount: string | null;
  dateText: string;
  today: boolean | null;
  loading: LoadingStateType;
  currencyCode: string | undefined;
}

const CardCollection: FC<CardCollectionProps> = ({
  dateText,
  date,
  today,
  amount,
  loading,
  unconfirmed,
  currencyCode,
}: CardCollectionProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className={`card-collection ${today && 'today'}`}>
      <div className="card-body" onClick={() => unconfirmed && setVisible(!visible)} role="none">
        <div className="title">
          <p className="text">{dateText}</p>
          <p className="date">{date}</p>
        </div>
        <p className="amount">
          {loading === 'pending' ? <LoaderSpinner /> : `${currencyCode ? currencyFormat(currencyCode) : ''} ${amount ?? '0'}`}
          {unconfirmed ? <p className="unconfirmed">*</p> : null}
        </p>
        <p className={visible ? 'text-unconfirmed' : 'd-none'}>Parte de los ingresos incluidos aquí son estimados.</p>
      </div>
    </div>
  );
};
export default CardCollection;
