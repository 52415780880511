import * as React from 'react';
import { SVGProps } from '../../utils/types';

function CloseIconSVG({ className = 'color-blue', height, width, onClick }: SVGProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 14}
      height={height || 13}
      className={className}
      onClick={onClick}
      viewBox="0 0 14 13"
    >
      <path d="M8.41 6.3l4.3-4.348a1.025 1.025 0 000-1.44.997.997 0 00-1.42 0L7 4.873 2.71.511a.997.997 0 00-1.42 0 1.025 1.025 0 000 1.44l4.3 4.349-4.3 4.348a1.016 1.016 0 00-.296.72 1.026 1.026 0 00.621.942.988.988 0 001.095-.222L7 7.73l4.29 4.359a1 1 0 00.71.3.987.987 0 00.71-.3 1.012 1.012 0 00.296-.72 1.025 1.025 0 00-.296-.72l-4.3-4.348z" />
    </svg>
  );
}

export default CloseIconSVG;
