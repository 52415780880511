import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { LinkComponentProps } from '../../utils/interfaces';

const LinkComponent: FC<LinkComponentProps> = ({ className = '', children, route, size = 'md' }) => (
  <Link className={`link-component ${size} ${className}`} to={route}>
    {children}
  </Link>
);

export default LinkComponent;
