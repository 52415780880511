/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCashflowMovements, getCashflowSummaryByDay, getCashflowSummaryByDayDetail } from '../../services/cashflowService';
import logger from '../../services/loggerService';
import { CashflowSummary, Cashflow } from '../../utils/interfaces';
import { FilterParams } from '../../utils/types';
import errorImage from '../../assets/images/error.png';
import { addModalToList, removeModalFromList } from './modalSlice';
import { mesaggeError } from '../../utils/stringUtils';
import { ENV_URL } from '../../config';

interface CashflowSummaryResponse {
  data: Array<CashflowSummary>;
}
interface CashflowMovementResponse {
  data: Array<Cashflow>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}
interface CashflowState {
  presentCashflowSummaryDayDetailList: Array<CashflowSummary>;
  presentCashflowSummaryDayList: Array<CashflowSummary>;
  prevCashflowSummaryDayList: Array<CashflowSummary>;
  nextCashflowSummaryDayPlatformList: Array<CashflowSummary>;
  nextCashflowSummaryDayList: Array<CashflowSummary>;
  presentCashflowSummaryDayLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  prevCashflowSummaryDayLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  nextCashflowSummaryDayLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  presentCashflowSummaryDayDetailLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  cashflowMovementList: Array<Cashflow>;
  cashflowMovementListLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  cashflowMovementPages: number;
  cashflowMovementTotal: number;
}

export const fetchPresentCashflowSummaryDayDetail = createAsyncThunk<CashflowSummaryResponse, FilterParams>(
  'presentCashflow/getCashflowSummaryByDayDetail',
  async (params, { dispatch }) => {
    try {
      const { data: response } = await getCashflowSummaryByDayDetail(params);
      return response;
    } catch (error) {
      const currentURL = window.location.href;
      const API_URL = ENV_URL;
      if (currentURL === API_URL) {
        dispatch(
          addModalToList({
            body: mesaggeError(),
            pictureUrl: errorImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      }
      throw error;
    }
  },
);

export const fetchPresentCashflowSummaryDay = createAsyncThunk<CashflowSummaryResponse, FilterParams>(
  'presentCashflow/getCashflowSummaryByDay',
  async (params, { dispatch }) => {
    try {
      const { data: response } = await getCashflowSummaryByDay(params);
      return response;
    } catch (error) {
      const currentURL = window.location.href;
      const API_URL = ENV_URL;
      if (currentURL === API_URL) {
        dispatch(
          addModalToList({
            body: mesaggeError(),
            pictureUrl: errorImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      }
      throw error;
    }
  },
);

export const fetchPrevCashflowSummaryDay = createAsyncThunk<CashflowSummaryResponse, FilterParams>(
  'prevCashflow/getCashflowSummaryDay',
  async (params, { dispatch }) => {
    try {
      const { data: response } = await getCashflowSummaryByDay(params);
      return response;
    } catch (error) {
      dispatch(
        addModalToList({
          body: mesaggeError(),
          pictureUrl: errorImage,
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
      throw error;
    }
  },
);

export const fetchNextCashflowSummaryDay = createAsyncThunk<CashflowSummaryResponse, FilterParams>(
  'nextCashflow/getCashflowSummaryDay',
  async (params, { dispatch }) => {
    try {
      const { data: response } = await getCashflowSummaryByDay(params);
      return response;
    } catch (error) {
      dispatch(
        addModalToList({
          body: mesaggeError(),
          pictureUrl: errorImage,
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
      throw error;
    }
  },
);

export const fetchCashflowMovements = createAsyncThunk<CashflowMovementResponse, FilterParams>(
  'cashflow/getCashflowMovements',
  async (params, { dispatch }) => {
    try {
      const { data: response } = await getCashflowMovements(params);
      return response;
    } catch (error) {
      dispatch(
        addModalToList({
          body: mesaggeError(),
          pictureUrl: errorImage,
          okButton: {
            buttonText: 'Aceptar',
            onClick: async () => {
              dispatch(removeModalFromList());
            },
          },
        }),
      );
      throw error;
    }
  },
);
// TODO add Total, pages ASAP (pagination)
const initialState: CashflowState = {
  presentCashflowSummaryDayDetailList: [],
  presentCashflowSummaryDayList: [],
  prevCashflowSummaryDayList: [],
  nextCashflowSummaryDayList: [],
  nextCashflowSummaryDayPlatformList: [],
  presentCashflowSummaryDayLoading: 'idle',
  presentCashflowSummaryDayDetailLoading: 'idle',
  prevCashflowSummaryDayLoading: 'idle',
  nextCashflowSummaryDayLoading: 'idle',
  cashflowMovementList: [],
  cashflowMovementListLoading: 'idle',
  cashflowMovementPages: 1,
  cashflowMovementTotal: 0,
};

export const cashflowSlice = createSlice({
  name: 'cashflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchPresentCashflowSummaryDayDetail
    builder.addCase(fetchPresentCashflowSummaryDayDetail.pending, (state, _action) => {
      state.presentCashflowSummaryDayDetailLoading = 'pending';
    });
    builder.addCase(fetchPresentCashflowSummaryDayDetail.rejected, (state, action) => {
      state.presentCashflowSummaryDayDetailLoading = 'failed';
      logger.error('fetchPresentCashflowSummaryDayPlatform.error', action.error);
    });
    builder.addCase(fetchPresentCashflowSummaryDayDetail.fulfilled, (state, action) => {
      state.presentCashflowSummaryDayDetailLoading = 'succeeded';
      state.presentCashflowSummaryDayDetailList = action?.payload?.data;
    });

    // fetchPresentCashflowSummaryDayPlatform
    builder.addCase(fetchPresentCashflowSummaryDay.pending, (state, _action) => {
      state.presentCashflowSummaryDayLoading = 'pending';
    });
    builder.addCase(fetchPresentCashflowSummaryDay.rejected, (state, action) => {
      state.presentCashflowSummaryDayLoading = 'failed';
      logger.error('fetchPresentCashflowSummaryDay.error', action.error);
    });
    builder.addCase(fetchPresentCashflowSummaryDay.fulfilled, (state, action) => {
      state.presentCashflowSummaryDayLoading = 'succeeded';
      state.presentCashflowSummaryDayList = action?.payload?.data;
    });

    // fetchPrevCashflowSummaryDayPlatform
    builder.addCase(fetchPrevCashflowSummaryDay.pending, (state, _action) => {
      state.prevCashflowSummaryDayLoading = 'pending';
    });
    builder.addCase(fetchPrevCashflowSummaryDay.rejected, (state, action) => {
      state.prevCashflowSummaryDayLoading = 'failed';
      logger.error('fetchPrevCashflowSummaryDay.error', action.error);
    });
    builder.addCase(fetchPrevCashflowSummaryDay.fulfilled, (state, action) => {
      state.prevCashflowSummaryDayLoading = 'succeeded';
      state.prevCashflowSummaryDayList = action?.payload?.data;
    });

    // fetchNextCashflowSummaryDayPlatform
    builder.addCase(fetchNextCashflowSummaryDay.pending, (state, _action) => {
      state.nextCashflowSummaryDayLoading = 'pending';
    });
    builder.addCase(fetchNextCashflowSummaryDay.rejected, (state, action) => {
      state.nextCashflowSummaryDayLoading = 'failed';
      logger.error('fetchNextCashflowSummaryDay.error', action.error);
    });
    builder.addCase(fetchNextCashflowSummaryDay.fulfilled, (state, action) => {
      state.nextCashflowSummaryDayLoading = 'succeeded';
      state.nextCashflowSummaryDayList = action?.payload?.data;
    });

    // fetchCashflowMovements
    builder.addCase(fetchCashflowMovements.pending, (state, _action) => {
      state.cashflowMovementListLoading = 'pending';
    });
    builder.addCase(fetchCashflowMovements.rejected, (state, action) => {
      state.cashflowMovementListLoading = 'failed';
      logger.error('fetchCashflowMovements.error', action.error);
    });
    builder.addCase(fetchCashflowMovements.fulfilled, (state, action) => {
      state.cashflowMovementListLoading = 'succeeded';
      state.cashflowMovementList = action.payload.data;
      state.cashflowMovementPages = action.payload.meta.pages;
      state.cashflowMovementTotal = action.payload.meta.total;
    });
  },
});

export default cashflowSlice.reducer;
