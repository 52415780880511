import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextInputComponent, Subtitle, FormButtonComponent } from '..';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { getProfileDataAsync } from '../../redux/slices/userSlice';
import { updateProfile } from '../../services/userService';
import { regexCountryCodes } from '../../utils/constants';
import { UpdateUserData } from '../../utils/interfaces';
import { FormFieldType } from '../../utils/types';
import LoaderScreen from '../LoaderScreen';

const FormUpdateProfile: FC = () => {
  const { user, loading } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues: UpdateUserData = { firstName: user?.firstName || '', phone: user?.phone || '', sureName: user?.sureName || '' };

  const handleSubmit = async (values: UpdateUserData): Promise<void> => {
    await updateProfile(values, user?.id || '');
    dispatch<void>(getProfileDataAsync());
  };

  const handleChangePassword = (): void => {
    navigate('/change-password');
  };

  const handleChangeEmail = (): void => {
    navigate('/change-email');
  };
  const ForgotSchema = Yup.object().shape({
    firstName: Yup.string().required('Este campo es requerido.').max(20, 'Tu nombre debe tener menos de 20 caracteres.'),
    phone: Yup.string()
      .matches(regexCountryCodes[user?.Tenant.countryCode ?? 'default'].telephoneRegExp, 'Número de teléfono inválido')
      .min(
        5,
        `Ingresá un teléfono válido, con el formato ${regexCountryCodes[user?.Tenant.countryCode ?? 'default']?.telephonePlaceholder}`,
      )
      .required(
        `Ingresá un teléfono válido, con el formato ${regexCountryCodes[user?.Tenant.countryCode ?? 'default']?.telephonePlaceholder}`,
      ),
    sureName: Yup.string().required('Este campo es requerido.').max(20, 'Tu apellido debe tener menos de 20 caracteres.'),
  });
  return (
    <div className="d-flex flex-column">
      <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={handleSubmit}>
        {({ errors, isSubmitting, isValid, dirty, touched }): ReactElement => {
          if (loading === 'pending') {
            return <LoaderScreen />;
          }
          return (
            <Form autoComplete="account" className="row mb-5">
              <Subtitle>Información Personal</Subtitle>
              <div className="col-md-6">
                <Field name="firstName">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInputComponent
                      required
                      containerClassName="mt-2 w-100 "
                      className="input"
                      type="text"
                      errorMessage={errors.firstName}
                      placeholder="Ingresá tu nombre"
                      autoComplete="new-firstname"
                      label="Nombre"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <Field name="sureName">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input"
                      errorMessage={errors.sureName}
                      placeholder="Ingresá tu Apellido"
                      label="Apellido"
                      type="text"
                      autoComplete="new-sureName"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <Field name="phone">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInputComponent
                      required
                      containerClassName="mt-2 w-100"
                      className="input"
                      errorMessage={!touched.phone && errors.phone}
                      placeholder="Ingresá tu Teléfono"
                      label="Teléfono"
                      type="text"
                      autoComplete="new-phone"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <TextInputComponent
                  containerClassName="mt-2 w-100"
                  className="input"
                  placeholder="Ingresá tu email"
                  label="Email"
                  type="text"
                  value={user?.email || ''}
                  disabled
                  autoComplete="new-email"
                />
              </div>
              <div className="w-100">{}</div>

              <div className="col-md-12">
                <FormButtonComponent className="submit-button mt-4 w-100" onClick={(): void => handleChangeEmail()}>
                  Cambiar Email
                </FormButtonComponent>
              </div>

              <div className="col-md-12">
                <FormButtonComponent className="submit-button mt-4 w-100" onClick={(): void => handleChangePassword()}>
                  Cambiar Contraseña
                </FormButtonComponent>
              </div>

              <div className="col-md-12">
                <FormButtonComponent
                  className="submit-button mt-4 w-100"
                  type="submit"
                  disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
                >
                  Guardar
                </FormButtonComponent>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormUpdateProfile;
