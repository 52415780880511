import { AxiosRequestConfig, AxiosPromise } from 'axios';
import axiosV2 from './axiosInstanceV2';
import { FilterParams } from '../utils/types';

// TODO: Type better this calls. If not we can't know what it returns
export const getCashflowSummaryDayPlatform = (params: FilterParams): AxiosPromise =>
  axiosV2.get('/cashflow/summary-day-platform', { params });

export const getCashflowXLSX = (params: FilterParams): AxiosPromise =>
  axiosV2.get('/cashflow/generate-report/xlsx', { params: { ...params } });

export const getCashflowMovements = (params: AxiosRequestConfig | FilterParams): AxiosPromise =>
  axiosV2.get('/cashflow/movements', { params });

export const getCashflowSummaryByDay = (params: FilterParams): AxiosPromise => axiosV2.get('/cashflow/summary-by-day', { params });

export const getCashflowSummaryByDayDetail = (params: FilterParams): AxiosPromise =>
  axiosV2.get('/cashflow/summary-by-day-detail', { params });

export default { getCashflowSummaryDayPlatform, getCashflowMovements };
