import { AxiosPromise } from 'axios';
import axiosV2 from './axiosInstanceV2';
import {
  Client,
  ExternalActivePlatform,
  AddClientExternalPlatform,
  ExternalPlatformCredentials,
  EditClientExternalPlatform,
  Company,
} from '../utils/interfaces';
import { FilterParams } from '../utils/types';

export const getAllClients = async (params: FilterParams): Promise<Client[]> => {
  const { data } = await axiosV2.get('/business-entities', { params: { ...params } });
  return data;
};
export const getSingleClient = async (id: string): Promise<Client> => {
  const { data } = await axiosV2.get<{ data: Client }>(`/business-entities/${id}`);
  return data.data;
};
export const getClientSettlementsXLSX = (params: FilterParams, id?: number): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/settlement/generate-report/xlsx`, { params: { ...params } });

export const getClientSalesXLSX = (params: FilterParams, id?: number): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/sales/generate-report/xlsx`, { params: { ...params } });

export const sendInvitationClient = (id: string): AxiosPromise<{ data: Client }> =>
  axiosV2.post(`/business-entities/${id}/send-invitation`);

export const sendMercadoPagoInvitation = (id: string, pecId: any): AxiosPromise<void> =>
  axiosV2.post(`/business-entities/${id}/send-fill-mp-credentials`, { pecId });

export const asyncCreateClient = (params: Record<string, unknown>): AxiosPromise<{ data: Client }> =>
  axiosV2.post(`/business-entities`, params);

export const asyncUpdateClient = (id: string, params: Record<string, unknown>): AxiosPromise =>
  axiosV2.patch(`/business-entities/${id}`, params);

export const getClientAccessRequests = async (): Promise<Array<Client>> => {
  const { data } = await axiosV2.get<{ data: Array<Client> }>('/self/tenant/business-entities-request');
  return data.data;
};
export const getClientsAccess = async (): Promise<Array<Client>> => {
  const { data } = await axiosV2.get<{ data: Array<Client> }>('/self/tenant/business-entities');
  return data.data;
};
export const deleteClientAccess = async (id: number | string): Promise<Client> => {
  const { data } = await axiosV2.post<{ data: Client }>(`/self/tenant/business-entities/${id}/reject`);
  return data.data;
};
export const denyClientAccessRequest = async (id: number | string): Promise<Client> => {
  const { data } = await axiosV2.post<{ data: Client }>(`/self/tenant/business-entities-request/${id}/reject`);
  return data.data;
};
export const deletePlatformCredentialClient = async (clietID: number | string, id: number | string): Promise<ExternalActivePlatform[]> => {
  const { data } = await axiosV2.delete(`/business-entities/${clietID}/platform-external-credentials/${id}`);
  return data.data;
};
export const approveClientAccess = async (id: number | string): Promise<Client> => {
  const { data } = await axiosV2.post<{ data: Client }>(`/self/tenant/business-entities-request/${id}/approve`);
  return data.data;
};
// External Platforms
export const getClientExternalPlatformsV2 = async (id: number | string): Promise<ExternalActivePlatform[]> => {
  const { data } = await axiosV2.get<{ data: ExternalActivePlatform[] }>(`/business-entities/${id}/platform-external-credentials`);
  return data.data;
};
export const getClientCompanyV2 = async (id: string): Promise<Company[]> => {
  const { data } = await axiosV2.get<{ data: Company[] }>(`/business-entities/${id}/companies`);
  return data.data;
};
export const addClientPlatformCredential = async (
  clientPlatformCredentials: AddClientExternalPlatform,
): Promise<ExternalPlatformCredentials> => {
  const { data } = await axiosV2.post<{ data: ExternalPlatformCredentials }>(
    `/business-entities/${clientPlatformCredentials.id}/platform-external-credentials`,
    clientPlatformCredentials.platformCredentials,
  );
  return data.data;
};
export const editClientPlatformCredential = async (
  clientPlatformCredentials: EditClientExternalPlatform,
): Promise<ExternalPlatformCredentials> => {
  const { data } = await axiosV2.patch<{ data: ExternalPlatformCredentials }>(
    `/business-entities/${clientPlatformCredentials.id}/platform-external-credentials/${clientPlatformCredentials.platformCredentials.id}`,
    clientPlatformCredentials.platformCredentials,
  );
  return data.data;
};
export const createClientCompany = (id: string): AxiosPromise => axiosV2.post(`/business-entities/${id}/create-account`);

export const linkClientWithCompany = async (id: string, body: { companyId?: number; tenantId: number }): Promise<void> => {
  await axiosV2.post(`/business-entities/${id}/request-access`, body);
};

export const getSalesBySettlementClient = (id: string, businessEntityId: string, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${businessEntityId}/sales/by-settlement/${id}`, { params });

export const getCompanyDataForAccessRequest = (params: Record<string, unknown>): AxiosPromise =>
  axiosV2.get(`/business-entities/company-data`, { params });
