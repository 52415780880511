import React, { FC, useEffect } from 'react';
import { LoaderScreen, Subtitle, Title } from '../../components';
import { ReportRowComponent } from '../../components/rows';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { listReports } from '../../redux/slices/reportsSlice';
import { asyncListBranches } from '../../redux/slices/branchSlice';

const ReportsPage: FC = () => {
  const { reportsList, loading } = useAppSelector((state) => state.reports);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch<void>(listReports());
    dispatch<void>(asyncListBranches());
  }, []);
  return loading === 'pending' ? (
    <LoaderScreen />
  ) : (
    <div>
      <div className="reports-page">
        <div className="mt-5">
          <Title>Mis Reportes</Title>
          <Subtitle size="sm" color="grey">
            Los reportes solicitados van a estar vigentes por un máximo de 7 días. Luego de ese tiempo ya no podrás descargarlos.
          </Subtitle>
        </div>
        <div className="d-flex flex-column">
          {reportsList.length ? (
            reportsList.map((report) => <ReportRowComponent report={report} key={report.id} />)
          ) : (
            <div>NO TIENE REPORTES GENERADOS</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ReportsPage;
