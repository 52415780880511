const DAY_OPTION: { weekday: string } = { weekday: 'long' };

const DATE_OPTION: { year: string; month: string; day: string } = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const LANGUAGE_OPTIONS: Record<string, string> = {
  EN: 'en-GB',
  ES: 'es-ES',
};

type TranslationsType = Record<string, Record<string, string>>;

const TRANSLATIONS: TranslationsType = {
  'en-GB': {
    DAY: 'Day',
    DATE: 'Date',
    MEALTIME: 'Mealtime',
    PRICE: 'Price',
    // Start of ag-Grid locale translations
    selectAll: '(Select All)',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No matches',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',

    // Text Filter
    contains: 'Contains',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Columns',
    filters: 'Filters',

    // Other
    loadingOoo: 'Loading...',
    noRowsToShow: 'No Rows To Show',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',

    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    resetColumns: 'Reset Columns',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copy With Headers',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export (.xlsx)',
    excelXmlExport: 'Excel Export (.xml)',
  },
  'es-ES': {
    DAY: 'día',
    DATE: 'fecha',
    PRICE: 'precio',
    // Start of ag-Grid locale translations
    selectAll: '(seleccionar todo)',
    searchOoo: 'Buscar...',
    blanks: '(espacios en blanco)',
    noMatches: 'no hay coincidencias',
    group: 'Grupo',
    ColumnLabels: 'Columnas',

    // Number Filter & Text Filter
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEquals: 'No es igual',
    inRange: 'Rango',
    empty: 'Elige uno',
    startsWith: 'Comience con',
    endsWith: 'Finalice con',
    blank: 'Vacio',
    notBlank: 'No Vacio',
    filterFromValue: 'Desde',
    filterToValue: 'Hasta',

    // Number Filter
    lessThan: 'menor que',
    greaterThan: 'mayor que',

    // Text Filter
    contains: 'Contiene',
    notContains: 'No Contiene',
    andCondition: 'Y',
    orCondition: 'O',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Buttons
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',

    // Filter Titles
    textFilter: 'filtro de texto',
    numberFilter: 'filtro de número',
    dateFilter: 'filtro de fecha',
    setFilter: 'filtro de valores',

    // Enterprise Menu (Charts)
    chartRange: 'Crear Gráfico',
    columnChart: 'Columna',
    groupedColumn: 'Agrupar',
    stackedColumn: 'Apilado',
    normalizedColumn: '100% Apilado',

    barChart: 'Barras',
    groupedBar: 'Agrupar',
    stackedBar: 'Apilado',
    normalizedBar: '100% Apilado',

    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Rosca',

    line: 'Linea',

    xyChart: 'X Y (Dispersión)',
    scatter: 'Dispersión',
    bubble: 'Burbuja',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Apilada',
    normalizedArea: '100% Apilada',

    histogramChart: 'Histogram',
    histogramFrequency: 'Frequency',

    combinationChart: 'Combinación',
    columnLineCombo: 'Columna y Línea',
    AreaColumnCombo: 'Area y Columna',

    // Charts
    pivotChartTitle: 'Gráfico Pivot',
    rangeChartTitle: 'Gráfico',
    settings: 'Configurar',
    data: 'Datos',
    format: 'Formato',
    categories: 'Categorias',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Valores',
    paired: 'Modo Emparejado',
    axis: 'Axis',
    navigator: 'Navegador',
    color: 'Color',
    thickness: 'Espesor',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoría',
    number: 'Número',
    time: 'Tiempo',
    autoRotate: 'Rotación Automática',
    xRotation: 'Rotación X',
    yRotation: 'Rotación Y',
    ticks: 'Enganche',
    width: 'Ancho',
    height: 'Alto',
    length: 'Cantidad',
    padding: 'Relleno',
    spacing: 'Espaciado',
    chart: 'Gráfico',
    title: 'Título',
    titlePlaceholder: 'Título del gráfico - haga doble clic para editar',
    background: 'Fondo',
    font: 'Frente',
    top: 'Parte Superior',
    right: 'Izquierda',
    bottom: 'Parte Inferior',
    left: 'Derecha',
    labels: 'Etiquetas',
    size: 'Tamaño',
    minSize: 'Tamaño Mínimo',
    maxSize: 'Tamaño Máximo',
    legend: 'Leyenda',
    position: 'Posición',
    markerSize: 'Tamaño del marcador',
    markerStroke: 'Trazo del marcador',
    markerPadding: 'Relleno del marcador',
    itemSpacing: 'Espaciado de elementos',
    itemPaddingX: 'Relleno Item X',
    itemPaddingY: 'Relleno Item Y',
    layoutHorizontalSpacing: 'Espaciado Horizontal',
    layoutVerticalSpacing: 'Espaciado Vertical',
    strokeWidth: 'Ancho del trazo',
    lineDash: 'Guión de línea',
    offset: 'Compensación',
    offsets: 'Compensaciones',
    tooltips: 'Info',
    callout: 'Callout',
    markers: 'Marcadores',
    shadow: 'Sombra',
    blur: 'Difuminar',
    xOffset: 'Compensación X',
    yOffset: 'Compensación Y',
    lineWidth: 'Ancho de línea',
    normal: 'Normal',
    bold: 'Negrita',
    italic: 'Italica',
    boldItalic: 'Italica Negrita',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidad de relleno',
    strokeOpacity: 'Opacidad de línea',
    histogramBinCount: 'Recuento de contenedores',
    columnGroup: 'Columna',
    barGroup: 'Barra',
    pieGroup: 'Torta',
    lineGroup: 'Línea',
    scatterGroup: 'X Y (Dispersión)',
    areaGroup: 'Area',
    histogramGroup: 'Histograma',
    combinationGroup: 'Combinación',
    groupedColumnTooltip: 'Agrupado',
    stackedColumnTooltip: 'Apilado',
    normalizedColumnTooltip: '100% Apilado',
    groupedBarTooltip: 'Agrupados',
    stackedBarTooltip: 'Apilados',
    normalizedBarTooltip: '100% Apiladas',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Rosa',
    lineTooltip: 'Línea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Apilado',
    normalizedAreaTooltip: '100% Apiladas',
    scatterTooltip: 'Dispersión',
    bubbleTooltip: 'Burbuja',
    histogramTooltip: 'Histograma',
    columnLineComboTooltip: 'Columna y Línea',
    areaColumnComboTooltip: 'Area y Columna',
    customComboTooltip: 'Combinación personalizada',
    noDataToChart: 'Los datos seleccionados no se pueden graficar.',
    pivotChartRequiresPivotMode: 'El gráfico dinámico requiere que el modo pivote esté habilitado.',
    chartSettingsToolbarTooltip: 'Menú',
    chartLinkToolbarTooltip: 'Vinculado a la cuadrícula',
    chartUnlinkToolbarTooltip: 'Desvinculado de la cuadrícula',
    chartDownloadToolbarTooltip: 'Descargar Gráfico',
    seriesChartType: 'Tipo de gráfico de serie',
    seriesType: 'Tipo de serie',
    secondaryAxis: 'Eje Secundario',

    // Side Bar
    columns: 'Columnas',
    filters: 'Filtros',
    values: 'Valores',
    columnLabel: 'Valor Columna',
    rowGroupColumnsEmptyMessage: 'Arrastrar aquí para agregar',
    pivots: 'Columna',
    groups: 'Fila',
    pivotMode: 'Modo Pivot',
    collapseAll: 'Colapsar Todo',
    expandAll: 'Expandir Todo',
    sum: 'Suma',
    mySum: 'Suma',
    min: 'Mínimo',
    max: 'Máximo',
    avg: 'Promedio',
    count: 'Total',

    valueAggregation: 'Valor',
    valueColumnsEmptyMessage: 'Arrastrar aquí para agregar',
    pivotColumnsEmptyMessage: 'Arrastrar aquí para agregar',

    // Other
    loadingOoo: 'cargando...',
    noRowsToShow: 'No hay ventas para mostrar',
    enabled: 'habilitado',

    // Menu
    pinColumn: 'Marcador columna',
    pinLeft: 'Pin a la izquierda',
    pinRight: 'Pin a la derecha',
    noPin: 'Sin Pin',
    copyWithGroupHeaders: 'Copiar con encabezados agrupados',

    autosizeThiscolumn: 'Tamaño automático de esta columna',
    autosizeAllColumns: 'Tamaño automático de todas las columnas',
    resetColumns: 'Restablecer columnas',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'copiar con encabezados',
    paste: 'pegar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'CSV Exportar',
    excelExport: 'Exportar Excel (.xlsx)',
    excelXmlExport: 'Exportar Excel (.xml)',
  },
};

export { DATE_OPTION, DAY_OPTION, TRANSLATIONS, LANGUAGE_OPTIONS };
