import React, { FC, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { CalendarCard, CalendarHeader, CalendarMoveButton, CouponAdvanceRows } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCollectionDataByDay, paramsToObject, parseFilters } from '../../utils/helper';
import { CalendarCardProps } from '../../utils/interfaces';
import {
  fetchPresentCashflowSummaryDay,
  fetchPrevCashflowSummaryDay,
  fetchNextCashflowSummaryDay,
  fetchPresentCashflowSummaryDayDetail,
} from '../../redux/slices/cashflowSlice';
import { FilterParams } from '../../utils/types';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';

moment.locale('es', {
  week: {
    dow: 0,
  },
});

const localizer = momentLocalizer(moment);

const ExternalWebview: FC = () => {
  const location = useLocation();
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const {
    presentCashflowSummaryDayLoading,
    prevCashflowSummaryDayLoading,
    nextCashflowSummaryDayLoading,
    presentCashflowSummaryDayDetailList,
    presentCashflowSummaryDayList,
    prevCashflowSummaryDayList,
    nextCashflowSummaryDayList,
  } = useAppSelector((state) => state.cashflow);

  const dispatch = useAppDispatch();
  const [selectMonth, setSelectedMonth] = useState(moment().startOf('month').endOf('month'));

  useEffect(() => {
    const fetchData = async () => {
      if (activeCurrency?.code) {
        const datesToFilter = (type: string): FilterParams => {
          let start;
          let end;

          switch (type) {
            case 'present':
              start = moment(selectMonth).startOf('month').format();
              end = moment(selectMonth).endOf('month').format();
              break;
            case 'prev':
              start = moment(selectMonth).subtract(1, 'month').startOf('month').format();
              end = moment(selectMonth).subtract(1, 'month').endOf('month').format();
              break;
            case 'next':
              start = moment(selectMonth).add(1, 'month').startOf('month').format();
              end = moment(selectMonth).add(1, 'month').endOf('month').format();
              break;
            default:
              break;
          }
          const paramsReport = {
            'filter[date][between]': `${start},${end}`,
            'filter[currencyCode][eq]': activeCurrency.code,
          };
          const queryParams = new URLSearchParams(location.search);
          const queryObject = paramsToObject(queryParams);
          const filterableObject = parseFilters({ ...queryObject, ...paramsReport });
          return filterableObject;
        };

        await dispatch<void>(fetchPresentCashflowSummaryDayDetail(datesToFilter('present')));
        await dispatch<void>(fetchPresentCashflowSummaryDay(datesToFilter('present')));
        await dispatch<void>(fetchPrevCashflowSummaryDay(datesToFilter('prev')));
        await dispatch<void>(fetchNextCashflowSummaryDay(datesToFilter('next')));
      }
    };

    fetchData();
  }, [activeCurrency, selectMonth, location]);

  const nextMonth = () => {
    setSelectedMonth(moment(selectMonth).add(1, 'month'));
  };

  const prevMonth = () => {
    setSelectedMonth(moment(selectMonth).subtract(1, 'month'));
  };

  const selectDay = (date: Date) => {
    if (presentCashflowSummaryDayLoading === 'succeeded') {
      // apply filter when selecting day
      console.log('SELECCION DE DIA', date);
    }
  };

  return (
    <div className="calendar-webview my-4 p-3">
      <div style={{ position: 'relative', top: '20px' }}>
        <h2 className="calendar-title">Calendario</h2>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <p className="powered-by-text">powered by</p>
            <p className="powered-by-nubceo-text"> nubceo</p>
          </div>
          <h2 className="text-month">
            {moment(selectMonth).format('MMMM').charAt(0).toUpperCase() + moment(selectMonth).format('MMMM').slice(1).toLowerCase()}
          </h2>
        </div>
        <select className="mb-5 input-select-month text-center">
          <option value="August">Agosto</option>
          <option value="January">Enero</option>
          <option value="February">Febrero</option>
          <option value="March">Marzo</option>
          <option value="April">Abril</option>
          <option value="May">Mayo</option>
          <option value="June">Junio</option>
          <option value="July">Julio</option>
          <option value="September">Septiembre</option>
          <option value="October">Octubre</option>
          <option value="November">Noviembre</option>
          <option value="December">Diciembre</option>
        </select>
      </div>
      <Calendar
        components={{
          toolbar: (props) => {
            useEffect(() => {
              props.onNavigate('DATE', new Date(selectMonth.format('YYYY-MM-DD')));
            }, [selectMonth]);
            return <>{/* <p>header</p>> */}</>;
          },

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dateCellWrapper: (props: { value: Date }, key): Element<CalendarCardProps> => {
            const { value } = props;
            const totaledAmounts = presentCashflowSummaryDayList.find((item) => moment(item.summaryDate).isSame(value, 'day'));

            const collection = getCollectionDataByDay(presentCashflowSummaryDayDetailList, value, totaledAmounts);
            return (
              <CalendarCard
                key={key}
                onClick={selectDay}
                loading={presentCashflowSummaryDayLoading}
                collection={collection}
                selectMonth={selectMonth}
                isInternalCalendar={false}
                {...props}
              />
            );
          },
          month: {
            header: CalendarHeader,
          },
        }}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        step={60}
      />
      {/* HARDCODED BUTTONS */}
      <div>
        {/* TNA */}
        <div className="d-flex flex-row justify-content-end me-3">
          <div className="d-flex flex-row align-items-baseline mb-4 justify-content-center container-tna">
            <p className="tna-title">TNA.</p>
            <p className="tna-value">15%</p>
          </div>
        </div>
        {/* BUTTON PREV AND NEXT */}
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-row align-items-baseline mb-4 button-container" onClick={prevMonth} role="none">
            <div className="d-flex flex-row justify-content-between align-items-center p-2 w-100">
              <div className="prev-month-webview">
                <DropdownArrowIconSVG width={26} className="color-white" />
              </div>
              <div className="d-flex flex-column">
                <p className="title-button">
                  {moment(selectMonth).subtract(1, 'month').format('MMMM').toUpperCase()} {selectMonth.format('YYYY')}
                </p>
                <p className="button-value">$5.677.381</p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-items-baseline mb-4 button-container" onClick={nextMonth} role="none">
            <div className="d-flex flex-row justify-content-between align-items-center p-2 w-100">
              <div className="d-flex flex-column">
                <p className="title-button">
                  {moment(selectMonth).add(1, 'month').format('MMMM').toUpperCase()} {selectMonth.format('YYYY')}
                </p>
                <p className="button-value">$538.147</p>
              </div>
              <div className="next-month-webview">
                <DropdownArrowIconSVG width={26} className="color-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ORIGINAL HIDDEN CALENDAR BUTTONS */}
      <div className="calendar-step-move-button d-none">
        <CalendarMoveButton
          collectionReportList={prevCashflowSummaryDayList}
          loading={prevCashflowSummaryDayLoading}
          set="prev"
          onClick={prevMonth}
          selectMonth={selectMonth.format('YYYY-MM-DD')}
          containerClassName="month-button-month d-inline-flex"
        />
        <CalendarMoveButton
          collectionReportList={nextCashflowSummaryDayList}
          loading={nextCashflowSummaryDayLoading}
          set="next"
          onClick={nextMonth}
          selectMonth={selectMonth.format('YYYY-MM-DD')}
          containerClassName="month-button-month d-inline-flex float-end"
        />
      </div>

      {/* ADVANCE COUPONS */}
      <CouponAdvanceRows presentCashflow={presentCashflowSummaryDayDetailList} />
    </div>
  );
};
export default ExternalWebview;
