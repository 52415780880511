import React, { useEffect, useMemo, useState } from 'react';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getMessaging, isSupported, onMessage, MessagePayload } from 'firebase/messaging';
import MessageNotification from '../../components/MessageNotification';
import logger from '../../services/loggerService';
import { ENV_FIREBASE_CONFIG } from '../../config';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeMessage as removeLocalMessage } from '../../redux/slices/messagesSlice';

export type PushMessagesType = {
  data: {
    type: string;
    message: string;
    title: string;
    cta?: string;
    typeStyle?: string;
  };
  fcmMessageId: string;
};

const MessageBox: React.FC = () => {
  const [messagesList, setMessagesList] = useState<PushMessagesType[]>([]);
  const { messagesList: localMessagesList } = useAppSelector((state) => state.messages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeFirebaseMessaging = async () => {
      if (await isSupported()) {
        if (!getApps().length) {
          initializeApp(ENV_FIREBASE_CONFIG);
        } else {
          getApp(); // Stop duplicated inits
        }
        const messaging = getMessaging();
        onMessage(messaging, (payload: MessagePayload) => {
          logger.info('Push message received', payload);
          const newMessage: PushMessagesType = {
            data: {
              type: payload.data?.type || '',
              message: payload.data?.message || '',
              title: payload.data?.title || '',
              cta: payload.data?.cta,
              typeStyle: payload.data?.typeStyle,
            },
            fcmMessageId: payload.messageId || '',
          };
          setMessagesList((prevMessagesList) => [...prevMessagesList, newMessage]);
        });
      }
    };

    initializeFirebaseMessaging();
  }, []);

  useMemo(() => {
    if (localMessagesList.length) {
      const newMessagesList = messagesList.filter((message) => message.data.type !== 'local-notification');
      setMessagesList([...newMessagesList, ...localMessagesList]);
    }
  }, [localMessagesList.length]);

  const removeMessage = (id: string): void => {
    const newMessagesList = messagesList.filter((messages) => messages.fcmMessageId !== id);
    if (localMessagesList?.find((message) => message.fcmMessageId === id)) {
      dispatch(removeLocalMessage({ id }));
    }
    setMessagesList(newMessagesList);
  };

  return (
    <div className="message-container">
      <div className="p-3 me-3">
        {messagesList.length
          ? messagesList.map((message) => (
              <MessageNotification message={message} key={message.fcmMessageId} removeMessage={removeMessage} />
            ))
          : null}
      </div>
    </div>
  );
};

export default MessageBox;
