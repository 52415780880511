import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { LoggerLevels, ExternalPlatformType, ExternalPlatformBranchType } from './types';
import imgRappi from '../assets/images/externalPlatforms/delivery/rappi.png';
import imgPrisma from '../assets/images/externalPlatforms/card/prisma.png';
import imgPedidosYa from '../assets/images/externalPlatforms/delivery/pedidosya.png';
import imgDLocal from '../assets/images/externalPlatforms/delivery/d-local.png';
import imgPassCard from '../assets/images/externalPlatforms/delivery/passcard.png';
import imgEdenred from '../assets/images/externalPlatforms/delivery/edenred.png';
import imgCreditel from '../assets/images/externalPlatforms/delivery/creditel.png';
import imgFiserv from '../assets/images/externalPlatforms/card/fiserv.png';
import imgMercadoPago from '../assets/images/externalPlatforms/wallet/mercadopago.png';
import imgVisaNet from '../assets/images/externalPlatforms/card/visa.png';
import imgItalcred from '../assets/images/externalPlatforms/card/italcred.png';
import imgGetnet from '../assets/images/externalPlatforms/card/getnet.png';
import imgAccor from '../assets/images/externalPlatforms/card/accor.png';
import imgContabilium from '../assets/images/externalPlatforms/erp/contabilium.png';
import imgZoologic from '../assets/images/externalPlatforms/erp/zoologic.png';
import imgModdo from '../assets/images/externalPlatforms/erp/moddo.png';
import imgNaranja from '../assets/images/externalPlatforms/card/naranja.png';
import imgCash from '../assets/images/externalPlatforms/cash/cash.png';
import imgBillSantaFe from '../assets/images/externalPlatforms/wallet/santafe.png';
import imgOca from '../assets/images/externalPlatforms/card/oca.png';
import imgTiendaNube from '../assets/images/externalPlatforms/ecommerce/tiendanube.png';
import imgAmex from '../assets/images/externalPlatforms/card/amex.png';
import imgCabal from '../assets/images/externalPlatforms/card/cabal.png';
import imgAnda from '../assets/images/externalPlatforms/card/anda.png';
import imgDiners from '../assets/images/externalPlatforms/card/diners.png';
import imgCDirectos from '../assets/images/externalPlatforms/card/creditosDirectos.png';
import imgMaxirest from '../assets/images/externalPlatforms/erp/maxirest.png';
import imgXubio from '../assets/images/externalPlatforms/erp/xubio.png';
import imgCiti from '../assets/images/externalPlatforms/card/citi.png';
import imgColpatria from '../assets/images/externalPlatforms/card/colpatria.png';
import imgDidi from '../assets/images/externalPlatforms/delivery/didi.png';
import illustration1 from '../assets/images/illustrations/illustration-1.jpg';
import illustration2 from '../assets/images/illustrations/illustration-2.jpg';
import illustration3 from '../assets/images/illustrations/illustration-3.jpg';
import illustration4 from '../assets/images/illustrations/illustration-4.jpg';
import illustration5 from '../assets/images/illustrations/illustration-5.jpg';
import { ProfileResponse } from '../services/userService';
import { Countries, CountryCode } from './interfaces';
import { ENV_INSTRUCTIONAL_VIDEO_MP_AR, ENV_INSTRUCTIONAL_VIDEO_MP_UY, ENV_INSTRUCTIONAL_VIDEO_PRISMA_AR } from '../config';

// Date
export const f = new Date();
export const fetch = moment(f).format('DD-MM-YYYY');
export const fetchDate = moment(f).format('YYYY-MM-DD');
export const fetchdB = moment(f).format('YYYY-MM-DD');
export const dayToday = moment(f).utc().format('YYYY-MM-DD');
export const tFetch = moment().add(1, 'd').format('YYYY-MM-DD');
export const today = moment(f).locale('es').format('D MMMM ');
export const todayDay = moment(f).locale('es').format('DD');
export const todayFormat = moment(f).locale('es').format('YYYYMMDD');
export const todayFormatList = moment(f).locale('es').format('YYYYMMDD');
export const tomorrow = moment().add(1, 'd').format('D MMMM ');
export const Yesterday = moment().add(-1, 'd').format('YYYYMMDD ');
export const dayActual = moment(f).locale('es').format('DD');
export const monthActual = moment(f).locale('es').format('MM');
export const yearActual = moment(f).locale('es').format('YYYY');

const countriesApp = [
  {
    code: 'AR',
    name: 'Argentina',
  },
  // {
  //   code: 'CO',
  //   name: 'Colombia',
  // },
  {
    code: 'UY',
    name: 'Uruguay',
  },
];

const loggerLevels: LoggerLevels = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};

const platformExternalCodesTrimmed = {
  PRISMA: 'prisma',
  FISERV: 'firstdata',
  PEDIDOSYA: 'pedidosya',
  RAPPI: 'rappi',
  MERCADOPAGO: 'mercadopago',
  VISANET: 'visanet',
  ITALCRED: 'italcred',
  GETNET: 'getnet',
  ACCOR: 'accor',
  CONTABILIUM: 'contabilium',
  CASH: 'cash',
  ZOOLOGIC: 'zoologic',
  MODDO: 'moddo',
  NARANJA: 'naranja',
  BILSANTAFE: 'bilsantafe',
  OCA: 'oca',
  TIENDANUBE: 'tiendanube',
  AMEX: 'amex',
  CABAL: 'cabal',
  MAXIREST: 'maxirest',
  XUBIO: 'xubio',
  SODEXO: 'sodexo',
  RED_LIDER: 'red lider',
  D_LOCAL: 'dlocal',
  PASSCARD: 'passcard',
  CREDITEL: 'creditel',
  ANDA: 'anda',
  DINERS: 'diners',
  CDIRECTOS: 'cdirectos',
  EDENRED: 'edenred',
  CITI: 'citi',
  COLPATRIA: 'colpatria',
  DIDI: 'didi',
};

export const EMAIL_PLATFORMS = [
  platformExternalCodesTrimmed.ANDA,
  platformExternalCodesTrimmed.AMEX,
  platformExternalCodesTrimmed.CABAL,
  platformExternalCodesTrimmed.CDIRECTOS,
  platformExternalCodesTrimmed.CREDITEL,
  platformExternalCodesTrimmed.EDENRED,
  platformExternalCodesTrimmed.PASSCARD,
  platformExternalCodesTrimmed.DINERS,
  platformExternalCodesTrimmed.DIDI,
  platformExternalCodesTrimmed.CITI,
];

export const formatAccordingToPlatform = (value: string): string | undefined => {
  const platformsNames: { [key: string]: string } = {
    anda_uy: 'TXT - CSV',
    amex_uy: 'TXT - CSV',
    cabal_uy: 'CSV',
    creditel_uy: 'TXT - CSV',
    cdirectos_uy: 'PDF + EXCEL',
    edenred_uy: 'EXCEL',
    passcard_uy: 'TXT - CSV',
    diners_co: 'CSV',
    didi_co: 'EXCEL',
    citi_co: 'TXT - PDF',
  };

  return platformsNames[value];
};

type ExternalPlatformData = {
  logoImage: string | null;
  color: string;
  name: string;
  type: ExternalPlatformType | null;
  branchType: ExternalPlatformBranchType | null;
  code: string;
  linkArg?: string;
  linkUy?: string;
};

export const externalPlatformsBasicData = (platformCode: string): ExternalPlatformData => {
  const platformData = {
    [platformExternalCodesTrimmed.RAPPI]: {
      logoImage: typeof imgRappi,
      color: '#f97175',
      name: 'Rappi',
      type: ExternalPlatformType.delivery,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.RAPPI,
    },
    [platformExternalCodesTrimmed.PASSCARD]: {
      logoImage: imgPassCard,
      color: '#049e8d',
      name: 'Passcard',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.PASSCARD,
    },
    [platformExternalCodesTrimmed.EDENRED]: {
      logoImage: imgEdenred,
      color: '#049e8d',
      name: 'Edenred',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.EDENRED,
    },
    [platformExternalCodesTrimmed.D_LOCAL]: {
      logoImage: imgDLocal,
      color: '#472782',
      name: 'dLocal',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.D_LOCAL,
    },
    [platformExternalCodesTrimmed.CREDITEL]: {
      logoImage: imgCreditel,
      color: '#e9ce11',
      name: 'Creditel',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.CREDITEL,
    },
    [platformExternalCodesTrimmed.PRISMA]: {
      logoImage: imgPrisma,
      color: '#fcda00',
      name: 'Prisma',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.businessNumber,
      code: platformExternalCodesTrimmed.PRISMA,
      linkArg: `${ENV_INSTRUCTIONAL_VIDEO_PRISMA_AR}`,
    },
    [platformExternalCodesTrimmed.CASH]: {
      logoImage: imgCash,
      color: '#fcda00',
      name: 'Efectivo',
      type: ExternalPlatformType.delivery || ExternalPlatformType.erp,
      branchType: ExternalPlatformBranchType.businessNumber,
      code: platformExternalCodesTrimmed.CASH,
    },
    [platformExternalCodesTrimmed.PEDIDOSYA]: {
      logoImage: imgPedidosYa,
      color: '#f32f40',
      name: 'PedidosYa',
      type: ExternalPlatformType.delivery,
      branchType: null,
      code: platformExternalCodesTrimmed.PEDIDOSYA,
    },
    [platformExternalCodesTrimmed.FISERV]: {
      logoImage: imgFiserv,
      color: '#094263',
      name: 'Fiserv',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.businessNumber,
      code: platformExternalCodesTrimmed.FISERV,
    },
    [platformExternalCodesTrimmed.MERCADOPAGO]: {
      logoImage: imgMercadoPago,
      color: '#2d9ee3',
      name: 'Mercado Pago',
      type: ExternalPlatformType.wallet,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.MERCADOPAGO,
      linkArg: `${ENV_INSTRUCTIONAL_VIDEO_MP_AR}`,
      linkUy: `${ENV_INSTRUCTIONAL_VIDEO_MP_UY}`,
    },
    [platformExternalCodesTrimmed.VISANET]: {
      logoImage: imgVisaNet,
      color: '#ec4348',
      name: 'VisaNet',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.VISANET,
    },
    [platformExternalCodesTrimmed.ITALCRED]: {
      logoImage: imgItalcred,
      color: '#092f87',
      name: 'Italcred',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.ITALCRED,
    },
    [platformExternalCodesTrimmed.GETNET]: {
      logoImage: imgGetnet,
      color: '#E68484',
      name: 'Getnet',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.GETNET,
    },
    [platformExternalCodesTrimmed.ACCOR]: {
      logoImage: imgAccor,
      color: '#1e1852',
      name: 'Accor',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.ACCOR,
    },
    [platformExternalCodesTrimmed.CONTABILIUM]: {
      logoImage: imgContabilium,
      color: '#04314a',
      name: 'Contabilium',
      type: ExternalPlatformType.erp,
      branchType: null,
      code: platformExternalCodesTrimmed.CONTABILIUM,
    },
    [platformExternalCodesTrimmed.ZOOLOGIC]: {
      logoImage: imgZoologic,
      color: '#04314a',
      name: 'Zoo Logic / Dragonfish',
      type: ExternalPlatformType.erp,
      branchType: null,
      code: platformExternalCodesTrimmed.ZOOLOGIC,
    },
    [platformExternalCodesTrimmed.MODDO]: {
      logoImage: imgModdo,
      color: '#124f6a',
      name: 'Moddo',
      type: ExternalPlatformType.erp,
      branchType: null,
      code: platformExternalCodesTrimmed.MODDO,
    },
    [platformExternalCodesTrimmed.NARANJA]: {
      logoImage: imgNaranja,
      color: '#04314a',
      name: 'Naranja',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.NARANJA,
    },
    [platformExternalCodesTrimmed.BILSANTAFE]: {
      logoImage: imgBillSantaFe,
      color: '#111c4e',
      name: 'Billetera Santa Fe',
      type: ExternalPlatformType.wallet,
      branchType: null,
      code: platformExternalCodesTrimmed.BILSANTAFE,
    },
    [platformExternalCodesTrimmed.OCA]: {
      logoImage: imgOca,
      color: '#006ed2',
      name: 'Oca',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.OCA,
    },
    [platformExternalCodesTrimmed.TIENDANUBE]: {
      logoImage: imgTiendaNube,
      color: '#006ed2',
      name: 'Tiendanube',
      type: ExternalPlatformType.ecommerce,
      branchType: null,
      code: platformExternalCodesTrimmed.TIENDANUBE,
    },
    [platformExternalCodesTrimmed.AMEX]: {
      logoImage: imgAmex,
      color: '#006fcf',
      name: 'American Express',
      code: platformExternalCodesTrimmed.AMEX,
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
    },
    [platformExternalCodesTrimmed.CABAL]: {
      logoImage: imgCabal,
      color: '#016298',
      name: 'CABAL',
      code: platformExternalCodesTrimmed.CABAL,
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
    },
    [platformExternalCodesTrimmed.MAXIREST]: {
      logoImage: imgMaxirest,
      color: '#e76321',
      name: 'Maxirest',
      code: platformExternalCodesTrimmed.MAXIREST,
      type: ExternalPlatformType.erp,
      branchType: null,
    },
    [platformExternalCodesTrimmed.XUBIO]: {
      logoImage: imgXubio,
      color: '#e83c84',
      name: 'Xubio',
      code: platformExternalCodesTrimmed.XUBIO,
      type: ExternalPlatformType.erp,
      branchType: null,
    },
    [platformExternalCodesTrimmed.ANDA]: {
      logoImage: imgAnda,
      color: '#386ae0',
      name: 'Anda',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.ANDA,
    },
    [platformExternalCodesTrimmed.DINERS]: {
      logoImage: imgDiners,
      color: '#1e4280',
      name: 'Diners',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.DINERS,
    },
    [platformExternalCodesTrimmed.CDIRECTOS]: {
      logoImage: imgCDirectos,
      color: '#307154',
      name: 'Creditos Directos',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.CDIRECTOS,
    },
    [platformExternalCodesTrimmed.CITI]: {
      logoImage: imgCiti,
      color: '#004685',
      name: 'Citi',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.CITI,
    },
    [platformExternalCodesTrimmed.DIDI]: {
      logoImage: imgDidi,
      color: '#FBA672',
      name: 'Didi',
      type: ExternalPlatformType.delivery,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.DIDI,
    },
    [platformExternalCodesTrimmed.COLPATRIA]: {
      logoImage: imgColpatria,
      color: '#df0d20',
      name: 'Colpatria',
      type: ExternalPlatformType.card,
      branchType: ExternalPlatformBranchType.branch,
      code: platformExternalCodesTrimmed.COLPATRIA,
    },
    default: {
      logoImage: null,
      color: '#eeeeee',
      name: platformCode,
      code: platformCode,
      type: null,
      branchType: null,
    },
  };

  let codeTrimmed;
  const platformsCountrySufix = ['_ar', '_uy', '_co'];
  const isTrimmed = platformsCountrySufix?.reduce((acc, curr) => platformCode?.includes(curr) || acc, false);
  if (isTrimmed) {
    codeTrimmed = platformCode?.indexOf('_') > -1 ? platformCode?.trim()?.slice(0, -3) : platformCode;
  } else {
    codeTrimmed = platformCode?.toLowerCase();
  }
  return platformData[codeTrimmed] || platformData.default;
};

const platformExternalCodesByCountry = {
  PRISMA_AR: 'prisma_ar',
  PRISMA_UY: 'prisma_uy',
  FISERV_AR: 'fiserv_ar',
  FISERV_UY: 'fiserv_uy',
  PEDIDOSYA_AR: 'pedidosya_ar',
  PEDIDOSYA_UY: 'pedidosya_uy',
  RAPPI_AR: 'rappi_ar',
  RAPPI_UY: 'rappi_uy',
  MERCADOPAGO_AR: 'mercadopago_ar',
  MERCADOPAGO_CO: 'mercadopago_co',
  MERCADOPAGO_UY: 'mercadopago_uy',
  VISANET_UY: 'visanet_uy',
  NARANJA_AR: 'naranja_ar',
  CONTABILIUM_AR: 'contabilium_ar',
  BILSANTAFE_AR: 'bilsantafe_ar',
  OCA_UY: 'oca_uy',
  TIENDA_NUBE_AR: 'tienda_nube_ar',
  TIENDA_NUBE_UY: 'tienda_nube_uy',
  CASH: 'cash_ar',
  AMEX: 'amex_ar',
  PASSCARD: 'passcard_uy',
  EDENRED: 'edenred_uy',
  D_LOCAL: 'dlocal_uy',
  CREDITEL: 'creditel_uy',
  ANDA: 'anda_uy',
  DINERS: 'diners_uy',
  CDIRECTOS: 'cdirectos_uy',
  CITI: 'citi_co',
  DIDI: 'didi_co',
  COLPATRIA: 'colpatria_co',
};

const paymentMethodsCodes = {
  AMEX: 'amex',
  AMERICAN_EXPRESS: 'american express',
  MASTERCARD: 'mastercard',
  MASTERCARD_PREPAGA: 'mastercard prepaga',
  MASTERCARD_DEBIT: 'mastercard debit',
  MASTER_DEBIT: 'master debit',
  MASTER: 'master',
  CABAL: 'cabal',
  OCA: 'oca',
  CABAL_DEBIT: 'cabal debit',
  VISA: 'visa',
  VISANET_UY: 'visanet_uy',
  NARANJA: 'naranja',
  SEND_MONEY: 'send_money',
  MONEY_ADVANCE: 'money_advance',
  VISA_DEBIT: 'visa debit',
  MAESTRO: 'maestro',
  MERCADOPAGO_CC: 'mercadopago_cc',
  MERCADOPAGO_AR: 'mercadopago_ar',
  OCA_UY: 'oca_uy',
  CASH: 'cash_ar',
  SODEXO: 'sodexo rou lider',
  RED_LIDER: 'red lider',
  PASSCARD: 'passcard_uy',
  EDENRED: 'edenred_uy',
  CREDITEL: 'creditel_uy',
  D_LOCAL: 'dlocal_uy',
  ANDA: 'anda credit',
  DINERS: 'diners',
  CDIRECTOS: 'cdirectos_uy',
  CITI: 'citi_co',
  COLPATRIA: 'colpatria_co',
};

const saleType = {
  ORDER: 'order',
  CHARGEBACK: 'chargeback',
  RETURN: 'return',
  ADJUSTMENT: 'adjustment',
};

const regexCodes = {
  nameRegex: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s'`]+$/,
  phone: /^\+?([0-9]{7,14})$/g,
  password: /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  providers: /^([0-9]*-*[0-9]*,?[0-9]*-*[0-9])*$/g,
};

export type ContentRegexCountryCode = {
  name: string;
  taxCodeRegExp: RegExp;
  taxCodeRegExpNoScript: RegExp;
  taxCodePlaceholder: string | undefined;
  taxCodeLength: number;
  telephonePlaceholder: string | undefined;
  telephoneRegExp: RegExp;
  countryName: string;
  stateName: string;
  taxCodeHyphenPosition: number[];
  terms: string;
  taxRegExpUpperscore: RegExp;
};

export type RegexCountryCodes = { [key: string]: ContentRegexCountryCode };

export const platformExternalOrder: Record<string, number> = {
  prisma_ar: 1,
  firstdata_ar: 2,
  visanet_uy: 1,
  oca_uy: 2,
  firstdata_uy: 3,
};

const regexCountryCodes: RegexCountryCodes = {
  AR: {
    name: 'CUIT',
    taxCodeRegExp: /^(20|23|24|25|26|27|30|33|34)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/,
    taxCodeRegExpNoScript: /^(20|23|24|25|26|27|30|33|34)([0-9]{9})$/,
    taxCodePlaceholder: '00-00000000-0',
    taxCodeLength: 13,
    taxRegExpUpperscore: /^((20|23|24|25|26|27|30|33|34)-[0-9]{8}-[0-9]{1})$/,
    taxCodeHyphenPosition: [2, 11],
    telephonePlaceholder: '123456789010',
    telephoneRegExp: /^\+?(?:[0-9-]?){7,14}[0-9]$/g,
    countryName: 'Argentina',
    stateName: 'Provincia',
    terms: 'https://www.nubceo.com/terminos-y-condiciones/',
  },

  CO: {
    name: 'NIT',
    taxCodeRegExp: /^([0-9]{9}-[0-9]{1})$/,
    taxCodeRegExpNoScript: /^([0-9]{10})$/,
    taxCodePlaceholder: '000000000-0',
    taxCodeLength: 11,
    taxRegExpUpperscore: /^([0-9]{9}-[0-9]{1})$/,
    taxCodeHyphenPosition: [9],
    telephonePlaceholder: '123456789010',
    telephoneRegExp: /^\+?(?:[0-9-]?){7,14}[0-9]$/g,
    countryName: 'Colombia',
    stateName: 'Departamento',
    //* TODO TERMINOS Y CONDICIONES COLOMBIA???
    terms: 'https://www.nubceo.com/terminos-y-condiciones/',
  },

  UY: {
    name: 'RUT',
    countryName: 'Uruguay',
    stateName: 'Departamento',
    taxCodeRegExp: /^([0-9]{2}-[0-9]{6}-[0-9]{3}-[0-9]{1})$/,
    taxCodeRegExpNoScript: /^([0-9]{12})$/,
    taxCodePlaceholder: '00-000000-000-0',
    taxCodeHyphenPosition: [2, 9, 13],
    taxCodeLength: 15,
    taxRegExpUpperscore: /^([0-9]{2}-[0-9]{6}-[0-9]{3}-[0-9]{1})$/,
    telephonePlaceholder: '912341231',
    telephoneRegExp: /^\+?(?:[0-9-]?){7,14}[0-9]$/g,
    terms: 'https://www.nubceo.com/uy/terminos-y-condiciones-uy/',
  },
  default: {
    name: 'CUIT / RUT / NIT',
    stateName: '',
    countryName: '',
    taxCodeRegExp: /./,
    taxRegExpUpperscore: /./,
    taxCodeRegExpNoScript: /./,
    taxCodeLength: 15,
    taxCodeHyphenPosition: [],
    taxCodePlaceholder: undefined,
    telephonePlaceholder: undefined,
    telephoneRegExp: /^\+?(?:[0-9-]?){7,14}[0-9]$/g,
    terms: '',
  },
};

export {
  regexCodes,
  regexCountryCodes,
  countriesApp,
  loggerLevels,
  platformExternalCodesByCountry,
  platformExternalCodesTrimmed,
  paymentMethodsCodes,
  saleType,
};

export const VERSION_APP = process.env.REACT_APP_VERSION || '1.0.0';

export const operations = [
  {
    value: 'store',
    name: 'Tengo comercio a la calle',
  },
  {
    value: 'online',
    name: 'Vendo solo por internet',
  },
  {
    value: 'no_store',
    name: 'No tengo comercio',
  },
];

export const countryCodeOptions = [
  {
    value: '54',
    name: 'ARG (+54)',
  },
  {
    value: '58',
    name: 'UY (+58)',
  },
  // {
  //   value: '57',
  //   name: 'CO (+57)',
  // },
];

export const CUSTOM_DATE_FILTER_OPTION = 'Personalizado';
export const THIS_MONTH_DATE_FILTER_OPTION = 'Mes Actual';
export const optionsDateValues: Record<string, string | string[]> = {
  'Mes Actual': [`${moment().startOf('month').format('YYYY-MM-DD')}`, `${moment().endOf('month').format('YYYY-MM-DD')}`],
  'Mes anterior': [
    `${moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}`,
    `${moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}`,
  ],
  /*  TODO when Data Warehouse is active, we migth have to uncomment this filters
  // 'Año en curso': `${moment().startOf('year').format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`,
  // 'Año anterior': `${moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')},${moment()
  //   .subtract(1, 'year')
  //   .endOf('year')
     .format('YYYY-MM-DD')}`, */
  Personalizado: CUSTOM_DATE_FILTER_OPTION,
};

export const optionsDate = Object.keys(optionsDateValues).map((key, index) => ({
  id: index,
  name: key,
  value: key,
}));

export const optionsIncluded = [
  {
    id: 1,
    name: 'Todas',
    value: '',
  },
  {
    id: 2,
    name: 'Liquidadas',
    value: 'included',
  },
  {
    id: 3,
    name: 'No liquidadas',
    value: 'not_included',
  },
];
export const optionsOperation = [
  {
    id: 1,
    name: 'Todos',
    value: '',
  },
  {
    id: 2,
    name: 'Venta',
    value: 'order',
  },
  {
    id: 3,
    name: 'Contracargo',
    value: 'chargeback',
  },
  {
    id: 2,
    name: 'Devolución',
    value: 'return',
  },
  {
    id: 3,
    name: 'Ajuste',
    value: 'adjustment',
  },
];

export const optionsIsOrganization = [
  {
    id: 'human',
    label: 'Persona Humana',
    value: '',
  },
  {
    id: 'legal',
    label: 'Persona Jurídica',
    value: 'isOrganization',
  },
];

export const optionsHours = [
  { name: '00:00', value: '0300' },
  { name: '00:30', value: '0330' },
  { name: '01:00', value: '0400' },
  { name: '01:30', value: '0430' },
  { name: '02:00', value: '0500' },
  { name: '02:30', value: '0530' },
  { name: '03:00', value: '0600' },
  { name: '03:30', value: '0630' },
  { name: '04:00', value: '0700' },
  { name: '04:30', value: '0730' },
  { name: '05:00', value: '0800' },
  { name: '05:30', value: '0830' },
  { name: '06:00', value: '0900' },
  { name: '06:30', value: '0930' },
  { name: '07:00', value: '1000' },
  { name: '07:30', value: '1030' },
  { name: '08:00', value: '1100' },
  { name: '08:30', value: '1130' },
  { name: '09:00', value: '1200' },
  { name: '09:30', value: '1230' },
  { name: '10:00', value: '1300' },
  { name: '10:30', value: '1330' },
  { name: '11:00', value: '1400' },
  { name: '11:30', value: '1430' },
  { name: '12:00', value: '1500' },
  { name: '12:30', value: '1530' },
  { name: '13:00', value: '1600' },
  { name: '13:30', value: '1630' },
  { name: '14:00', value: '1700' },
  { name: '14:30', value: '1730' },
  { name: '15:00', value: '1800' },
  { name: '15:30', value: '1830' },
  { name: '16:00', value: '1900' },
  { name: '16:30', value: '1930' },
  { name: '17:00', value: '2000' },
  { name: '17:30', value: '2030' },
  { name: '18:00', value: '2100' },
  { name: '18:30', value: '2130' },
  { name: '19:00', value: '2200' },
  { name: '19:30', value: '2230' },
  { name: '20:00', value: '2300' },
  { name: '20:30', value: '2330' },
  { name: '21:00', value: '2400' },
  { name: '21:30', value: '0030' },
  { name: '22:00', value: '0100' },
  { name: '22:30', value: '0130' },
  { name: '23:00', value: '0200' },
  { name: '23:30', value: '0230' },
];

export const TimeZoneByCountry: Countries = {
  AR: 'America/Argentina/Buenos_Aires',
  UY: 'America/Montevideo',
  CO: 'America/Bogota',
  US: 'America/New_York',
};

export const reportChargerbackOptions = [
  {
    code: true,
    name: 'Activado',
  },
  {
    code: false,
    name: 'Desactivado',
  },
];
export const storageVars = {
  completePECCredentials: 'completePECCredentials',
  completePECCredentialsClient: 'completePECCredentialsClient',
};
export const config = {
  nubceo: {
    supportPhone: '541144440802',
    supportPhoneUy: '59892296971',
    limitCountInvalidCredentials: 4,
  },
  idFacebookAnalytics: '1727736244063961',
};
export const colorsGraphicsExpenseReports = [
  '#1F4690',
  '#FFA500',
  '#94B49F',
  '#FFE5B4',
  '#1A4D2E',
  '#541690',
  '#F24C4C',
  '#990000',
  '#F7D716',
  '#377D71',
  '#810955',
  '#6A67CE',
];
export const randomImages = [illustration1, illustration2, illustration3, illustration4, illustration5];

export const showLink = (platform: string | undefined, user: ProfileResponse): React.ReactElement | string | null | undefined => {
  const countryCode = user?.Tenant.countryCode;
  const msg = `Hola! Necesito ayuda.`;

  if (platform && (countryCode === CountryCode.UY || countryCode === CountryCode.AR)) {
    const link =
      countryCode === CountryCode.UY ? externalPlatformsBasicData(platform).linkUy : externalPlatformsBasicData(platform).linkArg;

    return (
      link && (
        // TODO they are commented until the instructional videos are corrected
        // <a href={link} target="_blank" className="text-decoration-none" rel="noreferrer">
        //   ¿Necesitás ayuda? Mirá el instructivo.
        // </a>

        <a
          href={`https://wa.me/${
            user?.Tenant.countryCode === CountryCode.AR ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy
          }?text=${msg}`}
          target="_blank"
          className="text-decoration-none"
          rel="noreferrer"
        >
          ¿Necesitas ayuda? Contáctanos
        </a>
      )
    );
  }

  return null;
};

const MessageModalTokenExpired = (): React.ReactElement => (
  <>
    <p className="font-md font-weight-bold">Tu sesión ha expirado.</p>
    <p>Por falta de actividad se cerró tu sesión.</p>
  </>
);

export const MessageModalInvalidCredentials = (): React.ReactElement => (
  <>
    <p className="font-md font-weight-bold">¡Atención!</p>
    <p>
      Queda solo un intento de ingreso de contraseña. En el caso de que sea incorrecto, se bloqueará el usuario y deberá desbloquearlo el
      área de soporte. Te recomendamos restablecer la clave en esta instancia, si no estás seguro de cuál es.
    </p>
  </>
);

export const MessageModalBlockedUser = (): React.ReactElement => (
  <>
    <p className="font-md font-weight-bold">¡Ups!</p>
    <p>Se bloqueó el usuario por cantidad de intentos fallidos. Para desbloquearlo, comunicate con tu asesor comercial.</p>
  </>
);

export const MessageToApplyFilters = (): React.ReactElement => (
  <p className="mt-3 font-sm label-filter-aplicated">
    Para aplicar los filtros seleccionados, presioná el botón <b>Aplicar Filtros</b>.
  </p>
);

export default MessageModalTokenExpired;
