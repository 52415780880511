import { AxiosPromise } from 'axios';
import { FilterParams, ReportsType } from '../utils/types';
import axiosV2 from './axiosInstanceV2';

export const getReports = async (): Promise<ReportsType[]> => {
  const { data: response, status } = await axiosV2.get<{ data: ReportsType[] }>('/report?sort[createdAt][desc]');
  if (status !== 200) throw new Error('TESTING');
  return response.data;
};

export const getExpenseReports = (params: FilterParams): AxiosPromise => axiosV2.get('/report/expenses-summary', { params: { ...params } });

export const getReportExpenseXLSX = (params: FilterParams): AxiosPromise =>
  axiosV2.get('/report/generate-report/expense/xlsx', { params: { ...params } });
