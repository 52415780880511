import { stepCodes } from '../pages/RecoverPassword';
import { ActivityCode, CountryCode, CurrencyCode } from './interfaces';

/**
 * Formats a number to a string format with localization, with the option to shorten it
 * @param {number} number
 * @param {boolean} shorten
 * @param {number} decimals Amounts of decimals to display. Only applies if shorteen = false.
 */
export function decimalFormat(number: number, locale = 'es-ES'): string {
  let numberStr = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  if (numberStr.length === 7 && !numberStr.includes('-')) {
    numberStr = `${numberStr.substring(0, 1)}.${numberStr.substring(1)}`;
  }
  if (numberStr.length === 8 && numberStr.includes('-')) {
    numberStr = `${'-'}${numberStr.substring(1, 2)}.${numberStr.substring(2)}`;
  }
  return numberStr;
}

// TODO: remove this function when not in use
export function formatNumber(number: number, shorten = false, decimals = 2): string {
  // TODO: this has to come from internationalization
  const locale = 'es-AR';
  let numberStr = '';

  if (shorten) {
    if (number >= 1000000) {
      // Millions with 1 decimal
      const shortedNum = Math.round(number / 100000) / 10;
      numberStr = `${shortedNum.toLocaleString(locale)} M`;
    } else if (number >= 10000) {
      // Thousands
      const shortedNum = Math.round(number / 1000);
      numberStr = `${shortedNum.toLocaleString(locale)} mil`;
    } else {
      const shortedNum = Math.round(number);
      numberStr = shortedNum.toLocaleString(locale);
    }
  } else {
    // decimal factor is 100 if 2 decimales, 1000 if 3 decimales, etc
    const decimalFactor = 10 ** decimals;
    const roundedNumber = Math.round(number * decimalFactor) / decimalFactor;
    numberStr = roundedNumber.toLocaleString(locale);
  }

  return numberStr;
}

// TODO: delete this function, unnecessary. Refactor calls before.
export const totalAmountFormatted = (number: number): string => formatNumber(number);

export const currencyFormat = (currencyCode: string): string => {
  if (currencyCode === CurrencyCode.USD) {
    return 'USD ';
  }
  return '$ ';
};

export const handleTaxCode = (countryCode: string): string => {
  let res = '';
  switch (countryCode) {
    case CountryCode.AR:
      res = 'CUIT';
      break;
    case CountryCode.UY:
      res = 'RUT';
      break;
    case CountryCode.CO:
      res = 'NIT';
      break;
    default:
      res = 'CUIT / RUT / NIT';
      break;
  }
  return res;
};

export const capitalizeFirstLetter = (letter: string): string => letter.charAt(0).toUpperCase() + letter.slice(1);

export const arrayContains = (array: any[], value: any): boolean => array.indexOf(value) !== -1;

export const removeDot = (value: string): string => value.replace('.', '');

export const lettersUpperCase = (value: string): string => value.toUpperCase();
export const switchTitle = (step: string): string => {
  switch (step) {
    case stepCodes.ENTER_EMAIL:
      return 'Indicanos la dirección de email con la que creaste tu cuenta, así podemos enviarte un correo con instrucciones para recuperar tu contraseña.';
    case stepCodes.ENTER_NEW_PASSWORD:
      return `Ingresá el código que te enviamos por correo así nos aseguramos que realmente sos el propietario de esa dirección de e-mail.
            
      Escribí tu nueva contraseña con la que vas a ingresar a nubceo.
   
   `;
    default:
      return 'Indicanos la dirección de email con la que creaste tu cuenta, así podemos enviarte un correo con instrucciones para recuperar tu contraseña.';
  }
};

export const getNameInitials = (name: string): string => {
  const arrayName = name.split(' ');
  // Return first name initial and last name initial
  return `${arrayName.length > 0 ? arrayName[0].charAt(0) : ''}${arrayName.length > 1 ? arrayName[arrayName.length - 1].charAt(0) : ''}`;
};

export const mesaggeError = (): string => {
  let mesagge = '';
  mesagge = 'Estamos teniendo dificultades para mostrarte la información solicitada. Por favor, volvé a intentar en unos minutos.';
  return mesagge;
};

export const parseActivity = (activityCode: string | undefined): string => {
  switch (activityCode) {
    case ActivityCode.CONTADOR:
      return 'Contador';
    case ActivityCode.SERVICIOS:
      return 'Prestador de servicios';
    case ActivityCode.FABRICANTE:
      return 'Fabricante';
    case ActivityCode.VENTA:
      return 'Venta de productos';
    default:
      return 'SERVICIO';
  }
};
