import React, { FC } from 'react';
import { InputWrapperProps, CheckBoxProps } from '../../../utils/interfaces';
import { Paragraph } from '../../textComponents';
import InputWrapper from '../InputWrapper';

const CheckBoxComponent: FC<CheckBoxProps & InputWrapperProps> = ({
  label,
  errorMessage,
  name,
  className = '',
  containerClassName,
  required,
  requiredOnlyOption,
  disabled,
  children,
  checked,
  onChange,
}: CheckBoxProps & InputWrapperProps) => (
  <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
    <div className="checkbox-component-container">
      <input
        className={`checkbox-component ${errorMessage && 'error'} ${className}`}
        required={required}
        disabled={disabled}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <Paragraph>
        {children} {requiredOnlyOption && <span className="required">*</span>}
      </Paragraph>
    </div>
  </InputWrapper>
);

export default CheckBoxComponent;
