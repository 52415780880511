import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';
import { AxiosError } from 'axios';
import { FormButtonComponent, LoaderScreen, Notification, Subtitle, Title } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import errorImage from '../../assets/images/error.png';
import {
  asyncAddExternalPlatform,
  asyncEditExternalPlatform,
  asyncListUsedExternalPlatforms,
} from '../../redux/slices/externalPlatformSlice';
import { asyncListExternalPlatformsHelper } from '../../utils/apiHelpers';
import { handleKeyDownDefault } from '../../utils/helper';
import { config, externalPlatformsBasicData, platformExternalCodesTrimmed, showLink } from '../../utils/constants';
import logger from '../../services/loggerService';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import { ExternalActivePlatform, ExternalPlatformCredentials } from '../../utils/interfaces';
import { ExternalPlatformType } from '../../utils/types';
import { useTimer } from '../../utils/hooks';
import { asyncClientEditExternalPlatform, asyncClientAddExternalPlatform, asyncListClients } from '../../redux/slices/clientsSlice';
import {
  ENV_MERCADO_LIBRE_APP_REDIRECT_URL,
  ENV_MERCADO_LIBRE_APP_ID,
  ENV_TIENDA_NUBE_APP_ID,
  ENV_TIENDA_NUBE_APP_REDIRECT_URL,
} from '../../config';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { deletePlatformCredential, viewExternalPlatformCredential } from '../../services/externalPlatformService';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import {
  AmexCredentialsForm,
  CabalCredentialsForm,
  ContabiliumCredentialsForm,
  EmailAndPasswordGenericCredentialsForm,
  EmailTypePlatformForm,
  GetnetCredentialsForm,
  MercadoPagoCredentialsForm,
  NaranjaCredentialsForm,
  PrismaCredentialsForm,
  TiendaNubeCredentialsForm,
  VisanetCredentialsForm,
  XubioCredentialsForm,
  ZoologicCredentialsForm,
} from '../../components/forms';
import errorHandlerHelper from '../../utils/errorHandler';
import successImage from '../../assets/images/success.png';
import { CloseIconSVG } from '../../assets/svgComponents';
import { deletePlatformCredentialClient, sendMercadoPagoInvitation } from '../../services/clientsService';
import FiservCredentialsForm from '../../components/forms/platformCredentialsForms/FiservCredentialsForm';
import { listCurrencies } from '../../redux/slices/currencySlice';
import ModdoCredentialsForm from '../../components/forms/platformCredentialsForms/ModdoCredentialsForm';
import ColpatriaCredentialsForm from '../../components/forms/platformCredentialsForms/ColpatriaCredentialsForm';

export type ExternalPlatformCredentialsForm = {
  password: string;
  username: string;
  codeAux?: string;
  codeAux2?: string;
  codeAux3?: string;
  codeAux4?: string;
};

const ExternalPlatform: FC = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activity } = useAppSelector((state) => state.userProfile);
  const [externalPlatformCodes, setExternalPlatformCodes] = useState<Array<string> | null>(null);
  const [externalPlatformData, setExternalPlatformData] = useState<ExternalPlatformCredentials[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [eliminatedPlatform, setEliminatedPlatform] = useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const url = location.pathname;
  const [existingPlatform, setExistingPlatform] = useState<ExternalActivePlatform | undefined>();
  const [canEditPlatform, setCanEditPlatform] = useState(true);
  const { user } = useAppSelector((state) => state.userProfile);
  const { platformPECs } = useAppSelector((state) => state.externalPlatform);
  const { platformPECs: platformPECsClient, singleClient: client } = useAppSelector((state) => state.clients);
  const countDownTimer = useTimer(existingPlatform?.updatedAt || 0);
  const [meLiUrl, setMeLiUrl] = useState('auth.mercadolibre.com.ar');
  const tiendaNubeUrl = 'https://www.tiendanube.com';
  const waitingTimeToRecharge = 640000;
  const isCounterUserPage = url.includes('clients');
  const countryCode = isCounterUserPage ? client?.countryCode : user?.Tenant?.countryCode;
  const selectedPlatformValidation = selectedPlatform?.trim().slice(0, -3) || existingPlatform?.platformExternalCode?.trim().slice(0, -3);
  const isNewPlatform = url.includes('new');
  const externalPlatform = location?.state?.externalPlatform;
  const companyId = location?.state?.company;
  const { singleClient } = useAppSelector((state) => state.clients);
  const typesOfErpAndEcommerce: string[] | null = [ExternalPlatformType.erp, ExternalPlatformType.ecommerce];

  useEffect(() => {
    dispatch<void>(asyncListClients({}));
    setLoading(true);
    // eslint-disable-next-line default-case
    switch (countryCode) {
      case 'UY':
        setMeLiUrl('auth.mercadolibre.com.uy');
        break;
      case 'CO':
        setMeLiUrl('auth.mercadolibre.com.co');
        break;
    }

    if (!isNewPlatform) {
      setLoading(true);
      if (activity !== 'contador') {
        setExistingPlatform(externalPlatform);
        viewExternalPlatformCredential(id || '')
          // asyncListActiveExternalPlatformsHelper({ 'filter[id][eq]': id }, dispatch)
          .then(({ data, status }) => {
            const platform = status === 200 ? setExistingPlatform(data.data) : setEliminatedPlatform(true);
            return platform;
          })
          .catch((error) => errorHandlerHelper(error, dispatch))
          .finally(() => setLoading(false));
      } else if (activity === 'contador') {
        if (externalPlatform) {
          setExistingPlatform(externalPlatform);
        } else {
          setEliminatedPlatform(true);
        }
        setLoading(false);
      }
    } else if (countryCode) {
      asyncListExternalPlatformsHelper({ 'filter[countryCode][eq]': countryCode }, dispatch)
        .then((response) => {
          const externalPlatforms = response.data;
          const platformCodes =
            location.state.type[0] === ExternalPlatformType.erp
              ? externalPlatforms.filter((platform) => location.state.type.includes(platform.type)).map((item) => item.code.trim())
              : externalPlatforms
                  .filter(
                    (platform) =>
                      platform.type !== ExternalPlatformType.erp &&
                      platform.type !== ExternalPlatformType.ecommerce &&
                      platform.source !== 'email',
                  )
                  .map((item) => item.code.trim());
          if (platformCodes.length >= 1) {
            setExternalPlatformCodes(platformCodes);
            setExternalPlatformData(externalPlatforms);
          }
        })
        .catch((ex) => console.log(ex))
        .finally(() => setLoading(false));
    }
  }, [user, id]);

  useEffect(() => {
    const platformExists = platformPECs?.length && platformPECs.find((item) => item.id === Number(id));
    const platformExistsClient = platformPECsClient?.length && platformPECsClient.find((item) => item.id === Number(id));
    if ((platformExists || platformExistsClient) && countDownTimer) {
      setCanEditPlatform(false);
    }
    if ((!platformExists || !platformExistsClient) && !countDownTimer) {
      setCanEditPlatform(true);
    }
    // First Time (comes from Register empty)
    if (existingPlatform?.username === '') {
      setCanEditPlatform(true);
    }
  }, [platformPECs, countDownTimer]);

  const updateExternalPlatform = (
    username: string,
    password: string,
    codeAux?: string,
    codeAux2?: string,
    codeAux3?: string,
    codeAux4?: string,
  ): void => {
    if (existingPlatform && id) {
      if (existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.MERCADOPAGO) {
        window.open(
          `http://${meLiUrl}/authorization?response_type=code&client_id=${ENV_MERCADO_LIBRE_APP_ID}&state=${user?.tenantId}-${id}&redirect_uri=${ENV_MERCADO_LIBRE_APP_REDIRECT_URL}`,
        );
      } else if (existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.TIENDANUBE) {
        window.open(
          `${tiendaNubeUrl}/apps/${ENV_TIENDA_NUBE_APP_ID}/authorize?state=${user?.tenantId}-${id}&redirect_uri=${ENV_TIENDA_NUBE_APP_REDIRECT_URL}`,
        );
      } else if (activity !== 'contador') {
        dispatch(
          asyncEditExternalPlatform({
            id,
            username,
            password,
            codeAux:
              externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.VISANET ||
              externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.ZOOLOGIC ||
              externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.CABAL ||
              externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.MAXIREST ||
              externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.PRISMA
                ? codeAux
                : undefined,
            codeAux2:
              existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC ||
              existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.VISANET
                ? codeAux2
                : undefined,
            codeAux3:
              existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC ? codeAux3 : undefined,
            codeAux4:
              existingPlatform.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC ||
              platformExternalCodesTrimmed.VISANET
                ? codeAux4
                : undefined,
          }),
        )
          .then(() => {
            // Re-call used external platform and currencies list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
            navigate(-1);
          })
          .finally(() => {
            // DO NOTHING
          });
      } else if (activity === 'contador' && client) {
        dispatch(
          asyncClientEditExternalPlatform({
            id: client.id.toString(),
            platformCredentials: {
              id: externalPlatform.id.toString(),
              username,
              password,
              codeAux:
                externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.VISANET ||
                externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.CABAL ||
                externalPlatformsBasicData(existingPlatform.platformExternalCode).code === platformExternalCodesTrimmed.PRISMA
                  ? codeAux
                  : undefined,
            },
          }),
        )
          .then(() => {
            // Re-call used external platform list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
            navigate(-1);
          })
          .catch((error) => errorHandlerHelper(error, dispatch));
      }
      // segmentTrack(`${EVENTS.COMPANY.EXTERNAL_PLATFORM.UPDATE.SUCCESS}_${existingPlatform.platformExternalCode}`, existingPlatform);
    } else if (selectedPlatform) {
      if (selectedPlatform.trim().slice(0, -3) === platformExternalCodesTrimmed.MERCADOPAGO) {
        dispatch(
          asyncAddExternalPlatform({
            platformExternalCode: selectedPlatform,
            username: '',
            password: '',
            companyId,
          }),
        )
          .then((response: any) => {
            const { id: platformId } = response.payload.document;
            // segmentTrack(`${EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.SUCCESS}_${code}`, response);
            navigate(-1);
            window.open(
              `http://${meLiUrl}/authorization?response_type=code&client_id=${ENV_MERCADO_LIBRE_APP_ID}&state=${user?.tenantId}-${platformId}&redirect_uri=${ENV_MERCADO_LIBRE_APP_REDIRECT_URL}`,
            );
            // Re-call used external platform list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
          })
          .catch((error) => console.log(error));
      } else if (selectedPlatform.trim().slice(0, -3) === platformExternalCodesTrimmed.TIENDANUBE) {
        dispatch(
          asyncAddExternalPlatform({
            platformExternalCode: selectedPlatform,
            username: '',
            password: '',
            companyId: location?.state?.company,
          }),
        )
          .then((response: any) => {
            const { id: platformId } = response.payload.document;
            // const { id: platformId, code } = response.payload.document;
            // segmentTrack(`${EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.SUCCESS}_${code}`, response);
            navigate(-1);
            window.open(
              `${tiendaNubeUrl}/apps/${ENV_TIENDA_NUBE_APP_ID}/authorize?state=${user?.tenantId}-${platformId}&redirect_uri=${ENV_TIENDA_NUBE_APP_REDIRECT_URL}`,
            );
            // Re-call used external platform list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
          })
          .catch((error) => console.log(error));
      } else if (activity !== 'contador') {
        dispatch(
          asyncAddExternalPlatform({
            platformExternalCode: selectedPlatform,
            username,
            password,
            companyId,
            codeAux:
              externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.VISANET ||
              externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.ZOOLOGIC ||
              externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.CABAL ||
              externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.MAXIREST ||
              externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.PRISMA
                ? codeAux
                : undefined,
            codeAux2:
              selectedPlatform.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC || platformExternalCodesTrimmed.VISANET
                ? codeAux2
                : undefined,
            codeAux3: selectedPlatform.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC ? codeAux3 : undefined,
            codeAux4:
              selectedPlatform.trim().slice(0, -3) === platformExternalCodesTrimmed.ZOOLOGIC || platformExternalCodesTrimmed.VISANET
                ? codeAux4
                : undefined,
          }),
        )
          // TODO: Type this right
          .then((_response: any) => {
            // segmentTrack(`${EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.SUCCESS}_${response.payload.document.code}`, response);
            navigate(-1);
            // Re-call used external platform list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
          })
          .catch((ex) => {
            logger.error('asyncAddExternalPlatform', ex);
            navigate(-1);
          })
          .finally(() => {
            // DO NOTHING
          });
      } else if (activity === 'contador' && client) {
        dispatch(
          asyncClientAddExternalPlatform({
            id: client?.id.toString(),
            platformCredentials: {
              platformExternalCode: selectedPlatform,
              username,
              password,
              codeAux:
                externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.VISANET ||
                externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.CABAL ||
                externalPlatformsBasicData(selectedPlatform).code === platformExternalCodesTrimmed.PRISMA
                  ? codeAux
                  : undefined,
            },
          }),
        )
          .then((_response: any) => {
            // segmentTrack(`${EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.SUCCESS}_${response.payload.document.code}`, response);
            // Re-call used external platform list after 3 minutes:
            setTimeout(async () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              dispatch(asyncListUsedExternalPlatforms());
              dispatch(listCurrencies());
            }, waitingTimeToRecharge);
            navigate(-1);
          })
          .catch((error) => errorHandlerHelper(error, dispatch));
      }
    }
  };

  const goBack = (): void => {
    if (selectedPlatform) {
      setSelectedPlatform(null);
    }
    navigate(-1);
  };

  const isMeLi =
    selectedPlatform?.trim().slice(0, -3) === platformExternalCodesTrimmed.MERCADOPAGO ||
    existingPlatform?.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.MERCADOPAGO;
  const isTiendaNube =
    selectedPlatform?.trim().slice(0, -3) === platformExternalCodesTrimmed.TIENDANUBE ||
    existingPlatform?.platformExternalCode.trim().slice(0, -3) === platformExternalCodesTrimmed.TIENDANUBE;

  const textBody = (): ReactElement => (
    <>
      <p>Te redirigiremos al sitio de Mercado Libre para que puedas vincularlo.</p>
      <p className="font-weight-bold">Previamente, asegúrate de tener iniciada la sesión que corresponda a la empresa a vincular.</p>
    </>
  );
  const handleSubmit = async (values: ExternalPlatformCredentialsForm): Promise<void> => {
    const { username, password, codeAux, codeAux2, codeAux3, codeAux4 } = values;
    let userConfirm = false;

    const showModal = async (): Promise<boolean> =>
      new Promise<boolean>((resolve) => {
        dispatch(
          addModalToList({
            body: textBody(),
            className: 'modal-md-mp',
            pictureUrl: errorImage,
            required: true,
            okButton: {
              buttonText: 'Ir',
              onClick: () => {
                resolve(true);
                dispatch(removeModalFromList());
              },
            },
            cancelButton: {
              buttonText: 'No estoy seguro',
              onClick: () => {
                resolve(false);
                dispatch(removeModalFromList());
              },
            },
          }),
        );
      });

    if (isMeLi) {
      if (isMeLi && existingPlatform?.setupCompleted) {
        const confirmed = await showModal();
        if (confirmed) {
          userConfirm = true;
        } else {
          navigate(-1);
        }
      } else {
        const confirmed = await showModal();
        if (confirmed) {
          userConfirm = true;
        } else {
          navigate(-1);
        }
      }
    } else if (isTiendaNube) {
      userConfirm = window.confirm('Te redirigiremos al sitio de Tiendanube para que puedas vincularlo');
    } else {
      userConfirm = window.confirm('Este proceso puede tardar unos momentos. Recibirás una notificación cuando termine.');
    }

    if (userConfirm) {
      const newCodeAux = codeAux?.trim().replaceAll('-', '');
      const newCodeAux2 = codeAux2?.trim().replaceAll('-', '');
      const newCodeAux3 = codeAux3?.trim().replaceAll('-', '');
      const newCodeAux4 = codeAux4?.trim();
      updateExternalPlatform(username, password, newCodeAux, newCodeAux2, newCodeAux3, newCodeAux4);
    } else {
      navigate(`/companies/${companyId}`);
    }
  };

  const handleSelectPlatform = (platformCode: string): void => {
    // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.SELECT_PLATFORM, { platformCode });
    setSelectedPlatform(platformCode);
  };

  const handleDeleteSelectedPlatform = (): void => {
    setSelectedPlatform(null);
  };

  const showExternalPlatform = (): ReactElement[] | ReactElement | undefined => {
    if (existingPlatform || selectedPlatform) {
      const platformCode = existingPlatform ? existingPlatform.platformExternalCode : selectedPlatform || '';
      const iconShow = (icon: any) => {
        let res;
        if (externalPlatformsBasicData(icon)?.logoImage) {
          res = externalPlatformIcon({
            code: icon === 'cash' ? 'cash_ar' : icon,
            className: 'platform-image',
          });
        } else {
          res = (
            <div className="external-platform-logo-not-supported">
              <p className="text-not-supported">{icon.slice(0, 1)}</p>
            </div>
          );
        }
        return res;
      };

      return (
        <div className="d-flex align-items-center">
          <div
            className="selected platform-icon"
            onClick={existingPlatform ? handleKeyDownDefault : handleDeleteSelectedPlatform}
            role="none"
          >
            {iconShow(platformCode)}
            {/* {externalPlatformIcon({ code: platformCode, className: 'platform-image' })} */}
          </div>
          <p className="ms-3 font-weight-medium">{externalPlatformsBasicData(platformCode)?.name}</p>
        </div>
      );
    }

    return externalPlatformCodes?.map((platformCode) => (
      <div
        className={`${selectedPlatform && selectedPlatform === platformCode && 'selected'} platform-icon`}
        onClick={(): void => handleSelectPlatform(platformCode)}
        role="none"
      >
        {externalPlatformIcon({ code: platformCode, className: 'platform-image' })}
      </div>
    ));
  };

  const platformCode = existingPlatform ? existingPlatform.platformExternalCode : selectedPlatform || '';

  const handleDeletePlatform = (): void => {
    dispatch(
      addModalToList({
        body: `¿Estás seguro que deseas eliminar esta cuenta de ${externalPlatformsBasicData(platformCode)?.name} como ${
          !typesOfErpAndEcommerce.includes(externalPlatformsBasicData(platformCode).type || '') ? 'medio de cobro' : 'sistema de gestión'
        }?`,
        required: true,
        okButton: {
          buttonText: 'Eliminar',
          onClick: async (): Promise<void> => {
            if (activity !== 'contador') {
              try {
                deletePlatformCredential(id).catch((error) => {
                  throw new Error(error);
                });
                // segmentTrack(platformCode);
                dispatch(
                  addModalToList({
                    body: `El ${
                      !typesOfErpAndEcommerce.includes(externalPlatformsBasicData(platformCode).type || '')
                        ? 'medio de cobro'
                        : 'sistema de gestión'
                    } se ha eliminado correctamente`,
                    pictureUrl: successImage,
                    okButton: {
                      buttonText: 'Aceptar',
                      onClick: async () => {
                        dispatch(removeModalFromList());
                        navigate(companyId ? `/companies/${companyId}` : '/companies');
                      },
                    },
                  }),
                );
              } catch (error) {
                errorHandlerHelper(error as AxiosError, dispatch);
                throw error;
              }
            } else if (activity === 'contador') {
              try {
                if (singleClient?.id) {
                  if (id) {
                    deletePlatformCredentialClient(singleClient?.id ?? '', id).catch((error) => {
                      throw new Error(error);
                    });
                  }
                  // segmentTrack(platformCode);
                  dispatch(
                    addModalToList({
                      body: `El ${
                        externalPlatformsBasicData(platformCode)?.type !== ExternalPlatformType.erp
                          ? 'medio de cobro'
                          : 'sistema de gestión'
                      } se ha eliminado correctamente`,
                      pictureUrl: successImage,
                      okButton: {
                        buttonText: 'Aceptar',
                        onClick: async () => {
                          dispatch(removeModalFromList());
                          navigate(`/companies/${companyId}`);
                        },
                      },
                    }),
                  );
                }
              } catch (error) {
                errorHandlerHelper(error as AxiosError, dispatch);
                throw error;
              }
            }
          },
        },
        cancelButton: {
          buttonText: 'Cancelar',
        },
      }),
    );
  };

  const sendClientRequest = (): void => {
    if (selectedPlatform && client) {
      dispatch(
        asyncClientAddExternalPlatform({
          id: client?.id.toString(),
          platformCredentials: {
            platformExternalCode: selectedPlatform,
            username: '',
            password: '',
            companyId: location.state?.client.relCompanyId,
          },
        }),
      )
        .then(async (res: any) => {
          const pecId = res.payload.id;
          if (id) {
            sendMercadoPagoInvitation(id, pecId)
              .then((): void => {
                dispatch(
                  addModalToList({
                    body: 'Se ha enviado la solicitud correctamente.',
                    pictureUrl: successImage,
                    okButton: {
                      buttonText: 'Aceptar',
                      onClick: async () => {
                        dispatch(removeModalFromList());
                      },
                    },
                  }),
                );
              })
              .catch((error) => errorHandlerHelper(error, dispatch));
          }
        })
        .catch((error) => errorHandlerHelper(error, dispatch));
    }
  };

  const textPlatform = !isNewPlatform
    ? {
        titleEdit: typesOfErpAndEcommerce.includes(externalPlatformsBasicData(platformCode).type || '')
          ? 'Editar Sistema de Gestión (ERP)'
          : 'Editar medio de cobro',
        subtitleEdit: typesOfErpAndEcommerce.includes(externalPlatformsBasicData(platformCode).type || '')
          ? 'Editar el sistema de gestión:'
          : 'Editar el medio de cobro:',
      }
    : {
        titleNew: typesOfErpAndEcommerce.includes(location.state?.type[0] || '')
          ? 'Nuevo Sistema de Gestión (ERP)'
          : 'Nuevo medio de cobro',
        subtitleNew: typesOfErpAndEcommerce.includes(location.state?.type[0] || '')
          ? 'Seleccioná un sistema de gestión:'
          : 'Seleccioná un medio de cobro:',
      };

  const renderContent = (): ReactElement => {
    const renderPlatformCredentialsForm = (): React.ReactElement | null => {
      switch (selectedPlatformValidation) {
        case platformExternalCodesTrimmed.VISANET:
          return (
            <VisanetCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.ZOOLOGIC:
          return (
            <ZoologicCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.XUBIO:
          return (
            <XubioCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.CABAL:
          return (
            <CabalCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.COLPATRIA:
          return (
            <ColpatriaCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.AMEX:
          return (
            <AmexCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.GETNET:
          return (
            <GetnetCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.CONTABILIUM:
          return (
            <ContabiliumCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.FISERV:
          return (
            <FiservCredentialsForm
              selectedPlatform={selectedPlatform ?? ''}
              companyId={companyId}
              handleDeletePlatform={handleDeletePlatform}
              currentValues={existingPlatform}
              existingPlatform={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.MODDO:
          return (
            <ModdoCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.PRISMA:
          return (
            <PrismaCredentialsForm
              canEditPlatform={canEditPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleSubmitForm={handleSubmit}
              currentValues={existingPlatform}
              existingPlatform={existingPlatform}
            />
          );
        case platformExternalCodesTrimmed.NARANJA:
          return (
            <NaranjaCredentialsForm
              selectedPlatform={selectedPlatform ?? ''}
              companyId={companyId}
              currentValues={existingPlatform}
              handleDeletePlatform={handleDeletePlatform}
            />
          );
        case platformExternalCodesTrimmed.MERCADOPAGO:
          return (
            <>
              <MercadoPagoCredentialsForm handleDeletePlatform={handleDeletePlatform} handleSubmitForm={handleSubmit} />
              {activity === 'contador' ? (
                <>
                  <Subtitle size="sm" color="grey" className="w-75">
                    Para conectar Mercado Pago es necesario que el titular de la cuenta realice la vinculación. Enviale una nueva solicitud
                    al titular para que pueda vincularlo automáticamente{' '}
                  </Subtitle>
                  <FormButtonComponent
                    disabled={canEditPlatform === false || !selectedPlatformValidation}
                    className="mt-4"
                    onClick={(): void => sendClientRequest()}
                  >
                    Enviar Solicitud
                  </FormButtonComponent>
                  <FormButtonComponent className="mt-2 isNewPlatform ms-2" onClick={(): void => handleDeletePlatform()}>
                    Eliminar
                  </FormButtonComponent>
                </>
              ) : null}
            </>
          );
        case platformExternalCodesTrimmed.TIENDANUBE:
          return <TiendaNubeCredentialsForm handleDeletePlatform={handleDeletePlatform} handleSubmitForm={handleSubmit} />;
        default:
          if (selectedPlatformValidation) {
            if (existingPlatform?.PlatformExternal.source === 'email') {
              return (
                <div className="mt-3 d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <p className="ms-3">Email para envío de información:</p>
                      <strong className="ms-3">{existingPlatform.username || `pec_${existingPlatform.id}@asistente.nubceo.com`}</strong>
                      <p className="ms-3">
                        Si necesitás ayuda, comunicate al{' +'}
                        {user?.Tenant.countryCode === 'AR' ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        navigator.clipboard.writeText(existingPlatform.username || `pec_${existingPlatform.id}@asistente.nubceo.com`)
                      }
                      className="copy-button icon-button ms-3 mb-1"
                    >
                      <FaRegCopy />
                    </button>
                  </div>
                  <FormButtonComponent className="mt-4" onClick={handleDeletePlatform}>
                    Eliminar
                  </FormButtonComponent>
                </div>
              );
            }
            const platformFiltered = externalPlatformData?.find((platform) => platform.code.trim() === selectedPlatform);

            return platformFiltered !== undefined && platformFiltered.source === 'email' ? (
              <EmailTypePlatformForm
                handleDeletePlatform={handleDeletePlatform}
                companyId={companyId}
                selectedPlatform={selectedPlatform ?? ''}
              />
            ) : (
              <EmailAndPasswordGenericCredentialsForm
                canEditPlatform={canEditPlatform}
                handleDeletePlatform={handleDeletePlatform}
                handleSubmitForm={handleSubmit}
                currentValues={existingPlatform}
              />
            );
          }
          return null;
      }
    };

    if ((externalPlatformCodes?.length || existingPlatform) && user) {
      return (
        <>
          <Subtitle className="my-3">{existingPlatform ? textPlatform.subtitleEdit : textPlatform.subtitleNew}</Subtitle>
          <div className="platforms-container d-flex w-100">{showExternalPlatform()}</div>
          {(selectedPlatform || existingPlatform) && !isMeLi && !platformExternalCodesTrimmed.FISERV ? (
            <Subtitle className="my-3 mt-5">{existingPlatform ? 'Editá tus datos de acceso' : 'Ingresá tus datos de acceso'}</Subtitle>
          ) : null}
          <p className="my-3">{showLink(selectedPlatform || existingPlatform?.platformExternalCode, user)}</p>
          {renderPlatformCredentialsForm()}
        </>
      );
    }
    if (eliminatedPlatform) return <p>Esta plafatorma ya no está disponible. ¿Es posible que la hayas eliminado?</p>;
    return <p>Aún no tenemos integraciones disponibles</p>;
  };

  return (
    <div className="w-100 h-100 external-platform-page">
      {canEditPlatform === false && (
        <div className="notifications-container">
          <Notification
            notification={{ type: 'warning', title: 'Atención', body: `Podrás editar los datos de acceso en ${countDownTimer}` }}
          />
        </div>
      )}
      <div className="d-flex flex-row justify-content-between align-items-center col-12 col-md-10 col-lg-9">
        <Title className="mt-5">{existingPlatform ? textPlatform.titleEdit : textPlatform.titleNew}</Title>
        <CloseIconSVG className="close-icon color-blue mt-5" height={40} width={40} onClick={goBack} />
      </div>

      {loading ? <LoaderScreen /> : renderContent()}
    </div>
  );
};

export default ExternalPlatform;
