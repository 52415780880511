import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import moment from 'moment';
import React, { useMemo, useState, useRef } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSmartDataSettlements } from '../../services/smartDataService';
import { externalPlatformsBasicData } from '../../utils/constants';
import externalPlatformIcon from '../../utils/externalPlatformIcon';
import { TRANSLATIONS } from '../../utils/TransaltionsSmartData';
import {
  amountCell,
  filterTypes,
  parseFilterModelSmartData,
  parsePercentage,
  parsePlatformName,
  defaultColDefValues,
  gridOptions,
  handleFilterChanged,
  addPivotColDefs,
  changeFilterItems,
  buildDefaultDateFiltersAlert,
  handleGoBackSmartData,
} from '../../utils/smartDataUtils';
import errorHandlerHelper from '../../utils/errorHandler';
import { Subtitle, Notification, LoaderSpinner } from '../../components';
import { CloseIconSVG } from '../../assets/svgComponents';

const PivotTableSettlements = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user, loading } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const [pivotModeState, setPivotModeState] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showInFilters, setShowInFilters] = useState({
    commissionsAmount: false,
    percCommissions: false,
    otherDiscountsAmount: false,
    percOtherDiscounts: false,
    percTaxes: false,
    taxesAmount: false,
    collectionAmount: false,
  });
  const loadingUrl = 'https://www.ag-grid.com/example-assets/loading.gif';

  // cellRenderer functions
  const platformExternalCell = (p: { data: { [x: string]: string } }): React.ReactElement | null => {
    let showContent = null;
    if (p?.data && externalPlatformsBasicData(p?.data['platformExternal.name']).name) {
      showContent = (
        <>
          {externalPlatformIcon({
            codeDataFlex: parsePlatformName(p?.data['platformExternal.name']),
            className: 'external-platform-logo-pivot me-2',
          })}
          {externalPlatformsBasicData(p?.data['platformExternal.name']).name}
        </>
      );
    }
    return showContent;
  };

  const branchCell = (
    p: { data: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined } },
    key: string,
  ): React.ReactElement | null => {
    let showContent = null;
    if (p?.data && p?.data[key]) {
      showContent = <p>{p?.data[key]}</p>;
    }
    return showContent;
  };

  const dateCell = (params: { value: moment.MomentInput; data: any }): string | React.ReactElement | null => {
    if (!params?.value && !params?.data) {
      return <img src={loadingUrl} alt="Cargando" />;
    }
    if (params?.value) {
      const newDate = moment(params?.value).locale('es').format('DD/MM/YYYY');
      return newDate;
    }
    return null;
  };

  // default definition of columns
  const defaultColDef = useMemo(() => defaultColDefValues, []);

  // column definition
  const columnDefs = [
    {
      headerName: 'Empresa',
      field: 'company.name',
      cellRenderer: (p: any): React.ReactElement | null => branchCell(p, 'company.name'),
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
    },
    {
      headerName: 'Sucursal/Comercio',
      field: 'branch.name',
      cellRenderer: (p: any): React.ReactElement | null => branchCell(p, 'branch.name'),
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
    },
    {
      headerName: 'Plataforma',
      field: 'platformExternal.name',
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
      cellRenderer: platformExternalCell,
      sortable: false,
    },
    {
      headerName: 'Fecha de Liquidación',
      field: 'date',
      filter: 'agDateColumnFilter',
      cellRenderer: dateCell,
      filterParams: filterTypes.filterForDate,
      cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Fecha De Pago',
      field: 'collection.date',
      cellRenderer: dateCell,
      filterParams: filterTypes.filterForDate,
      filter: 'agDateColumnFilter',
      cellStyle: { textAlign: 'right' },
    },
    { headerName: 'Tarjeta', field: 'cardBrand', filter: 'agTextColumnFilter', filterParams: filterTypes.filterForText },
    { headerName: 'Banco', field: 'collection.bankName', filter: 'agTextColumnFilter', filterParams: filterTypes.filterForText },
    {
      headerName: 'Referencia Plataforma',
      field: 'externalReference',
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
    },
    {
      headerName: 'Plataforma Relacionada',
      field: 'relatedPlatform',
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
    },
    {
      headerName: 'Moneda',
      cellStyle: { textAlign: 'center' },
      field: 'currencyCode',
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForText,
    },
    {
      headerName: 'Monto Bruto',
      field: 'grossAmount',
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['sum', 'min', 'max', 'avg', 'count'],
      cellRenderer: amountCell,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
    },
    {
      headerName: 'Monto Neto',
      field: 'amount',
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['sum', 'min', 'max', 'avg', 'count'],
      cellRenderer: amountCell,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
    },
    {
      headerName: 'Total Comisiones',
      field: 'commissionsAmount',
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['sum', 'min', 'max', 'avg'],
      cellRenderer: amountCell,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      suppressFiltersToolPanel: showInFilters.commissionsAmount,
    },
    {
      headerName: '% Comisión',
      field: 'percCommissions',
      cellRenderer: parsePercentage,
      filter: 'agNumberColumnFilter',
      filterParams: filterTypes.filterForNumbers,
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['min', 'max', 'avg'],
      defaultAggFunc: 'avg',
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      suppressFiltersToolPanel: showInFilters.percCommissions,
    },
    {
      headerName: 'Total Impuestos',
      field: 'taxesAmount',
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['sum', 'min', 'max', 'avg'],
      cellRenderer: amountCell,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      suppressFiltersToolPanel: showInFilters.taxesAmount,
    },
    {
      headerName: 'Monto de Pago',
      field: 'collection.amount',
      cellStyle: { textAlign: 'right' },
      allowedAggFuncs: ['sum', 'min', 'max', 'avg'],
      cellRenderer: amountCell,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      suppressFiltersToolPanel: showInFilters.collectionAmount,
    },
    {
      headerName: 'ID Liquidación',
      filter: 'agTextColumnFilter',
      filterParams: filterTypes.filterForId,
      field: 'id',
      enableRowGroup: false,
      enablePivot: false,
      enableValue: false,
    },
    { headerName: 'Mes Liquidación', field: 'monthYear', filter: 'agTextColumnFilter', filterParams: filterTypes.filterForText },
  ];

  const gridParamsRef = useRef(null);

  // handling filters and calling the api
  const datasource = {
    getRows(params: any, submitRequestPivot = false): void {
      const { endRow, sortModel, filterModel, rowGroupCols, pivotMode, groupKeys, valueCols, pivotCols } = params.request;
      setPivotModeState(pivotMode);
      gridParamsRef.current = params;
      const paramsRequest = `?page=${endRow / (!pivotMode ? 25 : 1000)}&pageSize=${pivotMode ? '1000' : '25'}`;
      let body = {
        filterModel: parseFilterModelSmartData(filterModel),
        groupKeys,
        pivotCols,
        pivotMode,
        rowGroupCols,
        sortModel,
        valueCols,
      };

      const filterKeys = Object.keys(filterModel);

      if (pivotMode && !valueCols?.length) {
        body = { ...body, sortModel: {} };
      }

      // set filters when exiting pivot
      const isFilterPivotActive = filterKeys?.length ? filterKeys[0][0].includes('|') : false;
      if ((isFilterPivotActive && !pivotMode) || (!pivotMode && pivotCols?.length)) {
        params.api.setFilterModel(null);
      }

      if (!pivotMode || (pivotMode && submitRequestPivot)) {
        getSmartDataSettlements(body, paramsRequest)
          .then((httpResponse) => httpResponse)
          .then((response) => {
            params.success({
              rowData: response?.data?.data,
              rowCount: response?.data?.meta?.total,
            });

            const pivotField = response?.data?.data?.pivotResultColDefs;
            if (pivotMode) {
              params.success({
                rowData: response?.data?.data?.data,
                rowCount: response?.data?.meta?.total,
              });
              setLoadingButton(false);
            }

            if (rowGroupCols?.length && valueCols?.length && !sortModel?.length) {
              changeFilterItems(setShowInFilters, valueCols);
            } else if (!rowGroupCols?.length) {
              setShowInFilters({
                commissionsAmount: false,
                percCommissions: false,
                otherDiscountsAmount: false,
                percOtherDiscounts: false,
                collectionAmount: false,
                taxesAmount: false,
                percTaxes: false,
              });
            }

            //* Add cell renderer to amounts cells in pivotMode
            pivotField?.forEach((colDef: any) => {
              // if the column has children, iterate through them
              if (colDef.children) {
                colDef.children.forEach((childColDef: any) => {
                  // if the child column definition is the last level, add the valueFormatter property
                  if (!childColDef.children) {
                    childColDef.cellStyle = { textAlign: 'right' };
                    childColDef.cellRenderer = amountCell;
                  }
                });
              }
            });
            if (!params?.parentNode?.alreadyRendered) {
              addPivotColDefs(params.columnApi, pivotField);
            }
          })
          .catch((error) => {
            errorHandlerHelper(error, dispatch);
            params.fail();
            setLoadingButton(false);
          });
      } else if (pivotMode && !submitRequestPivot) {
        params.success({
          rowData: [{ loading: 'Cargando' }],
          rowCount: 1,
        });
      }
    },
  };

  // TODO Trigger manual query
  const handleRequestPivotMode = (): void => {
    setLoadingButton(true);
    datasource.getRows(gridParamsRef?.current, true);
  };

  // TODO Perform pivot query when displaying the row
  const onRowGroupOpened = (event: { expanded: boolean }): void => {
    if (event.expanded) {
      setLoadingButton(true);
      // TODO A timeout is added so that it does not collide with the automatic query made by the grid
      setTimeout(() => {
        handleRequestPivotMode();
      }, 10);
    }
  };

  // general grid styles
  const gridStyle = useMemo(() => ({ height: '70vh', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '50%' }), []);

  const onGridReady = (params: GridReadyEvent): void => {
    // load data for grid
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <div style={containerStyle}>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row justify-content-between w-100">
          <Subtitle className="my-2">Smart Data - Liquidaciones</Subtitle>
          <div className="d-flex flex-row">
            <div className="d-flex my-2 container-button">
              {pivotModeState && (
                <button className="button-pivot" type="button" onClick={(): void => handleRequestPivotMode()} id="fetch-button">
                  <span className="ms-2 d-md-inline">{loadingButton ? <LoaderSpinner className="xs" /> : 'Armar Pivot'}</span>
                </button>
              )}
            </div>
            <CloseIconSVG
              className="mt-3 ms-2 close-icon color-blue cursor-pointer"
              height={24}
              width={24}
              onClick={(): void => handleGoBackSmartData(navigate)}
            />
          </div>
        </div>
      </div>
      <div className="notifications-container my-2">
        <Notification notification={buildDefaultDateFiltersAlert(user, loading)} />
      </div>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          onFilterChanged={(params): void => handleFilterChanged(params, datasource)}
          columnDefs={columnDefs}
          gridOptions={{ ...gridOptions, onRowGroupOpened }}
          pivotMode={pivotModeState}
          defaultColDef={defaultColDef}
          removePivotHeaderRowWhenSingleValueColumn
          animateRows
          suppressFieldDotNotation
          autoGroupColumnDef={{
            width: 180,
            cellRendererParams: {
              innerRenderer: (p: any) => {
                if (p?.node?.field === 'platformExternal.name') {
                  return platformExternalCell(p);
                }
                return p.value !== 'null' ? p.value : '';
              },
            },
          }}
          cacheBlockSize={!pivotModeState ? 25 : 1000}
          serverSideInfiniteScroll
          rowModelType="serverSide"
          onGridReady={onGridReady}
          enableCharts
          sideBar
          rowSelection="multiple"
          localeText={TRANSLATIONS['es-ES']}
        />
      </div>
    </div>
  );
};
export default PivotTableSettlements;
