import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { ModalTypes } from '../../containers/ModalContainer';
import { CallbackFunction, CallbackPromise } from '../../utils/types';

export interface ModalType {
  body?: string | React.ReactElement;
  pictureUrl?: string;
  required?: boolean;
  companyId?: string;
  tenantId?: string;
  branchId?: string;
  className?: string;
  handleCloseButton?: () => void;
  type?: ModalTypes;
  okButton?: {
    onClick: CallbackPromise;
    buttonText: string;
  };
  cancelButton?: {
    onClick?: CallbackFunction;
    buttonText: string;
  };
  hideModalAccept?: boolean;
}

export interface ModalState {
  modal: ModalType;
  modalList: ModalType[];
}

const initialState: ModalState = {
  modal: {},
  modalList: [],
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    addModalToList: (state, action: PayloadAction<any>) => {
      const runManualWalkthrough = window.localStorage.getItem('runManualWalkthrough');
      if (!runManualWalkthrough) {
        state.modalList.push(action.payload);
      }
    },
    removeModalFromList: (state) => {
      state.modalList.shift();
    },
    removeAllModalsFromList: (state) => {
      state.modalList = [];
    },
  },
});

export const { addModalToList, removeModalFromList, removeAllModalsFromList } = modalSlice.actions;
export default modalSlice.reducer;
