import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { LoaderScreen, PaginationWithCount, Title } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Branch, LoadingStateType } from '../../utils/interfaces';
import { CloseIconSVG } from '../../assets/svgComponents';
import BranchCard from '../../components/BranchCard';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { asyncListBranches } from '../../redux/slices/branchSlice';
import errorHandlerHelper from '../../utils/errorHandler';
import errorImage from '../../assets/images/error.png';
import successImage from '../../assets/images/success.png';
import { mergeBranches } from '../../services/branchService';
import { ModalTypes } from '../../containers/ModalContainer';
import useQueryParams from '../../hooks/useQueryParams';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { externalPlatformsBasicData } from '../../utils/constants';
import externalPlatformIcon from '../../utils/externalPlatformIcon';

type DecodedToken = {
  permissions: Array<string>;
};

const Branches: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { pages, branchesList, loading, total } = useAppSelector((state) => state.branch);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectable, setSelectable] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const page = useQueryParams('page') || '1';
  const topRef = useRef<HTMLDivElement>(null);
  const groupedBranchesText = 'Sucursales Agrupadas';

  useEffect(() => {
    const getRoleUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken: DecodedToken = await jwtDecode(token);
        const permissions = decodedToken?.permissions || [];

        const hasBranchEditPermissions = (perms: string[]): boolean => {
          const requiredPermissions = ['branch_create', 'branch_delete', 'branch_update'];
          return requiredPermissions.every((permission) => perms.includes(permission));
        };

        if (hasBranchEditPermissions(permissions)) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };
    getRoleUser();
  }, []);

  const groupedBranches = branchesList.reduce((acc: { [x: string]: any[] }, branch) => {
    const platformExternalCode =
      (!branch.relatedBranches.length && branch.PlatformExternals[0]?.platformExternalCode) || groupedBranchesText;

    if (!acc[platformExternalCode]) {
      acc[platformExternalCode] = [];
    }

    acc[platformExternalCode].push(branch);
    return acc;
  }, {});

  // Render sections by external platform
  const renderSections = (): (React.ReactElement | null)[] =>
    Object.entries(groupedBranches).map(([platformExternalCode, branches]) => {
      if (!Array.isArray(branches)) {
        return null; // Handle the case where branches is not an array
      }

      return (
        <>
          <div className="d-flex flex-column w-100 mt-4">
            <div className="d-flex flex-row">
              <p className="font-weight-bold">{externalPlatformsBasicData(platformExternalCode).name}</p>
              {platformExternalCode !== groupedBranchesText ? (
                <>
                  {externalPlatformIcon({
                    code: platformExternalCode,
                    className: 'external-platform-mini-logo-branches',
                  })}
                </>
              ) : null}
            </div>
            <span className="separator mt-1 mb-2" />
          </div>
          {branches.map((branch: Branch) => (
            <BranchCard
              key={branch.id + Math.floor(Math.random() * 1000)}
              branch={branch}
              selectedBranches={selectedBranches}
              setSelectedBranch={handleSelectBranch}
              selectable={selectable}
              showUnlinkIcon
              isAdmin={isAdmin}
            />
          ))}
        </>
      );
    });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({
      ...queryObject,
      'filter[companyId][in]': `[${id}]`,
      pageSize: '25',
    });
    dispatch(asyncListBranches(filterableObject));
  }, [location]);

  const handleCancelSelectItems = (): void => {
    setSelectable(false);
    setSelectedBranches([]);
  };

  const handleEditNameBranch = (data: { data: { companyId: string; tenantId: string; id: string } }): void => {
    dispatch(
      addModalToList({
        type: ModalTypes.EDIT_NAME_BRANCH,
        required: true,
        companyId: data?.data?.companyId,
        tenantId: data?.data?.tenantId,
        branchId: data?.data?.id,
      }),
    );
  };

  const handleCombineItems = async (): Promise<void> => {
    const ids = selectedBranches.map((branch: Branch) => Number(branch.id));
    await mergeBranches(ids)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(
            addModalToList({
              body: 'Fusionaste las sucursales con éxito.',
              pictureUrl: successImage,
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch<void>(
                    asyncListBranches({
                      pageSize: '25',
                      'filter[companyId][in]': `[${id}]`,
                    }),
                  );
                  handleCancelSelectItems();
                  handleEditNameBranch(res?.data);
                },
              },
            }),
          );
        } else {
          dispatch(
            addModalToList({
              body: 'Hubo un error fusionando las sucursales. Probá más tarde.',
              pictureUrl: errorImage,
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch(removeModalFromList());
                },
              },
            }),
          );
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  };

  const showSuccessCombine = (): void => {
    dispatch(
      addModalToList({
        body: <p className="my-3">¿Estás seguro que las sucursales seleccionadas son en realidad una misma sucursal?</p>,
        okButton: {
          buttonText: 'Aceptar',
          onClick: async () => {
            handleCombineItems();
          },
        },
        cancelButton: {
          buttonText: 'Cancelar',
          onClick: async () => {
            dispatch(removeModalFromList());
          },
        },
      }),
    );
  };

  const handleSelectItems = (): void => {
    setSelectable(true);
  };

  const handleSelectBranch = (selectedBranch: Branch | undefined): void => {
    if (selectable) {
      setSelectedBranches((prevState: any) => {
        const found = prevState.find((item: Branch) => selectedBranch && item?.id === selectedBranch?.id);
        if (found) {
          return prevState.filter((item: Branch) => selectedBranch && item.id !== selectedBranch.id);
        }
        return [...prevState, { ...selectedBranch }];
      });
    }
  };

  const handleCloseIcon = (): void => {
    navigate(`/companies/${id}`);
  };

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  return (
    <div className="w-100 h-100 branch-page">
      <div className="d-flex flex-row justify-content-between align-items-center col-12 col-md-10 col-lg-9" ref={topRef}>
        <Title className="mt-5 mb-3">Sucursales</Title>
        <div className="d-flex flex-row">
          {selectable ? (
            <div className="d-flex flex-row ms-3 align-items-end mb-2 me-3">
              {selectedBranches?.length ? (
                <div role="none" onClick={(): void => showSuccessCombine()}>
                  <p className="textOptionsGroup me-2">Agrupar</p>
                </div>
              ) : null}
              <div onClick={(): void => handleCancelSelectItems()} role="none">
                <p className="textOptions">Cancelar</p>
              </div>
            </div>
          ) : (
            <div onClick={(): void => handleSelectItems()} role="none" className="d-flex align-items-end mb-2 me-3">
              <p className="textOptions">{isAdmin ? 'Seleccionar' : null}</p>
            </div>
          )}
          <CloseIconSVG className="close-icon color-blue mt-5" height={40} width={40} onClick={(): void => handleCloseIcon()} />
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between align-items-center col-12 col-md-10 col-lg-9">
        {loading === LoadingStateType.PENDING ? (
          <LoaderScreen />
        ) : (
          <>
            {renderSections()}
            {loading === LoadingStateType.FAILED || branchesList?.length === 0 ? <p className="mt-5">No tienes sucursales</p> : null}
          </>
        )}
      </div>
      <PaginationWithCount
        page={page}
        handlePagination={handlePagination}
        pageSize={25}
        pages={pages}
        length={branchesList?.length || 0}
        loading={loading}
        total={total}
      />
    </div>
  );
};

export default Branches;
