import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Paragraph, Title, NeedHelpLink } from '../../components';
import variables from '../../scss/_exports.module.scss';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import illustration1 from '../../assets/images/illustrations/illustration-1.jpg';
import illustration2 from '../../assets/images/illustrations/illustration-2.jpg';
import illustration3 from '../../assets/images/illustrations/illustration-3.jpg';
import illustration4 from '../../assets/images/illustrations/illustration-4.jpg';
import illustration5 from '../../assets/images/illustrations/illustration-5.jpg';
import { switchTitle } from '../../utils/stringUtils';
import switchRecoverForm from '../../utils/switchFormRecover';

export const stepCodes = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  ENTER_NEW_PASSWORD: 'ENTER_NEW_PASSWORD',
};

const RecoverPassword: FC = () => {
  const [randomImage, setRandomImage] = useState(illustration1);
  const [selectedEmail, setSelectedEmail] = useState('');
  const location = useLocation();
  const isRenewalPasswordBoolean = location?.state?.isRenewalPassword;

  const [steps, setSteps] = useState(stepCodes.ENTER_EMAIL);

  useEffect(() => {
    // if (history.location.state?.fromRegister) {
    // segmentTrack(EVENTS.AUTH.REGISTER.LOGIN);
    // }
  }, []);

  useEffect(() => {
    const images = [illustration1, illustration2, illustration3, illustration4, illustration5];
    setRandomImage(images[Math.floor(Math.random() * (5 - 0))]);
  }, []);

  return (
    <div className="login-page row justify-content-center" style={{ height: '100%' }}>
      <div className="section p-5 d-flex flex-column justify-content-center col-xs-12 col-md-6">
        <Title color={variables.colorBlue}>¡Recuperá tu contraseña!</Title>
        <Paragraph>{switchTitle(steps)}</Paragraph>

        {switchRecoverForm(steps, selectedEmail, setSteps, setSelectedEmail, isRenewalPasswordBoolean)}
      </div>
      <div
        style={{
          backgroundImage: `url(${randomImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '15% 40%',
          height: 'auto',
        }}
        className="section col-xs-12 col-md-6 d-flex flex-column align-items-end"
      >
        <NeedHelpLink
          className="help-link"
          paragraphColor="color-white"
          // analytics={EVENTS.AUTH.LOGIN.HELP}
        />
        <p className="copyright font-xs font-weight-light color-white">
          {`© Nubceo ${moment().format('YYYY')}, todos los derechos reservados.`}
        </p>
      </div>
    </div>
  );
};

export default RecoverPassword;
