import React, { FC, useState, ReactNode, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Field, Form, Formik, FormikValues } from 'formik';
import {
  CUSTOM_DATE_FILTER_OPTION,
  MessageToApplyFilters,
  THIS_MONTH_DATE_FILTER_OPTION,
  externalPlatformsBasicData,
  optionsDate,
  optionsDateValues,
} from '../../utils/constants';
import { cleanNullValues, paramsToObject } from '../../utils/helper';
import {
  SelectInputComponent,
  FilterTabComponent,
  FormButtonComponent,
  SearchInputComponent,
  Paragraph,
  DateInputComponent,
} from '../../components';
import variables from '../../scss/_exports.module.scss';
import { FormFieldType } from '../../utils/types';
import { useAppSelector } from '../../redux/hooks';
import { ExternalActivePlatform } from '../../utils/interfaces';
import { ScreenType, clearAllFilters, defaultDateFilter, getInitialValues, handleSubmit, sortPlatforms } from '../../utils/helperFilters';

const FilterExpenseReports = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const URLFilters: Record<string, string> = paramsToObject(new URLSearchParams(location.search));
  const { companiesList } = useAppSelector((state) => state.company);
  const { taxTypes } = useAppSelector((state) => state.taxes);
  const { clientExternalPlatforms, clientCompanies } = useAppSelector((state) => state.clients);
  const { externalUsedPlatformList: platformListUsed } = useAppSelector((state) => state.externalPlatform);
  const companiesListSorted = [...companiesList]?.sort((company) => (company.enabled ? -1 : 1));
  const [platformExternalArray, setPlatformExternalArray] = useState<ExternalActivePlatform[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string[]>();
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [customDateFilters, setCustomDateFilters] = useState(false);
  const isTaxesClientPage = url.includes('taxes-client');
  const isTaxesPage = url.includes('expense-reports');
  const segments = url.split('/');
  const clientID = `/${segments[1]}/${segments[2]}`;

  enum Property {
    COMPANIES = 'companies',
    BRANCHES = 'branches',
    PRESET_DATE_FILTERS = 'presetDateFilters',
  }

  const applyFilters = (values: FormikValues): void => {
    handleSubmit(values, navigate, url, true);
  };

  useMemo(() => {
    if (isTaxesClientPage) {
      return setPlatformExternalArray(clientExternalPlatforms);
    }
    return setPlatformExternalArray(platformListUsed);
  }, [platformListUsed, clientExternalPlatforms]);

  const initialValues = getInitialValues(URLFilters, !isTaxesClientPage);

  const handleOptionSelect = (
    fieldName: string,
    value: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
  ): void => {
    setFieldValue(fieldName, value);
    setCustomDateFilters(value === CUSTOM_DATE_FILTER_OPTION);
    if (value !== CUSTOM_DATE_FILTER_OPTION) {
      setFieldValue('startDate', value);
      setFieldValue('endDate', value);
    }
  };

  const deleteFilterTab = (values: FormikValues, property: string): void => {
    let newValues = { ...values, [property]: null };
    if (isTaxesClientPage || isTaxesPage) {
      const start = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][0];
      const end = optionsDateValues[THIS_MONTH_DATE_FILTER_OPTION][1];

      switch (property) {
        case Property.COMPANIES:
          newValues = { ...newValues, branches: null, platformCode: null };
          break;
        case Property.BRANCHES:
          newValues = { ...newValues, platformCode: null };
          break;
        case Property.PRESET_DATE_FILTERS:
          newValues = { ...newValues, presetDateFilters: THIS_MONTH_DATE_FILTER_OPTION, startDate: start, endDate: end };
          break;
        default:
          break;
      }
    }
    const notNullValues = cleanNullValues(newValues);
    const params = new URLSearchParams(notNullValues).toString();
    navigate(params ? `?${params}` : url);
  };

  return (
    <div className="border-round flex-grow-1">
      <Formik onSubmit={applyFilters} initialValues={initialValues}>
        {({ values, setFieldValue }): ReturnType<FC> => (
          <>
            <Form id="filters">
              <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9">
                <Field name="companies">
                  {({ field }: FormFieldType): ReactNode => {
                    let companiesArray = companiesListSorted;
                    if (isTaxesClientPage) {
                      companiesArray = clientCompanies;
                    }

                    return (
                      <SearchInputComponent
                        label="Empresas"
                        options={companiesArray.map((company) => ({
                          id: company?.id,
                          value: company?.id,
                          name: company?.name,
                          pill: company.enabled ? undefined : (
                            <div className="filter-tag px-3 py-1 inactive-badge">
                              <Paragraph color={variables.colorGray}>Inactiva</Paragraph>
                            </div>
                          ),
                        }))}
                        onOptionClick={(name, value): void => {
                          if (isTaxesClientPage) {
                            setFieldValue('branches', null);
                          }
                          setSelectedCompany(value);
                          setFieldValue(name, value);
                        }}
                        containerClassName="input"
                        placeholder={
                          companiesListSorted?.length === values.companies?.length ? 'Todas las empresas' : 'Seleccionar empresa(s)'
                        }
                        {...field}
                        value={selectedCompany?.length ? values.companies : []}
                      />
                    );
                  }}
                </Field>
                <Field name="platformCode">
                  {({ field }: FormFieldType): ReactNode => {
                    const platformArray = isTaxesClientPage ? clientExternalPlatforms : platformExternalArray;
                    return (
                      <SelectInputComponent
                        label="Plataforma"
                        options={platformArray.reduce<{ id: string | number; name: string; value: string }[]>((acc, platform) => {
                          const found = acc.find((el) => el.name === externalPlatformsBasicData(platform?.platformExternalCode)?.name);
                          if (!found) {
                            acc.push({
                              id: platform.id,
                              name: externalPlatformsBasicData(platform.platformExternalCode)?.name,
                              value: platform.platformExternalCode,
                            });
                          }
                          return sortPlatforms(acc);
                        }, [])}
                        containerClassName="input"
                        onOptionClick={(name, value): void => {
                          setSelectedPlatform(value);
                          setFieldValue(name, value);
                        }}
                        {...field}
                        value={selectedPlatform ? values.platformCode : ''}
                        placeholder="Todas las Plataformas"
                      />
                    );
                  }}
                </Field>
                {isTaxesClientPage ? (
                  <>
                    <Field name="taxTypeCode">
                      {({ field }: FormFieldType): ReactNode => (
                        <SearchInputComponent
                          label="Impuestos"
                          options={taxTypes.map((taxType) => ({
                            code: taxType.code,
                            name: taxType.name,
                            value: taxType.code,
                          }))}
                          onOptionClick={(name, value): void => {
                            setFieldValue('taxTypeCode', null);
                            setFieldValue(name, value);
                          }}
                          containerClassName="input"
                          placeholder="Todos los Impuestos"
                          {...field}
                          value={values.taxTypeCode ?? []}
                        />
                      )}
                    </Field>
                    <>
                      <div className="d-flex flex-wrap col-12 col-md-10 col-lg-12 mt-2">
                        <Field name="startDate">
                          {({ field }: FormFieldType): ReactNode => (
                            <DateInputComponent
                              label="Fecha desde"
                              containerClassName="input"
                              {...field}
                              value={values.startDate ?? ''}
                              max={values.endDate ?? undefined}
                            />
                          )}
                        </Field>
                        <Field name="endDate">
                          {({ field }: FormFieldType): ReactNode => (
                            <DateInputComponent
                              label="Fecha hasta"
                              containerClassName="input"
                              {...field}
                              value={values.endDate ?? ''}
                              min={values.startDate ?? undefined}
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  </>
                ) : null}
                {!isTaxesClientPage ? (
                  <>
                    <Field name="presetDateFilters">
                      {({ field }: FormFieldType): React.ReactElement => (
                        <SelectInputComponent
                          defaultValue={THIS_MONTH_DATE_FILTER_OPTION}
                          required
                          containerClassName="input"
                          placeholder="Seleccioná el periodo"
                          label="Fecha"
                          options={optionsDate}
                          onOptionClick={(name, value): void => {
                            handleOptionSelect(name, value, setFieldValue);
                          }}
                          {...field}
                        />
                      )}
                    </Field>
                    {!isTaxesClientPage && customDateFilters ? (
                      <div className="d-flex flex-wrap col-12 col-md-10 col-lg-12 mt-2">
                        <Field name="startDate">
                          {({ field }: FormFieldType): ReactNode => (
                            <DateInputComponent
                              label="Fecha desde"
                              containerClassName="input"
                              {...field}
                              value={values.startDate ?? ''}
                              max={values.endDate ?? undefined}
                            />
                          )}
                        </Field>
                        <Field name="endDate">
                          {({ field }: FormFieldType): ReactNode => (
                            <DateInputComponent
                              label="Fecha hasta"
                              containerClassName="input"
                              {...field}
                              value={values.endDate ?? ''}
                              min={values.startDate ?? undefined}
                            />
                          )}
                        </Field>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="w-100">
                {Object.values(values).find((elem) => elem !== null) ? <MessageToApplyFilters /> : null}
                <FormButtonComponent type="submit" className="mt-3">
                  Aplicar filtros
                </FormButtonComponent>
              </div>
              <FormButtonComponent
                onClick={(): void => {
                  clearAllFilters(
                    isTaxesClientPage ? ScreenType.TAXES_CLIENT : ScreenType.EXPENSE_REPORTS,
                    setFieldValue,
                    initialValues,
                    navigate,
                    undefined,
                    undefined,
                    clientID,
                  );
                  setCustomDateFilters(false);
                  setSelectedCompany([]);
                  defaultDateFilter(setFieldValue, isTaxesClientPage ? ScreenType.TAXES_CLIENT : ScreenType.EXPENSE_REPORTS);
                }}
                className="mt-3"
              >
                Limpiar filtros
              </FormButtonComponent>
            </Form>
            {Object.keys(URLFilters).filter((filter) => filter !== 'page').length
              ? Object.values(URLFilters).find((value) => value) && (
                  <div className="w-100 p-0 mt-5 d-flex flex-column">
                    <p className="flex-grow-1 font-weight-bold">Filtros Aplicados</p>
                    <div className="d-flex flex-wrap">
                      {Object.keys(URLFilters)
                        .filter((filter) => (!isTaxesClientPage ? !(filter.includes('startDate') || filter.includes('endDate')) : filter))
                        .map((property) =>
                          URLFilters[property] ? (
                            <FilterTabComponent
                              property={property}
                              key={property}
                              array={URLFilters}
                              onClick={(): void => {
                                deleteFilterTab(values, property);
                                if (isTaxesClientPage) {
                                  switch (property) {
                                    case 'companies':
                                      setFieldValue('platformCode', null);
                                      setFieldValue('branches', null);
                                      break;
                                    case 'branches':
                                      setFieldValue('platformCode', null);
                                      break;
                                    default:
                                      break;
                                  }
                                }
                                setFieldValue(property, null);
                              }}
                            />
                          ) : null,
                        )}
                    </div>
                  </div>
                )
              : null}
          </>
        )}
      </Formik>
    </div>
  );
};

export default FilterExpenseReports;
