import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaginationWithCount } from '../..';
import useQueryParams from '../../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { asyncListClientCompanies, asyncListClientExternalPlatforms } from '../../../redux/slices/clientsSlice';
import { asyncListTaxTypesClient } from '../../../redux/slices/taxesSlice';
import { Tax } from '../../../utils/interfaces';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';
import LoaderScreen from '../../LoaderScreen';
import TaxRow from './TaxRow';
import { paramsToObject, parseFilters } from '../../../utils/helper';

type TaxesProps = {
  id?: string | null;
  relTenantId?: string | null;
  taxesArray?: Tax[] | null;
  handlePaginationCallBack?: ((searchParams: Record<string, string>) => void) | null;
};

const TaxComponent: FC<TaxesProps> = ({ taxesArray, handlePaginationCallBack, id, relTenantId }: TaxesProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const page = useQueryParams('page') || '1';
  const location = useLocation();
  const { taxesList, loading, loadingClient, pages, total } = useAppSelector((state) => state.taxes);
  const runWalkthrough = window.localStorage.getItem('walkthrough');

  useEffect(() => {
    if (!taxesArray || !taxesArray.length) {
      // if (page !== '1') segmentTrack(EVENTS.TAXES.PAGINATE, { page });
      dispatch<void>(asyncListClientExternalPlatforms(id ? id.toString() : ''));
      dispatch<void>(asyncListClientCompanies(id ? id.toString() : ''));
    }
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const queryObject = paramsToObject(queryParams);
  const filterableObject = parseFilters({ ...queryObject });

  useEffect(() => {
    if (relTenantId) {
      dispatch<void>(asyncListTaxTypesClient({ id: Number(relTenantId), params: filterableObject }));
    }
  }, [relTenantId, location]);

  if (loading === 'pending' || loadingClient === 'pending') {
    return <LoaderScreen />;
  }

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (handlePaginationCallBack) return handlePaginationCallBack(searchParams);
    return navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  if (loading === 'succeeded' || loadingClient === 'succeeded') {
    return taxesArray?.length || taxesList?.length ? (
      <>
        <div style={{ overflow: !runWalkthrough ? 'hidden' : 'visible' }} className="settlements-component">
          {taxesArray?.length
            ? taxesArray?.map((row: Tax) => <TaxRow key={row.id} tax={row} />)
            : taxesList?.map((row: Tax) => <TaxRow key={row.id} tax={row} />)}
        </div>
        <PaginationWithCount
          page={page}
          handlePagination={handlePagination}
          pageSize={25}
          pages={pages}
          length={taxesArray?.length || taxesList?.length || 0}
          loading={loading}
          total={total}
        />
      </>
    ) : (
      <div>No tiene impuestos para mostrar</div>
    );
  }
  if (loading === 'failed' || loadingClient === 'failed' || (!taxesArray?.length && !taxesList)) {
    return <div>No tiene liquidaciones para mostrar</div>;
  }

  return null;
};

TaxComponent.defaultProps = {
  id: '',
  relTenantId: '',
  taxesArray: [],
  handlePaginationCallBack: null,
};

export default TaxComponent;
