import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExpenseReports, getReports } from '../../services/reportsService';
import errorHandlerHelper from '../../utils/errorHandler';
import { ExpenseReportsResponse } from '../../utils/interfaces';
import { ExpenseReportsType, FilterParams, LoadingStateType, ReportsType } from '../../utils/types';

export type ReportsState = {
  reportsList: ReportsType[];
  expenseReportsList: ExpenseReportsType[];
  loading: LoadingStateType;
  pages: number;
  total: number;
};

export const listReports = createAsyncThunk('reports/listReports', async (_params, { dispatch }) => {
  try {
    const response = await getReports();
    return response;
  } catch (e) {
    errorHandlerHelper(e, dispatch);
    return [];
  }
});

export const asyncListExpenseReports = createAsyncThunk<ExpenseReportsResponse, FilterParams>(
  'reports/getExpenseReports',
  async (params) => {
    const { data: response } = await getExpenseReports(params);
    return response;
  },
);

const initialState: ReportsState = {
  reportsList: [],
  expenseReportsList: [],
  loading: 'idle',
  pages: 0,
  total: 0,
};

export const notificationsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listReports.fulfilled, (state, action: PayloadAction<ReportsType[]>) => {
      state.loading = 'succeeded';
      state.reportsList = action.payload;
    });
    builder.addCase(listReports.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(asyncListExpenseReports.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListExpenseReports.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(asyncListExpenseReports.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.expenseReportsList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
    });
  },
});

export default notificationsSlice.reducer;
