import React, { FC, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarCard, CalendarHeader, CalendarMoveButton, CollectionsCalendarModal, FetchButton, ToolBar } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCollectionDataByDay, getTotalAmount, paramsToObject, parseFilters } from '../../utils/helper';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { CalendarCardProps } from '../../utils/interfaces';
import {
  fetchPresentCashflowSummaryDay,
  fetchPrevCashflowSummaryDay,
  fetchNextCashflowSummaryDay,
  fetchPresentCashflowSummaryDayDetail,
} from '../../redux/slices/cashflowSlice';
import { joyrideStyles, STEPS_CALENDAR } from '../../components/Walkthrough';
import { Filters } from '../../containers';
import { FilterParams, FilterType } from '../../utils/types';
// import ToggleSwitch from '../../components/inputs/ToggleSwitch';

moment.locale('es', {
  week: {
    dow: 0,
  },
});

const localizer = momentLocalizer(moment);

const CalendarView: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const {
    // loadings
    presentCashflowSummaryDayLoading,
    prevCashflowSummaryDayLoading,
    nextCashflowSummaryDayLoading,
    // platform detail
    presentCashflowSummaryDayDetailList,
    // totaled amounts
    presentCashflowSummaryDayList,
    prevCashflowSummaryDayList,
    nextCashflowSummaryDayList,
  } = useAppSelector((state) => state.cashflow);

  const dispatch = useAppDispatch();
  const [selectMonth, setSelectedMonth] = useState(moment().startOf('month').endOf('month'));
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  // const [toggleSwitch, setToggleSwitch] = useState(false);
  // const toggler = (): void => (toggleSwitch ? setToggleSwitch(false) : setToggleSwitch(true));

  const walkthrough = (): void => {
    const tourViewed = window.localStorage.getItem('walkthrough');
    if (!tourViewed) {
      setRunWalkthrough(true);
    }
  };
  useEffect(() => {
    walkthrough();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (activeCurrency?.code) {
        const datesToFilter = (type: string): FilterParams => {
          let start;
          let end;

          switch (type) {
            case 'present':
              start = moment(selectMonth).startOf('month').format();
              end = moment(selectMonth).endOf('month').format();
              break;
            case 'prev':
              start = moment(selectMonth).subtract(1, 'month').startOf('month').format();
              end = moment(selectMonth).subtract(1, 'month').endOf('month').format();
              break;
            case 'next':
              start = moment(selectMonth).add(1, 'month').startOf('month').format();
              end = moment(selectMonth).add(1, 'month').endOf('month').format();
              break;
            default:
              break;
          }
          const paramsReport = {
            'filter[date][between]': `${start},${end}`,
            'filter[currencyCode][eq]': activeCurrency.code,
          };
          const queryParams = new URLSearchParams(location.search);
          const queryObject = paramsToObject(queryParams);
          const filterableObject = parseFilters({ ...queryObject, ...paramsReport });
          return filterableObject;
        };

        await dispatch<void>(fetchPresentCashflowSummaryDayDetail(datesToFilter('present')));
        await dispatch<void>(fetchPresentCashflowSummaryDay(datesToFilter('present')));
        await dispatch<void>(fetchPrevCashflowSummaryDay(datesToFilter('prev')));
        await dispatch<void>(fetchNextCashflowSummaryDay(datesToFilter('next')));
      }
    };

    fetchData();
  }, [activeCurrency, selectMonth, location]);

  const nextMonth = () => {
    setSelectedMonth(moment(selectMonth).add(1, 'month'));
  };

  const prevMonth = () => {
    setSelectedMonth(moment(selectMonth).subtract(1, 'month'));
  };

  const handleCloseModal = async () => {
    dispatch(removeModalFromList());
  };

  const selectDay = (date: Date) => {
    if (presentCashflowSummaryDayLoading === 'succeeded') {
      dispatch(
        addModalToList({
          body: <CollectionsCalendarModal day={moment(date).format('YYYY-MM-DD')} />,
          required: true,
          okButton: {
            buttonText: 'Cerrar',
            onClick: handleCloseModal,
          },
          className: 'modal-to-display-rows',
        }),
      );
    }
  };

  const handleJoyrideCallback = (data: { action?: any; status?: any }) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      navigate('/settlements');
    } else if (data.action === ACTIONS.CLOSE) {
      window.localStorage.removeItem('runManualWalkthrough');
      window.localStorage.setItem('walkthrough', 'true');
      window.localStorage.setItem('walkthroughCompanies', 'true');
      window.localStorage.setItem('walkthroughPlatforms', 'true');
      window.location.reload();
    }
  };

  return (
    <div className="my-calendar-step-1 calendar-step-settlements calendar-page-component mb-4 mt-5 w-100">
      {runWalkthrough ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run
          disableCloseOnEsc
          disableOverlayClose
          steps={STEPS_CALENDAR}
          styles={joyrideStyles}
        />
      ) : null}
      <h2 className="font-weight-medium font-xl">Calendario de ingresos</h2>
      <div className="d-flex flex-row justify-content-between">
        <Filters
          containerClassName="mt-3 mb-5 w-75"
          typeFilter={FilterType.CALENDAR}
          setSelectedMonth={setSelectedMonth}
          selectMonth={selectMonth}
        />
        <div className="mt-3 mb-5">
          <FetchButton dataType={5} date={selectMonth} />
        </div>
      </div>
      <Calendar
        components={{
          toolbar: (props) => {
            useEffect(() => {
              props.onNavigate('DATE', new Date(selectMonth.format('YYYY-MM-DD')));
            }, [selectMonth]);
            return (
              <>
                <ToolBar
                  labelDate={`${moment(selectMonth).format('MMMM YYYY')}`}
                  loading={presentCashflowSummaryDayLoading}
                  nextMonth={nextMonth}
                  prevMonth={prevMonth}
                  amount={getTotalAmount(presentCashflowSummaryDayList, selectMonth.format('YYYY-MM-DD'))}
                  {...props}
                />
              </>
            );
          },

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dateCellWrapper: (props: { value: Date }, key): Element<CalendarCardProps> => {
            const { value } = props;
            const totaledAmounts = presentCashflowSummaryDayList.find((item) => moment(item.summaryDate).isSame(value, 'day'));

            const collection = getCollectionDataByDay(presentCashflowSummaryDayDetailList, value, totaledAmounts);
            return (
              <CalendarCard
                key={key}
                onClick={selectDay}
                loading={presentCashflowSummaryDayLoading}
                collection={collection}
                selectMonth={selectMonth}
                {...props}
              />
            );
          },
          month: {
            header: CalendarHeader,
          },
        }}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        step={60}
      />
      <div className="calendar-step-move-button">
        <CalendarMoveButton
          collectionReportList={prevCashflowSummaryDayList}
          loading={prevCashflowSummaryDayLoading}
          set="prev"
          onClick={prevMonth}
          selectMonth={selectMonth.format('YYYY-MM-DD')}
          containerClassName="month-button d-inline-flex"
        />
        <CalendarMoveButton
          collectionReportList={nextCashflowSummaryDayList}
          loading={nextCashflowSummaryDayLoading}
          set="next"
          onClick={nextMonth}
          selectMonth={selectMonth.format('YYYY-MM-DD')}
          containerClassName="month-button d-inline-flex float-end"
        />
      </div>
    </div>
  );
};
export default CalendarView;
