import React, { useEffect } from 'react';
import LoaderScreen from '../components/LoaderScreen';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { listCurrencies, setActiveCurrency } from '../redux/slices/currencySlice';

const LoaderWrapper: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { loading: loadingProfile, user } = useAppSelector((state) => state.userProfile);
  const { loading: loadingAuth, messages } = useAppSelector((state) => state.auth);
  const { currencyList, activeCurrency } = useAppSelector((state) => state.currencies);

  useEffect(() => {
    if (user) dispatch(listCurrencies());
  }, [user]);

  useEffect(() => {
    if (currencyList?.length > 0) {
      const filteredCurrencyList = currencyList?.find((currency) => currency.countryCode === user?.Tenant.countryCode);
      if (filteredCurrencyList) dispatch(setActiveCurrency(filteredCurrencyList));
    }
  }, [currencyList]);

  // Set default currency based on countries and
  return (loadingProfile === 'pending' || (messages.logout && loadingAuth === 'pending')) && (!activeCurrency || messages.logout) ? (
    <LoaderScreen className="app-loader" label={loadingAuth ? messages.logout : undefined} />
  ) : (
    <>{children}</>
  );
};
export default LoaderWrapper;
