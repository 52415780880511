import React, { FC, ReactElement } from 'react';
import { Form, Formik } from 'formik';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { useAppSelector } from '../../../redux/hooks';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';

interface MercadoPagoCredentialsFormProps {
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
}

const MercadoPagoCredentialsForm: FC<MercadoPagoCredentialsFormProps> = ({ handleDeletePlatform, handleSubmitForm }) => {
  const { activity } = useAppSelector((state) => state.userProfile);
  return (
    <Formik initialValues={{ password: '', username: '' }} onSubmit={handleSubmitForm}>
      {(): ReactElement => (
        <Form className="row mb-5 d-flex flex-column">
          {activity !== 'contador' ? (
            <div className="col-12">
              <PlatformCredentialsFooterButtons handleDeletePlatform={handleDeletePlatform} redirectingPlatformExternal />
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default MercadoPagoCredentialsForm;
