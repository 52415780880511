/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addPlatformsCredential,
  editPlatformCredential,
  getExternalActivePlatforms,
  getExternalPlatforms,
  getExternalUsedPlatforms,
} from '../../services/externalPlatformService';
import logger from '../../services/loggerService';
import { errorHandlerHelper } from '../../utils/errorHandler';
import {
  AddExternalPlatformResponse,
  ExternalActivePlatform,
  ExternalPlatformCredentials,
  ListExternalActivePlatformsResponse,
  ListExternalPlatformsResponse,
  EditExternalPlatformCredentials,
  ListExternalUsedPlatformsResponse,
} from '../../utils/interfaces';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { ExternalPlatformCredentialsForm, RequestParams } from '../../utils/types';

export const asyncListExternalPlatforms = createAsyncThunk<ListExternalPlatformsResponse, RequestParams>(
  '/platform_external',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatforms = await getExternalPlatforms(params);
      externalPlatforms.data.sort((platform: ExternalPlatformCredentials) => {
        if (platform.order === 2) {
          return -1;
        }
        return 0;
      });
      return externalPlatforms;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncListActiveExternalPlatforms = createAsyncThunk<ListExternalActivePlatformsResponse, RequestParams>(
  'self/platform-external-active',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const externalActivePlatforms = await getExternalActivePlatforms(params);
      return externalActivePlatforms;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncListUsedExternalPlatforms = createAsyncThunk<ListExternalUsedPlatformsResponse, RequestParams>(
  'self/platform-external-used',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const externalUsedPlatforms = await getExternalUsedPlatforms(params);
      return externalUsedPlatforms;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncAddExternalPlatform = createAsyncThunk<AddExternalPlatformResponse, ExternalPlatformCredentialsForm>(
  'platform_external_credential',
  async (platformCredentials, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatform = await addPlatformsCredential(platformCredentials);
      return externalPlatform;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const asyncEditExternalPlatform = createAsyncThunk<ExternalActivePlatform, EditExternalPlatformCredentials>(
  'platform_external_credential/:id',
  async (platform, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatform = await editPlatformCredential(platform);
      return externalPlatform;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

interface ExternalPlatformState {
  externalPlatformList: ExternalPlatformCredentials[] | null;
  externalActivePlatformList: Array<ExternalActivePlatform>;
  externalUsedPlatformList: Array<ExternalActivePlatform>;
  platformPECs: Array<{ id: number; updatedAt: string }> | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: ExternalPlatformState = {
  externalPlatformList: null,
  externalActivePlatformList: [],
  externalUsedPlatformList: [],
  platformPECs: null,
  loading: 'idle',
};

export const externalPlatformsSlice = createSlice({
  name: 'externalPlatforms',
  initialState,
  reducers: {
    removePEC: (state, action: PayloadAction<number>) => {
      if (state.platformPECs) {
        const filteredPECs = state.platformPECs.filter((item) => Number(item.id) !== Number(action.payload));
        state.platformPECs = filteredPECs;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncListExternalPlatforms.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListExternalPlatforms.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListExternalPlatforms.error', action.error);
    });
    builder.addCase(asyncListExternalPlatforms.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.externalPlatformList = action.payload.data;
      logger.info('externalPlatformList', state.externalPlatformList);
    });
    builder.addCase(asyncListActiveExternalPlatforms.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListActiveExternalPlatforms.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListActiveExternalPlatforms.error', action.error);
    });
    builder.addCase(asyncListActiveExternalPlatforms.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.externalActivePlatformList = action.payload.documents.docs;
      logger.info('externalActivePlatformList', state.externalActivePlatformList);
    });
    builder.addCase(asyncListUsedExternalPlatforms.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListUsedExternalPlatforms.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListActiveExternalPlatforms.error', action.error);
    });
    builder.addCase(asyncListUsedExternalPlatforms.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.externalUsedPlatformList = action.payload?.data;
      logger.info('externalActivePlatformList', state.externalUsedPlatformList);
    });
    builder.addCase(asyncAddExternalPlatform.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncAddExternalPlatform.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncAddExternalPlatform.error', action.error);
      // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.FAILED, action.error);
    });
    builder.addCase(asyncAddExternalPlatform.fulfilled, (state, action) => {
      if (state.platformPECs) {
        state.platformPECs = [{ id: action.payload.document.id, updatedAt: action.payload.document.updatedAt }, ...state.platformPECs];
      } else {
        state.platformPECs = [{ id: action.payload.document.id, updatedAt: action.payload.document.updatedAt }];
      }
      state.loading = 'succeeded';
      // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.CREATE.SUCCESS, action.payload.document);
    });
    builder.addCase(asyncEditExternalPlatform.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncEditExternalPlatform.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncEditExternalPlatform.error', action.error);
      // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.UPDATE.FAILED, action.error);
    });
    builder.addCase(asyncEditExternalPlatform.fulfilled, (state, action) => {
      const findedPEC = state.platformPECs?.find((item) => item.id === action.payload.id);
      if (findedPEC) {
        if (state.platformPECs) {
          state.platformPECs = [{ ...findedPEC }, ...state.platformPECs];
        } else {
          state.platformPECs = [{ ...findedPEC }];
        }
      } else {
        state.platformPECs = [{ id: action.payload.id, updatedAt: action.payload.updatedAt }];
      }
      state.loading = 'succeeded';
      // segmentTrack(EVENTS.COMPANY.EXTERNAL_PLATFORM.UPDATE.SUCCESS, action.payload);
    });
  },
});

export const { removePEC } = externalPlatformsSlice.actions;
export default externalPlatformsSlice.reducer;
