import axios from './axiosInstance';

export async function meliAuth(payload: { code: string; tenantId: string; id: string }): Promise<void> {
  await axios.post(`/platform_external/meli-auth`, payload);
}
export async function logMeliError(payload: {
  platformExternalCredentialId: number;
  tenantId: number;
  platformExternalCode: string;
  result: string;
  status: string;
  resultInfo: string;
}): Promise<void> {
  await axios.post(`/platform_external_attempt`, payload);
}
