import * as React from 'react';
import { SVGProps } from '../../utils/types';

function UserIcon({ className = 'color-blue', height = 25, width = 23 }: SVGProps): React.ReactElement {
  return (
    <svg width={width} height={height} viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 7.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm8.713 5.065a6.75 6.75 0 10-8.925 0 9.326 9.326 0 00-4.163 3.345C1.263 18.219.75 20.99.75 23a1.25 1.25 0 102.5 0c0-1.658.437-3.885 1.675-5.66 1.192-1.707 3.184-3.09 6.575-3.09 3.39 0 5.383 1.383 6.575 3.09 1.238 1.775 1.675 4.002 1.675 5.66a1.25 1.25 0 102.5 0c0-2.009-.513-4.781-2.125-7.09a9.326 9.326 0 00-4.162-3.345z"
      />
    </svg>
  );
}

export default UserIcon;
