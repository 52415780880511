import React, { FC, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Title, FetchButton, SalesList } from '../../components';
import { Filters } from '../../containers';
import { useAppSelector } from '../../redux/hooks';
// import useQueryParams from '../../hooks/useQueryParams';
import { paramsToObject, parseFilters } from '../../utils/helper';
// import { segmentTrack } from '../../utils/segment/hooks';
// import { EVENTS } from '../../utils/segment/events';
import { asyncListSales, setLoadingExpandedSale, setSales } from '../../redux/slices/salesSlice';
import errorHandlerHelper from '../../utils/errorHandler';
import { getSale } from '../../services/salesService';
import { FilterType } from '../../utils/types';

const CollectionsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const page = useQueryParams('page') || '1';
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const { salesList } = useAppSelector((state) => state.sales);
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if (page !== '1') segmentTrack(EVENTS.COLLECTIONS.PAGINATE, { page });
    if (activeCurrency?.code) {
      const queryParams = new URLSearchParams(location.search);
      const queryObject = paramsToObject(queryParams);
      const filterableObject = parseFilters({
        ...queryObject,
        currencyCode: activeCurrency?.code,
        lite: 'true',
      });
      dispatch(asyncListSales(filterableObject));
    }
  }, [activeCurrency, location]);

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  const handleExpandAcc = async (id: string): Promise<void> => {
    if (salesList) {
      const entireSale = salesList?.find((sale) => sale.id === id);
      if (entireSale && !entireSale?.Branch) {
        dispatch(setLoadingExpandedSale('pending'));
        const indexSale: number = salesList.indexOf(entireSale);
        try {
          const { data: response } = await getSale(id);
          const newSalesList = [...salesList];
          newSalesList[indexSale] = response.data;
          dispatch(setSales(newSalesList));
        } catch (error) {
          errorHandlerHelper(error, dispatch);
        } finally {
          dispatch(setLoadingExpandedSale('idle'));
        }
      }
    }
  };

  return (
    <div className="collections-page mb-4 mt-5" ref={topRef}>
      <Title size="sm">Ventas</Title>
      <Filters containerClassName="mt-3 mb-3" typeFilter={FilterType.SALES} />
      <div className="me-2 my-2 flex-grow-1 d-flex justify-content-end">
        <FetchButton dataType={2} />
      </div>
      <SalesList handlePaginationCallBack={handlePagination} handleExpandAcc={handleExpandAcc} salesArray={salesList} />
    </div>
  );
};
export default CollectionsPage;
