/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import logger from '../../services/loggerService';
import { getCollectionReport } from '../../services/reportCollectionService';
import { CollectionReport } from '../../utils/interfaces';

interface CollectionReportResponse {
  data: Array<CollectionReport>;
}
interface CollectionReportState {
  collectionReportList: Array<CollectionReport>;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}
export const fetchCollectionReport = createAsyncThunk<CollectionReportResponse, Record<string, unknown>>(
  'collectionReport/getCollectionReport',
  async (params) => {
    const { data: response } = await getCollectionReport(params);
    return response;
  },
);

const initialState: CollectionReportState = {
  collectionReportList: [],
  loading: 'idle',
};

export const collectionReportSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionReport.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchCollectionReport.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('fetchCollectionReport.error', action.error);
    });
    builder.addCase(fetchCollectionReport.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.collectionReportList = action.payload.data;
      logger.info('fetchCollectionReport.success', state.collectionReportList);
    });
  },
});

export default collectionReportSlice.reducer;
