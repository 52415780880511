/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import logoBrubank from '../../assets/images/logo_brubank.png';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { getCashflowMovements } from '../../services/cashflowService';
import { formatNumber } from '../../utils/stringUtils';
import { LoaderSpinner } from '../../components';

const AdvanceSettlementPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { activeCurrency } = useAppSelector((state) => state.currencies);
  const [loading, setLoading] = useState(false);
  const [settlementsList, setSettlementsList] = useState([]);
  const { settlement } = location.state || {};

  const getDayCollection = async (): Promise<void> => {
    if (activeCurrency?.code) {
      try {
        setLoading(true);
        const startDate = moment(settlement?.date).startOf('day').utc().format();
        const endDate = moment(settlement?.date).endOf('day').utc().format();
        const params = {
          'filter[date][between]': `${startDate},${endDate}`,
          pageSize: '3000',
          'filter[currencyCode][eq]': activeCurrency.code,
          page: '1',
        };
        const queryParams = new URLSearchParams(location.search);
        const queryObject = paramsToObject(queryParams);
        const filterableObject = parseFilters({ ...queryObject, ...params });
        const { data: response } = await getCashflowMovements(filterableObject);
        setSettlementsList(response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDayCollection();
  }, [activeCurrency]);

  const handleAdvanceSettlement = (): void => {
    const messageModal = () => (
      <div>
        <div>
          <p className="modal-title">¡Atención!</p>
        </div>
        <div className="mt-5 mb-3 container-body">
          <p className="body-text">¿Estas seguro/a de querer realizar el adelanto de liquidaciones?</p>
        </div>
        <div className="d-flex flex-row justify-content-around">
          <div className="modal-button-cancel" onClick={() => dispatch(removeModalFromList())} role="none">
            <div className="modal-button-text">
              <p>Volver</p>
            </div>
          </div>
          <div className="modal-button-ok" onClick={handleAdvanceSettlement} role="none">
            <div className="modal-button-text">
              <p>Confirmar</p>
            </div>
          </div>
        </div>
        <div>
          <img src={logoBrubank} width={86} height={86} alt="logo_brubank" className="logo-brubank" />
        </div>
      </div>
    );
    dispatch(
      addModalToList({
        hideModalAccept: true,
        body: messageModal(),
      }),
    );
  };

  const getSettlementsList = () =>
    settlementsList.map(
      (singleSettlement: any) =>
        singleSettlement?.movement?.amount > 1 && (
          <>
            <div className="single-settlement-advance">
              <div className="divider">{}</div>
              <div className="settlement-button" onClick={handleAdvanceSettlement} role="none">
                <div className="settlement-button-text">
                  <p>Realizar adelanto</p>
                </div>
              </div>
              <div className="settlement-details px-4">
                <div className="settlement-row">
                  <p className="settlement-title-label">Liquidación</p>
                  <p className="settlement-amount">${formatNumber(singleSettlement?.movement?.amount, true)}</p>
                </div>
                <div className="d-flex flex-row justify-content-between mb-4">
                  <p className="settlement-date-label">Fecha</p>
                  <p className="settlement-date-value-label">{moment(singleSettlement?.movement?.date).format('DD-MM-YYYY')}</p>
                </div>
                <div className="settlement-row">
                  <p className="settlement-label">Costo de adelantamiento</p>
                  <p className="settlement-value advance-cost">${formatNumber((singleSettlement?.movement?.amount / 100) * 2.21, true)}</p>
                </div>
                <div className="settlement-row">
                  <p className="settlement-label">Neto de adelanto</p>
                  <p className="settlement-value advance-net">
                    ${formatNumber(singleSettlement?.movement?.amount - (singleSettlement?.movement?.amount / 100) * 2.21, true)}
                  </p>
                </div>
                <div className="settlement-row mb-4">
                  <p className="settlement-label">Adelanto en días</p>
                  <p className="settlement-value">X</p>
                </div>
              </div>
            </div>
          </>
        ),
    );

  return (
    <>
      <div className="advance-settlement-page">
        <div className="header-advance-settlement">
          <div className="header-text">
            <p className="header-total">Total</p>
            <p className="header-liquidation">Liquidación</p>
          </div>
          <p className="header-amount">{formatNumber(settlement?.platforms[0]?.amount, true)}</p>
        </div>
        <div className="details">
          <div className="details-row">
            <p className="details-label">Fecha</p>
            <p className="details-value">{moment(settlement?.date).format('DD-MM-YYYY')}</p>
          </div>
          <div className="details-row">
            <p className="details-label">Tasa efectiva</p>
            <p className="details-value rate">2,21%</p>
          </div>
        </div>
        {loading ? (
          <div className="d-flex flex-row justify-content-center">
            <LoaderSpinner className="mx-auto mt-5 loader-spinner-webview" />
          </div>
        ) : (
          <>
            <div className="total-advance">
              <div className="divider">{}</div>
              <div className="total-advance-ask" onClick={handleAdvanceSettlement} role="none">
                <div className="total-advance-button-text-ask">
                  <p>¿Desea realizar un adelanto total de la fecha?</p>
                </div>
              </div>
              <div className="total-advance-button" onClick={handleAdvanceSettlement} role="none">
                <div className="total-advance-button-text">
                  <p>Realizar adelanto total</p>
                </div>
              </div>
              <div className="total-advance-details px-4">
                <div className="total-advance-row">
                  <p className="total-advance-label">Costo de adelantamiento</p>
                  <p className="total-advance-value">${formatNumber((settlement?.platforms[0]?.amount / 100) * 2.21, true)}</p>
                </div>
                <div className="total-advance-row">
                  <p className="total-advance-label">Neto de adelanto</p>
                  <p className="total-advance-value">
                    ${formatNumber(settlement?.platforms[0]?.amount - (settlement?.platforms[0]?.amount / 100) * 2.21, true)}
                  </p>
                </div>
                <div className="total-advance-row">
                  <p className="total-advance-label">Adelanto en días</p>
                  <p className="settlement-value">X</p>
                </div>
              </div>
            </div>
            {getSettlementsList()}
          </>
        )}
        <div className="powered-by me-3">
          <p>powered by</p>
          <p className="brand">nubceo</p>
        </div>
      </div>
    </>
  );
};

export default AdvanceSettlementPage;
