import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '../redux/hooks';

const PrivateRoute: React.FC = ({ children }) => {
  const { user } = useAppSelector((state) => state.userProfile);
  const location = useLocation();

  if (!children) return null;
  if (user && !user.onboardingComplete && user.Tenant.activityCode !== 'contador') return <Navigate to="/onboarding" />;
  return user ? <>{children}</> : <Navigate to="/login" state={{ referrer: location }} />;
};

export default PrivateRoute;
