import { AxiosPromise } from 'axios';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';
import { FilterParams } from '../utils/types';

export const getCollections = (params: FilterParams): AxiosPromise => axios.get('/collection', { params: { ...params } });

export const getCollectionsXLSX = (params: FilterParams): AxiosPromise =>
  axios.get('/collection/generate-report/xlsx', { params: { ...params } });

export const getSummaryCollectionByPe = (id: number, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/collections/summary-by-pe`, { params: { ...params } });
