import { ChangeEvent, ReactNode } from 'react';
import { URLSearchParams } from 'url';
import { User } from './interfaces';

export type SidebarItem = {
  title: string;
  path: string | null;
  icon: ReactNode;
  iconOpened?: boolean;
  iconClosed?: boolean;
  subnav?: SidebarItem[];
};

export type SidebarLinkProps = {
  onClick: () => void;
  item: SidebarItem;
  openMenu: boolean;
};

export type MeliProps = {
  code: null | string;
  state: null | string;
  error: null | string;
};

export type TiendaNubeProps = {
  code: null | string;
  state: null | string;
  error: null | string;
};
export type InputComponent = {
  onChange?: (event: ChangeEvent<HTMLInputElement> | any) => void;
  onClick?: (event: MouseEvent | any, ...rest: any[]) => void;
  name?: string;
  value?: string | number | any | undefined;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | any;
  autoComplete?: string;
};

export type TextItem = {
  children: ReactNode;
  className?: string;
  color?: string;
  size?: string;
  cursive?: boolean;
  capitalize?: boolean;
  weight?: string;
};

export type SVGProps = {
  fill?: string;
  className?: string;
  height?: string | number;
  width?: string | number;
  onClick?: () => void;
};

export type LoggerLevels = {
  DEBUG: string;
  INFO: string;
  WARN: string;
  ERROR: string;
};

export type FormFieldType = {
  field: {
    name: string;
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

export type CompleteOnBoardingResponse = {
  data: boolean;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
  status: number;
};

export type RequestParams = Record<string, unknown> | undefined | FilterParams;

export type CallbackFunction = (...args: any[]) => void;
export type CallbackPromise = (...args: any[]) => Promise<void>;

export type FilterFormikKeys = 'companyId' | 'platformCode' | 'startDate' | 'endDate' | 'included_settlement' | 'type';

export interface SortParams {
  'sort[createdAt][desc]': '';
}
export interface FilterParams {
  page?: string;
  pageSize?: string;

  'filter[date][between]'?: string;
  'filter[paymentDate][between]'?: string;
  'filter[date][gte]'?: string;
  'filter[paymentDate][gte]'?: string;
  'filter[date][lte]'?: string;
  'filter[paymentDate][lte]'?: string;
  'filter[dateSince]'?: string;
  'filter[dateUntil]'?: string;
  'filter[presentedDate][gte]'?: string;
  'filter[presentedDate][lte]'?: string;

  'filter[companyId][eq]'?: string;
  'filter[branchId][eq]'?: string;
  'filter[companyId][in]'?: string;
  'filter[branchId][in]'?: string;
  'filter[platform_external_code][eq]'?: string;
  'filter[included_settlement]'?: string;
  'filter[isProjected][eq]'?: string;
  'filter[type][in]'?: string;
  'filter[currencyCode][eq]'?: string;
  'filter[taxTypeCode][in]'?: string;
  'filter[platformExternalCode][eq]'?: string;
  'filter[name][iLike]'?: string;
  'filter[lite]'?: string;
}

export interface URLFilterParams extends FilterParams, URLSearchParams {}

export type CompanyOnBoardingPost = {
  name: string;
  countryCode: string;
};

export type ResponseDispatchAction<P> = {
  type: string;
  payload: P;
};

export type ExternalPlatformCredentialsForm = {
  platformExternalCode: string | number;
  companyId?: number | string;
  omitReason?: string;
  username?: string;
  password?: string;
  codeAux?: string;
  codeAux2?: string;
  codeAux3?: string;
  codeAux4?: string;
};

export type LoadingStateType = 'idle' | 'pending' | 'succeeded' | 'failed';

export type ReportsType = {
  User: User;
  createdAt: string;
  id: string;
  userId: string;
  fileUrl: string;
  filters: string;
  type: 'sale' | 'collection';
  status: 'completed' | 'in_process';
};

export type ExpenseReportsType = {
  entity: string;
  typeCode: string;
  typeName: string;
  amount: number;
};

export type PieGraphDataType = {
  labels: string[];
  datasets: { data: number[]; backgroundColor: string[]; borderWidth: number }[];
};

export type ExpenseReportsSummaryType = {
  typeName: string;
  amount: number;
  entity: string;
};

export type IdentifySegmentParams = [
  userId: string | number,
  traits?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callbackFunction?: CallbackFunction,
];

export type GroupSegmentParams = [
  tenantId: string | number,
  traits?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callbackFunction?: CallbackFunction,
];

export type TrackSegmentParams<S = unknown> = [
  eventName: string,
  traits?: S,
  options?: Record<string, unknown>,
  callbackFunction?: CallbackFunction,
];
export enum ExternalPlatformType {
  cash = 'cash',
  card = 'card',
  erp = 'erp',
  delivery = 'delivery',
  wallet = 'wallet',
  ecommerce = 'ecommerce',
}
export enum ExternalPlatformBranchType {
  branch = 'branch',
  businessNumber = 'businessNumber',
}

export enum CashflowType {
  collection = 'collection',
  payment = 'payment',
}

export enum StorageVars {
  tokenExpired = 'tokenExpired',
  daysRemainingExpiredPassword = 'daysRemainingExpiredPassword',
  token = 'token',
  tokenRefresh = 'tokenRefresh',
  completePECCredentialsClient = 'completePECCredentialsClient',
  omittedFeedback = 'omittedFeedback',
  redirectFromTiendanube = 'redirectFromTiendanube',
  showMessageFiservStorage = 'showMessageFiservStorage',
}

export enum FilterType {
  CALENDAR = 'calendar',
  EXPENSE_REPORTS = 'expense-reports',
  SETTLEMENT = 'settlement',
  SALES = 'sales',
  WORKPAPERS = 'workPapers',
}
