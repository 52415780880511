/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Branch } from '../../utils/interfaces';
import { FilterParams, LoadingStateType } from '../../utils/types';
import logger from '../../services/loggerService';
import { errorHandlerHelper } from '../../utils/errorHandler';
import { listBranches } from '../../services/branchService';

interface BranchState {
  branchesList: Array<Branch>;
  branch?: Branch | null;
  loading: LoadingStateType;
  pages: number;
  total: number;
}

export const asyncListBranches = createAsyncThunk<any, FilterParams | undefined>(
  'branch/listBranches',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const branchesList = await listBranches(params);
      return branchesList;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

const initialState: BranchState = {
  branchesList: [],
  branch: null,
  loading: 'idle',
  pages: 1,
  total: 1,
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncListBranches.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListBranches.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListBranches.error', action.error);
    });
    builder.addCase(asyncListBranches.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
      state.branchesList = action.payload.data;
    });
  },
});

export default branchSlice.reducer;
