import React, { FC, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Field, Formik, FormikValues } from 'formik';
import { cleanNullValues, paramsToObject } from '../../utils/helper';
import { FormFieldType } from '../../utils/types';
import { ScreenType, clearAllFilters, getInitialValues, handleSubmit } from '../../utils/helperFilters';
import { DateInputComponent } from '../inputs';
import { MessageToApplyFilters } from '../../utils/constants';
import { FormButtonComponent } from '../buttons';
import FilterTabComponent from '../FilterTabComponent';

const FilterWorkPapers = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;
  const URLFilters: Record<string, string> = paramsToObject(new URLSearchParams(location.search));

  const applyFilters = (values: FormikValues): void => {
    handleSubmit(values, navigate, url);
  };

  const deleteFilterTab = (values: FormikValues, property: string): void => {
    const newValues = { ...values, [property]: null };
    const notNullValues = cleanNullValues(newValues);
    const params = new URLSearchParams(notNullValues).toString();
    navigate(params ? `?${params}` : url);
  };

  const initialValues = getInitialValues(URLFilters, false);

  return (
    <div className="border-round flex-grow-1">
      <Formik onSubmit={applyFilters} initialValues={initialValues}>
        {({ values, setFieldValue }): ReturnType<FC> => (
          <div className="d-flex flex-wrap col-12 col-md-10 col-lg-9 mt-2">
            <Field name="dateSince">
              {({ field }: FormFieldType): ReactNode => (
                <DateInputComponent
                  label="Fecha desde"
                  containerClassName="input"
                  {...field}
                  value={values.dateSince ?? ''}
                  max={values.dateUntil ?? undefined}
                />
              )}
            </Field>
            <Field name="dateUntil">
              {({ field }: FormFieldType): ReactNode => (
                <DateInputComponent
                  label="Fecha hasta"
                  containerClassName="input"
                  {...field}
                  value={values.dateUntil ?? ''}
                  min={values.dateSince ?? undefined}
                />
              )}
            </Field>
            <>
              <div className="w-100">
                {Object.values(values).find((elem) => elem !== null) ? <MessageToApplyFilters /> : null}
                <FormButtonComponent className="mt-3" onClick={(): void => applyFilters(values)}>
                  Aplicar filtros
                </FormButtonComponent>
              </div>
              <FormButtonComponent
                onClick={(): void => {
                  clearAllFilters(ScreenType.WORK_PAPERS, setFieldValue, initialValues, navigate);
                }}
                className="mt-3"
              >
                Limpiar filtros
              </FormButtonComponent>
              {Object.keys(URLFilters).filter((filter) => filter !== 'page').length
                ? Object.values(URLFilters).find((value) => value) && (
                    <div className="w-100 p-0 mt-5 d-flex flex-column">
                      <p className="flex-grow-1 font-weight-bold">Filtros Aplicados</p>
                      <div className="d-flex flex-wrap">
                        {Object.keys(URLFilters).map((property) =>
                          URLFilters[property] ? (
                            <FilterTabComponent
                              property={property}
                              key={property}
                              array={URLFilters}
                              onClick={(): void => {
                                deleteFilterTab(values, property);
                                setFieldValue(property, null);
                              }}
                            />
                          ) : null,
                        )}
                      </div>
                    </div>
                  )
                : null}
            </>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default FilterWorkPapers;
