import React from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import countries from './countries';
import { SelectInputComponent } from '..';
import { setActiveCurrency } from '../../redux/slices/currencySlice';
import { Currency } from '../../utils/interfaces';
import { addModalToList } from '../../redux/slices/modalSlice';

const CurrencySelector: React.FC = () => {
  const { activeCurrency, currencyList } = useAppSelector((state) => state.currencies);
  const dispatch = useAppDispatch();
  const confirmChangeCurrency = (currency: Currency): void => {
    dispatch(setActiveCurrency(currency));
  };
  const handleCurrency = (value: string): void => {
    const currency = currencyList.find((c) => c.code === value);
    if (currency) {
      if (currency?.code === activeCurrency?.code) {
        dispatch(
          addModalToList({
            body: `Seleccioná un valor diferente a ${currency?.code} (${currency?.name}) ya es la moneda en uso`,
            required: true,
            cancelButton: {
              buttonText: 'Continuar',
            },
          }),
        );
      } else {
        dispatch(
          addModalToList({
            body: `Sólo vas a ver la información registrada en la moneda ${currency?.name} (${currency?.code}). ¿Querés continuar?`,
            required: true,
            okButton: {
              buttonText: 'Continuar',
              onClick: async (): Promise<void> => {
                confirmChangeCurrency(currency);
              },
            },
            cancelButton: {
              buttonText: 'Cancelar',
            },
          }),
        );
      }
    }
  };
  return (
    <>
      {activeCurrency?.countryCode && (
        <>
          <SelectInputComponent
            label="Selección de moneda:"
            style={{ maxWidth: 90 }}
            name="currency"
            onOptionClick={(name, value): void => handleCurrency(value)}
            containerClassName=""
            options={currencyList.map((item) => ({
              id: item.code,
              image: (
                <img
                  src={countries[countries.findIndex((elem) => elem.code === item?.countryCode)]?.imageFlag}
                  alt={item.code}
                  style={{
                    height: 20,
                    width: 30,
                  }}
                />
              ),
              name: item.code,
              value: item.code,
            }))}
            value={activeCurrency.code}
            image={
              <img
                src={countries[countries.findIndex((elem) => elem.code === activeCurrency?.countryCode)]?.imageFlag}
                alt={activeCurrency.code}
                style={{
                  height: 20,
                  width: 30,
                }}
              />
            }
          />
        </>
      )}
    </>
  );
};
export default CurrencySelector;
