import * as React from 'react';
import { SVGProps } from '../../utils/types';

function CloseIconSVG({ className, height, width, onClick }: SVGProps): React.ReactElement {
  return (
    <svg width={width || 50} height={height || 50} className={className} onClick={onClick} viewBox="0 0 50 50">
      <path d="M40.932 8.537L18.126 31.263l-8.772-8.72a5.34 5.34 0 00-7.797-.3 5.282 5.282 0 00-1.553 3.941 5.26 5.26 0 001.854 3.81l12.493 12.473A5.318 5.318 0 0018.126 44a5.332 5.332 0 003.72-1.533l26.582-26.425A5.282 5.282 0 0050 12.289a5.26 5.26 0 00-1.572-3.752A5.332 5.332 0 0044.68 7a5.332 5.332 0 00-3.748 1.537z" />
    </svg>
  );
}

export default CloseIconSVG;
