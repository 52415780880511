import errorCodes from './errorCodes.json';
// TO ADD A NEW ERROR MESSAGE IT NEEDS TO BE ADDED IN errorCodes.json and HERE
const errorCodesBody = [
  {
    code: 'access-denied',
    title: errorCodes['access-denied'].title,
    message: errorCodes['access-denied'].message,
  },
  {
    code: 'permission-denied',
    title: errorCodes['permission-denied'].title,
    message: errorCodes['permission-denied'].message,
  },
];
export default errorCodesBody;
