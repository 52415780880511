import React, { FC, ReactElement } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInputComponent } from '../../inputs';
import { FormFieldType } from '../../../utils/types';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalActivePlatform } from '../../../utils/interfaces';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';

interface GetNetCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
}

const GetnetCredentialsForm: FC<GetNetCredentialsFormProps> = ({
  canEditPlatform,
  handleDeletePlatform,
  handleSubmitForm,
  currentValues,
}) => {
  const validateForm = Yup.object().shape({
    username: Yup.string().required('Ingresá tu código de comercio.'),
  });

  const initialValues = {
    password: '',
    username: currentValues?.username || '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitForm}>
      {({ errors, isSubmitting, isValid, dirty }): ReactElement => (
        <Form className="row mb-5 d-flex flex-column">
          <div className="container-input col-12 col-xl-6">
            <Field name="username">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  label="Código de Comercio"
                  placeholder="Ingresá el código de comercio"
                  errorMessage={field.value && errors.username}
                  required
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input background-yellow-30 my-3 w-50">
            <div className="notification warning">
              <div className="notification-content">
                <div className="font-xs mx-4">
                  Asegúrate de haber coordinado con <b>nubceo</b> y <b>Getnet</b> la habilitación de esta integración.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <PlatformCredentialsFooterButtons
              disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
              handleDeletePlatform={handleDeletePlatform}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GetnetCredentialsForm;
