import React, { FC, ReactElement } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { PasswordInputComponent, TextInputComponent } from '../../inputs';
import { FormFieldType } from '../../../utils/types';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalActivePlatform } from '../../../utils/interfaces';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';

interface ColpatriaCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
}

const ColpatriaCredentialsForm: FC<ColpatriaCredentialsFormProps> = ({
  canEditPlatform,
  handleDeletePlatform,
  handleSubmitForm,
  currentValues,
}) => {
  const validateForm = Yup.object().shape({
    username: Yup.string().required('Tenés que escribir un numero de usuario.'),
    password: Yup.string().required('Tenés que escribir una contraseña.'),
  });

  const initialValues = {
    username: currentValues?.username || '',
    password: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitForm}>
      {({ errors, isSubmitting, isValid, dirty }): ReactElement => (
        <Form className="row mb-5 d-flex flex-column">
          <div className="container-input col-12 col-xl-6">
            <Field name="username">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  label="Usuario"
                  placeholder="Ingresá un número de usuario"
                  errorMessage={field.value && errors.username}
                  required
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="password">
              {({ field }: FormFieldType): ReactElement => (
                <PasswordInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.password}
                  placeholder="Ingresá la contraseña"
                  label="Contraseña"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="col-12">
            <PlatformCredentialsFooterButtons
              disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
              handleDeletePlatform={handleDeletePlatform}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ColpatriaCredentialsForm;
