/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllClients,
  getClientAccessRequests,
  getClientsAccess,
  getClientExternalPlatformsV2,
  addClientPlatformCredential,
  editClientPlatformCredential,
  getClientCompanyV2,
} from '../../services/clientsService';
import logger from '../../services/loggerService';
import { errorHandlerHelper } from '../../utils/errorHandler';
import {
  Client,
  AddClientExternalPlatform,
  ExternalActivePlatform,
  ExternalPlatformCredentials,
  EditClientExternalPlatform,
  Company,
} from '../../utils/interfaces';
import { FilterParams } from '../../utils/types';

export const asyncListClients = createAsyncThunk<any, FilterParams>(
  'clients/listClients',
  async (_params, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllClients(_params);
      return response;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncListClientsAccessRequest = createAsyncThunk<Array<Client>>(
  'self/tenant/business-entities',
  async (_params, { rejectWithValue, dispatch }) => {
    try {
      const accessRequests = await getClientAccessRequests();
      return accessRequests;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncListClientsAccess = createAsyncThunk<Array<Client>>(
  'self/tenant/business-entities',
  async (_params, { rejectWithValue, dispatch }) => {
    try {
      const accesses = await getClientsAccess();
      return accesses;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncListClientExternalPlatforms = createAsyncThunk<ExternalActivePlatform[], string | number>(
  'client/external_platforms',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatforms = await getClientExternalPlatformsV2(id);
      return externalPlatforms;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncListClientCompanies = createAsyncThunk<Company[], string>(
  'client/companies',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const companies = await getClientCompanyV2(id);
      return companies;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncClientAddExternalPlatform = createAsyncThunk<ExternalPlatformCredentials, AddClientExternalPlatform>(
  'client/platform_external_credential',
  async (platformCredentials, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatform = await addClientPlatformCredential(platformCredentials);
      return externalPlatform;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const asyncClientEditExternalPlatform = createAsyncThunk<ExternalPlatformCredentials, EditClientExternalPlatform>(
  'client/platform_external_credential/:id',
  async (platform, { rejectWithValue, dispatch }) => {
    try {
      const externalPlatform = await editClientPlatformCredential(platform);
      return externalPlatform;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);
export const setSingleClient = createAsyncThunk<Client, Client>('client/getSingleClient', (client, { rejectWithValue, dispatch }) => {
  try {
    return client;
  } catch (error) {
    errorHandlerHelper(error, dispatch);
    return rejectWithValue(error);
  }
});
interface ClientsState {
  singleClient: Client | null;
  clientsList: Array<Client>;
  clientExternalPlatforms: ExternalActivePlatform[];
  clientCompanies: Company[];
  platformPECs: Array<{ id: number; updatedAt: string }> | null;
  loading: 'pending' | 'succeeded' | 'failed';
  loadingExternalPlatforms: 'pending' | 'succeeded' | 'failed';
  pages: number;
  total: number;
}
const initialState: ClientsState = {
  singleClient: null,
  clientsList: [],
  clientExternalPlatforms: [],
  clientCompanies: [],
  platformPECs: null,
  loading: 'pending',
  loadingExternalPlatforms: 'pending',
  pages: 1,
  total: 1,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    removeClientData: (state) => {
      state.clientExternalPlatforms = [];
      state.singleClient = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSingleClient.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(setSingleClient.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncSetSingleClient.error', action.error);
    });
    builder.addCase(setSingleClient.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.singleClient = action.payload;
      logger.info('asyncSetSingleClient.successs', action.payload);
    });
    builder.addCase(asyncListClients.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListClients.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListClients.error', action.error);
    });
    builder.addCase(asyncListClients.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.clientsList = action.payload.data;
      state.pages = action.payload.meta.pages;
      state.total = action.payload.meta.total;
      logger.info('asyncListClients.successs', action.payload);
    });
    builder.addCase(asyncListClientExternalPlatforms.pending, (state) => {
      state.loadingExternalPlatforms = 'pending';
    });
    builder.addCase(asyncListClientExternalPlatforms.rejected, (state, action) => {
      state.loadingExternalPlatforms = 'failed';
      logger.error('asyncListClientExternalPlatforms.error', action.error);
    });
    builder.addCase(asyncListClientExternalPlatforms.fulfilled, (state, action) => {
      state.loadingExternalPlatforms = 'succeeded';
      state.clientExternalPlatforms = action.payload;
    });
    builder.addCase(asyncListClientCompanies.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListClientCompanies.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncListClientCompanies.error', action.error);
    });
    builder.addCase(asyncListClientCompanies.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.clientCompanies = action.payload;
    });
    builder.addCase(asyncClientAddExternalPlatform.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncClientAddExternalPlatform.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncClientAddExternalPlatform.error', action.error);
    });
    builder.addCase(asyncClientAddExternalPlatform.fulfilled, (state, action) => {
      if (state.platformPECs) {
        state.platformPECs = [{ id: action.payload.id, updatedAt: action.payload.updatedAt }, ...state.platformPECs];
      } else {
        state.platformPECs = [{ id: action.payload.id, updatedAt: action.payload.updatedAt }];
      }
      state.loading = 'succeeded';
    });
    builder.addCase(asyncClientEditExternalPlatform.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncClientEditExternalPlatform.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('asyncClientEditExternalPlatform.error', action.error);
    });
    builder.addCase(asyncClientEditExternalPlatform.fulfilled, (state, action) => {
      const findedPEC = state.platformPECs?.find((item) => item.id === action.payload.id);
      if (findedPEC) {
        if (state.platformPECs) {
          state.platformPECs = [{ ...findedPEC }, ...state.platformPECs];
        } else {
          state.platformPECs = [{ ...findedPEC }];
        }
      } else {
        state.platformPECs = [{ id: action.payload.id, updatedAt: action.payload.updatedAt }];
      }
      state.loading = 'succeeded';
    });
  },
});
export const { removeClientData } = clientsSlice.actions;
export default clientsSlice.reducer;
