import React, { useState } from 'react';
import { FaFrown, FaMeh, FaSmile } from 'react-icons/fa';
import variables from '../../scss/_exports.module.scss';
import SendSVG from '../../assets/svgComponents/Send';
import { useAppDispatch } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { postUserFeedback } from '../../services/userService';
import { handleKeyDownDefault } from '../../utils/helper';
import { ActionButtonComponent } from '../buttons';
import { TextInputComponent } from '../inputs';
import { UserFeedback } from '../../utils/interfaces';
import { CloseIconSVG } from '../../assets/svgComponents';
import { StorageVars } from '../../utils/types';

const ModalFeedback: React.FC = () => {
  const dispatch = useAppDispatch();
  const [feedback, setFeedback] = useState('');
  const [rate, setRate] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const handlePress = (newRate: any, button: any) => {
    setRate((prevRate) => (prevRate === newRate ? null : newRate));
    setActiveButton((prevButton) => (prevButton === button ? null : button));
  };

  const handleSubmit = (): void => {
    setActiveButton(null);
    const userFeedback: UserFeedback = {
      score: rate,
      featureRequest: feedback,
      platformVariant: 'web',
    };
    postUserFeedback(userFeedback)
      .catch(() => {
        setTimeout(() => {
          dispatch(
            addModalToList({
              body: 'Lo sentimos, no pudimos guardar tu feedback.',
              okButton: {
                buttonText: 'Aceptar',
                onClick: async () => {
                  dispatch(removeModalFromList());
                },
              },
            }),
          );
          localStorage.setItem(StorageVars.omittedFeedback, new Date().toString());
        }, 500);
      })
      .finally(() => dispatch(removeModalFromList()));
  };

  return (
    <div className="background-gray-60 modal-wrapper-feedback" onKeyDown={handleKeyDownDefault} role="none" id="modal">
      <div className="modal-popUp border-round d-flex flex-column position-relative">
        <div className="container-feedback">
          <button className="align-self-end close-button" type="button" onClick={() => dispatch(removeModalFromList())}>
            <CloseIconSVG className="me-1 color-blue" />
          </button>
          <div className="titleText">
            <p className="font-lg">¿Qué opinás de nubceo hasta ahora?</p>
          </div>
          <br />
          <div className="d-flex justify-content-around">
            <div onClick={(): void => handlePress(1, 1)} role="none">
              <div className="p-3 ">
                <FaFrown
                  size={25}
                  color={variables.colorRedTransparent50}
                  className={`icon-face ${activeButton === 1 && 'active-button'}`}
                />
              </div>
            </div>
            <div onClick={(): void => handlePress(5, 2)} role="none">
              <div className="p-3">
                <FaMeh size={25} color={variables.colorYellow} className={`icon-face ${activeButton === 2 && 'active-button'}`} />
              </div>
            </div>
            <div onClick={(): void => handlePress(10, 3)} role="none">
              <div className="p-3">
                <FaSmile size={25} color={variables.colorGreen} className={`icon-face ${activeButton === 3 && 'active-button'}`} />
              </div>
            </div>
          </div>
          <p className="label-input">¿Qué más te gustaría ver en la app? ¿Tenés alguna idea o sugerencia?</p>
          <div className="input-container">
            <TextInputComponent
              containerClassName="w-100"
              className="input"
              placeholder="Podés dejar tu comentario..."
              type="text"
              value={feedback}
              onChange={(ev): void => setFeedback(ev.target.value)}
              defaultValue={setFeedback}
              autoComplete="new-email"
            />
          </div>
          <div className="button-container">
            <ActionButtonComponent
              text="Enviar"
              onClick={(): void => handleSubmit()}
              icon={<SendSVG width={16} height={16} className="color-white button-send" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFeedback;
