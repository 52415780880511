import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import SingleClientContainer from '../../containers/Clients/SingleClientContainer';
import ClientsContainer from '../../containers/Clients/ClientsContainer';
import { LoaderScreen, SalesList, SettlementsList } from '../../components';
import { ExternalPlatformCredentialsForm } from '..';
import { paramsToObject, parseFilters } from '../../utils/helper';
import { asyncListClients } from '../../redux/slices/clientsSlice';

const ClientsPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { clientsList, loading, pages, total } = useAppSelector((state) => state.clients);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryObject = paramsToObject(queryParams);
    const filterableObject = parseFilters({
      ...queryObject,
    });
    dispatch(asyncListClients(filterableObject));
  }, [location]);

  if (loading === 'pending') {
    return <LoaderScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<ClientsContainer clientsList={clientsList} pages={pages} total={total} />} />
      <Route path="new" element={<SingleClientContainer />} />
      <Route path=":id/external-platform/new" element={<ExternalPlatformCredentialsForm />} />
      <Route path=":id/sales-client" element={<SalesList />} />
      <Route path=":id/settlements-client" element={<SettlementsList />} />
      <Route path=":id/external-platform/:id" element={<ExternalPlatformCredentialsForm />} />
    </Routes>
  );
};

export default ClientsPage;
