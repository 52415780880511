import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { AxiosError, AxiosPromise } from 'axios';
import logger from '../services/loggerService';
import { ModalType, addModalToList } from '../redux/slices/modalSlice';
import errorImage from '../assets/images/error.png';
import apiErrors from './apiErrors';

const errorParser = (name: string): ModalType => ({
  body: apiErrors[name]?.body || '¡Ups! Tuvimos un problema.',
  required: apiErrors[name]?.required || false,
  pictureUrl: apiErrors[name]?.pictureUrl || errorImage,
  className: apiErrors[name]?.className || undefined,
  hideModalAccept: apiErrors[name]?.hideModalAccept || false,
});

export const errorHandlerHelper = (error: AxiosError, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): void => {
  if (error?.response?.data.name === 'INVALID_CREDENTIALS') {
    const parsedError = errorParser(error?.response?.data.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.name === 'UNAUTHORIZED') {
    const parsedError = errorParser(error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.name === 'INVALID_CREDENTIALS') {
    const parsedError = errorParser(error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.name === 'PREVIOUSLY_USED_PASSWORD') {
    const parsedError = errorParser(error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.name === 'error') {
    const parsedError = errorParser(error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.name === 'BLOCKED_USER') {
    const parsedError = errorParser(error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.response?.data.name === 'EMAIL_USED' || error?.name === 'EMAIL_USED') {
    const parsedError = errorParser(error?.name || error?.response?.data.name);
    dispatch(addModalToList(parsedError));
  }
  if (error?.response?.data.name === 'COMPANY_SAME_COUNTRY_AND_TAXCODE' || error?.name === 'COMPANY_SAME_COUNTRY_AND_TAXCODE') {
    const parsedError = errorParser(error?.response?.data.name || error?.name);
    dispatch(addModalToList(parsedError));
  }
  if (
    error?.response?.data.name === 'PLATFORM_EXTERNAL_CREDENTIAL_ALREADY_EXISTS' ||
    error?.name === 'PLATFORM_EXTERNAL_CREDENTIAL_ALREADY_EXISTS'
  ) {
    const parsedError = errorParser(error?.response?.data.name || error?.name);
    dispatch(addModalToList(parsedError));
  }
  return logger.error('errorhandlerHelper', error);
};

/**
 * This function will alert the user about errors ocurring in the server
 * @param apifunction Axios call to server
 * @param dispatch appDispatch for dispatching modal
 */
export const errorHandlerHelperV2 = async <T>(
  apifunction: () => AxiosPromise,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<T> => {
  try {
    const { data } = await apifunction();
    return data;
  } catch (error: any) {
    let errorName = error?.name;
    const walkthrough = !!(window.localStorage.getItem('walkthrough') || window.localStorage.getItem('runManualWalkthrough'));
    if (error.name === 'Error') {
      errorName = error.response.data.name;
    }
    const parsedError = errorParser(errorName);
    if (walkthrough) {
      dispatch(addModalToList(parsedError));
    }
    throw error;
  }
};

export default errorHandlerHelper;
