import React, { FC, ReactElement } from 'react';
import { CurrencyCode, CurrencySymbol } from '../../utils/interfaces';
import { decimalFormat } from '../../utils/stringUtils';
import { ExpenseReportsSummaryType, ExpenseReportsType } from '../../utils/types';

interface TableComponentItem {
  reportList?: ExpenseReportsType[];
  summaryList?: ExpenseReportsSummaryType[];
  currency?: string | null;
}

const TableComponent: FC<TableComponentItem> = ({ reportList, summaryList, currency }) => {
  const row = (data?: ExpenseReportsType[]): ReactElement<ExpenseReportsType> => {
    const rows: any = [];
    if (data)
      data.map(
        (item): ReactElement =>
          rows.push(
            <tr>
              <td className="td-summary">{item.typeName}</td>
              <td className="amount td-summary">
                {currency && currency === CurrencyCode.USD ? CurrencySymbol.USD : CurrencySymbol.AR} {decimalFormat(item.amount)}
              </td>
            </tr>,
          ),
      );
    return rows;
  };

  const rowSummary = (data?: ExpenseReportsSummaryType[]): ReactElement<ExpenseReportsType> => {
    const rows: any = [];
    if (data)
      data.map(
        (item): ReactElement =>
          rows.push(
            <tr>
              <td className="td-summary">{item.typeName}</td>
              <td className="amount td-summary">
                {currency && currency === CurrencyCode.USD ? CurrencySymbol.USD : CurrencySymbol.AR} {decimalFormat(item.amount)}
              </td>
            </tr>,
          ),
      );
    return rows;
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th className="th-summary">Concepto</th>
            <th className="th-summary">Monto Neto</th>
          </tr>
        </thead>
        {reportList?.length ? <tbody>{row(reportList)}</tbody> : <tbody>{rowSummary(summaryList)}</tbody>}
      </table>
    </div>
  );
};

export default TableComponent;
