import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { listNotifications } from '../../services/notificationsService';
import { errorHandlerHelperV2 } from '../../utils/errorHandler';
import { FilterParams, LoadingStateType, SortParams } from '../../utils/types';
import logger from '../../services/loggerService';

export type NotificationPopUpComponent = {
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
  title: string;
  body: string;
  link?: string;
  interactive?: boolean;
  absolute?: boolean;
};

export type NotificationType = {
  createdAt: string;
  id: string;
  medium: string;
  message: string;
  messageHtml: string | null;
  response: string;
  status: string;
  template: string | null;
  tenantId: string;
  title: string;
  cta?: string;
  to: string;
  typeNotification: string;
  updatedAt: string;
  userId: string;
};

export type NotificationsState = {
  pages: number;
  notificationsList: Array<NotificationType>;
  notificationsPopUpList: Array<NotificationPopUpComponent>;
  loading: LoadingStateType;
};

interface NotificationResponse {
  data: Array<NotificationType>;
  meta: {
    count: number;
    pages: number;
    total: number;
  };
}

const initialState: NotificationsState = {
  notificationsList: [],
  notificationsPopUpList: [],
  loading: 'idle',
  pages: 0,
};

export const asyncListNotifications = createAsyncThunk<NotificationResponse, (FilterParams & SortParams) | undefined>(
  'notification/getNotifications',
  async (params, { dispatch }) => {
    const response = await errorHandlerHelperV2<NotificationResponse>(() => listNotifications(params), dispatch);
    return response;
  },
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotificationPopUp: (state, action: PayloadAction<NotificationPopUpComponent>) => {
      state.notificationsPopUpList = [...state.notificationsPopUpList, action.payload];
    },
    removeNotification: (state, action: PayloadAction<{ title: string }>) => {
      state.notificationsPopUpList = state.notificationsPopUpList.filter((notifications) => notifications.title !== action.payload.title);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncListNotifications.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncListNotifications.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('listNotifications.error', action.error);
    });
    builder.addCase(asyncListNotifications.fulfilled, (state, action: PayloadAction<NotificationResponse>) => {
      state.loading = 'succeeded';
      state.notificationsList = action.payload.data;
      state.pages = action.payload.meta.pages;
      logger.info('listNotifications.success', state.notificationsList);
    });
  },
});

export const { addNotificationPopUp, removeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
