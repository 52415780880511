/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import React, { FC, ReactElement, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FormButtonComponent } from '..';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import { UpdateEmail } from '../../utils/interfaces';
import { FormFieldType } from '../../utils/types';
import successImage from '../../assets/images/success.png';
import LoaderScreen from '../LoaderScreen';
import logger from '../../services/loggerService';
import TextInput from '../inputs/TextInput';
import { asyncEditUserEmail, getProfileDataAsync } from '../../redux/slices/userSlice';
import errorHandlerHelper from '../../utils/errorHandler';

const FormUpdateEmail: FC = () => {
  const { user } = useAppSelector((state) => state.userProfile);
  const initialValues: UpdateEmail = { oldEmail: user?.email || '', emailConfirm: '', newEmail: '' };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const ForgotSchema = Yup.object().shape({
    oldEmail: Yup.string().email('Debés ingresar un email válido.').required('Debés escribir tu email.'),
    newEmail: Yup.string()
      .email('Debés ingresar un email válido.')
      .required('Debés escribir tu email.')
      .max(250, 'Tu email debe tener menos de 250 caracteres.')
      .notOneOf([Yup.ref('oldEmail'), null], 'El email es igual al anterior'),
    emailConfirm: Yup.string()
      .email('Debés ingresar un email válido.')
      .required('Debés escribir tu email.')
      .max(250, 'Tu email debe tener menos de 250 caracteres.')
      .oneOf([Yup.ref('newEmail'), null], 'Los emails no coinciden.'),
  });
  const handleSubmit = async (values: UpdateEmail): Promise<void> => {
    setLoading(true);
    try {
      const { payload } = await dispatch(
        asyncEditUserEmail({
          ...user,
          email: values?.emailConfirm,
          username: values?.emailConfirm,
        }),
      );
      if (payload.status === 200) {
        dispatch(
          addModalToList({
            body: 'Te enviamos un código a tu nueva dirección de correo para verificar tu cambio de e-mail.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch(removeModalFromList());
                dispatch<void>(getProfileDataAsync());
                navigate(-1);
              },
            },
          }),
        );
      }
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      logger.error('Update email.error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <Formik initialValues={initialValues} validationSchema={ForgotSchema} onSubmit={handleSubmit}>
        {({ errors, isSubmitting, isValid, dirty }): ReactElement => {
          if (loading) return <LoaderScreen />;
          return (
            <Form autoComplete="account" className="row mb-5 d-flex flex-column">
              <div className="col-md-6">
                <Field name="oldEmail">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInput
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      type="email"
                      errorMessage={field.value.length > 0 && errors.oldEmail}
                      placeholder="Ingresá tu email actual"
                      autoComplete="new-email"
                      label="Email Actual"
                      disabled
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-100">{}</div>
              <div className="col-md-6">
                <Field name="newEmail">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInput
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={field.value.length > 0 && errors.newEmail}
                      placeholder="Ingresá tu nuevo email"
                      label="Email Nuevo"
                      type="email"
                      autoComplete="new-email"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <Field name="emailConfirm">
                  {({ field }: FormFieldType): ReactElement => (
                    <TextInput
                      required
                      containerClassName="mt-2 w-100"
                      className="input w-100"
                      errorMessage={field.value.length > 0 && errors.emailConfirm}
                      placeholder="Ingresá tu email nuevamente"
                      label="Confirmar Email"
                      type="email"
                      autoComplete="new-email-confirm"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-100">{}</div>
              <div className="col-md-6">
                <FormButtonComponent
                  className="submit-button mt-4 w-100"
                  type="submit"
                  disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
                >
                  Cambiar Email
                </FormButtonComponent>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormUpdateEmail;
