import { Field, Form, Formik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import * as Yup from 'yup';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { CloseIconSVG, PlusSVG } from '../../assets/svgComponents';
import {
  FormButtonComponent,
  PlatformRowComponent,
  Subtitle,
  Title,
  TextInputComponent,
  ActionButtonComponent,
  LoaderScreen,
  SwitchInputComponent,
  TaxCodeInputComponent,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { asyncListCompanies, setLoading } from '../../redux/slices/companySlice';
import { regexCountryCodes } from '../../utils/constants';
import { asyncAddAddressHelper, asyncPatchAddressHelper, asyncGetCompanyDataHelper, formatTaxCode } from '../../utils/helper';
import {
  Company,
  CompanyRequestPost,
  AddressRequestPost,
  ExternalActivePlatform,
  AddCompanyResponse,
  ListExternalActivePlatformsResponse,
} from '../../utils/interfaces';
import { FormFieldType, ExternalPlatformType } from '../../utils/types';
import variables from '../../scss/_exports.module.scss';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import { createCompany, editCompany } from '../../services/companyService';
import errorHandlerHelper, { errorHandlerHelperV2 } from '../../utils/errorHandler';
import { getExternalActivePlatforms } from '../../services/externalPlatformService';
import { refreshToken } from '../../services/authService';
import { joyrideStyles, STEPS_COMPANIES, STEPS_PLATFORMS_EXTERNALS } from '../../components/Walkthrough';

const CompanyPage = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [company, setCompany] = useState<Company | null>(null);
  const { loadingCompanies: loadingCompany } = useAppSelector((stateLoading) => stateLoading.company);
  const { user } = useAppSelector((stateUser) => stateUser.userProfile);
  const [externalActivePlatformList, setExternalActivePlatformList] = useState<ListExternalActivePlatformsResponse>();
  const { id: paramId } = useParams();
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  const [runWalkthroughPlatform, setRunWalkthroughPlatform] = useState(false);

  const getActiveExternalPlatforms = async (): Promise<void> => {
    try {
      const externalActivePlatforms = await getExternalActivePlatforms({ 'filter[companyId][eq]': paramId });
      setExternalActivePlatformList(externalActivePlatforms);
    } catch (error: any) {
      errorHandlerHelper(error, dispatch);
    }
  };
  const walkthrough = (): void => {
    const tourViewed = window.localStorage.getItem('walkthroughCompanies');
    if (!tourViewed) {
      setRunWalkthrough(true);
    }
  };

  const getCompanyData = (): void => {
    if (paramId && paramId !== 'new') {
      dispatch(setLoading('pending'));
      asyncGetCompanyDataHelper(paramId, dispatch)
        .then((response: any) => {
          if (response.response?.status) {
            dispatch(setLoading('failed'));
            return false;
          }
          setCompany(response);
          return dispatch(setLoading('succeeded'));
        })
        .catch((error) => errorHandlerHelper(error, dispatch));
      getActiveExternalPlatforms();
    }
  };
  const walkthroughPlatformOnly = (): void => {
    const tourViewedCompanies = window.localStorage.getItem('walkthroughCompanies');
    const tourViewedPlatforms = window.localStorage.getItem('walkthroughPlatforms');
    if (tourViewedCompanies && !tourViewedPlatforms) {
      setRunWalkthroughPlatform(true);
    }
  };

  useEffect(() => {
    walkthrough();
    getCompanyData();
    walkthroughPlatformOnly();
  }, []);

  const countryCode = user?.Tenant?.countryCode || 'AR';
  const taxCodeRegex = regexCountryCodes[countryCode].taxCodeRegExp;

  const updateAddress = (values: CompanyRequestPost & AddressRequestPost): any => {
    if (company?.Address?.id && user?.tenantId) {
      return asyncPatchAddressHelper(
        {
          id: company.Address.id,
          tenantId: user?.tenantId,
          street: values.street,
          number: values.number,
          postalCode: values.postalCode,
          state: values.state,
          city: values.city,
        },
        dispatch,
      );
    }
    if (user?.tenantId) {
      return asyncAddAddressHelper(
        {
          street: values.street,
          number: values.number,
          postalCode: values.postalCode,
          state: values.state,
          city: values.city,
          tenantId: user.tenantId,
        },
        dispatch,
      );
    }
    return true;
  };
  const walkthroughPlatform = (): void => {
    window.localStorage.setItem('walkthroughCompanies', 'true');
    setRunWalkthrough(false);
    const tourViewed = window.localStorage.getItem('walkthroughPlatforms');
    if (!tourViewed) {
      setRunWalkthroughPlatform(true);
    }
  };

  const updateCompany = async ({ values, payload }: { values: CompanyRequestPost & AddressRequestPost; payload: any }): Promise<any> => {
    if (company) {
      try {
        const { data: response } = await errorHandlerHelperV2<AddCompanyResponse>(
          () =>
            editCompany({
              id: company.id,
              name: values.name,
              taxCode: values.taxCode,
              category: values.category,
              addressId: payload.id,
              enabled: values?.enabled || false,
            }),
          dispatch,
        );
        // segmentTrack(EVENTS.COMPANY.UPDATE.SUCCESS, { company: response });
        getCompanyData();
        walkthroughPlatform();
        return response;
      } catch (error) {
        // segmentTrack(EVENTS.COMPANY.UPDATE.FAILED, { error });
        return error;
      }
    }
    try {
      const { data: response } = await errorHandlerHelperV2<AddCompanyResponse>(
        () =>
          createCompany({
            name: values.name,
            taxCode: values.taxCode,
            category: values.category,
            addressId: payload.id,
            countryCode,
          }),
        dispatch,
      );
      // segmentTrack(EVENTS.COMPANY.CREATE.SUCCESS, { company: response });
      return response;
    } catch (error) {
      // segmentTrack(EVENTS.COMPANY.CREATE.FAILED, { error });
      return error;
    }
  };

  const handleSubmit = (values: CompanyRequestPost & AddressRequestPost, { setSubmitting }: any): void => {
    values.taxCode = values.taxCode.trim().replaceAll('-', '');
    updateAddress(values).then((resAddress: any) => {
      updateCompany({ values, payload: resAddress })
        .then((response: any) => {
          if (response?.status && response.status !== 200) {
            walkthroughPlatform();
            setSubmitting(false);
            return false;
          }
          return true;
        })
        .then(() => refreshToken())
        .then(() => {
          dispatch<void>(asyncListCompanies());
        })
        .catch((error) => errorHandlerHelper(error, dispatch))
        .finally(() => {
          if (state?.platformsWithoutUsername) {
            navigate(`/external-platform/${state?.platformsWithoutUsername}`);
          } else if (!runWalkthroughPlatform && !runWalkthrough) {
            navigate('/companies');
          }
        });
    });
  };
  const validationScheme = Yup.object().shape(
    {
      name: Yup.string().required('El campo razón social es obligatorio.'),
      taxCode: Yup.string()
        .required(`El ${regexCountryCodes[countryCode].name} es obligatorio.`)
        .matches(taxCodeRegex, `El ${regexCountryCodes[countryCode].name} es inválido.`),
      category: Yup.string(),
      street: Yup.string().required('El campo dirección es obligatorio.'),
      postalCode: Yup.string().required('El campo código postal es obligatorio.'),
      number: Yup.string().required('El campo número es obligatorio.'),
      state: Yup.string().required(`El campo ${regexCountryCodes[countryCode].stateName.toLowerCase()} es obligatorio.`),
      city: Yup.string().required('El campo localidad es obligatorio.'),
    },
    [
      ['street', 'postalCode'],
      ['street', 'number'],
      ['street', 'state'],
      ['street', 'city'],
      ['number', 'state'],
      ['number', 'city'],
      ['state', 'city'],
      ['postalCode', 'number'],
      ['postalCode', 'state'],
      ['postalCode', 'city'],
    ],
  );

  const initialValues: CompanyRequestPost & AddressRequestPost = {
    name: company?.name || '',
    enabled: company?.enabled || false,
    taxCode: formatTaxCode(company?.taxCode, company?.countryCode) || '',
    category: company?.category || '',
    street: company?.Address?.street || '',
    number: company?.Address?.number || '',
    postalCode: company?.Address?.postalCode || '',
    state: company?.Address?.state || '',
    city: company?.Address?.city || '',
    countryCode: company?.countryCode || '',
    addressId: company?.addressId || null,
    tenantId: user?.tenantId || 0,
  };

  let textButton = 'Guardar';
  if (paramId === 'new') {
    textButton = 'Crear';
  }
  if (loadingCompany === 'pending') {
    textButton = 'Cargando...';
  }

  const handleAddPlatform = (): void => {
    navigate('/external-platform/new', {
      state: {
        company: company?.id,
        type: [ExternalPlatformType.wallet, ExternalPlatformType.delivery],
      },
    });
  };

  // TODO it is hidden until the branches screen has all its complete functionalities
  const showBranches = (): void => {
    navigate(`${location.pathname}/branches`);
  };

  const handleAddErpPlatform = (): void => {
    navigate('/external-platform/new', {
      state: {
        company: company?.id,
        type: [ExternalPlatformType.erp, ExternalPlatformType.ecommerce],
      },
    });
  };

  const handleCloseIcon = (): void => {
    navigate('/companies');
  };

  const handleJoyrideCallback = (data: { action?: any; status?: any }) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      window.localStorage.setItem('walkthrough', 'true');
      window.localStorage.setItem('walkthroughPlatforms', 'true');
    }
    if (data.action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      window.localStorage.removeItem('runManualWalkthrough');
      window.location.replace('/');
    }
  };

  return (
    <div
      style={{ overflow: runWalkthrough || runWalkthroughPlatform ? 'hidden' : 'visible' }}
      className="step-companies company-page w-100 h-100"
    >
      {runWalkthrough ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run
          disableCloseOnEsc
          hideCloseButton
          spotlightClicks
          disableOverlayClose
          steps={STEPS_COMPANIES}
          styles={joyrideStyles}
        />
      ) : null}
      <div className="mt-5 d-flex flex-row justify-content-between col-12 col-md-10 col-lg-9">
        <div>
          <Title>{paramId === 'new' && loadingCompany !== 'pending' ? 'Nueva empresa' : company?.name}</Title>
          <Subtitle>Ingresá los datos de tu empresa</Subtitle>
        </div>
        <CloseIconSVG className="close-icon color-blue" height={40} width={40} onClick={() => handleCloseIcon()} />
      </div>
      {loadingCompany === 'pending' ? (
        <div>
          <LoaderScreen />
        </div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={handleSubmit}>
          {({ errors, values, isSubmitting, touched, setFieldValue }): ReactElement => (
            <>
              <div className="step-companies-data col-12 col-md-10 col-lg-9 d-flex flex-column">
                <Form className="d-flex flex-wrap">
                  {paramId !== 'new' && (
                    <div className="w-100">
                      <Field type="checkbox" name="enabled">
                        {({ field }: FormFieldType): ReactElement => (
                          <SwitchInputComponent
                            {...field}
                            label="Empresa activa"
                            // analytics={{ event: EVENTS.COMPANY.UPDATE.DISABLE, data: { companyId: paramId } }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <Field name="taxCode">
                    {({ field }: FormFieldType): ReactElement => (
                      <TaxCodeInputComponent
                        countryCode={countryCode}
                        customChange={setFieldValue}
                        errorMessage={touched.taxCode && errors.taxCode}
                        containerClassName="input"
                        label={regexCountryCodes[countryCode].name}
                        placeholder={`${`Ingresá ${regexCountryCodes[countryCode].name} de tu empresa`}`}
                        {...field}
                        value={values.taxCode ?? ''}
                        required
                      />
                    )}
                  </Field>
                  <Field name="name">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.name && errors.name}
                        containerClassName="input"
                        label="Razón social"
                        placeholder="Ingresá la razón social"
                        {...field}
                        required
                      />
                    )}
                  </Field>
                  <Field name="street">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.street && errors.street}
                        containerClassName="input"
                        label="Dirección"
                        placeholder="Ingresá la dirección"
                        {...field}
                        required
                      />
                    )}
                  </Field>
                  <Field name="number">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        type="number"
                        errorMessage={touched.number && errors.number}
                        containerClassName="input input-short"
                        label="Número"
                        placeholder={company?.Address?.number || 'Número'}
                        {...field}
                        value={values.number ?? ''}
                        required
                      />
                    )}
                  </Field>
                  <Field name="postalCode">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.postalCode && errors.postalCode}
                        containerClassName="input input-short"
                        placeholder={company?.Address?.postalCode || 'Código postal'}
                        label="C.P"
                        {...field}
                        value={values.postalCode ?? ''}
                        required
                      />
                    )}
                  </Field>
                  <Field name="state">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.state && errors.state}
                        containerClassName="input"
                        label={regexCountryCodes[countryCode].stateName}
                        placeholder={`Ingresá ${regexCountryCodes[countryCode].stateName.toLowerCase()}`}
                        {...field}
                        required
                      />
                    )}
                  </Field>
                  <Form />
                  <Field name="city">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.city && errors.city}
                        containerClassName="input"
                        label="Localidad"
                        placeholder="Ingresá localidad"
                        {...field}
                        required
                      />
                    )}
                  </Field>
                  <Field name="category">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        errorMessage={touched.category && errors.category}
                        containerClassName="input w-100"
                        label="Actividad"
                        placeholder="Indicá la actividad de la empresa"
                        {...field}
                      />
                    )}
                  </Field>
                  <FormButtonComponent type="submit" className="mt-4" disabled={isSubmitting}>
                    {textButton}
                  </FormButtonComponent>
                </Form>
              </div>
              {state?.completeCompanyDetails && !company?.taxCode ? (
                <div className="container-input background-yellow-30 mt-3 w-75">
                  <div className="notification warning">
                    <div className="notification-header">
                      <BsFillExclamationTriangleFill />
                    </div>
                    <div className="notification-content">
                      <div className="font-xs">Primero debés completar los datos de la empresa.</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {runWalkthroughPlatform ? (
                    <Joyride
                      callback={handleJoyrideCallback}
                      continuous
                      run
                      disableCloseOnEsc
                      hideCloseButton
                      disableOverlayClose
                      steps={STEPS_PLATFORMS_EXTERNALS}
                      styles={joyrideStyles}
                    />
                  ) : null}
                  <div>
                    <div className="col-12 col-md-10 col-lg-9 mt-5 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                      <Subtitle>Sucursales</Subtitle>
                    </div>
                    <ActionButtonComponent
                      text="Ver sucursales"
                      onClick={showBranches}
                      icon={null}
                      containerClassName="me-2 add-platform-button"
                      inactive={company?.taxCode === null || !company?.enabled}
                    />
                  </div>
                  {paramId && paramId !== 'new' && (
                    <div className="step-platforms">
                      <div className="step-platforms-external">
                        <div className="col-12 col-md-10 col-lg-9 mt-5 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                          <Subtitle>Medios de cobro</Subtitle>
                        </div>
                        {company?.taxCode === null && (
                          <p className="mb-3">Para agregar un nuevo medio de cobro, primero debés completar los datos de tu empresa.</p>
                        )}
                        <ActionButtonComponent
                          inactive={(company?.taxCode === null && true) || !company?.enabled}
                          text="Agregar medio de cobro"
                          onClick={handleAddPlatform}
                          icon={<PlusSVG fill={variables.colorWhite} />}
                          containerClassName="me-2 add-platform-button"
                        />
                        {company?.taxCode || (!company?.taxCode && runWalkthroughPlatform) ? (
                          <div className="pb-5 col-12 col-md-10 col-lg-9 d-flex flex-wrap">
                            {company?.enabled &&
                              externalActivePlatformList?.documents.docs
                                ?.filter((p) => p.PlatformExternal.type !== 'erp')
                                ?.filter((p) => p.PlatformExternal.type !== 'ecommerce')
                                ?.map((item: ExternalActivePlatform) => (
                                  <PlatformRowComponent
                                    platform={item}
                                    key={`${item.id}-${item.createdAt}`}
                                    containerClassName="platform-row"
                                  />
                                ))}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-10 col-lg-9 mt-5 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                        <Subtitle>Sistemas de Gestion (ERP)</Subtitle>
                      </div>
                      {company?.taxCode === null && (
                        <p className="mb-3">
                          Para agregar un nuevo sistema de gestión (ERP), primero debés completar los datos de tu empresa.
                        </p>
                      )}
                      <ActionButtonComponent
                        inactive={(company?.taxCode === null && true) || !company?.enabled}
                        text="Agregar Sistema de Gestión (ERP)"
                        onClick={handleAddErpPlatform}
                        icon={<PlusSVG fill={variables.colorWhite} />}
                        containerClassName="me-2 add-platform-button"
                      />
                      {company?.taxCode || (!company?.taxCode && runWalkthroughPlatform) ? (
                        <div className="pb-5 col-12 col-md-10 col-lg-9 d-flex flex-wrap">
                          {company?.enabled &&
                            externalActivePlatformList?.documents.docs
                              ?.filter((p) => p.PlatformExternal.type === 'erp' || p.PlatformExternal.type === 'ecommerce')
                              ?.map((item: ExternalActivePlatform) => (
                                <PlatformRowComponent
                                  platform={item}
                                  key={`${item.id}-${item.createdAt}`}
                                  containerClassName="platform-row"
                                />
                              ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CompanyPage;
