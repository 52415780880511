import React, { ReactElement } from 'react';
import {
  CalendarPage,
  ClientsPage,
  CompaniesPage,
  ExternalPlatformCredentialsForm,
  CompanyOnBoardingPage,
  CompanyPage,
  Dashboard,
  // CollectionsPage,
  SettlementsPage,
  Meli,
  TiendaNube,
  notFoundPage,
  ReportsPage,
  SettingsPage,
  SalesPage,
  NotificationsPage,
  InformationPage,
  UsersPage,
  UserPage,
  SalesClientPage,
  SettlementsClientPage,
  ExpenseReportsPage,
  TaxesClientPage,
  WorkPapersPage,
  ChangePassword,
  ChangeEmail,
  PivotTableSales,
  PivotTableSettlements,
  PivotTableTaxes,
  SmartData,
  Branches,
  EditBranchPage,
  UngroupBranchPage,
  InfoFiservFTP,
} from '../pages';
import { SingleClientContainer } from '../containers/Clients';

export const authenticatedRegularRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
  },
  // {
  //   path: '/collections',
  //   component: CollectionsPage,
  //   exact: true,
  // },
  {
    path: '/sales',
    component: SalesPage,
    exact: true,
  },
  {
    path: '/companies',
    component: CompaniesPage,
    exact: true,
  },
  {
    path: '/edit-branch',
    component: EditBranchPage,
    exact: true,
  },
  {
    path: '/ungroup-branch',
    component: UngroupBranchPage,
    exact: true,
  },
  {
    path: '/users',
    component: UsersPage,
    exact: true,
  },
  {
    path: '/users/:id',
    component: UserPage,
    exact: true,
  },
  {
    path: '/companies/:id',
    component: CompanyPage,
    exact: true,
  },
  {
    path: '/companies/:id/branches',
    component: Branches,
    exact: true,
  },
  {
    path: '/external-platform/:id',
    component: ExternalPlatformCredentialsForm,
    exact: true,
  },
  {
    path: '/reports',
    component: ReportsPage,
    exact: true,
  },
  {
    path: '/smart-data',
    component: SmartData,
    exact: true,
  },
  {
    path: '/data-flex-sales',
    component: PivotTableSales,
    exact: true,
  },
  {
    path: '/data-flex-settlements',
    component: PivotTableSettlements,
    exact: true,
  },
  {
    path: '/data-flex-expenses',
    component: PivotTableTaxes,
    exact: true,
  },
  {
    path: '/expense-reports',
    component: ExpenseReportsPage,
    exact: true,
  },
  {
    path: '/settings',
    component: SettingsPage,
    exact: true,
  },
  {
    path: '/info-fiserv',
    component: InfoFiservFTP,
    exact: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/change-email',
    component: ChangeEmail,
    exact: true,
  },
  {
    path: '/settlements',
    component: SettlementsPage,
    exact: true,
  },
  {
    path: '/calendar',
    component: CalendarPage,
    exact: true,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
  },
  {
    path: '/profile',
    component: InformationPage,
    exact: true,
  },
];

export const fullScreenRoutes = [
  {
    path: '/external/mercadopago/auth',
    component: Meli,
  },
  {
    path: '/external/tiendanube/auth',
    component: TiendaNube,
  },
];

export const publicRoutes = [
  {
    path: '/404',
    exact: true,
    component: notFoundPage,
  },
  {
    path: '/onboarding',
    component: CompanyOnBoardingPage,
    exact: true,
  },
];

export const authenticatedAccountantRoutes: Route[] = [
  {
    path: '/clients',
    component: ClientsPage,
    exact: true,
  },
  {
    path: '/clients/:id',
    component: SingleClientContainer,
    exact: true,
  },
  {
    path: '/reports',
    component: ReportsPage,
    exact: true,
  },
  {
    path: '/data-flex-sales',
    component: PivotTableSales,
    exact: true,
  },
  {
    path: '/data-flex-settlements',
    component: PivotTableSettlements,
    exact: true,
  },
  {
    path: '/data-flex-taxes',
    component: PivotTableTaxes,
    exact: true,
  },
  {
    path: '/clients/:id/sales-client',
    component: SalesClientPage,
    exact: true,
  },
  {
    path: '/clients/:id/settlements-client',
    component: SettlementsClientPage,
    exact: true,
  },
  {
    path: '/clients/:id/taxes-client',
    component: TaxesClientPage,
    exact: false,
  },
  {
    path: '/clients/:id/external-platform/:id',
    component: ExternalPlatformCredentialsForm,
    exact: true,
  },
  {
    path: '/profile',
    component: InformationPage,
    exact: true,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
    exact: true,
  },
  {
    path: '/work-papers/:id',
    component: WorkPapersPage,
    exact: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/change-email',
    component: ChangeEmail,
    exact: true,
  },
];

type Route<T = ReactElement | any> = {
  path: string;
  exact: boolean;
  component: React.FC<T>;
};
