export const ENV = process.env.NODE_ENV;
export const ENV_URL = process.env.REACT_APP_URL;
export const ENV_API_URL = process.env.REACT_APP_API_URL;
export const ENV_MERCADO_LIBRE_APP_REDIRECT_URL = process.env.REACT_APP_MERCADO_LIBRE_APP_REDIRECT_URL;
export const ENV_TIENDA_NUBE_APP_REDIRECT_URL = process.env.REACT_APP_TIENDA_NUBE_APP_REDIRECT_URL;
export const ENV_INSTRUCTIONAL_VIDEO_MP_AR = process.env.INSTRUCTIONAL_VIDEO_MP_AR;
export const ENV_INSTRUCTIONAL_VIDEO_PRISMA_AR = process.env.INSTRUCTIONAL_VIDEO_PRISMA_AR;
export const ENV_INSTRUCTIONAL_VIDEO_MP_UY = process.env.INSTRUCTIONAL_VIDEO_MP_UY;
export const ENV_MERCADO_LIBRE_APP_ID = process.env.REACT_APP_MERCADO_LIBRE_APP_ID;
export const ENV_TIENDA_NUBE_APP_ID = process.env.REACT_APP_TIENDA_NUBE_APP_ID;
export const ENV_PAGE_SIZE_COMPANIES = process.env.REACT_APP_PAGE_SIZE_COMPANIES;
export const ENV_SESSION_INACTIVITY_IN_MINUTES = process.env.REACT_APP_SESSION_INACTIVITY_IN_MINUTES;
export const ENV_API_URL_2 = process.env.REACT_APP_API_URL_2;

export const ENV_FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const ENV_VAPID_KEY = { vapidKey: process.env.REACT_APP_FIREBASE_FCM_PUBLIC_KEY };
export const isProd = window.location.origin.includes('cash.nubceo.com');
