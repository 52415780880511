import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import moment from 'moment';
import { PaginationWithCount } from '../..';
import useQueryParams from '../../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { LoadingStateType, Sale } from '../../../utils/interfaces';
// import { EVENTS } from '../../../utils/segment/events';
// import { segmentTrack } from '../../../utils/segment/hooks';
import LoaderScreen from '../../LoaderScreen';
import SaleRow from './SaleRow';
import { getTrialStatus, paramsToObject } from '../../../utils/helper';
import errorHandlerHelper from '../../../utils/errorHandler';

type SalesProps = {
  salesArray?: Sale[] | null;
  handlePaginationCallBack?: ((searchParams: Record<string, string>) => void) | null;
  handleExpandAcc?: (id: string) => void;
};

const SalesComponent: FC<SalesProps> = ({ salesArray, handlePaginationCallBack, handleExpandAcc }: SalesProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const page = useQueryParams('page') || '1';
  const { salesList, loading, loadingClient, pages, total } = useAppSelector((state) => state.sales);
  const [expiredTrialMessage, setExpiredTrialMessage] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const queryObject = paramsToObject(queryParams);

  const diffBetweenDatesNotHigherThan62 = (init: string, end: string): boolean => {
    if (!end) return false;
    if (!init) return false;
    return moment(init).add(62, 'day') >= moment(end);
  };

  useEffect(() => {
    getTrialStatus()
      .then((cashTier) => {
        const { trialExpired } = cashTier;
        const tierCancelled = cashTier?.Tier?.name?.toLowerCase().includes('cancelled');
        if (trialExpired || tierCancelled) {
          setExpiredTrialMessage('Tu período de prueba ha expirado. Suscribite para poder seguir viendo tu información.');
        }
      })
      .catch((error) => errorHandlerHelper(error, dispatch));
  }, []);

  const handlePagination = (searchParams: Record<string, string>): void => {
    if (handlePaginationCallBack) return handlePaginationCallBack(searchParams);
    return navigate(`?${new URLSearchParams(searchParams).toString()}`);
  };

  const getSalesList = () => {
    if (loading === LoadingStateType.PENDING || loadingClient === LoadingStateType.PENDING) {
      return <LoaderScreen />;
    }

    if (loading === LoadingStateType.SUCCEDED || loadingClient === LoadingStateType.SUCCEDED) {
      return (salesArray?.length && loadingClient === LoadingStateType.SUCCEDED) || salesList?.length ? (
        <>
          <div className="sales-component mt-2">
            {salesArray?.length
              ? salesArray?.map((row: Sale) => <SaleRow handleExpandAcc={handleExpandAcc ?? undefined} key={row.id} sale={row} />)
              : salesList?.map((row: Sale) => <SaleRow handleExpandAcc={handleExpandAcc ?? undefined} key={row.id} sale={row} />)}
          </div>
          <PaginationWithCount
            page={page}
            handlePagination={handlePagination}
            pageSize={25}
            pages={pages}
            length={salesArray?.length || salesList?.length || 0}
            loading={loading}
            loadingClient={loadingClient}
            total={total}
          />
        </>
      ) : null;
    }

    return null;
  };

  const showMessage = () => {
    if (expiredTrialMessage === '' && (loading === LoadingStateType.FAILED || loadingClient === LoadingStateType.FAILED)) {
      return (
        <div className="container-input background-yellow-30 m-3">
          <div className="notification warning">
            <div className="notification-header">
              <BsFillExclamationTriangleFill />
            </div>
            <div className="notification-content">
              <div className="font-xs">
                {diffBetweenDatesNotHigherThan62(queryObject.startDate, queryObject.endDate)
                  ? 'Estamos teniendo dificultades para mostrarte la información solicitada. Por favor, volvé a intentar en unos minutos.'
                  : '¡Tu consulta es muy grande! Usá los filtros de fechas u otros para traer la información resumida. Seleccioná un período no mayor a 62 días.'}
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (expiredTrialMessage !== '') {
      return (
        <div className="container-input background-yellow-30 m-3">
          <div className="notification warning">
            <div className="notification-header">
              <BsFillExclamationTriangleFill />
            </div>
            <div className="notification-content">
              <div className="font-xs">{expiredTrialMessage}</div>
            </div>
          </div>
        </div>
      );
    }
    if (!salesArray?.length || !salesList?.length) {
      return <div>No tiene ventas para mostrar</div>;
    }

    return null;
  };
  return (
    <>
      {getSalesList()}
      {showMessage()}
    </>
  );
};

SalesComponent.defaultProps = {
  salesArray: [],
  handlePaginationCallBack: null,
  handleExpandAcc: (_id: string): void => {
    // DO NOTHING
  },
};
export default SalesComponent;
