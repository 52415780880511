import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIconSVG from '../../assets/svgComponents/CloseIcon';
import { FormButtonComponent, LoaderScreen, TextInputComponent, Title } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addModalToList } from '../../redux/slices/modalSlice';
import successImage from '../../assets/images/success.png';
import errorHandlerHelper from '../../utils/errorHandler';
import { editBranchService } from '../../services/branchService';
import { asyncListBranches } from '../../redux/slices/branchSlice';
import { Branch } from '../../utils/interfaces';
import BranchCardUnmergeAndEditComponent from '../../components/BranchCardUnmergeAndEdit/BranchCardUnmergeAndEdit';

const EditBranchPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { branch } = location.state;
  const { user } = useAppSelector((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [nameBranch, setNameBranch] = useState(branch?.name ? branch.name : '');
  const [nameBranchError, setNameBranchError] = useState({
    name: '',
    title: '',
  });
  const branchList = branch?.relatedBranches ? [branch, ...branch?.relatedBranches] : [branch];

  const goBack = (): void => {
    setIsLoading(true);
    navigate(`/companies/${branch.companyId}/branches`);
    setIsLoading(false);
  };

  const editMyBranch = async (): Promise<void> => {
    try {
      const { data: res } = await editBranchService({
        companyId: branch.companyId,
        tenantId: user?.tenantId.toString() || '',
        name: nameBranch,
        id: branch.id,
      });
      if (res.status === 200) {
        setIsLoading(false);
        dispatch(
          addModalToList({
            body: 'La sucursal se edito correctamente.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                dispatch<void>(asyncListBranches({ pageSize: '25', 'filter[companyId][in]': `[${branch.companyId}]` }));
                goBack();
              },
            },
          }),
        );
      }
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const editBranch = (): void => {
    if (nameBranch === '') {
      setNameBranchError((AlertActivate) => ({
        ...AlertActivate,
        name: 'error',
        title: 'No puede quedar el nombre de la Sucursal en blanco',
      }));
    }
    setIsLoading(true);
    editMyBranch();
  };

  const handleSelectBranch = (selectedBranch: Branch | undefined): void => {
    setSelectedBranches((prevState: any) => {
      const found = prevState.find((b: Branch) => selectedBranch && b.id === selectedBranch.id);
      if (found) {
        return prevState.filter((b: Branch) => selectedBranch && b.id !== selectedBranch.id);
      }
      return [...prevState, { ...selectedBranch }];
    });
  };

  return (
    <div className="d-flex">
      <div className="w-75">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between mt-3">
            <Title className="mb-3">Editar Sucursal</Title>
            <CloseIconSVG className="close-icon color-blue mt-3 cursor-close" height={24} width={24} onClick={goBack} />
          </div>

          <div className="w-75">
            <TextInputComponent
              label="Nombre"
              placeholder="Ingresá el nombre"
              onChange={(e): void => setNameBranch(e?.target?.value)}
              className={nameBranchError.name === 'error' ? 'inputDataError' : 'input'}
              value={nameBranch || ''}
            />
          </div>
          {nameBranchError?.name === 'error' ? <p className="nameBranchError">{nameBranchError?.title}</p> : null}
          <div className="mt-3">
            {branch?.relatedBranches?.length
              ? branchList.map((b) => (
                  <BranchCardUnmergeAndEditComponent
                    key={b.id}
                    branch={b}
                    selectedBranches={selectedBranches}
                    setSelectedBranch={handleSelectBranch}
                    editTypeAction
                    selectable
                    showUnlinkIcon
                  />
                ))
              : null}
          </div>
          <FormButtonComponent
            className="submit-button mt-4 w-100"
            disabled={nameBranch === '' || isLoading}
            onClick={(): void => editBranch()}
          >
            Editar
          </FormButtonComponent>
        </div>
        <div className="mt-3">{isLoading === true ? <LoaderScreen /> : null}</div>
      </div>
    </div>
  );
};

export default EditBranchPage;
