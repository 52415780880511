import React, { FC, ReactElement } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { PasswordInputComponent, TextInputComponent } from '../../inputs';
import { FormFieldType } from '../../../utils/types';
import { regexCodes } from '../../../utils/constants';
import PlatformCredentialsFooterButtons from './platformCredentialsFooterButtons';
import { ExternalActivePlatform } from '../../../utils/interfaces';
import { ExternalPlatformCredentialsForm } from '../../../pages/ExternalPlatform';

interface ZoologicCredentialsFormProps {
  canEditPlatform?: boolean;
  handleDeletePlatform?: () => void;
  handleSubmitForm: (values: ExternalPlatformCredentialsForm) => void;
  currentValues?: ExternalActivePlatform;
}

const ZoologicCredentialsForm: FC<ZoologicCredentialsFormProps> = ({
  canEditPlatform,
  handleDeletePlatform,
  handleSubmitForm,
  currentValues,
}) => {
  const validateForm = Yup.object().shape({
    codeAux: Yup.string().matches(regexCodes.url, 'Ingresá una url válida').required('Ingresá la API URL de tu comercio'),
    codeAux2: Yup.string().required('Ingresá el código API de tu comercio'),
    codeAux3: Yup.string().required('Ingresá la API KEY secreta de tu comercio'),
    codeAux4: Yup.string().required('Ingresá la Base de datos'),
    username: Yup.string().required('Ingresá un usuario válido.'),
    password: Yup.string().required('Tenés que escribir una contraseña.'),
  });

  const initialValues = {
    codeAux: currentValues?.codeAux || '',
    codeAux2: currentValues?.codeAux2 || '',
    codeAux3: currentValues?.codeAux3 || '',
    codeAux4: currentValues?.codeAux4 || '',
    username: currentValues?.username || '',
    password: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitForm}>
      {({ errors, isSubmitting, isValid, dirty }): ReactElement => (
        <Form className="row mb-5 d-flex flex-column">
          <div className="container-input background-yellow-30 my-3 w-50">
            <div className="notification warning">
              <div className="notification-header">
                <BsFillExclamationTriangleFill />
              </div>
              <div className="notification-content">
                <div className="font-xs mx-4">Requiere implementación</div>
              </div>
            </div>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="codeAux">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.codeAux}
                  placeholder="Ingresá la API URL de tu comercio"
                  label="API Url"
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="codeAux2">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.codeAux2}
                  placeholder="Ingresá el código API de tu comercio"
                  label="API Código de cliente"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="codeAux3">
              {({ field }: FormFieldType): ReactElement => (
                <PasswordInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.codeAux3}
                  label="API KEY Secreta"
                  // placeholder={existingPlatform ? '•••••••••' : 'Ingresá la contraseña'}
                  placeholder="Ingresá la API KEY secreta"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="codeAux4">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.codeAux4}
                  placeholder="Ingresá la Base de Datos"
                  label="Base de Datos"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="username">
              {({ field }: FormFieldType): ReactElement => (
                <TextInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={field.value && errors.username}
                  placeholder="Ej: 20123456780"
                  label="Usuario"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="container-input col-12 col-xl-6">
            <Field name="password">
              {({ field }: FormFieldType): ReactElement => (
                <PasswordInputComponent
                  required
                  containerClassName="mt-2 w-100"
                  className="input w-100"
                  errorMessage={errors.password}
                  placeholder="Ingresá la contraseña"
                  label="Contraseña"
                  disabled={!canEditPlatform}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="col-12">
            <PlatformCredentialsFooterButtons
              disabled={isSubmitting || !isValid || (isValid && !dirty) || (dirty && !isValid)}
              handleDeletePlatform={handleDeletePlatform}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ZoologicCredentialsForm;
