import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authLogin, authRegister, completeOnboarding } from '../../services/authService';
import logger from '../../services/loggerService';
import { errorHandlerHelper, errorHandlerHelperV2 } from '../../utils/errorHandler';
import { registerUserToken } from '../../utils/firebaseHelper';
import { setDaysRemainingExpiredPassword, setTokens } from '../../utils/helper';
import { UserCredentials, User, UserRegisterData, UserResponse } from '../../utils/interfaces';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentGroup, segmentIdentify, segmentReset, segmentTrack } from '../../utils/segment/hooks';
import { CompleteOnBoardingResponse } from '../../utils/types';
import { getProfileDataAsync } from './userSlice';

export interface LoginState {
  user: User | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  messages: Record<string, string>;
}

export const loginAsync = createAsyncThunk<UserResponse, UserCredentials, Record<string, unknown>>(
  'auth/login',
  async (credentials: UserCredentials, { dispatch }) => {
    const { data } = await errorHandlerHelperV2<{ data: UserResponse }>(() => authLogin(credentials), dispatch);

    setTokens(data.token, data.tokenRefresh);
    setDaysRemainingExpiredPassword(data.daysRemaining);

    // TODO - WHAT HAPPEN WHEN A USER FCM TOKEN IS NOT REGISTERED?
    await registerUserToken(dispatch).catch((error) => errorHandlerHelper(error, dispatch));

    // THIS WILL LET THE USER GO INTO DASHBOARD
    dispatch<void>(getProfileDataAsync());

    return data;
  },
);

export const registerAsync = createAsyncThunk<UserResponse, UserRegisterData, Record<string, unknown>>(
  'auth/register',
  async (userData: UserRegisterData, { dispatch }) => {
    const { data } = await errorHandlerHelperV2<{ data: UserResponse }>(() => authRegister(userData), dispatch);

    setTokens(data.token, data.tokenRefresh);

    // TODO - WHAT HAPPEN WHEN A USER FCM TOKEN IS NOT REGISTERED?
    await registerUserToken(dispatch).catch((error) => errorHandlerHelper(error, dispatch));

    // THIS WILL LET THE USER GO INTO DASHBOARD
    dispatch<void>(getProfileDataAsync());

    return data;
  },
);

export const asyncCompleteOnBoarding = createAsyncThunk<CompleteOnBoardingResponse>(
  'onboarding/onboarding-complete',
  async (_params, { rejectWithValue, dispatch }) => {
    try {
      const response = await completeOnboarding();
      return response;
    } catch (error) {
      errorHandlerHelper(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

const initialState: LoginState = {
  user: null,
  loading: 'idle',
  messages: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadingUser(state, _action) {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    setUser: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'succeeded';
      }
      state.user = action.payload;
    },
    setAuthMessages: (state, action) => {
      state.loading = 'pending';
      state.messages = { ...state.messages, ...action.payload.message };
    },
    logout: (state) => {
      // segmentReset();
      localStorage.clear();
      state.user = null;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(loginAsync.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('loginAsync.error', action.error);
      // segmentTrack(EVENTS.AUTH.LOGIN.FAILED, action.error);
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      const { user } = action.payload;
      state.loading = 'succeeded';
      state.user = user;
      // segmentIdentify(user.id, {
      //   email: user.email,
      //   name: user.firstName,
      // });
      // const tierCode = getTierCode();
      // segmentGroup(user.tenantId, {
      //   tenant: user.tenantId,
      //   tiercode: tierCode,
      // });
      // segmentTrack(EVENTS.AUTH.LOGIN.SUCCESS, { userId: user.id, userEmail: user.email, tenantId: user.tenantId });
      logger.info('loginAsync.success', user);
    });
    // Register
    builder.addCase(registerAsync.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(registerAsync.rejected, (state, action) => {
      state.loading = 'failed';
      // segmentTrack(EVENTS.AUTH.REGISTER.FAILED, action.error);
      logger.error('error', action.error);
    });
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      const { user } = action.payload;
      state.loading = 'succeeded';
      state.user = user;
      // segmentIdentify(user.id, {
      //   email: user.email,
      //   name: user.firstName,
      // });
      // segmentGroup(user.tenantId, {
      //   tenant: user.tenantId,
      // });
      // segmentTrack(EVENTS.AUTH.REGISTER.SUCCESS, { userId: user.id, userEmail: user.email, tenantId: user.tenantId });
      logger.info('registerAsync.success', user);
    });
    // OnBoardingComplete
    builder.addCase(asyncCompleteOnBoarding.pending, (state, _action) => {
      state.loading = 'pending';
    });
    builder.addCase(asyncCompleteOnBoarding.rejected, (state, action) => {
      state.loading = 'failed';
      logger.error('error', action.error);
      // segmentTrack(EVENTS.ONBOARDING.COMPLETE.FAILED);
    });
    builder.addCase(asyncCompleteOnBoarding.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      logger.info('registerAsync.success', action);
      // segmentTrack(EVENTS.ONBOARDING.COMPLETE.SUCCESS);
    });
  },
});

export const { loadingUser, logout, setUser, updateUser, setAuthMessages } = authSlice.actions;
export default authSlice.reducer;
