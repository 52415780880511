/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, FC } from 'react';
import { CustomInputProps, InputWrapperProps } from '../../../utils/interfaces';
import InputWrapper from '../InputWrapper';

const NumberInput: FC<CustomInputProps & InputWrapperProps> = ({
  label,
  value,
  errorMessage,
  placeholder,
  name,
  className = '',
  containerClassName,
  required,
  disabled,
  readOnly,
  customChange,
  maxLength,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!Number(e.target.value) && e.target.value !== '') return;
    customChange(name, e.target.value.trim());
  };
  return (
    <InputWrapper required={required} containerClassName={containerClassName} label={label} errorMessage={errorMessage}>
      <input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`number-input-component ${errorMessage && 'error'} ${className}`}
        type="text"
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
      />
    </InputWrapper>
  );
};

export default NumberInput;
