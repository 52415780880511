/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { SidebarItem, SidebarLinkProps } from '../../utils/types';
import { Paragraph } from '../textComponents';

const SubMenu: FC<SidebarLinkProps> = ({ item, openMenu }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = (): void => setSubnav(!subnav);

  return (
    <>
      {item.path !== null ? (
        <Link data-tip={item.title} className={`sidebar-link ${!openMenu && 'closed-menu'}`} onClick={showSubnav} to={item.path}>
          <ReactTooltip />
          {item.icon}
          <Paragraph size="lg" className="sidebar-level">
            {item.title}
          </Paragraph>
          {item.subnav ? <FaChevronDown className="icon" /> : <FaChevronRight className="icon" />}
          <div>{item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}</div>
        </Link>
      ) : (
        <div data-tip={item.title} className={`sidebar-link ${!openMenu && 'closed-menu'}`} onClick={showSubnav} role="none">
          <ReactTooltip />
          {item.icon}
          <Paragraph size="lg" className="sidebar-level">
            {item.title}
          </Paragraph>
          {item.subnav ? <FaChevronDown className="icon" /> : <FaChevronRight className="icon" />}
          <div>{item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}</div>
        </div>
      )}
      {subnav &&
        item?.subnav?.map((subnavItem: SidebarItem, index: number) =>
          subnavItem.path !== null ? (
            <Link
              data-tip={subnavItem.title}
              className={`sidebar-link dropdown-link ${!openMenu && 'closed-menu'}`}
              to={subnavItem.path}
              key={index}
            >
              <ReactTooltip />
              {subnavItem.icon}
              <Paragraph size="lg" className="sidebar-level">
                {subnavItem.title}
              </Paragraph>
              <FaChevronRight className="icon" />
            </Link>
          ) : (
            <div data-tip={subnavItem.title} className={`sidebar-link dropdown-link ${!openMenu && 'closed-menu'}`} role="none" key={index}>
              <ReactTooltip />
              {subnavItem.icon}
              <Paragraph size="lg" className="sidebar-level">
                {subnavItem.title}
              </Paragraph>
              <FaChevronRight className="icon" />
            </div>
          ),
        )}
    </>
  );
};
export default SubMenu;
