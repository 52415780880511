import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButtonComponent, FormButtonComponent } from '../components';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeModalFromList } from '../redux/slices/modalSlice';
import { MessageModalBlockedUser, config } from './constants';
import { CountryCode } from './interfaces';

type ApiErrorType = {
  code: string;
  body: string | React.ReactElement;
  className?: string;
  pictureUrl?: string;
  required?: boolean;
  hideModalAccept?: boolean;
};

const MessageModalExpiredPassword = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div>
      <p className="font-md font-weight-bold">¡Ups!</p>
      <p>Tu contraseña ha expirado o es la primera vez que ingresas.</p>
      <p>Deberás actualizarla para tener acceso a tu cuenta.</p>
      <div className="d-flex justify-content-center">
        <ActionButtonComponent
          text="Renovar contraseña"
          onClick={(): void => {
            dispatch(removeModalFromList());
            navigate('/forgot-password', {
              state: {
                isRenewalPassword: true,
              },
            });
          }}
          icon={null}
          containerClassName="mt-3 me-2 add-platform-button"
        />
      </div>
    </div>
  );
};

const ExpiredTrialMessage = (): React.ReactElement => {
  const { user } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const subscriptionUrl =
    user?.Tenant?.countryCode === CountryCode.AR
      ? 'https://www.nubceo.com/cash-ar/suscripcion-ar/'
      : 'https://www.nubceo.com/cash-uy/suscripcion-uy/';
  const msg = 'Se me venció el período de prueba y quiero conocer más sobre el producto.';

  return (
    <>
      <div className="d-flex flex-column">
        <div className="justify-content-center">
          <b>Período de prueba vencido</b>
        </div>
        <div className="justify-content-center">
          Se te venció el período de prueba. Contactá al equipo de nubceo para renovar la suscripción
        </div>
        <div className="text-center mt-3">
          <FormButtonComponent onClick={(): Window | null => window.open(subscriptionUrl, '_blank')}>Conocer más</FormButtonComponent>
        </div>
        <div className="text-center mt-3">
          <FormButtonComponent
            onClick={(): Window | null =>
              window.open(
                `https://wa.me/${
                  user?.Tenant.countryCode === CountryCode.AR ? config.nubceo.supportPhone : config.nubceo.supportPhoneUy
                }?text=${msg}`,
              )
            }
          >
            Quiero suscribir
          </FormButtonComponent>
        </div>
        <div className="text-center mt-3">
          <FormButtonComponent onClick={(): { payload: undefined; type: string } => dispatch(removeModalFromList())}>
            Lo veré más tarde
          </FormButtonComponent>
        </div>
      </div>
    </>
  );
};

const apiErrors: Record<string, ApiErrorType> = {
  INVALID_CREDENTIALS: {
    code: '1001',
    body: 'Usuario y/o contraseña incorrectos',
  },
  PREVIOUSLY_USED_PASSWORD: {
    code: '400',
    body: 'La contraseña actual no puede ser igual a ninguna de las últimas 9 contraseñas. Por favor, elige una nueva contraseña.',
  },
  EXPIRED_PASSWORD: {
    code: '',
    body: <MessageModalExpiredPassword />,
    hideModalAccept: true,
  },
  BLOCKED_USER: {
    code: '',
    body: <MessageModalBlockedUser />,
  },
  UNAUTHORIZED: {
    code: '1004',
    body: 'No tienes permisos suficientes para realizar esta acción.',
  },
  EMAIL_USED: {
    code: '1005',
    body: 'Este email ya se encuentra registrado',
  },
  EXPIRED_TRIAL: {
    code: '1005',
    body: <ExpiredTrialMessage />,
    hideModalAccept: true,
  },
  CANCELLED_TIER: {
    code: '1006',
    body: <ExpiredTrialMessage />,
    hideModalAccept: true,
  },
  BUSINESS_ENTITY_NOT_FOUND: {
    code: '',
    body: 'Cliente no encontrado',
  },
  INVALID_TOKEN: {
    code: '',
    body: 'Token incorrecto',
  },
  EMAIL_NOT_FOUND: {
    code: '',
    body: 'El email no existe',
  },
  COMPANY_SAME_COUNTRY_AND_TAXCODE: {
    code: '',
    body: 'Ya existe esta compañía.',
  },
  PLATFORM_EXTERNAL_CREDENTIAL_ALREADY_EXISTS: {
    code: '409',
    body: 'Los datos de acceso para este medio de cobro ya existen.',
  },
};

export default apiErrors;
