import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormButtonComponent, Subtitle, Title } from '../../components';
import { useAppDispatch } from '../../redux/hooks';
import { Branch } from '../../utils/interfaces';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import errorHandlerHelper from '../../utils/errorHandler';
import successImage from '../../assets/images/success.png';
import { unMergeBranches } from '../../services/branchService';
import { CloseIconSVG } from '../../assets/svgComponents';
import BranchCardUnmergeAndEditComponent from '../../components/BranchCardUnmergeAndEdit/BranchCardUnmergeAndEdit';

const UngroupBranchPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedBranches, setSelectedBranches] = useState<Branch[]>([]);
  const [branchesList, setBranchesList] = useState<Branch[]>([]);
  const branchRelated = location.state?.relatedBranches;
  const mainBranch = location.state?.mainBranch;

  useEffect(() => {
    if (branchRelated && mainBranch) {
      setBranchesList([mainBranch, ...branchRelated]);
    }
  }, [branchRelated, mainBranch]);

  const handleSelectBranch = (selectedBranch: Branch | undefined): void => {
    setSelectedBranches((prevState: any) => {
      const found = prevState.find((branch: Branch) => selectedBranch && branch.id === selectedBranch.id);
      if (found) {
        return prevState.filter((branch: Branch) => selectedBranch && branch.id !== selectedBranch.id);
      }
      return [...prevState, { ...selectedBranch }];
    });
  };

  const getSelectedBranchesNames = (): string => {
    const names = selectedBranches.map((br: Branch) => br.name).join(', ');
    if (names.endsWith(', ')) {
      return names.slice(0, -1);
    }
    return names;
  };

  const handleUngroupBranches = async (): Promise<void> => {
    const branchesId = selectedBranches.map((br: Branch) => Number(br.id));
    try {
      const { data: res } = await unMergeBranches(branchesId);
      if (res.status === 200) {
        const branchesFiltered = branchesList.filter((branch: Branch) => !res.data.find((br: Branch) => br.id === branch.id));
        dispatch(
          addModalToList({
            body: 'Desagrupaste las sucursales con éxito.',
            pictureUrl: successImage,
            okButton: {
              buttonText: 'Aceptar',
              onClick: async () => {
                setBranchesList(branchesFiltered);
                setSelectedBranches([]);
                navigate(-1);
              },
            },
          }),
        );
      }
    } catch (error) {
      errorHandlerHelper(error, dispatch);
    }
  };

  const ungroupBranches = (): void => {
    dispatch(
      addModalToList({
        body: `¿Estás seguro que querés desagrupar a las sucursales "${getSelectedBranchesNames()}"?`,
        pictureUrl: successImage,
        okButton: {
          buttonText: 'Aceptar',
          onClick: async () => {
            handleUngroupBranches();
          },
        },
        cancelButton: {
          buttonText: 'Cancelar',
          onClick: async () => {
            dispatch(removeModalFromList());
          },
        },
      }),
    );
  };

  return (
    <div className="w-100 h-100 branch-page">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between mt-3 w-75">
          <Title className="mb-3">Desagrupar Sucursales</Title>
          <CloseIconSVG
            className="close-icon color-blue mt-3 cursor-close"
            height={40}
            width={40}
            onClick={(): void => navigate(-1)}
          />{' '}
        </div>
        <Subtitle className="mt-3">{`Sucursales agrupadas dentro de ${mainBranch?.name}:`}</Subtitle>
      </div>

      <div className="d-flex flex-column justify-content-between align-items-center col-12 col-md-10 col-lg-9 mt-3">
        {branchesList?.length > 0 &&
          branchesList?.map((branch: Branch) => (
            <BranchCardUnmergeAndEditComponent
              key={branch.id}
              branch={branch}
              selectedBranches={selectedBranches}
              setSelectedBranch={handleSelectBranch}
              ungroupTypeAction
              selectable
              showUnlinkIcon
            />
          ))}
        {!branchesList?.length && 'No tenes sucursales'}
      </div>

      <div className="d-flex flex-row ">
        <FormButtonComponent
          className="submit-button mt-4 me-1 w-100"
          disabled={!selectedBranches?.length}
          onClick={(): void => ungroupBranches()}
        >
          Desagrupar
        </FormButtonComponent>
        <FormButtonComponent className="submit-button mt-4 w-100" onClick={(): void => navigate(-1)}>
          Cerrar
        </FormButtonComponent>
      </div>
    </div>
  );
};

export default UngroupBranchPage;
