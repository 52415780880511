import { AxiosPromise } from 'axios';
import { FilterParams } from '../utils/types';
import axios from './axiosInstance';
import axiosV2 from './axiosInstanceV2';

export const getBalance = (params: FilterParams): AxiosPromise => axios.get('/tax/balance', { params });

export const getTaxesClient = (id: number, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/taxes`, { params: { ...params } });

export const getSummaryTaxesByPe = (id: number, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/taxes/summary-by-pe`, { params: { ...params } });

export const getTaxTypes = (id: number, params: FilterParams): AxiosPromise =>
  axiosV2.get(`/tenant/${id}/tax-types-used`, { params: { ...params } });

export const getClientTaxesXLSX = (params: FilterParams, id?: number): AxiosPromise =>
  axiosV2.get(`/business-entities/${id}/tax/generate-report/xlsx`, { params: { ...params } });

export const getTaxesXLSX = (params: FilterParams): AxiosPromise => axios.get('/tax/generate-report/xlsx', { params: { ...params } });

export default { getBalance };
